import { ViewingHistoryItem } from '../../models/viewingHistoryItem';
import { ViewingHistoryError } from '../../services/viewing-history/viewing-history';

export const ActionTypes = {
  SERIES_UVH_REQUEST: 'viewing_history/series_request',
  CATEGORY_UVH_REQUEST: 'viewing_history/category_request',
  ASSET_UVH_REQUEST: 'viewing_history/asset_request',
  REMOVE_ASSET_PROGRESS: 'viewing_history/remove_local_history',
  UVH_ASSET_SUCCESS: 'viewing_history/load_asset_success',
  UVH_SUCCESS: 'viewing_history/load_success',
  UVH_RESET: 'viewing_history/reset',
  UVH_CATEGORY_LOADED: 'viewing_history/category_loaded',
  UVH_ERROR: 'viewing_history/error',
};

export const seriesViewingHistoryRequest = (categoryIds, mainCategoryId) => {
  return {
    type: ActionTypes.SERIES_UVH_REQUEST,
    payload: {
      categoryIds,
      mainCategoryId,
    },
  };
};

export const categoryViewingHistoryRequest = (categoryId) => {
  return {
    type: ActionTypes.CATEGORY_UVH_REQUEST,
    payload: {
      categoryId,
    },
  };
};

export const assetViewingHistoryRequest = (assetId) => {
  return {
    type: ActionTypes.ASSET_UVH_REQUEST,
    payload: {
      assetId,
    },
  };
};

export const categoryLoaded = (mainCategoryId) => {
  return {
    type: ActionTypes.UVH_CATEGORY_LOADED,
    payload: {
      mainCategoryId,
    },
  };
};

export const viewingHistorySuccess = (history: ViewingHistoryItem[]) => ({
  type: ActionTypes.UVH_SUCCESS,
  payload: {
    items: history,
  },
});

export const viewingHistoryAssetSuccess = (historyItem: ViewingHistoryItem) => ({
  type: ActionTypes.UVH_ASSET_SUCCESS,
  payload: {
    historyItem,
  },
});

export const viewingHistoryError = (errorType: ViewingHistoryError) => ({
  type: ActionTypes.UVH_ERROR,
  payload: {
    errorType: errorType,
  },
});

export const viewingHistoryReset = () => ({
  type: ActionTypes.UVH_RESET,
});

export const removeAssetLocalProgress = (assetId) => {
  return {
    type: ActionTypes.REMOVE_ASSET_PROGRESS,
    payload: {
      assetId,
    },
  };
};
