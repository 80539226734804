import React, { FunctionComponent } from 'react';
import { NaviMenuLink } from './NaviMenuLink';
import { useAuth } from '../../services/auth';
import { useSelector } from 'react-redux';
import { selectUserOperator } from '../../state/user/user.selectors';
import { hasUserProfileAccess, useProfileType } from './naviUtils';
import { IconPlay } from 'owlet-ui';
import { MenuIcon } from './styles';

const icon = (
  <MenuIcon>
    <IconPlay />
  </MenuIcon>
);

export const NaviMenuContinue: FunctionComponent = () => {
  const { isAuthenticated } = useAuth();
  const userOperator: string = useSelector(selectUserOperator);
  const profileType = useProfileType();

  if (!isAuthenticated) {
    return null;
  }

  const hasProfileAccess = hasUserProfileAccess(userOperator, profileType);

  return (
    <NaviMenuLink
      path={hasProfileAccess ? '/omatili' : '/jatka'}
      title="Jatka katselua"
      hash={hasProfileAccess ? 'jatka-katselua' : undefined}
      image={icon}
    />
  );
};
