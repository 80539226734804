import subMilliseconds from 'date-fns/subMilliseconds';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

let timeDiffMs: number = 0;

/**
 * Updates the time difference between client and the server
 * @param serverDate
 */
export function setTimeDifference(serverDate: Date): void {
  const clientDate = new Date();
  timeDiffMs = differenceInMilliseconds(clientDate, serverDate);
  console.log(`Time difference with server is ${timeDiffMs}ms`);
}

/**
 * Get the current Date that has been synchronized with the server
 */
export function getCurrentDate(): Date {
  return subMilliseconds(new Date(), timeDiffMs);
}
