export interface Paths extends Array<Path> {}

export enum PathType {
  Curated = 'curated',
  Assets = 'assets',
  Categories = 'categories',
  Channels = 'channels',
  Event = 'event',
  Mixed = 'mixed',
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  All = 'ALL',
}

export interface Path {
  type: PathType;
  path: string;
  visibleUrl: string;
  dataUrl: string;
  localDataUrl?: string;
  subscriptionStatus?: SubscriptionStatus;
  params?:
    | {
        id: number | string;
      }
    | {
        name: string;
      }
    | {
        category: number;
        genre: string;
      }
    | {
        category: number;
        collection: string;
      };
  title?: string;
  description?: string;
}
