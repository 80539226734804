import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IconWarning, RED, SIZE_SMALL } from 'owlet-ui';

const Wrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: var(--navi-height) 0 0;
  padding: 1rem 0;
`;

const NoScriptIcon = styled(IconWarning)`
  width: 2rem;
  display: block;
  fill: ${RED};
  font-size: ${SIZE_SMALL};
  margin-right: 0.5rem;
`;

export const NoScriptWarning: FunctionComponent = () => {
  return (
    <noscript>
      <style>{'.root-progress { display: none !important; }'}</style>
      <Wrap>
        <NoScriptIcon />
        Sivusto vaatii JavaScriptin toimiakseen.
      </Wrap>
    </noscript>
  );
};
