import React from 'react';
import { SVGProps } from '../utils';

const SvgFacebook = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510.2 510.2" {...props}>
    <path d="M378.529 84.492H131.707c-26.035 0-47.217 21.18-47.217 47.215v246.822c0 26.035 21.182 47.217 47.217 47.217h246.822c26.035 0 47.215-21.182 47.215-47.217V131.707c0-26.035-21.18-47.215-47.215-47.215zm20.815 294.037c0 11.479-9.338 20.816-20.814 20.816h-77.031V300.89l35.463.016c3.16 0 6.135-2.652 6.498-5.791l3.98-33.734c.162-1.381-.217-2.668-1.062-3.619s-2.08-1.475-3.475-1.475l-41.525.002c.004-11.158.008-24.758.008-29.893 0-15.211 9.959-25.398 25.371-25.953 3.99-.145 8.678-.215 14.328-.215 2.086 0 2.953.023 4.969.023 2.422 0 3.936-1.109 3.936-4.09 0-8.912-.02-31.303-.02-31.303 0-3.633-1.773-5.031-5.787-5.352-5.02-.4-10.061-.719-15.398-.902-3.596-.123-7.484-.236-11.539-.236-9.35 0-16.498.629-21.855 1.926-14.965 3.617-25.727 11.283-32.904 23.432-5.406 9.16-8.113 19.395-8.277 31.289-.121 8.678-.135 30.066-.133 41.279l-35.727-.01c-1.459 0-2.838.576-3.881 1.619s-1.617 2.422-1.617 3.883l-.01 33.615a5.447 5.447 0 001.613 3.883 5.45 5.45 0 003.879 1.617l35.762-.01.006 98.453H131.707c-11.479 0-20.816-9.338-20.816-20.816V131.707c0-11.477 9.338-20.814 20.816-20.814h246.822c11.477 0 20.814 9.338 20.814 20.814v246.822z" />
  </svg>
);

export default SvgFacebook;
