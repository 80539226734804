import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Category } from '../../models/category';
import { getImageUrl, useTitleImage } from '../../services/images';
import { ImageOrientation, ImageSize } from '../../models/image';
import { StripeImage } from '../stripe/StripeImage';
import { categoryLink } from '../../services/links';
import Link from 'next/link';
import { CategoryTitle, IconLabels, TopLeftLabels, TopRightLabels } from '../stripe/stripes';
import { clampLines, ratio, WHITE, WHITE15 } from 'owlet-ui';
import { CategoryUpcomingBadge } from '../category-upcoming-badge/CategoryUpcomingBadge';
import { selectIsMobileLayout } from '../../state/app/app.selectors';
import classNames from 'classnames';
import { isSpeakFinnish } from '../../services/assets';
import { useContentLabels } from '../../services/labels';
import { SpeakFinLabel } from '../labels/SpeakFinLabel';
import { CuratedCategory } from '../../models/curated';
import { useDetailsFloat } from '../stripe/float/hooks';
import { TitleImageStyle } from '../title-image/TitleImage';
import { GridItemOptions } from '../../services/swr-grid';
import { RatingLabels } from '../labels/RatingLabels';
import { SortType } from '../../models/deferred';
import { buildEDDLMoreinfoEvent, useEDDL } from '../../services/tracking/eddl-analytics';
import { ContentItemContext, ContentSectionContext } from '../../services/content-context';
import { ProductLockLabel } from '../stripe/product-lock/ProductLockLabel';

type GridCategoryProps = {
  category: Category;
  options?: GridItemOptions;
};

const CategoryGridItemContainer = styled.a`
  display: block;
  padding-bottom: ${ratio(4, 2.75)};
  position: relative;
  color: ${WHITE};
  border-radius: 0.25rem;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.6) 100%);
    transition: background 250ms ease;
    border-radius: 0.25rem;
    pointer-events: none;
  }
`;

const CategoryGridItemContent = styled.div`
  opacity: 1;
  position: absolute;
  z-index: 2;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  transition: opacity 250ms ease;
  text-shadow: ${(props) => props.theme.textShadow};
  &.has-rating {
    .text-title {
      display: block;
      max-width: 83%;
    }
  }
`;

const CategoryGridItem = styled.li`
  display: block;
  grid-column: span 2 / auto;
  overflow: hidden;
  position: relative;
  margin: 0 ${(props) => props.theme.stripeHalfMargin} 3rem;
  background: ${WHITE15};
  border-radius: 0.25rem;
`;

const Title = styled(CategoryTitle)`
  ${clampLines(2)}
`;

const StyledSpeakFinLabel = styled(SpeakFinLabel)`
  white-space: nowrap;
`;

export const GridCategory: FunctionComponent<GridCategoryProps> = ({ category, options = {} }: GridCategoryProps) => {
  const isMobile = useSelector(selectIsMobileLayout);
  const { topLeftLabels, topRightLabels, iconLabels } = useContentLabels(category, 'normal');
  const title = useTitleImage(category, category.title, TitleImageStyle.Stripe);
  const { sort } = options;

  const id = `grid-category-${category.id}`;
  const { state, updateState } = useDetailsFloat();

  const curatedItem = useMemo<CuratedCategory>(
    () => ({
      category,
      categoryId: category.id,
    }),
    [category]
  );

  const imageUrl = getImageUrl(category, ImageOrientation.Landscape, ImageSize.Medium);
  const linkData = categoryLink(category);

  const { index } = useContext(ContentItemContext);
  const contentSectionContext = useContext(ContentSectionContext);

  const handleMouseOver = useCallback(() => {
    if (state?.item !== curatedItem) {
      updateState({
        item: curatedItem,
        parentId: id,
        contentContext: {
          ...contentSectionContext,
          index,
        },
        wideParent: true,
      });
    }
  }, [id, state, index, updateState, curatedItem]);

  const handleEDDL = useEDDL(() => buildEDDLMoreinfoEvent({ ...contentSectionContext, index }, category), [
    category,
    index,
    contentSectionContext,
  ]);

  const className = classNames({ mobile: isMobile });

  const hasBottomRightLabel = sort === SortType.Likes || sort === SortType.Imdb;

  return (
    <CategoryGridItem className={className} onMouseOver={handleMouseOver} id={id}>
      <Link href={linkData.href} as={linkData.as} passHref>
        <CategoryGridItemContainer onClick={handleEDDL}>
          <StripeImage url={imageUrl} orientation={ImageOrientation.Landscape} alt={category.title} />
          {topLeftLabels?.length > 0 && <TopLeftLabels>{topLeftLabels}</TopLeftLabels>}
          <TopRightLabels>
            <ProductLockLabel item={category}>
              {isSpeakFinnish(category) && <StyledSpeakFinLabel />}
              {topRightLabels}
            </ProductLockLabel>
          </TopRightLabels>
          {category && <CategoryUpcomingBadge category={category} />}
          <CategoryGridItemContent className={hasBottomRightLabel && 'has-rating'}>
            {iconLabels?.length > 0 && <IconLabels>{iconLabels}</IconLabels>}
            <Title>{title}</Title>
          </CategoryGridItemContent>
          <RatingLabels item={category} ratingType={sort} />
        </CategoryGridItemContainer>
      </Link>
    </CategoryGridItem>
  );
};
