import { User } from '../models/user';
import { Config } from '../models/config';
import { getConfig, getConfigValue } from './conf';
import { getUserProperty } from './properties';
import { OperatorProperties, Operators } from '../models/operator';
import { isSmallOperatorUser } from './operator';
import { useMemo } from 'react';

export function isMTVTermsUser(user: User): boolean {
  const operator = getUserProperty(user, OperatorProperties.Operator);
  const isOperatorNonVAS = Boolean(operator) && operator !== Operators.TeliaVas;

  return isOperatorNonVAS || isSmallOperatorUser(user);
}

export function getTermsPropertyName(user: User): string {
  if (!user) {
    return null;
  }

  const configName: keyof Config = isMTVTermsUser(user) ? 'termsVersion' : 'termsVersionTelia';
  return getConfigValue(configName);
}

export function useTermsPending(user: User): boolean {
  return useMemo<boolean>(() => {
    if (getConfig().termsCheck.enabled && user) {
      const termsPropertyName = getTermsPropertyName(user);
      return Boolean(termsPropertyName) && !getUserProperty(user, termsPropertyName);
    }

    return false;
  }, [user]);
}
