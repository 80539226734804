import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SIZE_TINY, WHITE15, WHITE } from 'owlet-ui';
import { WideContainer } from '../stripe/stripes';

interface SpeakFinLabelProps {
  className?: string;
}

const Wrap = styled.div`
  background: ${WHITE};
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  text-transform: uppercase;
  font-size: ${SIZE_TINY};
  line-height: 1.1;
  color: ${WHITE15};
  position: relative;
  padding: 0.25rem 0.4rem;
  display: grid;
  grid-template-columns: min-content;
  text-shadow: none;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 15%;
    border: 0.3rem solid transparent;
    border-left-color: ${WHITE};
    border-top-color: ${WHITE};
  }

  ${WideContainer} & {
    white-space: nowrap;
  }
`;

export const SpeakFinLabel: FunctionComponent<SpeakFinLabelProps> = ({ className }: SpeakFinLabelProps) => {
  return <Wrap className={className}>Puhumme suomea</Wrap>;
};
