import { createContext } from 'react';
import { ModalOpts } from './models';

export interface ModalContextProps {
  modals: ModalOpts[];
  pushModal: (modal: ModalOpts) => void;
  popModal: (modal?: ModalOpts | string) => void;
  clearModals: () => void;
}

export const ModalContext = createContext<ModalContextProps>({
  modals: [],
  pushModal: () => {},
  popModal: () => {},
  clearModals: () => {},
});
