import includes from 'lodash/includes';
import { CmProduct, PaymentProvider, PaymentType, ProductPayment, ProductStatus } from '../../models/product';
import { getConfig, getVimondApiUrl } from '../conf';
import { fetchJSON } from '../api';
import { AutorenewStatus, Order } from '../../models/order';
import { isAfter } from 'date-fns';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import some from 'lodash/some';
import { getCurrentDate } from '../time';
import { categoryLink, LinkData, movieLink } from '../links';
import { ImageOrientation, ImageSize } from '../../models/image';
import { fetchCategoryAssets, fetchCategoryById } from '../categories';
import { Category, CategoryType } from '../../models/category';
import { getImageUrl } from '../images';
import { getOrderConversions } from './order-conversions';
import { Asset } from '../../models/asset';
import { formatPeriodLength, secondsToDays } from '../format/date';

import * as ProductsService from './products'; // Required for testing

export function findProductPaymentByProviderId(product: CmProduct, providerId: PaymentProvider): ProductPayment {
  if (!product?.product?.productPayments || !providerId) {
    return null;
  }

  return product.product.productPayments.find((pp) => pp.paymentProviderId === providerId);
}

export function getCreditCardPayment(product: CmProduct): ProductPayment {
  let creditCardPayment = null;

  if (product?.product?.productPayments) {
    creditCardPayment = findProductPaymentByProviderId(product, PaymentProvider.CreditCard);

    // TEST -> PROD PROVIDER
    const { env } = getConfig();
    if (!creditCardPayment && (env === 'dev' || env === 'test')) {
      creditCardPayment = findProductPaymentByProviderId(product, PaymentProvider.CreditCardProd);
    }
  }

  if (!creditCardPayment) {
    console.error(
      `Credit card payment method not found (product ${product?.product?.productGroupId}/${product?.product?.id})`
    );
    throw new Error('Credit card payment method not found');
  }

  return creditCardPayment;
}

export function getBankPayment(product: CmProduct): ProductPayment {
  return findProductPaymentByProviderId(product, PaymentProvider.Bank);
}

export function isSubscriptionProduct(product: CmProduct): boolean {
  return Boolean(product && product.product.paymentPlan.paymentType === PaymentType.Subscription);
}

export function isSinglePeriodProduct(product: CmProduct): boolean {
  return Boolean(product && product.product.paymentPlan.paymentType === PaymentType.SinglePeriod);
}

export function isProductEnabled({ product: { enabled, productStatus } }: CmProduct): boolean {
  return enabled && productStatus === ProductStatus.Enabled;
}

export function isProductHidden({ product: { enabled, productStatus } }: CmProduct): boolean {
  return enabled && productStatus === ProductStatus.Hidden;
}

export function isInitPriceFree(product: CmProduct): boolean {
  let payment = findProductPaymentByProviderId(product, PaymentProvider.CreditCard);

  if (!payment) {
    payment = product?.product?.productPayments?.[0];
  }

  return payment?.initPrice === 0;
}

export function isTrialPeriodProduct(productGroupId: number | string, productId: number | string): boolean {
  return includes(['471/876'], `${productGroupId}/${productId}`);
}

export function getFreePeriodString(product: CmProduct): string {
  if (product && product.product.productPayments) {
    const freePayment = product.product.productPayments.find((pp) => isNumber(pp.initPrice) && pp.initPrice <= 1);

    if (freePayment) {
      return formatPeriodLength(secondsToDays(freePayment.initPeriod));
    }
  }

  return null;
}

export function findProductById(productId: number | string, cmProducts: CmProduct[]): CmProduct {
  if (!cmProducts || isNil(productId)) {
    return null;
  }

  return cmProducts.find(({ product }) => product.id === Number(productId));
}

export function findProductForOrder(order: Order, products: CmProduct[]): CmProduct {
  if (!order || !products) {
    return null;
  }

  return products.find(
    ({ product }) => product.id === order.productId && product.productGroupId === order.productGroupId
  );
}

/**
 * Find alternative product based on the order history.
 *
 * @param product
 * @param allProducts
 * @param activeOrders
 * @param futureOrders
 * @param historyOrders
 */
export function findAlternativeProduct(
  product: CmProduct,
  allProducts: CmProduct[],
  activeOrders: Order[],
  futureOrders: Order[],
  historyOrders: Order[]
): CmProduct {
  // If user doesn't have any orders, there is no need to check any further
  if (
    !activeOrders ||
    !futureOrders ||
    !historyOrders ||
    (activeOrders.length === 0 && futureOrders.length === 0 && historyOrders.length === 0)
  ) {
    return null;
  }

  const {
    product: { id: productId, productGroupId },
  } = product;

  // Don't allow purchasing trial products again
  if (
    ProductsService.isTrialPeriodProduct(productGroupId, productId) &&
    !isTrialPeriodProductAvailable(activeOrders, futureOrders, historyOrders)
  ) {
    return ProductsService.getAlternativeProduct(product, allProducts);
  }

  return null;
}

// There are certain trial products that are only available if:
// * there are no active or future orders
// * there are no expired orders in history
export function isTrialPeriodProductAvailable(
  activeOrders: Order[],
  futureOrders: Order[],
  historyOrders: Order[]
): boolean {
  const now = getCurrentDate();
  const activeAndFutureOrders = [...activeOrders, ...futureOrders];

  const hasExpiredHistoryOrders = historyOrders.some((order) => isAfter(now, new Date(order.endDate)));

  const hasNonStoppedOrders = activeAndFutureOrders.some(
    (order) =>
      (order.autorenewStatus === AutorenewStatus.Active || order.autorenewStatus === AutorenewStatus.Stopped) &&
      isAfter(new Date(order.accessEndDate), now)
  );

  return !hasExpiredHistoryOrders && !hasNonStoppedOrders;
}

/**
 * Get first possible alternative product, regardless of order history.
 *
 * @param product
 * @param allProducts
 */
export function getAlternativeProduct(product: CmProduct, allProducts: CmProduct[]): CmProduct | null {
  const {
    product: { id: productId, productGroupId, price },
  } = product;

  const alternative = allProducts
    // matches group
    .filter((p) => p.product.productGroupId === productGroupId)
    // is available
    .filter((p) => isProductEnabled(p) || isProductHidden(p))
    // is not the same product
    .filter((p) => p.product.id !== productId)
    // matches price
    .filter(({ product: { productPayments } }) => productPayments && productPayments.length > 0)
    .find(({ product: { productPayments } }) =>
      productPayments.some(
        (payment) => payment.paymentProviderId === PaymentProvider.CreditCard && payment.initPrice === price
      )
    );

  if (alternative) {
    return alternative;
  }

  return null;
}

export function isOpCampaignProductId(productId: number): boolean {
  if (!productId) {
    return false;
  }

  return productId === 1194 || productId === 1616;
}

export function hasOpCampaignProduct(orders: Order[]): boolean {
  if (!orders) {
    return false;
  }

  return orders.some((o) => isOpCampaignProductId(o.productId));
}

export function isProductChangeDisabled(product: CmProduct): boolean {
  if (!product) {
    return false;
  }

  const {
    product: { id: productId },
  } = product;
  return some(getConfig().products.changeDisabledNotification, { productId });
}

export function isValueProductId(productId: number): boolean {
  if (!productId) {
    return false;
  }

  return includes(getConfig().products.valueProducts, productId);
}

export interface PPVDetails {
  category: Category;
  linkData: LinkData;
  image: { url: string; orientation: ImageOrientation };
}

export async function getPPVDetails(product: CmProduct): Promise<PPVDetails> {
  if (product) {
    const { categories } =
      (await fetchJSON(`${getVimondApiUrl()}/api/web/productgroup/${product.product.productGroupId}/categories`, {
        throwErrors: false,
      })) || {};

    if (categories?.length === 1) {
      const category = await fetchCategoryById(categories[0].id);

      if (category) {
        if (category.type === CategoryType.Movie) {
          const assets = await fetchCategoryAssets(category.id);

          if (assets?.length > 0) {
            return {
              category,
              linkData: movieLink(assets[0]),
              image: {
                url: getImageUrl(assets[0], ImageOrientation.Portrait, ImageSize.Medium),
                orientation: ImageOrientation.Portrait,
              },
            };
          }
        } else {
          return {
            category,
            linkData: categoryLink(category),
            image: {
              url: getImageUrl(category, ImageOrientation.Landscape, ImageSize.Medium),
              orientation: ImageOrientation.Landscape,
            },
          };
        }
      }
    } else if (categories?.length > 1) {
      // PPV contains multiple categories, just return null values (this is applicable to Henkilökuntaetu)
      return {
        category: null,
        linkData: null,
        image: null,
      };
    }
  }

  return null;
}

export function isDigitaProductGroupId(productGroupId: number | string): boolean {
  const groupId = productGroupId ? `${productGroupId}` : null;

  if (!groupId) {
    return false;
  }

  const { CmoreDigita, SportDigita, TotalDigita } = getConfig().products.productGroupIds;

  return groupId === `${CmoreDigita}` || groupId === `${SportDigita}` || groupId === `${TotalDigita}`;
}

/**
 * Returns true if `product` is a downgrade of `currentProduct`
 *
 * @param product
 * @param currentProduct
 */
export function isDowngrade(product: CmProduct, currentProduct: CmProduct): boolean {
  if (!product || !currentProduct) {
    return false;
  }

  const conversions = getOrderConversions(currentProduct.product.productGroupId);

  return includes(conversions?.downgrade, product.product.productGroupId);
}

export function filterProductsByContext(products: CmProduct[], context: Asset | Category): CmProduct[] {
  if (!products || !context) {
    return [];
  }

  return products.filter(({ product }) => context.productGroups.some((group) => group.id === product.productGroupId));
}

/**
 * Returns true if product is a subscription product with payment period longer than the standard 30 days, probably
 * usually a yearly product.
 *
 * @param product
 */
export function isLongPaymentPeriodProduct(product: CmProduct): boolean {
  const period = product?.product?.paymentPlan?.period;

  return isSubscriptionProduct(product) && secondsToDays(period) > 30;
}
