import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import { createGlobalStyle, keyframes, ThemeProps } from 'styled-components';
import { OwletTheme, FONT_MEDIUM, FONT_REGULAR, WHITE, WHITE05, WHITE10, TELIAPURPLE } from 'owlet-ui';
import { greaterThan } from 'owlet-ui/breakpoints';

export const FAMILY_BOLD_ALT = "'QuattroNewsBold', Arial, Helvetica, sans-serif";
export const FONT_BOLD_ALT = `normal 700 1rem ${FAMILY_BOLD_ALT}`;

const animatedBackground = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100vw 0;
  }
`;

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'QuattroNewsBold';
    src: url("https://static.katsomo.fi/fonts/QuattroNewsBold.eot");
    src: url("https://static.katsomo.fi/fonts/QuattroNewsBold.eot?#iefix") format("embedded-opentype"),
         url("https://static.katsomo.fi/fonts/QuattroNewsBold.woff2") format("woff2"),
         url("https://static.katsomo.fi/fonts/QuattroNewsBold.woff") format("woff"),
         url("https://static.katsomo.fi/fonts/QuattroNewsBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }

  html {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    font: ${FONT_REGULAR};
    color: ${WHITE};
    background: ${(props: ThemeProps<OwletTheme>) => props.theme.colors.background};
    overscroll-behavior-x: none;
  }

  #__next {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    line-height: 1.3;
  }

  a {
    color: ${WHITE};
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font: ${FONT_MEDIUM};
    font-size: inherit;
  }

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${TELIAPURPLE};
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }

  /* Prevent Chrome messing up images that have width:auto */
  img {
    aspect-ratio: auto;
  }

  .ab-testing-hide {
    display: none !important;
  }

  .anim-ph, .anim-ph-before::before, .anim-ph-after::after {
    background: ${WHITE10};
    background-image: linear-gradient(90deg, ${WHITE10} 0%, ${WHITE05} 50%, ${WHITE10} 100%);
    background-position: 0 0;
    background-repeat: repeat;
    animation: 6s ${animatedBackground} linear infinite;
    @media (prefers-reduced-motion) {
      animation-play-state: paused;
    }
  }

  .pause-animations {
    &:before, .anim-ph, .anim-ph-before::before, .anim-ph-after::after {
      animation-play-state: paused;
    }
  }

  :root {
    --vh: 1vh; /* LayoutInit sets this in mobile browsers */
    --navi-height: ${(props: ThemeProps<OwletTheme>) => props.theme.naviHeightSmall};

    ${greaterThan('large')`
      --navi-height: ${(props: ThemeProps<OwletTheme>) => props.theme.naviHeight};
    `}

    --subnavi-height: 0; /* Subnavi should update this when visible */
  }
`;
