import { NextWebVitalsMetric } from 'next/app';

export enum TrackingEvents {
  OperatorLogin = 'operator:login', // Successful operator login
  CancelCurated = 'cancel:curated', // Curated content shown in cancel flow
  CancelDone = 'cancel:done', // Order cancelled successfully
  PurchaseSwitchCurated = 'purchase:switch:curated', // Curated content shown when switching product
  PurchaseProductView = 'purchase:productView', // Non-authenticated user in purchase flow
  PurchaseConfirmation = 'purchase:confirmation', // Authenticated user in purchase flow
  PurchaseCheckout = 'purchase:checkout', // Order submitted in purchase flow
  PurchaseDone = 'purchase:done', // New purchase successful
  SignupDone = 'signup:done', // Successful user registration
  SignupError = 'signup:error', // Error in user registration
  SurveyDismiss = 'survey:dismiss', // User closes survey without answer
  SurveyAnswer = 'survey:answer', // User answers survey
}

export enum TrackingEventHandlerType {
  PageView = 'pageView',
  CustomEvent = 'customEvent',
}

export interface Tracker {
  name: string;
  [TrackingEventHandlerType.PageView]: TrackingEventHandler;
  [TrackingEventHandlerType.CustomEvent]: TrackingEventHandler;
  initialize: () => boolean | Promise<boolean>;
  loaded?: boolean;
  include?: Array<string | RegExp>;
  exclude?: Array<string | RegExp>;
}

export interface TrackingEvent<T = TrackingEventData> {
  id: string;
  handler: TrackingEventHandlerType;
  timestamp: number;
  label: string;
  url: string;
  data?: T;
}

export interface TrackingEventData {
  userId?: number | string;
  profileId?: string;
  orders?: Array<{ productId: number; productGroupId: number }>;
  [key: string]: any;
}

export type PerformanceTrackingEventData = TrackingEventData & {
  performance: NextWebVitalsMetric;
};

export type TrackingEventHandler<T = TrackingEventData> = (event: TrackingEvent<T>) => void;

export interface UserContext {
  userId?: number;
  profileId?: string;
  orders?: Array<{ productId: number; productGroupId: number }>;
}
