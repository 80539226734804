import React, { FunctionComponent } from 'react';
import { ProfileType, useProfileType } from './naviUtils';
import { NaviMenuLink } from './NaviMenuLink';
import { IconCalendar } from 'owlet-ui';
import { MenuStrokeIcon } from './styles';

const icon = (
  <MenuStrokeIcon>
    <IconCalendar />
  </MenuStrokeIcon>
);

export const NaviMenuComingSoon: FunctionComponent = () => {
  const profileType = useProfileType();

  if (profileType === ProfileType.Junior) {
    return null;
  }

  return <NaviMenuLink path="/tulossa" title="Tulossa" image={icon} />;
};
