import React, { FunctionComponent, PropsWithChildren } from 'react';

type ExternalLinkProps = Omit<JSX.IntrinsicElements['a'], 'target' | 'rel'>;

export const ExternalLink: FunctionComponent<PropsWithChildren<ExternalLinkProps>> = ({
  children,
  ...props
}: PropsWithChildren<ExternalLinkProps>) => {
  return (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};
