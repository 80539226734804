import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IconLink } from './styles';
import { requestSession } from '../../services/chromecast';
import { IconChromecastOn, IconChromecast } from 'owlet-ui';
import { useAuth } from '../../services/auth';
import {
  selectChromecastReceiverAvailability,
  selectChromecastSessionAvailability,
} from '../../state/player/player.selectors';

interface NaviChromecastProps {}

export const NaviChromecast: FunctionComponent<NaviChromecastProps> = () => {
  const { isAuthenticated } = useAuth();
  const chromecastAvailable = useSelector(selectChromecastReceiverAvailability);
  const chromecastSession = useSelector(selectChromecastSessionAvailability);
  const handleClick = useCallback(() => {
    requestSession();
  }, []);

  if (!chromecastAvailable || !isAuthenticated) {
    return null;
  }
  return (
    <IconLink onClick={handleClick} key="chromecast">
      {chromecastSession ? <IconChromecastOn /> : <IconChromecast />}
    </IconLink>
  );
};
