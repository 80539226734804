import React, { createContext, FunctionComponent, RefObject, useContext, useMemo, useState } from 'react';
import startsWith from 'lodash/startsWith';
import { Target } from '../models/curated';
import { CmProduct } from '../models/product';
import { useDispatch, useSelector } from 'react-redux';
import { selectValidProducts } from '../state/products/products.selectors';
import { useShoppingCart } from './products/purchase-flow';
import { usePathRouter } from './router/router';
import { selectPaths } from '../state/app/app.selectors';
import replace from 'lodash/replace';
import { getOrigin } from './api';
import toArray from 'lodash/toArray';
import { AnyHTMLElement, findNextElement } from 'owlet-ui';
import result from 'lodash/result';
import { purchaseSetStartingPoint } from '../state/purchase/purchase.actions';
import { Paths } from '../models/paths';
import { findEventPathByCategoryId, LinkData, PathPrefixes, searchLink, WebPathData } from './links';
import { findProductById } from './products/products';

const REGEX_MOVIE_TARGET = /^\/asset\/(\d+)\/?$/;
const REGEX_CATEGORY_TARGET = /^\/category\/(\d+)\/?$/;
const REGEX_GROUP_TARGET = /^\/category\/(\d+)\/group\/(\d+)\/?$/;
const REGEX_ASSET_TARGET = /^\/category\/(\d+)\/asset\/(\d+)\/?$/;
const REGEX_PLAY_TARGET = /\/play\/(\d+)\/?$/;
const REGEX_COLLECTION_TARGET = /\/collection\/(.+)\/?$/;

export function isSameOrigin(url: string): boolean {
  return startsWith(url, getOrigin());
}

export function isAbsolutePath(path: string): boolean {
  return path && !!path.match(/^https?:\/\//);
}

// Checks if the given path is an absolute url that points outside the current host
export function isExternalAbsolutePath(path: string): boolean {
  return isAbsolutePath(path) && !isSameOrigin(path);
}

export function isWebPath(path: string): boolean {
  return startsWith(path, PathPrefixes.External) || isAbsolutePath(path);
}

export function getWebPathData(path: string): WebPathData | null {
  if (startsWith(path, PathPrefixes.External)) {
    const params = new URLSearchParams(path.substring(PathPrefixes.External.length + 1));
    return {
      url: params.get('url'),
      style: params.get('style'),
    };
  }
  if (isAbsolutePath(path)) {
    return {
      url: path,
      style: 'popup',
    };
  }
  return null;
}

// Path that has to be converted into another path for it to be valid in web
// Example: anything starting with /category
export function isTargetPath(path: string): boolean {
  return !![
    PathPrefixes.Search,
    PathPrefixes.Category,
    PathPrefixes.Asset,
    PathPrefixes.Favorites,
    PathPrefixes.History,
  ].find((prefix) => startsWith(path, prefix));
}

export function isCategoryTargetPath(path: string): boolean {
  return path && !!path.match(REGEX_CATEGORY_TARGET);
}

export function isGroupTargetPath(path: string): boolean {
  return path && !!path.match(REGEX_GROUP_TARGET);
}

export function isAssetTargetPath(path: string): boolean {
  return path && !!path.match(REGEX_ASSET_TARGET);
}

export function isMovieTargetPath(path: string): boolean {
  return path && !!path.match(REGEX_MOVIE_TARGET);
}

// Converts target paths to real working and routable paths
export function convertTargetPathToRoutable(path: string, paths: Paths): LinkData {
  if (paths && isAssetTargetPath(path)) {
    const [, categoryId, assetId] = path.match(REGEX_ASSET_TARGET);
    return { href: '/ohjelma/[...path]', as: `/ohjelma/${categoryId}/jakso/${assetId}` };
  }

  if (paths && isGroupTargetPath(path)) {
    // Resolve /category/X/group/Y to /ohjelma/Y
    const [, categoryId, groupId] = path.match(REGEX_GROUP_TARGET);

    const event = findEventPathByCategoryId(categoryId, paths);
    const categorySlug = event?.visibleUrl || '';

    return { href: '/ohjelma/[...path]', as: `/ohjelma/${groupId || categoryId}${categorySlug}` };
  }

  if (paths && isCategoryTargetPath(path)) {
    const [, categoryId] = path.match(REGEX_CATEGORY_TARGET);
    return { href: '/ohjelma/[...path]', as: `/ohjelma/${categoryId}` };
  }

  if (paths && isMovieTargetPath(path)) {
    const [, assetId] = path.match(REGEX_MOVIE_TARGET);
    return { href: '/elokuva/[id]', as: `/elokuva/${assetId}` };
  }

  if (startsWith(path, PathPrefixes.Collection)) {
    const [, slug] = path.match(REGEX_COLLECTION_TARGET);
    return { href: '/poiminnat/[slug]', as: `/poiminnat/${slug}` };
  }

  if (startsWith(path, PathPrefixes.Search)) {
    return searchLink(path);
  }

  if (startsWith(path, PathPrefixes.Favorites)) {
    const url = '/suosikit';
    return { href: url, as: url };
  }

  if (startsWith(path, PathPrefixes.History)) {
    const url = '/jatka';
    return { href: url, as: url };
  }

  if (startsWith(path, PathPrefixes.Play)) {
    const [, assetId] = path.match(REGEX_PLAY_TARGET);
    return { href: '/katso/[id]/[[...slug]]', as: `/katso/${assetId}` };
  }

  return { href: path, as: path };
}

export interface UseTargetLinkHandler {
  handleTargetClick: (target: Target, elementRef?: RefObject<AnyHTMLElement>) => Promise<boolean>;
  convertToLinkData: (target: Target) => LinkData;
}

export function useTargetLinkHandler(): UseTargetLinkHandler {
  const validProducts: CmProduct[] = useSelector(selectValidProducts);
  const { openPurchaseFlowWithProduct } = useShoppingCart();
  const router = usePathRouter();
  const dispatch = useDispatch();
  const paths = useSelector(selectPaths);
  const { setProduct } = useContext(TargetLinkContext);

  const getNavigablePath = (target: Target): string => {
    const originlessPath = replace(target.path, getOrigin(), '');

    if (startsWith(originlessPath, '/')) {
      return originlessPath;
    } else if (/^https?:/.test(originlessPath)) {
      return null;
    }

    return `/${originlessPath}`;
  };

  const isExternal = (path: string): boolean => startsWith(path, PathPrefixes.External);

  const isFragment = (path: string): boolean => startsWith(path, '#');

  const isRegistrationPath = (path: string): boolean =>
    startsWith(path, PathPrefixes.Register) || startsWith(path, PathPrefixes.PurchaseFlow);

  const handleTargetClick = async (target: Target, elementRef?: RefObject<AnyHTMLElement>): Promise<boolean> => {
    if (!target) {
      return false;
    }

    const { path, productGroupId, productId } = target;

    if (isExternal(path)) {
      const { url } = getWebPathData(path);
      window.open(url, '_blank');
      return;
    }

    if (isFragment(path)) {
      // if the action target contains product information we want to scroll to the product,
      // otherwise just use the actual anchor from action.path
      const selector = productGroupId && productId ? `#product-${productGroupId}` : path;
      const elems = document.querySelectorAll(selector);

      let elem;

      if (elems.length > 0) {
        // Fix case where there is both product table and cards in dom (one hidden via Adobe Target)
        const visibleElements = toArray(elems).filter((elem: HTMLElement) => {
          return elem?.offsetParent; // This is null if element is inside element that has display:none
        });

        if (visibleElements.length === 1 || !elementRef.current) {
          elem = visibleElements[0];
        } else if (elementRef.current) {
          const nextEl = findNextElement(elems, elementRef.current);
          elem = nextEl || elems[0];
        }
      }

      if (elem) {
        // Set product in the context so that the target component (e.g. CuratedProductTable) can act upon it
        const product = findProductById(productId, validProducts);
        setProduct(product);

        result(elem, 'scrollIntoView');
        return true;
      }

      console.error('No element found with selector', selector);
      return false;
    }

    const navigablePath = getNavigablePath(target);

    if (isRegistrationPath(navigablePath) && productId && productGroupId) {
      const product = findProductById(productId, validProducts);

      if (product) {
        dispatch(purchaseSetStartingPoint({ href: router.pathname, as: router.asPath }));
        openPurchaseFlowWithProduct(product);
        return true;
      } else {
        console.error(`Product configured but not found (${productGroupId}/${productId})`);
      }
    }

    if (navigablePath) {
      const linkData = convertTargetPathToRoutable(navigablePath, paths);

      router.nextPush(linkData.href, linkData.as);
      return true;
    }

    if (isAbsolutePath(path)) {
      window.location.href = path;
      return true;
    }

    return false;
  };

  const convertToLinkData = (target: Target): LinkData => {
    if (!target || isFragment(target.path) || isExternal(target.path)) {
      return null;
    }

    const navigablePath = getNavigablePath(target);

    if (!navigablePath || isRegistrationPath(navigablePath)) {
      return null;
    }

    return convertTargetPathToRoutable(navigablePath, paths);
  };

  return { handleTargetClick, convertToLinkData };
}

export interface TargetLinkContextProps {
  product: CmProduct;
  setProduct: (product: CmProduct) => void;
}

export const TargetLinkContext = createContext<TargetLinkContextProps>({
  product: null,
  setProduct: () => {},
});

export const TargetLinkContextProvider: FunctionComponent = ({ children }) => {
  const [product, setProduct] = useState<CmProduct>();

  const contextValue = useMemo<TargetLinkContextProps>(
    () => ({
      product,
      setProduct,
    }),
    [product]
  );

  return <TargetLinkContext.Provider value={contextValue}>{children}</TargetLinkContext.Provider>;
};
