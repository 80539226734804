import produce from 'immer';
import { ActionTypes } from './app.actions';
import { Tree } from '../../models/tree';
import { Paths } from '../../models/paths';
import { MetatagValues } from '../../models/metatag';
import { defaultMetatags } from '../../services/seo';
import { getOrigin } from '../../services/api';

export enum ChatStatuses {
  notLoaded = 'notLoaded',
  closed = 'closed',
  opened = 'opened',
  started = 'started',
  suspended = 'suspended',
  ended = 'ended',
}

export enum PathsLoadedState {
  NotLoaded = 'notLoaded',
  Loaded = 'loaded',
  Error = 'error',
}

export interface AppState {
  tree: Tree;
  paths: Paths;
  lastRoute: string;
  title: string;
  metatags: MetatagValues;
  canonicalUrl: string;
  chatStatus: ChatStatuses;
  isMobileLayout: boolean;
  pathsLoadedState: PathsLoadedState;
  isActive: boolean;
  // Date when the document event 'visibilitychange' fired with document.visibilityState !== 'visible'
  invisibilityStart: Date | null;
}

export const initialAppState: AppState = {
  tree: [],
  paths: [],
  lastRoute: '',
  title: 'C More | Koukussa draamaan',
  metatags: defaultMetatags,
  canonicalUrl: null,
  chatStatus: ChatStatuses.notLoaded,
  isMobileLayout: null,
  pathsLoadedState: PathsLoadedState.NotLoaded,
  isActive: true,
  invisibilityStart: null,
};

const appReducer = (state = initialAppState, action: any) => {
  switch (action.type) {
    case ActionTypes.TREE_SUCCESS:
      return produce(state, (draft) => {
        draft.tree = action.payload;
      });
    case ActionTypes.TREE_FAIL:
      return produce(state, (draft) => {
        draft.tree = [];
      });
    case ActionTypes.PATHS_SUCCESS:
      return produce(state, (draft) => {
        draft.paths = action.payload;
        draft.pathsLoadedState = PathsLoadedState.Loaded;
      });
    case ActionTypes.PATHS_FAIL:
      return produce(state, (draft) => {
        draft.paths = [];
        draft.pathsLoadedState = PathsLoadedState.Error;
      });
    case ActionTypes.ROUTE_CHANGE:
      return produce(state, (draft) => {
        draft.lastRoute = action.payload;
      });
    case ActionTypes.TITLE_UPDATE:
      return produce(state, (draft) => {
        draft.title = action.payload || initialAppState.title;
      });
    case ActionTypes.TITLE_RESET:
      return produce(state, (draft) => {
        draft.title = initialAppState.title;
      });
    case ActionTypes.METATAG_UPDATE:
      return produce(state, (draft) => {
        draft.metatags = { ...defaultMetatags, ...action.payload };
      });
    case ActionTypes.METATAG_RESET:
      return produce(state, (draft) => {
        draft.metatags = defaultMetatags;
      });
    case ActionTypes.CANONICAL_URL_UPDATE:
      return produce(state, (draft) => {
        const canonicalUrl = action.payload;

        if (canonicalUrl) {
          draft.canonicalUrl = canonicalUrl.startsWith('http') ? canonicalUrl : `${getOrigin()}${canonicalUrl}`;
        } else {
          draft.canonicalUrl = null;
        }
      });
    case ActionTypes.CANONICAL_URL_RESET:
      return produce(state, (draft) => {
        draft.canonicalUrl = null;
      });
    case ActionTypes.SET_CHAT_STATUS:
      return produce(state, (draft) => {
        draft.chatStatus = action.payload;
      });
    case ActionTypes.SET_MOBILE_LAYOUT:
      return produce(state, (draft) => {
        draft.isMobileLayout = action.payload;
      });
    case ActionTypes.SET_ACTIVE:
      return produce(state, (draft) => {
        draft.isActive = action.payload.isActive;
      });
    case ActionTypes.SET_INVISIBILITY_START:
      return produce(state, (draft) => {
        draft.invisibilityStart = action.payload.date;
      });
    default:
      return state;
  }
};

export default appReducer;
