import { FeedbackState } from './feedback.reducers';
import { createSelector } from 'reselect';
import { FeedbackMessage } from '../../models/feedback';

export const selectFeedbackState = () => (state: any): FeedbackState => state.feedback;

export const selectFeedbackMessages = createSelector(selectFeedbackState(), (feedbackState: FeedbackState) => {
  return feedbackState.messages;
});

export const selectFeedbackMessagesWithNavigationClose = createSelector(
  selectFeedbackMessages,
  (messages: FeedbackMessage[]) => {
    return messages.filter((message) => message.closeOnNavigation !== false);
  }
);
