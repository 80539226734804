import { Asset, AssetType } from '../models/asset';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isObject from 'lodash/isObject';
import some from 'lodash/some';
import filter from 'lodash/filter';
import { addSeconds, differenceInSeconds, isBefore } from 'date-fns';

import { ContentTag } from '../models/content_tag';
import { Category } from '../models/category';
import { EpgItem } from '../models/channel';
import { User } from '../models/user';
import { Order } from '../models/order';
import { Notification, PersonalNotification } from 'models/notification';

import { getConfig, getMotherbirdApiUrl, getVimondApiUrl } from './conf';
import { fetchJSON } from './api';
import { isOrderAccessible } from './orders';
import { AccessType } from '../models/product';
import { getCurrentDate } from './time';
import { Subtitle } from '../models/subtitle';
import { CuratedAsset } from '../models/curated';
import isString from 'lodash/isString';
import { assertNumericId } from './utils';
import { Config } from '../models/config';

export async function fetchAssetById(assetId: string | number): Promise<Asset> {
  assertNumericId(assetId);

  const url = new URL(`${getMotherbirdApiUrl()}/v3/svod/web/asset/${assetId}`);
  return await fetchJSON<Asset>(url.toString(), { throwErrors: true });
}

/* Fetches next asset (episode) by asset id. If there's no next episode, the API gives 404 error. As this is expected
 * to happen quite often, we choose not to handle errors here. If there's no next episode, fetchJSON returns null.
 */
export async function fetchNextAssetById(assetId: string | number, includeUpcoming: boolean = true): Promise<Asset> {
  const url = new URL(`${getMotherbirdApiUrl()}/v3/svod/web/asset/${assetId}/next`);
  return await fetchJSON<Asset>(url.toString(), { throwErrors: true, params: { includeUpcoming } });
}

export async function getSubtitles(
  assetId: number | string,
  subtitleFormat: keyof Config['video']['subtitles']['contentTypes']
): Promise<Subtitle[]> {
  try {
    const url = `${getVimondApiUrl()}/api/web/asset/${assetId}/subtitles`;
    const assetSubtitles: Subtitle[] = await fetchJSON(url.toString(), { throwErrors: true });
    return filter(assetSubtitles, (subtitle: Subtitle) => {
      return subtitle.contentType === getConfig().video.subtitles.contentTypes[subtitleFormat];
    });
  } catch (err) {
    console.error('Error fetching subtitles: ', err);
  }
}

export function isAsset(item: any): boolean {
  return Boolean(item && item.id && item.categoryId && item.title && item.type);
}

export function isValid(asset: Asset): boolean {
  if (!asset) {
    return false;
  }

  return !isUpcoming(asset) && !isExpired(asset);
}

export function isLive(asset: Asset): boolean {
  const contentTags = get(asset, 'contentTags', []);

  return asset && includes(contentTags, ContentTag.Live) && isOngoing(asset);
}

export function isUpcoming(asset: Asset): boolean {
  if (!isAsset(asset)) {
    return false;
  }

  const liveBroadcastTime = get(asset, 'liveBroadcastTime');
  const start = new Date(liveBroadcastTime);
  const now = getCurrentDate();

  return !liveBroadcastTime || isBefore(now, start);
}

export function isSpeakFinnish(item: Asset | Category): boolean {
  if (!item || !item.audioLanguage) {
    return false;
  }
  return includes(item.audioLanguage, 'fin') && item.isChildren;
}

export function isMulticam(asset: Asset): boolean {
  if (asset.type !== AssetType.Sport) {
    return false;
  }

  return includes(asset.contentTags, ContentTag.HasRelated) && (isLive(asset) || isUpcoming(asset));
}

/**
 * Returns current progress based on epgLiveBroadcastTime (scale 0-1)
 */
export function getCurrentProgress(assetLike: Asset | EpgItem): number {
  if (assetLike.duration > 0 && assetLike.epgLiveBroadcastTime) {
    const diff = differenceInSeconds(getCurrentDate(), new Date(assetLike.epgLiveBroadcastTime));
    return Math.max(0, Math.min(1, diff / assetLike.duration));
  }

  return 0;
}

/**
 * @deprecated Use `useSelector(selectIsHvodUser)` instead
 * @param orders
 */
export function isHvodSubscriber(orders: Order[]): boolean {
  const { Hvod } = getConfig().products.productGroupIds;
  return some(orders, (order) => order.productGroupId === Hvod);
}

export function canUserPlay(asset: Asset, user: User, isGeoblocked: boolean, orders: Order[]): boolean {
  if (isExpired(asset)) {
    return false;
  }

  if (!isAired(asset)) {
    return false;
  }

  if (isGeoblocked) {
    return false;
  }

  return canUserAccess(asset, user, orders);
}

export function canUserAccess(context: Asset | Category, user: User, currentOrders: Order[]) {
  if (!isObject(context)) {
    return false;
  }

  const hasProductGroups = context.productGroups && context.productGroups.length > 0;
  const isFreeToWatch =
    hasProductGroups &&
    some(context.productGroups, (group) => {
      return group.accessType === AccessType.FreeNoLogin;
    });
  const isFreeToWatchWithLogin =
    hasProductGroups &&
    some(context.productGroups, (group) => {
      return group.accessType === AccessType.FreeLogin;
    });

  // Check if user has a currently active order in one of the context item's product groups
  const accessibleWithOrder =
    hasProductGroups &&
    some(
      currentOrders,
      (order) => isOrderAccessible(order) && some(context.productGroups, (group) => group.id === order.productGroupId)
    );

  // If context has no product groups, assume it's free to watch
  if (isFreeToWatch || !hasProductGroups) {
    return true;
  }

  if (!user) {
    return false;
  } else {
    return !!(accessibleWithOrder || isFreeToWatchWithLogin);
  }
}

export function isExpired(asset: Asset): boolean {
  const expireDate = get(asset, 'expireDate');
  const end = new Date(expireDate);
  const now = getCurrentDate();

  return expireDate ? isBefore(end, now) : false;
}

export function isOngoing(asset: Asset): boolean {
  const liveBroadcastTime = get(asset, 'liveBroadcastTime');
  const duration = get(asset, 'duration');
  const now = getCurrentDate();
  const start = new Date(liveBroadcastTime);
  const end = addSeconds(start, duration);

  return liveBroadcastTime && duration && isBefore(start, now) && isBefore(now, end);
}

export function isFreeToPlayWithoutLogin(asset: Asset): boolean {
  return asset && some(asset.productGroups, { accessType: AccessType.FreeNoLogin });
}

// Converts CuratedAsset to Asset and overrides asset fields from the curated item
export function curatedAssetToAsset(curatedAsset: CuratedAsset): Asset {
  if (!curatedAsset) {
    return null;
  }
  if (!curatedAsset.asset) {
    return (curatedAsset as any) as Asset;
  }

  const mergedItem: Asset = { ...curatedAsset.asset };
  mergedItem.title = curatedAsset.title || mergedItem.title;
  mergedItem.subtitle = curatedAsset.subtitle || mergedItem.subtitle;
  mergedItem.description = curatedAsset.description || mergedItem.description;
  if (curatedAsset.image) {
    if (isString(curatedAsset.image)) {
      mergedItem.image = curatedAsset.image;
    } else {
      mergedItem.image = curatedAsset.image.url;
    }
  }
  const hasCuratedImages = curatedAsset.images && curatedAsset.images.length > 0;
  mergedItem.images = hasCuratedImages ? curatedAsset.images : curatedAsset.asset.images;
  return mergedItem;
}

export function getParentalReasons(item: Category | Asset | EpgItem) {
  return 'parentalReasons' in item ? (item as Asset | EpgItem).parentalReasons : [];
}

// Private functions

function isAired(asset): boolean {
  const liveBroadcastTime = get(asset, 'liveBroadcastTime');
  const start = new Date(liveBroadcastTime);
  const now = getCurrentDate();

  return liveBroadcastTime && isBefore(start, now);
}
