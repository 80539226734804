import { createSelector } from 'reselect';
import get from 'lodash/get';
import find from 'lodash/find';

import { SeriesState } from './series.reducers';
import { sortSeasons } from '../../services/categories';

export const selectSeriesState = () => (state: any) => state.series;

export const getSeasons = createSelector(selectSeriesState(), (series: SeriesState) => {
  if (!series.category) return [];

  const groups = get(series.category, 'groups');

  return groups && groups.length > 0 ? sortSeasons(groups) : [];
});

export const getCurrentSeason = createSelector(selectSeriesState(), (series: SeriesState) => {
  if (!series.category) return {};

  const groups = get(series, 'category.groups');

  const currentGroup = find(groups, (group) => {
    return Number(group.id) === Number(series.selectedSeason);
  });

  return currentGroup ? currentGroup : series.category;
});

export const getSelectedAsset = createSelector(selectSeriesState(), (series: SeriesState) => {
  return series.selectedAsset;
});

export const getSeasonAssets = createSelector(selectSeriesState(), (series: SeriesState) => {
  return series.assets[series.selectedSeason];
});
