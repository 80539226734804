import React, { createContext, FunctionComponent, useMemo } from 'react';
import memoize from 'lodash/memoize';
import { CuratedComponentType } from '../models/curated';
import { EDDLComponentType } from './tracking/eddl-analytics';

const getMemoizedValue = memoize((index: number) => ({ index }));

export interface ContentItemContextProps {
  index: number;
}

/**
 * Stripe/grid context for the item displayed in the DetailsFloat
 */
export interface ContentContext {
  componentId?: number | null;
  sectionTitle?: string;
  index: number;
  component?: CuratedComponentType | EDDLComponentType | string | null;
}

/**
 * Context for content items (e.g. movies/categories on a stripe, movies/categories on a grid)
 */
export const ContentItemContext = createContext<ContentItemContextProps>({
  index: null,
});

export const ContentItemContextProvider: FunctionComponent<ContentItemContextProps> = ({ index, children }) => (
  <ContentItemContext.Provider value={getMemoizedValue(index)}>{children}</ContentItemContext.Provider>
);

/**
 * Context for content sections (e.g. curated component, a stripe, a grid)
 */
export const ContentSectionContext = React.createContext<ContentContext>({
  componentId: null,
  sectionTitle: null,
  index: null,
  component: null,
});

export const ContentSectionContextProvider: FunctionComponent<ContentContext> = ({
  componentId,
  sectionTitle,
  index,
  component,
  children,
}) => {
  const contextValue = useMemo<ContentContext>(() => ({ componentId, sectionTitle, index, component }), [
    componentId,
    sectionTitle,
    index,
    component,
  ]);

  return <ContentSectionContext.Provider value={contextValue}>{children}</ContentSectionContext.Provider>;
};
