import React from 'react';
import { SVGProps } from '../utils';

const SvgLock = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 498" {...props}>
    <path d="M149 51c25-32 66-53 107-51 42-2 83 19 108 52 19 25 28 56 28 87v76h38c5 0 9 5 12 8l1 221c1 26-20 50-45 54H123c-26 3-50-20-53-45l-1-230c4-4 8-8 13-8l38-1v-75c0-32 9-64 29-88m97-23a109 109 0 00-97 105v82h214l-1-89c-2-40-30-76-67-91l-29-7h-20M98 244v201c-1 13 12 26 25 25h266c13 1 25-11 25-25V244H98z" />
    <path d="M228 322c5-11 15-21 28-20 13-1 23 9 28 20l1 13c-3 8-8 14-14 20l-1 43c0 4-3 7-5 11l-9 2-9-2c-2-4-5-7-5-11l-1-44c-6-5-11-11-14-19l1-13z" />
  </svg>
);

export default SvgLock;
