// NEW PALETTE

import rgba from 'color-alpha';

export const MEDIUMPURPLE = '#af3ce8';
export const DARKPURPLE = 'rgba(255, 255, 255, 0.90)';
export const DARKESTPURPLE = '#100018';
export const LIGHTPURPLE = '#c383ff';
export const BLACK = '#000';
export const WHITE = '#fff';
export const RED = '#ff3334';
export const ERRORRED = '#f00';
export const WHITE05 = '#ffffff';
export const WHITE10 = '#351a44';
export const WHITE15 = '#41274e';
export const WHITE25 = '#574063';
export const WHITE40 = '#796782';
export const WHITE50 = '#8f8097';
export const WHITE60 = '#a599ac';
export const WHITE90 = '#e9e6ea';
export const LIGHTRED = '#fdafaf';
export const MEDIUMRED = '#fb666a';
export const BURGUNDYRED = '#710823';
export const TELIAPURPLE = '#990ae3';
export const TELIADARKPURPLE = '#570580';
export const DARKEN = rgba(DARKPURPLE, 0.45);
export const LIGHTEN = rgba(WHITE, 0.15);
export const LIGHTGREEN = '#d9ffeb';
export const TELIAGRAY = '#f9f9f9';
export const TELIAORANGE = '#f19f39';
