import { createSelector, Selector } from 'reselect';
import { DetailsFloatState, UIState } from './ui.reducers';

export const selectUIState = () => (state: any): UIState => state.ui;

export const selectDetailsFloatState: Selector<UIState, DetailsFloatState> = createSelector(
  selectUIState(),
  (uiState: UIState) => uiState.detailsFloat
);

export const selectPreviewVideosMuted: Selector<UIState, boolean> = createSelector(
  selectUIState(),
  (uiState: UIState) => uiState.previewVideosMuted
);
