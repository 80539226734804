import * as Sentry from '@sentry/node';
import get from 'lodash/get';
import map from 'lodash/map';
import assign from 'lodash/assign';

import { Subprofile, SubprofileImageGroup, VimondRawSubprofile } from '../models/subprofile';
import { getConfig, getKatsomoServiceApiUrl, getMotherbirdApiUrl, getVimondApiUrl } from './conf';
import { MinimalUserFormData, User, UserFormData, UserProperty } from '../models/user';
import { fetchJSON, fetchRaw, withTimeout } from './api';
import { userUpdate } from '../state/user/user.actions';
import keys from 'lodash/keys';
import { sanitizeUserInput } from './format';
import isString from 'lodash/isString';
import { useAuth } from './auth';
import { useDispatch, useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import { getUserProperty } from './properties';
import { cache } from './cache';
import { selectUserOperator } from '../state/user/user.selectors';
import { find } from 'lodash';

export const fetchUser = async (authToken: string = ''): Promise<User> => {
  return null;

  try {
    const headers: {
      Accept: string;
      'Content-type': string;
      Authorization?: string;
    } = {
      Accept: 'application/json;charset=utf-8',
      'Content-type': 'application/json;v=2',
    };

    if (authToken.length > 0) {
      headers.Authorization = `${authToken}`;
    }

    const response = await fetchRaw(`${getVimondApiUrl()}/api/web/user`, {
      withCredentials: true,
      cors: true,
      headers,
    });

    if (response.status === 200) {
      const jsonData: any = await response.json();
      return jsonData.user;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const fetchChatToken = async (authToken = '') => {
  return undefined;

  if (!authToken) {
    return undefined;
  }

  try {
    const response = await fetchRaw(`${getKatsomoServiceApiUrl()}/token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
      withCredentials: true,
      cors: true,
      body: JSON.stringify({ type: 'support' }),
    });
    const data = await response.json();
    return data.token;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const fetchTransitionToken = async (authToken = '') => {
  return undefined;

  if (!authToken) {
    return undefined;
  }

  try {
    const response = await fetchRaw(`${getKatsomoServiceApiUrl()}/token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
      withCredentials: true,
      cors: true,
      body: JSON.stringify({ type: 'transition-helper' }),
    });
    const data = await response.json();
    return data.token;
  } catch (error) {
    return undefined;
  }
};

export const fetchSubprofiles = async (authToken, mainProfile = null): Promise<any> => {
  return null;

  try {
    const headers: {
      Accept: string;
      'Content-type': string;
      Authorization: string;
    } = {
      Accept: 'application/json;charset=utf-8',
      Authorization: authToken,
      'Content-type': 'application/json;v=2',
    };

    const resp = await fetch(`${getVimondApiUrl()}/subprofiles`, {
      headers,
    });
    if (resp.status === 200) {
      const jsonData: any = await resp.json();
      const profiles: VimondRawSubprofile[] = jsonData.subProfiles;

      return {
        profiles: map(
          profiles,
          (profile: VimondRawSubprofile): Subprofile => {
            if (profile.subProfileName == '') {
              profile.subProfileName = mainProfile.firstName;
            }
            return formatSubProfile(profile);
          }
        ),
        activeProfileId: jsonData.activeSubProfileId,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const SUBPROFILE_DEFAULT_COLOR = '#EC525A';
export const SUBPROFILE_JUNIOR_DEFAULT_COLOR = '#2FF969';

export const formatSubProfile = (profile: VimondRawSubprofile): Subprofile => {
  let profileName;
  let startPage;
  let kidsProfile = false;
  let defaultColor = '';

  if (profile.subProfileName === '__kids__') {
    profileName = 'Juniori';
    startPage = '/juniori';
    kidsProfile = true;
    defaultColor = SUBPROFILE_JUNIOR_DEFAULT_COLOR;
  } else {
    if (profile.subProfileName) {
      profileName = profile.subProfileName;
      startPage = profile.startPage && profile.startPage !== '' ? profile.startPage : '/';
      defaultColor = SUBPROFILE_DEFAULT_COLOR;
    } else {
      profileName = 'Oletus';
      startPage = '/etusivu';
      defaultColor = SUBPROFILE_DEFAULT_COLOR;
    }
  }

  return {
    id: profile.subProfileId,
    name: profileName,
    defaultProfile: profile.defaultProfile,
    profilePicture: profile.profilePicture ? profile.profilePicture : defaultColor,
    kidsProfile,
    startPage,
  };
};

type ProfileImages = {
  profileImages: SubprofileImageGroup[];
  kidsProfileImages: SubprofileImageGroup[];
};
const profileImagesCacheKey = 'profileImages';

export async function fetchSubprofileImages() {
  const { subprofilesImageUrl } = getConfig();

  const profileUrl = `${subprofilesImageUrl}/profilePictures`;
  const kidsProfileUrl = `${subprofilesImageUrl}/childrenProfilePictures`;

  const cached = cache.get<ProfileImages>(profileImagesCacheKey);

  if (cached) {
    return cached;
  } else {
    const [profileImages, kidsProfileImages] = await Promise.all([
      fetchJSON<SubprofileImageGroup[]>(profileUrl).catch(() => [] as SubprofileImageGroup[]),
      fetchJSON<SubprofileImageGroup[]>(kidsProfileUrl).catch(() => [] as SubprofileImageGroup[]),
    ]);

    cache.set(profileImagesCacheKey, { profileImages, kidsProfileImages }, 60 * 15);

    return { profileImages, kidsProfileImages };
  }
}

export async function doUpdateUser(user: User, authToken: string): Promise<User> {
  const resp = await fetchRaw(`${getKatsomoServiceApiUrl()}/user`, {
    method: 'PUT',
    body: JSON.stringify({ user }),
    headers: {
      Authorization: authToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const json = await resp.json();

  if (!resp.ok) {
    throw new Error(get(json, 'error.code', 'UNKNOWN'));
  }

  return json.user;
}

export function useUpdateUser(): (userData: MinimalUserFormData | UserFormData) => Promise<User> {
  const { user, authToken } = useAuth();
  const dispatch = useDispatch();
  const operator = !!useSelector(selectUserOperator);

  const updateUser = async (userData: MinimalUserFormData | UserFormData): Promise<User> => {
    if (!user || !authToken) {
      console.error('[user] User update failed, not authenticated');
      throw new Error('NOT_AUTHENTICATED');
    }

    try {
      const cleanUser: User = assign(
        {},
        user,
        keys(userData).reduce((acc, key) => {
          const value = userData[key];
          const sanitizedValue = sanitizeUserInput(value);

          acc[key] = isString(sanitizedValue) ? sanitizedValue || null : sanitizedValue;

          return acc;
        }, {})
      );

      // Remove all non-numeric characters from phone number
      if (cleanUser.mobileNumber) {
        cleanUser.mobileNumber = `+${cleanUser.mobileNumber.replace(/[^\d]/g, '')}`;
      }

      // Find DOT users by TeliaId
      const properties: UserProperty[] = cleanUser?.properties?.property;
      const isDotUser = !!find(properties, { name: 'teliaId' });

      // Unify userName & email unless operator user
      if (!operator && !isDotUser) cleanUser.userName = cleanUser.email;
      // Don't send properties at this point
      delete cleanUser.properties;

      // Change user on the server and wait for response
      const userFromServer = await doUpdateUser(cleanUser, authToken);

      // Update method returns invalid properties
      delete userFromServer.properties;

      // Merge old and new users
      const updatedUser = assign({}, user, userFromServer);

      // Make sure old optional properties don't stay when they've been removed
      if (isNil(userFromServer.dateOfBirth)) {
        delete updatedUser.dateOfBirth;
      }
      if (isNil(userFromServer.gender)) {
        delete updatedUser.gender;
      }

      // Update redux state
      dispatch(userUpdate(updatedUser));

      return updatedUser;
    } catch (e) {
      console.error('[user] User update failed', e);
      throw e;
    }
  };

  return updateUser;
}

export async function updateSubProfile(
  subprofile: Subprofile,
  authToken: string,
  createNew: boolean = false
): Promise<{ status: string; createdNew?: boolean; subprofile?: Subprofile }> {
  if (subprofile.name) {
    let url: string;
    let method: 'POST' | 'PUT';
    const payload = { subProfileName: subprofile.name };

    if (subprofile.startPage) {
      assign(payload, { startPage: subprofile.startPage });
    }

    if (subprofile.profilePicture) {
      assign(payload, { profilePicture: subprofile.profilePicture });
    }

    if (subprofile.kidsProfile) {
      assign(payload, { kidsProfile: subprofile.kidsProfile });
      payload.subProfileName = '__kids__';
    }

    if (createNew) {
      url = `${getVimondApiUrl()}/subprofiles`;
      method = 'POST';
    } else {
      url = `${getVimondApiUrl()}/subprofiles/${subprofile.id}`;
      method = 'PUT';
    }

    const resp = await fetchRaw(url, {
      method,
      body: JSON.stringify(payload),
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!resp.ok) {
      throw new Error('Subprofile service API error');
    }

    return {
      status: 'ok',
      createdNew: createNew,
      subprofile: createNew ? formatSubProfile(await resp.json()) : subprofile,
    };
  } else {
    return { status: 'error: no name' };
  }
}

export async function deleteSubProfile(subprofileId: string, authToken: string): Promise<{ status: string }> {
  const resp = await fetchRaw(`${getVimondApiUrl()}/subprofiles/${subprofileId}`, {
    method: 'DELETE',
    headers: {
      Authorization: authToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (!resp.ok) {
    throw new Error('Subprofile service API error');
  }

  return { status: 'ok' };
}

export const HAYU_CODE_PROPERTY = 'hayuCode';

export function getHayuCode(user: User): string | null {
  const hayuCode = getUserProperty(user, HAYU_CODE_PROPERTY);
  if (!hayuCode || hayuCode.length < 1) {
    return null;
  }
  return hayuCode;
}

export async function addHayuCode(): Promise<boolean> {
  try {
    const response = await fetchRaw(`${getKatsomoServiceApiUrl()}/user/hayu`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    return response.ok;
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTag('hayu', 'true');
      scope.setExtra('hayu', 'Adding hayu code failed');
      Sentry.captureException(e);
    });

    return false;
  }
}

/**
 * Check from katsomo-service if there has been an earlier account with the same email address.
 * Used for preventing new free period purchases with the same address.
 *
 * @param authToken
 */
export async function checkDeletedEmailHistory(authToken: string): Promise<boolean> {
  try {
    const json: { userDeleted: boolean } = await fetchJSON(`${getKatsomoServiceApiUrl()}/user/checkEmail`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
      },
    });

    if (json) {
      return json.userDeleted;
    }
  } catch (e) {
    // ignore error
  }

  return false;
}

export const resolveProfileStartPageData = (startPage: string): { link: { href: string; as: string } } => {
  let template;
  if (startPage.indexOf('/category') !== -1) {
    template = '/ohjelma/[id].tsx';
  } else {
    template = startPage !== '/' ? '/[...slug]' : '/index';
  }

  return { link: { href: template, as: startPage } };
};

export function getProfileIdentifier(user: User, subprofile: Subprofile): string | null {
  if (!user) {
    return null;
  }

  return `${user.id}_${subprofile?.id || 'null'}`;
}

interface ClientIPResponse {
  ip: string;
}

export async function getClientIP(): Promise<string> {
  try {
    const json = await withTimeout(
      fetchJSON<ClientIPResponse>(`${getMotherbirdApiUrl()}/v3/ip`, {
        method: 'GET',
        throwErrors: false,
      }),
      2000
    );

    return json?.ip || '-';
  } catch (e) {
    return '-';
  }
}
