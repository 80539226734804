import React, { ReactNode, useEffect, useState } from 'react';
import { Image, ImageCollection, ImageOrientation, ImageSize, ResponsiveImage, TitleImageData } from '../models/image';
import { getConfig } from './conf';
import isString from 'lodash/isString';
import { CuratedAsset, CuratedCategory, CuratedStaticItem, HeroSlide } from '../models/curated';
import { Asset } from '../models/asset';
import { Category } from '../models/category';
import { TitleImage, TitleImageStyle } from '../components/title-image/TitleImage';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';

interface AnyWithImage {
  images?:
    | Image[]
    | {
        [ImageOrientation.Portrait]?: Image[];
        [ImageOrientation.Landscape]?: Image[];
      };
  image?: Image | string;
  [key: string]: any;
}

const { Landscape, Portrait } = ImageOrientation;
const { Medium, Large } = ImageSize;

type ImageWithWidth = Omit<Image, 'height'> | TitleImageData;

const findImage = {
  [Medium]: {
    [Landscape]: (images: ImageWithWidth[]) => images.find((im) => im.width >= 224 && im.width <= 640),
    [Portrait]: (images: ImageWithWidth[]) => images.find((im) => im.width >= 189 && im.width <= 384),
  },
  [Large]: {
    [Landscape]: (images: ImageWithWidth[]) => images.find((im) => im.width > 640),
    [Portrait]: (images: ImageWithWidth[]) => images.find((im) => im.width > 384),
  },
};

export function getImageUrl(
  item: AnyWithImage,
  orientation: ImageOrientation,
  size: ImageSize = ImageSize.Medium
): string {
  if (item && item.images) {
    const imageData = getImage(item, orientation, size);

    if (imageData) {
      return imageData.url;
    }
  }

  if (!item || !item.image) {
    return null;
  }

  return isString(item.image) ? item.image : item.image.url;
}

export function getCuratedImageUrl(
  item: CuratedAsset | CuratedCategory | CuratedStaticItem | HeroSlide,
  orientation: ImageOrientation,
  size: ImageSize = ImageSize.Medium
): string {
  let imageUrl;

  if (item.image) {
    imageUrl = isString(item.image) ? item.image : item.image.url;
  }

  if (!imageUrl && item.images) {
    imageUrl = getImageUrl(item, orientation, size);
  }

  if (!imageUrl) {
    imageUrl = getImageUrl((item as CuratedAsset).asset || (item as CuratedCategory).category, orientation, size);
  }

  return imageUrl;
}

export function getImage(item: AnyWithImage, orientation: ImageOrientation, size: ImageSize = ImageSize.Medium): Image {
  if (item.images) {
    let imageData;

    // If landscape, sort by widest first; if portrait, sort by highest first
    // TODO: this method should have more logic in recognizing image ratio and preventing wrong image orientations
    const widestFirst = (a: any, b: any) => b.width - a.width;
    const highestFirst = (a: any, b: any) => b.height - a.height;

    const compare = orientation === ImageOrientation.Landscape ? widestFirst : highestFirst;

    if (Array.isArray(item.images)) {
      const sortedImages = [...item.images].sort(compare);
      imageData = findImage[size][orientation](sortedImages);
    } else if (Array.isArray(item.images[orientation])) {
      const sortedImages = [...item.images[orientation]!].sort(compare);
      imageData = findImage[size][orientation](sortedImages);
    }

    return imageData;
  }

  return null;
}

export function getTitleImage(item: Asset | Category, size: ImageSize = ImageSize.Medium): TitleImageData {
  if (item?.titleImages?.length > 0) {
    const widestFirst = (a: any, b: any) => b.width - a.width;

    const sortedImages = [...item.titleImages].sort(widestFirst);
    return findImage[size][ImageOrientation.Landscape](sortedImages);
  }

  return null;
}

export function getPlaceholderImageUrl(orientation: ImageOrientation, size: ImageSize = ImageSize.Medium): string {
  const placeholderImages = getConfig().placeholderImage;
  return getImageUrl({ images: placeholderImages }, orientation, size);
}

export function useTitleImage(
  item: Asset | Category,
  fallbackTitle: string = null,
  imageStyle: TitleImageStyle = TitleImageStyle.Hero
): ReactNode {
  // Try to set default values for SSR
  const [titleImageUrl, setTitleImageUrl] = useState<string>(() => getTitleImage(item, ImageSize.Medium)?.url || null);
  const [titleImageReady, setTitleImageReady] = useState<boolean>(Boolean(titleImageUrl));

  useEffect(() => {
    if (item) {
      const titleImageData = getTitleImage(
        item,
        imageStyle === TitleImageStyle.Hero ? ImageSize.Large : ImageSize.Medium
      );

      setTitleImageUrl(titleImageData?.url || null);
      setTitleImageReady(true);
    } else {
      setTitleImageUrl(null);
      setTitleImageReady(false);
    }
  }, [item]);

  if (!item) {
    return fallbackTitle;
  }

  // If done with parsing titleImage and an image is available, show image, or if image is not available, show text
  // Otherwise return null
  return titleImageReady ? (
    titleImageUrl ? (
      <TitleImage imageUrl={titleImageUrl} imageStyle={imageStyle} alt={item.title} />
    ) : (
      <span className="text-title">{fallbackTitle || item.title}</span>
    )
  ) : null;
}

/**
 * Convert the given map or array of images, or the single fallback image, into an array of images.
 * Note that if fallbackImage is a string, it won't be returned.
 *
 * @param images
 * @param fallbackImage
 */
export function getResponsiveSources(
  images: Image[] | ImageCollection,
  fallbackImage?: Image | string
): ResponsiveImage[] {
  const imageArray: Image[] = (() => {
    if (isNil(images)) {
      return null;
    }

    if (isArray(images) && images.length > 0) {
      return images;
    }

    const collection = images as ImageCollection;

    if (collection?.landscape?.length > 0) {
      return collection.landscape;
    }

    if (fallbackImage && (fallbackImage as Image)?.url) {
      return [fallbackImage as Image];
    }

    return null;
  })();

  if (imageArray) {
    const sortedImages = sortBy(imageArray, ['width']);
    return sortedImages.map(({ url, width }) => ({
      url,
      width,
    }));
  } else {
    return [];
  }
}
