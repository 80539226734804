import { call, delay, fork, getContext, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  ActionTypes,
  activateSubProfile,
  authFail,
  authSuccess,
  clearLoginRedirect,
  deleteUserProperty,
  geoBlockFail,
  geoBlockRequest,
  geoBlockSuccess,
  loginFail,
  loginModalClose,
  loginSuccess,
  logoutFail,
  logoutSuccess,
  ordersFail,
  ordersSuccess,
  postAuthRequest,
  segmentsSuccess,
  setAuthMethod,
  setAuthToken,
  setProfiles,
  setTeliaVasPlayError,
  subprofilesLoadFailed,
  updateUserProperty,
  updateUserPropertyRequest,
  updateUserPropertyFail,
  updateUserPropertySuccess,
  setOrderStatus,
  clearOrderStatus,
  setShouldProfileRedirect,
} from './user.actions';
import get from 'lodash/get';
import find from 'lodash/find';
import { getUnixTime, isAfter } from 'date-fns';

import { CONST as cookieConst, remove as removeCookie } from '../../services/cookie';
import {
  authenticate as auth,
  getTokenFromLocalStorage,
  login,
  loginSubprofile,
  logout,
  OPERATOR_LOGIN_USER_FETCH_DELAY,
  removeTokenFromLocalStorage,
  storeTokenToLocalStorage,
} from '../../services/auth';
import { fetchSubprofiles, fetchUser } from '../../services/user';
import { getUserProperty, storeUserProperty } from '../../services/properties';
import { fetchOrders, refreshOrderStatus } from '../../services/orders';
import { checkGeoBlockStatus } from '../../services/geoblock';
import { viewingHistoryReset } from '../viewing-history/viewing-history.actions';
import {
  selectActiveOrders,
  selectActiveSubProfile,
  selectAuthToken,
  selectLoginModalCloseMethod,
  selectLoginModalCloseRedirect,
  selectShouldProfileRedirect,
  selectSubProfiles,
  selectUser,
} from './user.selectors';
import { loadSegments } from '../../services/dmp';
import { AuthenticationResponse, User, VimondUserStatus } from '../../models/user';
import { getItem, Keys, removeItem, setItem } from '../../services/local-storage';
import { Subprofile } from '../../models/subprofile';
import { getConfig } from '../../services/conf';
import { addFeedbackMessage } from '../feedback/feedback.actions';
import { FeedbackLevel } from '../../models/feedback';
import { generateFeedbackId } from '../../services/feedback';
import { AuthMethod, LoginModalCloseMethod, UserOrders } from './user.reducers';
import { Order } from '../../models/order';
import { cleanTeliaIdProperty } from '../../services/telia';
import { selectValidProducts } from '../products/products.selectors';
import { CmProduct } from '../../models/product';
import Router from 'next/router';
import { LinkData } from '../../services/links';
import { AnyAction } from 'redux';
import * as Sentry from '@sentry/node';
import { cleanCachedSurveyData } from '../../services/cx-score-cache';
import { OperatorProperties } from '../../models/operator';
import { convertTargetPathToRoutable } from '../../services/target-links';
import { selectPaths } from '../app/app.selectors';
import { clearClosedNotifications } from '../notifications/notifications.actions';

/**
 * Log authentication failures to Sentry. Currently only logs auth renewal failures.
 */
const logAuthFail = (authData: AuthenticationResponse, problemType: string) => {
  Sentry.withScope((scope) => {
    scope.setFingerprint(['Auth renewal', problemType]);
    scope.setTag('auth', 'yes');
    scope.setTag('authDataCode', authData?.code || '');
    Sentry.captureMessage('Authentication renewal failed');
  });
};

const sessionauthenticatedCode = 'SESSION_AUTHENTICATED';
export const PROFILE_SELECTION_EXPIRATION_MINUTES = 60 * 10_000;
export const PROFILE_MODAL_SKIP_PATHS = [
  '/telia-vas',
  '/aktivoi',
  '/omatili',
  '/asiakaspalvelu',
  '/tilaa',
  '/koodi',
  '/kampanja',
];
export const LOGIN_REDIRECT_SKIP_PATHS = ['/katso', '/ohjelma', '/elokuva'];

export function renewAuthToken(): any {
  return;
}

export function authenticate(): any {
  return;
}

export function* renewAuthentication(): any {
  yield renewAuthToken();
}

/**
 * @param user User
 * @return boolean True if user should be logged out
 */
function shouldForceLogoutUser(user: User): boolean {
  const lastAppOpen = getItem(Keys.LAST_APP_OPEN, { suffix: `-${user.id}` });
  const lastAppOpenDate = lastAppOpen ? new Date(Number(lastAppOpen) * 1000) : new Date();

  const lastPasswordReset = getUserProperty(user, 'lastPasswordReset');

  const updateLastAppOpen = () => {
    // password has not been changed, update "lastAppOpen" value in local storage
    setItem(Keys.LAST_APP_OPEN, `${Math.floor(Date.now() / 1000)}`, { suffix: `-${user.id}` });
  };

  if (lastPasswordReset) {
    // The password reset time is stored in UTC time but does not contain timezone definition
    // That's why we need to add UTC offset to make the backend timestamp comparable to our timestamp
    const utcOffset = new Date().getTimezoneOffset();
    const lastPasswordResetDate = new Date(lastPasswordReset);
    lastPasswordResetDate.setMinutes(lastPasswordResetDate.getMinutes() - utcOffset);

    if (isAfter(lastPasswordResetDate, lastAppOpenDate)) {
      // the password has been reset after the last app open, log user out.
      setItem(Keys.LAST_APP_OPEN, '', { suffix: `-${user.id}` });
      return true;
    }
  }

  updateLastAppOpen();

  return false;
}

export function doLogin(): any {
  return;
}

export function* doLoginSuccess(): any {
  yield put(loginModalClose());
}

/**
 * If login modal has been closed manually and there's a redirect path set,
 * redirect to the given path
 */
export function* doLoginModalCloseRedirect(): any {
  const redirectPath = yield select(selectLoginModalCloseRedirect);
  const closeMethod = yield select(selectLoginModalCloseMethod);
  const shouldProfileRedirect = yield select(selectShouldProfileRedirect);

  if (shouldProfileRedirect && redirectPath && closeMethod === LoginModalCloseMethod.Manual) {
    yield call(Router.replace, redirectPath);
  }
}

export function* doLogout(action?: AnyAction): any {
  try {
    console.log('logout: trying logout...');
    const logoutStatus: any = yield call(logout);

    if (logoutStatus) {
      yield call(clearAuthCookie);
      yield put(viewingHistoryReset());

      if (action?.payload !== false) {
        const link: LinkData = action?.payload || { href: '/', as: '/' };
        yield call(Router.push, link.href, link.as);
      }

      yield put(authFail());
      yield put(logoutSuccess());
      yield put(setAuthMethod(null));
      yield put(clearLoginRedirect());
      yield put(clearClosedNotifications());
      yield put(setTeliaVasPlayError(false));
      yield put(clearOrderStatus());
      yield put(geoBlockSuccess(false));
      removeTokenFromLocalStorage();
      cleanCachedSurveyData();

      // Clear modals when logging out
      // ModalContext is set to Redux context in store.tsx with createSagaMiddleware({ context })
      const modalContext = yield getContext('modals');
      modalContext?.clearModals();

      console.log('logout: success');
    } else {
      console.log('logout: fail');
      yield put(logoutFail());
    }
  } catch (err) {
    console.log('logout: fail');
    yield put(logoutFail());
  }
}

export function* storeAuthToken(action: AnyAction): any {
  if (action.payload) {
    storeTokenToLocalStorage(action.payload);
    yield true;
  } else {
    removeTokenFromLocalStorage();
    yield false;
  }
}

export function doOrdersRefresh(): any {
  return;
}

export function* doFetchSubprofiles(action: AnyAction): any {
  try {
    const subprofilesEnabled = getConfig().subprofilesEnabled;

    // Load subprofiles & place to state
    const { authToken } = action.payload;
    const user = yield select(selectUser);
    const profile = yield call(fetchSubprofiles, authToken, user);
    const profiles = profile.profiles;

    if (subprofilesEnabled) {
      if (profile) {
        const storedSubprofile = yield call(getItem, Keys.CURRENT_SUBPROFILE);
        const foundSubProfile = find(profiles, { id: storedSubprofile });
        let newSubProfileId: string;

        if (storedSubprofile && foundSubProfile) {
          newSubProfileId = foundSubProfile.id;
        } else {
          newSubProfileId = profile.activeProfileId;
        }

        yield put(setProfiles(profiles, newSubProfileId));
        const profileAuthData = yield call(loginSubprofile, authToken, newSubProfileId);

        if (profileAuthData) {
          yield put(setAuthToken(profileAuthData.ssoToken)); // Needed without 3rd party cookies in incognito mode
          yield put(activateSubProfile(newSubProfileId, profileAuthData.ssoToken));
        } else {
          yield put(
            addFeedbackMessage({
              id: generateFeedbackId(),
              level: FeedbackLevel.Error,
              message: 'Profiilin lataaminen epäonnistui. Käytössäsi on tunnuksesi pääprofiili.',
              closeOnNavigation: true,
              timeout: 10000,
            })
          );
          yield put(subprofilesLoadFailed());
        }
      } else {
        console.log('auth: fetchSubprofiles fail');
        yield put(
          addFeedbackMessage({
            id: generateFeedbackId(),
            level: FeedbackLevel.Error,
            message: 'Profiilien lataaminen epäonnistui. Käytössäsi on tunnuksesi pääprofiili.',
            closeOnNavigation: true,
            timeout: 10000,
          })
        );
        yield put(subprofilesLoadFailed());
      }
    } else {
      if (profile) {
        yield put(setProfiles(profiles, profile.activeProfileId));
      } else {
        console.log('auth: fetchSubprofiles fail');
      }
    }
  } catch (e) {
    yield put(subprofilesLoadFailed());
    console.log('auth: fetchSubprofiles fail');
  }
}

export function* doReFetchSubprofiles(action: AnyAction): any {
  try {
    const { authToken } = action.payload;
    const user = yield select(selectUser);
    const activeProfile = yield select(selectActiveSubProfile);
    const profile = yield call(fetchSubprofiles, authToken, user);

    if (profile && profile.profiles) {
      yield put(setProfiles(profile.profiles, activeProfile.id));
    } else {
      console.log('auth: fetchSubprofiles fail');
      yield put(subprofilesLoadFailed());
    }
  } catch (e) {
    yield put(subprofilesLoadFailed());
    console.log('auth: fetchSubprofiles fail');
  }
}

export function doPostAuthFetchOrders(action: AnyAction): any {
  return;
}

export function* doFetchSegments(action: AnyAction): any {
  // Load user segments
  const { authToken } = action.payload;
  const segments: string[] = yield call(loadSegments, authToken);
  yield put(segmentsSuccess(segments));
}

export function* changeSubprofile(action: AnyAction): any {
  try {
    const { authToken, subProfileId, redirectPath } = action.payload;

    const subProfiles: Subprofile[] = yield select(selectSubProfiles);
    const profileToSwitch = find(subProfiles, { id: subProfileId });
    const paths = yield select(selectPaths);
    const shouldProfileRedirect = yield select(selectShouldProfileRedirect);

    const profileAuthData = yield call(loginSubprofile, authToken, profileToSwitch?.id);

    if (profileAuthData && profileAuthData.ssoToken) {
      yield put(setAuthToken(profileAuthData.ssoToken)); // Switching profiles without 3rd party cookies fails without this
      yield call(setItem, Keys.CURRENT_SUBPROFILE, profileToSwitch.id);
      yield put(activateSubProfile(subProfileId, profileAuthData.ssoToken));
      yield put(viewingHistoryReset());

      // Store selected profile in localStorage
      setItem(Keys.PROFILE_ID, profileToSwitch.id, { expireMillis: PROFILE_SELECTION_EXPIRATION_MINUTES * 60 * 1000 });

      if (shouldProfileRedirect) {
        const redirectTo = convertTargetPathToRoutable(redirectPath || profileToSwitch?.startPage || '/etusivu', paths);
        if (redirectTo.as !== Router.asPath) {
          Router.push(redirectTo.href, redirectTo.as);
        }
      }

      yield put(setShouldProfileRedirect(true));
    } else if (!profileAuthData) {
      yield put(subprofilesLoadFailed());
      yield put(
        addFeedbackMessage({
          id: generateFeedbackId(),
          level: FeedbackLevel.Error,
          message: 'Profiilin lataaminen epäonnistui. Profiilia ei vaihdettu.',
          closeOnNavigation: true,
          timeout: 10000,
        })
      );
    }
  } catch (e) {
    console.error('Changing subprofile failed:', e);
  }
}

export function* activateSubprofile(action: AnyAction): any {
  if (action.payload.authToken) {
    storeTokenToLocalStorage(action.payload.authToken);
    yield true;
  } else {
    yield false;
  }
}

export function* doGeoBlockCheck(): any {
  try {
    console.log('geoblock: checking...');
    const authToken = yield select(selectAuthToken);
    const geoBlockStatus: boolean = yield call(checkGeoBlockStatus, authToken);

    yield put(geoBlockSuccess(geoBlockStatus));
    console.log(`geoblock: success (${geoBlockStatus ? 'blocked' : 'not blocked'})`);
  } catch (err) {
    console.log('geoblock: error', err);
    yield put(geoBlockFail());
  }
}

export function* doUserPropertyUpdate(action: AnyAction): any {
  const { name, value, previousValue, localOnly, delayed } = action.payload;

  if (localOnly) {
    return;
  }

  console.log('user property: updating...');
  const authToken = yield select(selectAuthToken);

  if (authToken) {
    try {
      yield put(updateUserPropertyRequest(name));

      if (delayed) {
        // This delay is needed to work around a problem with the Vimond platform.
        // Updating properties too fast can lead to duplicated properties in the database.
        yield delay(4000);
      }

      yield call(storeUserProperty, name, value, authToken);
      yield put(updateUserPropertySuccess('Käyttäjätiedot päivitetty', name));
      console.log('user property: updated');
    } catch (err) {
      console.log('user property: update failed', err);
      yield put(updateUserPropertyFail(err.message, name, previousValue));
    }
  } else {
    console.log('user property: update failed, not authenticated');
    yield put(updateUserPropertyFail('Not authenticated', name, previousValue));
  }
}

export function* doSmallOperatorPropertyUpdate(action: AnyAction): any {
  yield put(updateUserProperty(OperatorProperties.SmallOperator, action.payload));
}

export function* doTeliaIdCleanup(): any {
  const user = yield select(selectUser);
  const activeOrders: Order[] = yield select(selectActiveOrders);
  const validProducts: CmProduct[] = yield select(selectValidProducts);
  const authToken = yield select(selectAuthToken);

  const wasDeleted = yield call(cleanTeliaIdProperty, user, activeOrders, validProducts, authToken);

  // Successfully removed, also remove from state
  if (wasDeleted) {
    yield put(deleteUserProperty('teliaId'));
  }
}

export function* storeWelcomeBackModalSeenAt(action: AnyAction): any {
  if (typeof window !== 'undefined') {
    if (action.payload) {
      const timestamp = getUnixTime(action.payload);
      setItem(Keys.WELCOME_BACK_TIME, `${timestamp}`);
    } else {
      removeItem(Keys.WELCOME_BACK_TIME);
    }
  }
  yield true;
}

export function* clearAuthCookie(): any {
  yield call(removeCookie, cookieConst.auth);
}

export function* watchAuthRequest() {
  yield takeLeading(ActionTypes.AUTH_REQUEST, authenticate);
}

export function* watchAuthRenew() {
  yield takeLatest(ActionTypes.AUTH_RENEW, renewAuthentication);
}

export function* watchLoginRequest() {
  yield takeLatest(ActionTypes.LOGIN_REQUEST, doLogin);
}

export function* watchLoginSuccess() {
  yield takeLatest(ActionTypes.LOGIN_SUCCESS, doLoginSuccess);
}

export function* watchLoginModalClose() {
  yield takeLatest(ActionTypes.LOGIN_MODAL_CLOSE, doLoginModalCloseRedirect);
}

export function* watchLogoutRequest() {
  yield takeLatest(ActionTypes.LOGOUT_REQUEST, doLogout);
}

export function* watchAuthToken() {
  yield takeLatest(ActionTypes.SET_AUTH_TOKEN, storeAuthToken);
}

export function* watchOrdersRefresh() {
  yield takeLatest(ActionTypes.ORDERS_REFRESH, doOrdersRefresh);
}

export function* watchGeoBlockRequest() {
  yield takeLatest(ActionTypes.GEOBLOCK_REQUEST, doGeoBlockCheck);
}

export function* watchUserPropertyUpdate() {
  yield takeEvery(ActionTypes.USER_PROPERTY_UPDATE, doUserPropertyUpdate);
}

export function* watchSmallOperatorPropertyUpdate() {
  yield takeEvery(ActionTypes.UPDATE_SMALL_OPERATOR_PROPERTY, doSmallOperatorPropertyUpdate);
}

export function* watchFetchSubprofiles() {
  yield takeLatest(ActionTypes.POST_AUTH_REQUEST, doFetchSubprofiles);
}

export function* watchFetchOrders() {
  yield takeLatest(ActionTypes.POST_AUTH_REQUEST, doPostAuthFetchOrders);
}

export function* watchFetchSegments() {
  yield takeLatest(ActionTypes.POST_AUTH_REQUEST, doFetchSegments);
}

export function* watchChangeSubprofile() {
  yield takeLatest(ActionTypes.CHANGE_SUBPROFILE, changeSubprofile);
}

export function* watchActivateSubprofile() {
  yield takeLatest(ActionTypes.ACTIVATE_SUBPROFILE, activateSubprofile);
}

export function* watchRefetchSubprofiles() {
  yield takeLatest(ActionTypes.FETCH_PROFILES, doReFetchSubprofiles);
}

export function* watchTeliaId() {
  yield takeLatest(ActionTypes.ORDERS_SUCCESS, doTeliaIdCleanup);
}

export function* watchSetWelcomeBackModalSeenAt() {
  yield takeLatest(ActionTypes.SET_WELCOME_BACK_MODAL_SEEN_AT, storeWelcomeBackModalSeenAt);
}

const userSagas = [
  fork(watchAuthRequest),
  fork(watchAuthRenew),
  fork(watchLoginRequest),
  fork(watchLoginSuccess),
  fork(watchLoginModalClose),
  fork(watchLogoutRequest),
  fork(watchAuthToken),
  fork(watchOrdersRefresh),
  fork(watchGeoBlockRequest),
  fork(watchUserPropertyUpdate),
  fork(watchSmallOperatorPropertyUpdate),
  fork(watchFetchSubprofiles),
  fork(watchFetchOrders),
  fork(watchFetchSegments),
  fork(watchChangeSubprofile),
  fork(watchActivateSubprofile),
  fork(watchRefetchSubprofiles),
  fork(watchTeliaId),
  fork(watchSetWelcomeBackModalSeenAt),
];

export default userSagas;
