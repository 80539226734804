import styled from 'styled-components';

export const UnstyledButton = styled.button`
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: none;
  cursor: pointer;

  svg {
    display: block;
  }
`;
