import 'intersection-observer'; // Polyfill
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ChoppedTree } from './Navi';
import { ProfileType } from './naviUtils';
import { NaviTextLink } from './NaviTextLink';
import { Items } from './styles';
import { useIsomorphicLayoutEffect } from 'owlet-ui';

interface NaviItemsContainerProps {
  items: ChoppedTree;
  profileType: ProfileType;
  navRef: React.MutableRefObject<HTMLElement>;
  onClassNamesChange: (classNames: Record<string, boolean>) => void;
}

const Container = styled.div`
  flex: 1 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  overflow: hidden;
`;

export const MainItems = styled(Items)``;
export const ExtraItems = styled(Items)``;

const MINIMUM_INTERSECTION_RATIO = 0.99;

export const NaviItemsContainer: FunctionComponent<NaviItemsContainerProps> = React.memo(
  ({ items, profileType, navRef, onClassNamesChange }: NaviItemsContainerProps) => {
    const observerRef = useRef<IntersectionObserver>(null);
    const mainItemsRef = useRef<HTMLUListElement>(null);
    const extraItemsRef = useRef<HTMLUListElement>(null);

    // Different navi layouts
    const [init, setInit] = useState<boolean>(true);
    const [compact, setCompact] = useState<boolean>(true);
    const [mobile, setMobile] = useState<boolean>(false);

    useIsomorphicLayoutEffect(() => {
      if (navRef.current) {
        setInit(true);
      }
    }, []);

    // On layout change, update classnames on parent component
    useEffect(() => {
      onClassNamesChange({
        'navi-init': init,
        'navi-compact': compact,
        'navi-mobile': mobile,
      });
    }, [init, compact, mobile]);

    useEffect(() => {
      if (navRef.current) {
        observerRef.current = new IntersectionObserver(
          (entries) => {
            const extraEntry = entries.find((e) => e.target === extraItemsRef.current);
            if (extraEntry) {
              setCompact(!extraEntry.isIntersecting);
            }

            const mainEntry = entries.find((e) => e.target === mainItemsRef.current);
            if (mainEntry) {
              setMobile(!mainEntry.isIntersecting);
            }

            setInit(false);
          },
          {
            root: navRef.current,
            threshold: MINIMUM_INTERSECTION_RATIO,
          }
        );
      }
    }, [navRef.current]);

    useEffect(() => {
      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
          observerRef.current = null;
        }
      };
    }, []);

    useEffect(() => {
      if (observerRef.current && mainItemsRef.current) {
        observerRef.current.observe(mainItemsRef.current);
      }

      return () => {
        if (observerRef.current) {
          observerRef.current.unobserve(mainItemsRef.current);
        }
      };
    }, [observerRef.current, mainItemsRef.current]);

    useEffect(() => {
      if (observerRef.current && extraItemsRef.current) {
        observerRef.current.observe(extraItemsRef.current);
      }

      return () => {
        if (observerRef.current) {
          observerRef.current.unobserve(extraItemsRef.current);
        }
      };
    }, [observerRef.current, extraItemsRef.current]);

    return (
      <Container>
        {items && (
          <>
            <MainItems data-test="Navi__MainItems" ref={mainItemsRef}>
              {items.main.map((path) => (
                <li key={path.path}>
                  <NaviTextLink item={path} />
                </li>
              ))}
            </MainItems>
            <ExtraItems ref={extraItemsRef}>
              {profileType !== ProfileType.Junior &&
                items.extra.map((path) => (
                  <li key={path.path}>
                    <NaviTextLink item={path} />
                  </li>
                ))}
            </ExtraItems>
          </>
        )}
      </Container>
    );
  }
);

NaviItemsContainer.displayName = 'NaviItemsContainer';
