import React from 'react';
import styled, { css } from 'styled-components';
import keys from 'lodash/keys';
import rgba from 'color-alpha';
import { BLACK, TELIAPURPLE, WHITE } from '../colors';
import { NaviDirection } from './models';
import { breakpoints, OwletBreakpoints } from '../breakpoints';
import { hideScrollbar } from '../common/styles';

export const StripeContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
`;

type Slot = keyof OwletBreakpoints | 'default'; // Any known breakpoint or `default`

export interface SlotContainerProps {
  slotBreakpoints: Partial<Record<Slot, number>>;
}

// Recognize Chrome 93+ and Firefox 91+
export const modernBrowserSupport =
  '(accent-color: red) or ((text-justify: inter-word) and (tab-size: 4) and (not (-moz-outline-radius: 10px)))';

const stripeColumn = (itemCount: number | undefined) => {
  if (!itemCount) {
    return;
  }

  return css`
    grid-auto-columns: ${(props) => `calc((100% - ${props.theme.horizontalMargin} * 2) / ${itemCount})`};

    @supports ${modernBrowserSupport} {
      grid-auto-columns: ${100 / itemCount}%;
    }
  `;
};

export const BaseSlotContainer = css<SlotContainerProps>`
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
  ${hideScrollbar('horizontal')};
  display: grid;
  grid-auto-flow: column;
  place-items: stretch / stretch;

  grid-template-columns: ${(props) => props.theme.horizontalMargin};
  &::before,
  &::after {
    content: '';
    width: ${(props) => props.theme.horizontalMargin};
  }

  ${(props) => stripeColumn(props.slotBreakpoints.default)};

  ${(props) =>
    keys(props.slotBreakpoints)
      .filter((key: Slot) => key !== 'default')
      .map(
        (key: Slot) => css`
          @media (min-width: ${breakpoints[key]}px) {
            ${stripeColumn(props.slotBreakpoints[key])};
          }
        `
      )};

  @supports ${modernBrowserSupport} {
    padding: 0 ${(props) => props.theme.horizontalMargin};
    grid-template-columns: none;

    &::before,
    &::after {
      display: none;
    }
  }
`;

export const SlotContainer = styled.ul<SlotContainerProps>`
  list-style: none;
  ${BaseSlotContainer};
`;

export const NaviContainer = styled.div``;

const NaviCtrl = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  overscroll-behavior-x: none;

  > * {
    opacity: 0;
  }

  &.has-items > * {
    opacity: 1;
  }
`;

export const NaviCtrlPrev = styled(NaviCtrl)`
  left: 0;
`;

export const NaviCtrlNext = styled(NaviCtrl)`
  right: 0;
`;

const DefaultNaviCtrlElement = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${rgba(BLACK, 0.6)};
  color: ${WHITE};
  width: ${(props) => props.theme.horizontalMargin};
  pointer-events: all;
  cursor: pointer;

  &:hover {
    color: ${TELIAPURPLE};
  }
`;

export const getDefaultNaviCtrlElement = (direction: NaviDirection) => {
  return <DefaultNaviCtrlElement>{direction === NaviDirection.PREV ? '◄' : '►'}</DefaultNaviCtrlElement>;
};
