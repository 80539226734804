import { createSelector, Selector } from 'reselect';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import { NotificationContext, NotificationsState } from './notifications.reducers';
import {
  filterCenterNotifications,
  filterBannerNotifications,
  orderNotificationCenterNotifications,
  isPersonalNotification,
} from '../../services/notifications';
import { selectActiveOrders, selectUser, selectActiveSubProfile } from '../user/user.selectors';
import has from 'lodash/has';

export const selectNotificationsState = () => (state: any): NotificationsState => state.notifications;

export const hasLoadedNotifications = createSelector(selectNotificationsState(), (state: NotificationsState) => {
  return state.loaded;
});

export const selectNotificationCenterOpened = createSelector(
  selectNotificationsState(),
  (state: NotificationsState) => {
    return state.notificationCenterLastOpened;
  }
);

export const selectAllNotifications = createSelector(selectNotificationsState(), (state: NotificationsState) => {
  return state.notifications;
});

export const selectClosedNotifications = createSelector(selectNotificationsState(), (state: NotificationsState) => {
  return state.closedNotifications;
});

export const selectPersonalNotifications = createSelector(selectNotificationsState(), (state: NotificationsState) => {
  return state.personalNotifications;
});

export const selectCenterNotifications = createSelector(
  [selectAllNotifications, selectActiveSubProfile, selectUser, selectActiveOrders],
  (notifications, activeProfile, user, orders) => {
    return orderNotificationCenterNotifications(filterCenterNotifications(notifications, user, orders));
  }
);

export const selectAssetNotifications = createSelector([selectAllNotifications], (notifications) => {
  return notifications.filter(
    (notification) => has(notification, 'conditions.assets') || has(notification, 'conditions.categories')
  );
});

export const selectUnseenNotifications = createSelector(
  [selectCenterNotifications, selectPersonalNotifications, selectNotificationCenterOpened],
  (notifications, personalNotifications, notificationCenterLastOpened) => {
    const allNotifications = [...personalNotifications, ...notifications];
    if (!notificationCenterLastOpened) {
      return allNotifications;
    }
    return (
      allNotifications
        // Filter out overlay notifications
        .filter((notification) => isPersonalNotification(notification) || notification.type !== 'overlay')
        .filter((item) => {
          return 'published' in item
            ? isAfter(parseISO(item.published), notificationCenterLastOpened)
            : isAfter(new Date(item.createdAt), notificationCenterLastOpened);
        })
    );
  }
);

export const selectUnseenEmergencyNotifications = createSelector([selectUnseenNotifications], (notifications) => {
  return notifications.filter((item) => {
    return 'type' in item && item.type === 'emergency';
  });
});

export const selectMenuNotificationCount = createSelector(
  [selectUnseenNotifications, selectUnseenEmergencyNotifications],
  (notifications, emergencies) => {
    if (emergencies && emergencies.length > 0) {
      return emergencies.length;
    }
    if (notifications && notifications.length > 0) {
      return notifications.length;
    }
    return 0;
  }
);

export const selectOverlayNotifications = createSelector([selectAllNotifications], (notifications) =>
  notifications.filter((notification) => notification.type === 'overlay')
);

export const selectBannerNotifications = createSelector(
  [selectAllNotifications, selectUser, selectActiveOrders],
  (notifications, user, orders) => {
    return filterBannerNotifications(notifications, user, orders);
  }
);

export const selectNotificationCenterLastOpenedChecked = createSelector(
  selectNotificationsState(),
  (state: NotificationsState) => {
    return state.notificationCenterLastOpenedChecked;
  }
);

export const selectClosedNotificationsChecked = createSelector(
  selectNotificationsState(),
  (state: NotificationsState) => {
    return state.closedNotificationsChecked;
  }
);

export const selectNotificationContext: Selector<NotificationsState, NotificationContext> = createSelector(
  selectNotificationsState(),
  (state: NotificationsState) => state.notificationContext
);
