import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../services/auth';
import { changeSubprofile } from '../../state/user/user.actions';
import { RED } from 'owlet-ui';
import { Subprofile } from '../../models/subprofile';
import { naviLinkStyles, ProfileIcon } from './styles';
import { PersonalInformation } from '../personal-information/PersonalInformation';
import { getConfig } from '../../services/conf';

interface NaviSubprofileProps {
  subprofile: Subprofile;
  className?: string;
  isNavi?: boolean;
  showEdit?: boolean;
}

const SubprofileLink = styled.a`
  ${naviLinkStyles};
  padding: 0.5rem 1.25rem !important;
  word-break: break-all;
  white-space: normal;
`;

const getProfileImage = (subprofile) => {
  if (subprofile.profilePicture?.charAt(0) === '#') {
    return (
      <span style={{ backgroundColor: subprofile.profilePicture }}>
        <PersonalInformation>{subprofile.name?.substring(0, 2) || ''}</PersonalInformation>
      </span>
    );
  } else {
    return <img src={`${getConfig().subprofilesImageUrl}/${subprofile.profilePicture}`} />;
  }
};

export const SubProfileListItem: FunctionComponent<NaviSubprofileProps> = ({
  subprofile,
  isNavi = true,
  className = null,
}) => {
  const dispatch = useDispatch();
  const { isAuthLoading, isAuthenticated, authToken, activeSubprofile } = useAuth();

  if (isAuthLoading || !isAuthenticated || !subprofile) {
    return null;
  }

  const isActiveProfile = isNavi && activeSubprofile && activeSubprofile.id === subprofile.id;

  const onProfileChange = () => {
    dispatch(changeSubprofile(subprofile.id, authToken));
  };

  const image = getProfileImage(subprofile);
  const name = subprofile.name || 'Ei nimeä';

  return (
    <li className={className}>
      <SubprofileLink
        onClick={onProfileChange}
        style={{ color: isActiveProfile ? RED : '' }}
        data-test={`Navi__MenuLink__Profile__${name?.toString()?.replace(' ', '')}`}
      >
        <ProfileIcon>{image}</ProfileIcon>
        <PersonalInformation>{name}</PersonalInformation>
      </SubprofileLink>
    </li>
  );
};
