import { Tracker, TrackingEvent, TrackingEventHandlerType } from '../../models/tracking';
import { trackers } from './trackers';
import isArray from 'lodash/isArray';
import uniqueId from 'lodash/uniqueId';
import { getCurrentDate } from '../time';

const queue: Map<Tracker, TrackingEvent[]> = new Map();

function processEvent(event: TrackingEvent): void {
  trackers.forEach((tracker: Tracker) => {
    const hasIncludeConfig = isArray(tracker.include);
    const hasExcludeConfig = isArray(tracker.exclude);
    const isIncluded = hasIncludeConfig && tracker.include.some((pattern) => Boolean(event.label.match(pattern)));
    const isExcluded = hasExcludeConfig && tracker.exclude.some((pattern) => Boolean(event.label.match(pattern)));

    // If tracker includes only some events and this event doesn't match a pattern, filter it out
    // OR
    // If event matches an exlude pattern and does not match an include pattern, filter it out
    if ((hasIncludeConfig && !isIncluded) || (isExcluded && !isIncluded)) {
      return;
    }

    if (!tracker.loaded) {
      if (!queue.has(tracker)) {
        queue.set(tracker, []);
      }

      queue.get(tracker).push(event);
    } else {
      tracker[event.handler](event);
    }
  });
}

export function purgeEvents(tracker: Tracker): void {
  const events = queue.get(tracker);

  if (events) {
    while (events.length) {
      const event = events.shift();
      tracker[event.handler](event);
    }
  }
}

export function pageView(label: string, data?: Record<string, any>): void {
  const event: TrackingEvent = {
    id: uniqueId('page_view'),
    handler: TrackingEventHandlerType.PageView,
    timestamp: getCurrentDate().getTime(),
    label,
    data,
    url: location.href,
  };

  processEvent(event);
}

export function customEvent(label: string, data?: Record<string, any>): void {
  const event: TrackingEvent = {
    id: uniqueId('event'),
    handler: TrackingEventHandlerType.CustomEvent,
    timestamp: getCurrentDate().getTime(),
    label,
    data,
    url: location.href,
  };

  processEvent(event);
}
