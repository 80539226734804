import { useState } from 'react';
import useSSR from 'use-ssr';
import { useWindowEventListener } from 'owlet-ui';
import { getConfig } from './conf';
import { isBrowser } from './utils';

export const OWLET_CONSENT_EVENT = 'owlet.consent';
const CONSENTS_NOT_AVAILABLE = '-';

export enum ConsentGroup {
  StrictlyNecessary = '1',
  Performance = '2',
  Functional = '3',
  Targeting = '4',
  SocialMedia = '5',
}

const allowedConsentGroups = [
  ConsentGroup.StrictlyNecessary,
  ConsentGroup.Performance,
  ConsentGroup.Functional,
  ConsentGroup.Targeting,
  ConsentGroup.SocialMedia,
];

export const consentGroupNames = {
  [ConsentGroup.StrictlyNecessary]: 'Välttämättömät toiminnat',
  [ConsentGroup.Performance]: 'Suorituskyky',
  [ConsentGroup.Functional]: 'Toimivuus',
  [ConsentGroup.Targeting]: 'Kohdistaminen',
  [ConsentGroup.SocialMedia]: 'Sosiaalinen media',
};

export interface UserConsents {
  consentIsReady: boolean;
  hasChatConsent: boolean;
  hasSocialMediaConsent: boolean;
  hasPerformanceConsent: boolean;
  consentGroups: string;
}

const readConsentGroupsString = (): string => {
  if (!isBrowser()) {
    return '';
  }
  const groupString = (window as any)?.OnetrustActiveGroups?.toString() || '';
  return groupString?.match(/^[0-9|a-z|A-Z|,]+$/) ? groupString : '';
};

const parseConsentGroups = (consentGroupString: string = ''): string[] => {
  return consentGroupString.split(',').filter((group) => allowedConsentGroups.includes(group as ConsentGroup));
};

const createConsentGroupsString = (consentGroups: string[]) => {
  return consentGroups.length ? consentGroups?.join(',') : CONSENTS_NOT_AVAILABLE;
};

export function useConsent(): UserConsents {
  const { isBrowser } = useSSR();
  const { enabled: consentEnabled } = getConfig().consent || {};

  // Make site behave like consents are accepted if consent modal has been disabled
  const ignoreConsent = !consentEnabled;

  const getConsentGroups = () => {
    if (ignoreConsent || !isBrowser) {
      return [];
    }
    const consentGroupString = readConsentGroupsString();
    return parseConsentGroups(consentGroupString);
  };

  const [consentGroups, setConsentGroups] = useState<string[]>(getConsentGroups());

  const updateConsents = () => {
    setConsentGroups(getConsentGroups());
  };

  // This event is triggered by OptanonWrapper() -callback (in OneTrustCookieConsent.tsx) which runs when consent scripts
  // load and consent changes.
  useWindowEventListener(OWLET_CONSENT_EVENT, () => {
    updateConsents();
  });

  const hasConsentForFunctionalCookies = () => {
    return ignoreConsent || consentGroups.includes(ConsentGroup.Functional);
  };

  const hasChatConsent = () => {
    return hasConsentForFunctionalCookies();
  };

  const hasSocialMediaConsent = () => {
    return ignoreConsent || consentGroups.includes(ConsentGroup.SocialMedia);
  };

  const hasPerformanceConsent = () => {
    return ignoreConsent || consentGroups.includes(ConsentGroup.Performance);
  };

  return {
    consentIsReady: consentGroups.length > 0 || !consentEnabled,
    hasChatConsent: hasChatConsent(),
    hasSocialMediaConsent: hasSocialMediaConsent(),
    hasPerformanceConsent: hasPerformanceConsent(),
    consentGroups: createConsentGroupsString(consentGroups),
  };
}

// This is simpler to use with services than the hook above
export function getConsentGroups(): string {
  const { enabled } = getConfig().consent || {};
  if (!enabled) return CONSENTS_NOT_AVAILABLE;

  const consentGroups = parseConsentGroups(readConsentGroupsString());
  return createConsentGroupsString(consentGroups);
}
