import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getConfig, getKatsomoServiceApiUrl, getVimondApiUrl } from './conf';
import filter from 'lodash/filter';
import find from 'lodash/find';
import head from 'lodash/head';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import orderBy from 'lodash/orderBy';
import { AutorenewStatus, Order, OrderTransaction, TELIA_TERMS_START_DATE } from '../models/order';
import { isBefore, isFuture, isWithinInterval } from 'date-fns';
import { buildURL, fetchJSON, fetchRaw } from './api';
import { UserOrders } from '../state/user/user.reducers';
import {
  selectActiveOrders,
  selectAllOrders,
  selectFutureOrders,
  selectIsOrdersCompleted,
  selectOrderHistory,
} from '../state/user/user.selectors';
import { getCurrentDate } from './time';
import {
  findProductById,
  findProductForOrder,
  isSinglePeriodProduct,
  isSubscriptionProduct,
} from './products/products';
import { CmProduct } from '../models/product';
import { selectNonPPVOrders, selectPPVOrders } from '../state/products/products.selectors';
import { getOrderConversions } from './products/order-conversions';
import { OrderStatus } from '../models/user';
import every from 'lodash/every';

export type VimondOrderProperty = { order: Order } | { order: Order[] } | string;

export async function fetchOrders(authToken: string = '', userId: string | number): Promise<UserOrders> {
  try {
    const headers: {
      Accept: string;
      Authorization: string;
      'Content-type': string;
    } = {
      Accept: 'application/json;charset=utf-8',
      Authorization: `${authToken}`,
      'Content-type': 'application/json;v=2',
    };

    // Current and future orders
    const respReq = fetchRaw(`${getVimondApiUrl()}/api/web/user/${userId}/orders`, {
      withCredentials: true,
      cors: true,
      headers,
    });

    // Order history
    const resp2Req = fetchRaw(`${getVimondApiUrl()}/api/web/user/${userId}/orders/history`, {
      withCredentials: true,
      cors: true,
      headers,
    });

    const [resp, resp2] = await Promise.all([respReq, resp2Req]);
    const orders: { orders: { active: VimondOrderProperty; future: VimondOrderProperty } } = await resp.json();
    const ordersHistory: Array<{ order: Order }> = await resp2.json();

    if (orders.orders && ordersHistory) {
      return {
        active: parseResponseProperty(orders.orders.active),
        future: parseResponseProperty(orders.orders.future),
        history: map(ordersHistory, 'order'),
      };
    } else {
      console.error('orders: failed to parse response', { orders, ordersHistory });
    }
  } catch (error) {
    console.error(error);
  }

  return null;
}

function parseResponseProperty(value: VimondOrderProperty): Order[] {
  if (!value || isString(value) || !value.order) {
    return [];
  }

  if (isArray(value.order)) {
    return value.order;
  }

  return [value.order];
}

export async function fetchTransactions(order: Order, authToken: string): Promise<OrderTransaction[]> {
  return fetchJSON(`${getVimondApiUrl()}/api/web/user/${order.userId}/orders/${order.id}/transactions`, {
    headers: {
      authorization: authToken,
    },
  });
}

export async function terminateOrder(order: Order, authToken: string, instantly?: boolean): Promise<boolean> {
  const url = buildURL(
    `${getVimondApiUrl()}/api/web/order/${order.id}/terminate`,
    instantly ? { instantly } : undefined
  );

  const response = await fetchRaw(url, {
    method: 'PUT',
    body: JSON.stringify({ orderId: order.id }),
    headers: {
      Accept: 'application/json;v=2',
      Authorization: authToken,
    },
  });

  return response.ok;
}

export async function reactivateOrder(order: Order, authToken: string): Promise<boolean> {
  const url = `${getVimondApiUrl()}/api/web/order/${order.id}/reactivate`;

  const response = await fetchRaw(url, {
    method: 'PUT',
    body: JSON.stringify({ orderId: order.id }),
    headers: {
      Accept: 'application/json;v=2',
      Authorization: authToken,
    },
  });

  return response.ok;
}

/**
 * Calls katsomo-service to update user's order status. Resolves to status object (currently only VIP enabled/disabled).
 */
export async function refreshOrderStatus(): Promise<OrderStatus> {
  return null;
}

export function isOrderAccessible(order: Order): boolean {
  if (!order) {
    return false;
  }

  const now = getCurrentDate();
  const start = new Date(order.startDate);
  const end = new Date(order.accessEndDate);

  return isWithinInterval(now, { start, end });
}

export function isOrderActive(order: Order): boolean {
  return order && order.autorenewStatus === AutorenewStatus.Active;
}

export function isOrderStopped(order: Order): boolean {
  return order && order.autorenewStatus === AutorenewStatus.Stopped;
}

export function isOrderConverted(order: Order): boolean {
  return order && order.autorenewStatus === AutorenewStatus.Converted;
}

export function isOrderEligible(order: Order): boolean {
  return order && order.autorenewStatus !== AutorenewStatus.NotEligible;
}

export function hasFixedTermOrders(orders: Order[], cmProducts: CmProduct[]): boolean {
  if (!orders || !cmProducts) {
    return false;
  }

  return orders.some((order) => isSinglePeriodProduct(findProductById(order.productId, cmProducts)));
}

export function getActiveAndFutureOrders(nonPPVOrders: { active: Order[]; future: Order[] }) {
  return [...(nonPPVOrders.active || []), ...(nonPPVOrders.future || [])];
}

export function hasMultipleSubscriptions(activeAndFutureOrders, validProducts): boolean {
  return (
    activeAndFutureOrders.reduce((result, order) => {
      const product = findProductForOrder(order, validProducts);

      if (isSubscriptionProduct(product)) {
        return [...result, product];
      }

      return result;
    }, []).length > 1
  );
}

export function getPrimaryActiveOrder(activeOrders: Order[], cmProducts: CmProduct[]): Order {
  if (!activeOrders || !cmProducts) {
    return null;
  }

  const getLastActiveSingleOrder = () => {
    const singleOrders = filter(activeOrders, (o) => isSinglePeriodProduct(findProductForOrder(o, cmProducts)));
    return singleOrders.length > 0 ? maxBy(singleOrders, (o: Order) => new Date(o.endDate).getTime()) : null;
  };

  return getActiveSubscription(activeOrders, cmProducts) || getLastActiveSingleOrder();
}

export function getActiveSubscription(activeOrders: Order[], cmProducts: CmProduct[]): Order {
  const firstSubscription = find(activeOrders, (o) => isSubscriptionProduct(findProductForOrder(o, cmProducts)));
  const autorenewSub = find(
    activeOrders,
    (o) => isSubscriptionProduct(findProductForOrder(o, cmProducts)) && isOrderActive(o)
  );

  return autorenewSub || firstSubscription;
}

export function getLastActiveSingleOrder(activeOrders: Order[], cmProducts: CmProduct[]): Order {
  const unsortedSinglePeriodOrders = activeOrders?.filter((o) =>
    isSinglePeriodProduct(findProductForOrder(o, cmProducts))
  );
  // There might be multiple single period orders
  // Take the single order that will end the last
  const lastActiveSinglePeriod = head(orderBy(unsortedSinglePeriodOrders, 'endDate', 'desc'));
  return lastActiveSinglePeriod;
}

export function findOnGoingOrder(activeOrder, future, active): Order {
  const conversion =
    future && activeOrder ? future.find((o) => isOrderActive(o) && o.upgradeOrderId === activeOrder.id) : null;

  let nextOrder;

  if (!conversion && active?.length > 1) {
    nextOrder = activeOrder;
  } else {
    nextOrder = conversion || active?.[0];
  }

  return nextOrder;
}

/**
 * Returns true if subscription product's number of minimum periods is greater than 1,
 * and order's earliest end date is in the future.
 *
 * @param order
 * @param cmProducts
 */
export function isOrderBinding(order: Order, cmProducts: CmProduct[]): boolean {
  const cmProduct = findProductForOrder(order, cmProducts);

  if (!cmProduct || !isSubscriptionProduct(cmProduct)) {
    return false;
  }

  return cmProduct.product.minimumPeriods > 1 && isFuture(new Date(order.earliestEndDate));
}

export interface UseOrdersResponse {
  isOrdersCompleted: boolean;
  activeOrders: Order[];
  futureOrders: Order[];
  orderHistory: Order[];
  allOrders: Order[];
  nonPPVOrders: { active: Order[]; future: Order[] };
  ppvOrders: Order[];
}

export function useOrders(): UseOrdersResponse {
  const isOrdersCompleted: boolean = useSelector(selectIsOrdersCompleted);
  const activeOrders: Order[] = useSelector(selectActiveOrders);
  const futureOrders: Order[] = useSelector(selectFutureOrders);
  const orderHistory: Order[] = useSelector(selectOrderHistory);
  const allOrders: Order[] = useSelector(selectAllOrders);
  const nonPPVOrders: { active: Order[]; future: Order[] } = useSelector(selectNonPPVOrders);
  const ppvOrders: Order[] = useSelector(selectPPVOrders);

  return { isOrdersCompleted, activeOrders, futureOrders, orderHistory, allOrders, nonPPVOrders, ppvOrders };
}

export function useActiveOrderAndProduct(isOrdersCompleted: boolean, active: Order[], products: CmProduct[]) {
  const [activeOrder, setActiveOrder] = useState<Order>(null);
  const [activeProduct, setActiveProduct] = useState<CmProduct>(null);
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (isOrdersCompleted && active && products) {
      const order = getPrimaryActiveOrder(active, products);
      setActiveOrder(order);
      setActiveProduct(findProductForOrder(order, products));
      setReady(true);
    }
  }, [isOrdersCompleted, active, products]);

  return {
    activeOrder,
    activeProduct,
    ready,
  };
}

export function useProductForOrder(
  order: Order,
  products: CmProduct[]
): {
  product: CmProduct;
  productDone: boolean;
} {
  const [product, setProduct] = useState<CmProduct>(null);
  const [productDone, setProductDone] = useState<boolean>(false);
  useEffect(() => {
    setProduct(findProductForOrder(order, products));
    setProductDone(true);
  }, [order]);

  return {
    product,
    productDone,
  };
}

export function hasCoexistDowngraded(orders: Order[]): boolean {
  if (orders.length >= 2) {
    const recentlyStoppedOrder = orders.find(
      (order) => isOrderStopped(order) && isFuture(new Date(order.accessEndDate))
    );

    if (recentlyStoppedOrder) {
      const coexistPaths = getOrderConversions(recentlyStoppedOrder.productGroupId)?.coexist;

      if (coexistPaths?.length > 0) {
        const coexistOrder = orders.find((order) => coexistPaths.includes(order.productGroupId));

        return isOrderActive(coexistOrder);
      }
    }
  }

  return false;
}

export function isMTVTermsOrder(order: Order) {
  return isBefore(new Date(order.startDate), TELIA_TERMS_START_DATE);
}

/**
 * Returns order that user has not paid anything for (all transactions have price=0).
 * However, does not return the order if it doesn't have any transactions.
 *
 * @param activeOrders
 * @param products
 * @param authToken
 */
export async function getFreePeriodOrder(
  activeOrders: Order[],
  products: CmProduct[],
  authToken: string
): Promise<Order> {
  if (!activeOrders?.length || !authToken) {
    return null;
  }

  const subscriptions = activeOrders.filter((order) => {
    const product = findProductForOrder(order, products);
    return isSubscriptionProduct(product);
  });

  const tasks = subscriptions.map((order) => fetchTransactions(order, authToken));

  return Promise.all(tasks).then((ordersTransactions) => {
    const freePeriodTransactions = ordersTransactions.find((transactions) => every(transactions, { price: 0 }));

    if (freePeriodTransactions?.length > 0) {
      return activeOrders?.find((order) => order.id === freePeriodTransactions[0].orderId);
    }

    return null;
  });
}

export function findOrderByProductId(productId: number | string, orders: Order[]): Order {
  return orders?.find((o) => Number(o.productId) === Number(productId));
}
