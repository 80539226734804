import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { NAVI_ICON_WIDTH, NaviIconWrap } from './styles';
import { FONT_MEDIUM, IconAvatar, medium, RED, SIZE_BASE, TELIAPURPLE, WHITE } from 'owlet-ui';
import { useAuth } from '../../services/auth';
import { useNotifications } from '../../services/notifications';
import { getConfig } from '../../services/conf';
import { selectSubprofilesErrorStatus } from '../../state/user/user.selectors';
import { greaterThan } from 'owlet-ui/breakpoints';
import { PersonalInformation } from '../personal-information/PersonalInformation';
import { useInvisibilityStart } from '../../state/app/app.selectors';
import { profileModalOpen } from '../../state/user/user.actions';
import { useOnDocumentVisibilityChange } from '../../services/utils';
import addMinutes from 'date-fns/addMinutes';
import isAfter from 'date-fns/isAfter';
import { PROFILE_MODAL_SKIP_PATHS, PROFILE_SELECTION_EXPIRATION_MINUTES } from '../../state/user/user.sagas';
import { isOrderStopped, useOrders } from '../../services/orders';

interface NaviUserProps {
  className?: string;
  hasCreditCardError?: boolean;
}

const Wrap = styled.div`
  ${medium()};
  font-size: ${SIZE_BASE};
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${WHITE};

  svg {
    width: ${NAVI_ICON_WIDTH};
    display: block;
    filter: drop-shadow(1px 1px 0 #000);
    fill: ${WHITE};
  }
`;

const Username = styled.div`
  display: none;
  white-space: nowrap;
  margin-left: 0.5rem;

  ${greaterThan('xxlarge')`
    display: block;
  `}
`;

export const Notification = styled.div`
  color: ${WHITE};
  background-color: ${TELIAPURPLE};
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  right: -8px;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  padding-top: 2px;

  ${greaterThan('xxlarge')`
    top: 2px;
    right: -16px;
  `}
`;

export const ErrorNotification = styled(Notification)`
  background-color: ${RED};
`;

const Icon = styled.div`
  padding-top: 0.15rem;

  > span {
    width: 26px;
    height: 26px;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${WHITE};
    text-transform: uppercase;
    font-size: 12px;
    font-family: ${FONT_MEDIUM};
  }

  img {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const NaviUser: FunctionComponent<NaviUserProps> = ({ className, hasCreditCardError }: NaviUserProps) => {
  const { user, isAuthenticated, activeSubprofile, isSubProfileLoaded } = useAuth();
  const { menuUnseenNotificationsCount } = useNotifications();
  const dispatch = useDispatch();
  const subprofilesError = useSelector(selectSubprofilesErrorStatus);
  const subprofilesEnabled = getConfig().subprofilesEnabled;
  const authenticated = isAuthenticated && user && isSubProfileLoaded;
  const invisibilityStart = useInvisibilityStart();
  const order = useOrders().activeOrders?.[0];

  const onWindowFocus = useCallback(
    (isVisible: boolean) => {
      if (isVisible && invisibilityStart) {
        const profileSelectionTTL = addMinutes(invisibilityStart, PROFILE_SELECTION_EXPIRATION_MINUTES);
        const shouldReSelectProfile = isAfter(new Date(), profileSelectionTTL);
        const pathname = document.location.pathname;
        const shouldShowModal = PROFILE_MODAL_SKIP_PATHS.some((path) => pathname.includes(path)) === false;

        if (shouldReSelectProfile && shouldShowModal) {
          dispatch(profileModalOpen());
        }
      }
    },
    [invisibilityStart]
  );

  useOnDocumentVisibilityChange(onWindowFocus);

  if (!authenticated) {
    return null;
  }

  const isKidsProfile = activeSubprofile?.kidsProfile;

  let icon, username;
  if (subprofilesEnabled && activeSubprofile && !subprofilesError) {
    let image;
    if (activeSubprofile.profilePicture?.charAt(0) === '#') {
      image = (
        <span style={{ backgroundColor: activeSubprofile.profilePicture }}>
          <PersonalInformation>{activeSubprofile.name?.substring(0, 2) || ''}</PersonalInformation>
        </span>
      );
    } else {
      image = <img src={`${getConfig().subprofilesImageUrl}/${activeSubprofile.profilePicture}`} alt="" />;
    }

    icon = <Icon>{image}</Icon>;
    username = activeSubprofile.name ? activeSubprofile.name : user.firstName;
  } else {
    icon = (
      <NaviIconWrap>
        <IconAvatar />
      </NaviIconWrap>
    );
    username = user.firstName || 'Oletus';
  }

  return (
    <Wrap className={className}>
      {icon}
      <Username>
        <PersonalInformation>{username}</PersonalInformation>
      </Username>
      {(hasCreditCardError && new Date(order?.earliestEndDate) <= new Date() && (
        <ErrorNotification>!</ErrorNotification>
      )) ||
        (hasCreditCardError && !isOrderStopped(order) && <Notification>!</Notification>) ||
        (menuUnseenNotificationsCount > 0 && !isKidsProfile && (
          <Notification>{menuUnseenNotificationsCount}</Notification>
        ))}
    </Wrap>
  );
};
