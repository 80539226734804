import React, { FunctionComponent } from 'react';
import { ProfileType, useProfileType } from './naviUtils';
import { MenuIcon } from './styles';
import { IconAspa } from 'owlet-ui';
import { NaviMenuLink } from './NaviMenuLink';

const icon = (
  <MenuIcon>
    <IconAspa />
  </MenuIcon>
);

export const NaviMenuCustomerSupport: FunctionComponent = () => {
  const profileType = useProfileType();

  if (profileType === ProfileType.Junior) {
    return null;
  }

  return <NaviMenuLink path="/asiakaspalvelu" title="Asiakaspalvelu" image={icon} />;
};
