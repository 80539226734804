import { CmProduct, ProductPayment } from '../../models/product';
import { fetchJSON, SKIP_CACHE, withCache } from '../api';
import { CuratedComponent } from '../../models/curated';
import { getMotherbirdApiUrl, getVimondApiUrl } from '../conf';
import omit from 'lodash/omit';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';

export const PRODUCTS_CACHE_KEY = 'products';

type VimondProductPaymentData =
  | { productPayments: { productPayment: ProductPayment | ProductPayment[] | string } }
  | string;

export async function fetchAllProducts(cache = true): Promise<CmProduct[]> {
  const task = () => fetchProductsWithPayments();

  return cache ? withCache(task, PRODUCTS_CACHE_KEY) : task();
}

async function fetchProductsWithPayments(): Promise<CmProduct[]> {
  const content = await fetchJSON<CuratedComponent[]>(
    `${getMotherbirdApiUrl()}/v3/static/svod/web/curated/all-products`,
    {
      throwErrors: false,
    }
  );

  if (!content?.[0]) {
    throw new Error('Invalid products data');
  }

  const products = cleanProducts(content[0].products);

  const productPayments = await fetchMissingProductPayments(products);

  for (const product of products) {
    const resolvedPayments = productPayments[product.product.id];

    if (resolvedPayments === null) {
      product.product.productPayments = null;
      products[SKIP_CACHE] = true; // Don't cache invalid data
    } else if (resolvedPayments) {
      product.product.productPayments = await resolvedPayments;
    }
  }

  return products;
}

async function fetchMissingProductPayments(products: CmProduct[]): Promise<Record<number, Promise<ProductPayment[]>>> {
  const resolveProductPayments: Record<number, Promise<ProductPayment[]>> = products.reduce(
    (acc, product: CmProduct) => {
      const {
        product: { productPayments },
      } = product;

      if (!productPayments || productPayments.length === 0) {
        acc[product.product.id] = fetchProductPayments(product);
      }

      return acc;
    },
    {}
  );

  if (Object.keys(resolveProductPayments).length > 0) {
    console.log('products: fetch missing product payments...');
  }

  return resolveProductPayments;
}

export async function fetchProductPayments(product: CmProduct): Promise<ProductPayment[]> {
  const {
    product: { id, productGroupId },
  } = product;

  try {
    const data: VimondProductPaymentData = await fetchJSON(
      `${getVimondApiUrl()}/api/web/productgroup/${productGroupId}/products/${id}/productPayments`,
      {
        headers: {
          Accept: 'application/json;v=1',
        },
        throwErrors: true,
      }
    );

    if (!data || isString(data) || !data['productPayments'] || !data['productPayments'].productPayment) {
      return [];
    }

    if (isArray(data.productPayments.productPayment)) {
      return data.productPayments.productPayment;
    }

    return [data.productPayments.productPayment as ProductPayment];
  } catch (e) {
    return null;
  }
}

function cleanProducts(products: CmProduct[]): CmProduct[] {
  return (
    products
      // Remove CmProducts that don't have Product
      .filter(({ product }) => Boolean(product))
      // Set up numeric id and omit unnecessary properties to reduce data size
      .map((cmProduct) => {
        cmProduct.product.id = Number(cmProduct.product['@id']);

        cmProduct.product.productPayments = cmProduct.product.productPayments.map(
          (payment) =>
            omit(payment, [
              '@uri',
              'initPeriodIso',
              'discountedPrice',
              'recurringPrice',
              'recurringDiscountedPrice',
              'sortIndex',
              'paymentObjectUri',
              'autorenewWarningChannel',
              'autoRenewWarningEnabled',
            ]) as ProductPayment
        );

        return omit(cmProduct, [
          'theme',
          'product.@id',
          'product.@uri',
          'product.productPaymentsUri',
          'product.currency',
          'product.comment',
          'product.paymentPlan.@id',
          'product.paymentPlan.name',
          'product.paymentPlan.periodIso',
          'product.sortIndex',
        ]) as CmProduct;
      })
  );
}
