import { CSSProperties, MouseEventHandler, useCallback, useEffect, useRef, useState, WheelEventHandler } from 'react';
import { isSafari, isTouchDevice } from '../../../services/browser';
import { DetailsFloatState } from '../../../state/ui/ui.reducers';
import { DETAILS_FLOAT_DELAY } from './DetailsFloat';
import { usePassWheelEvent, useScrollBarHeight, useWindowEventListener } from 'owlet-ui';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectDetailsFloatState } from '../../../state/ui/ui.selectors';
import { clearDetailsFloat, setDetailsFloat } from '../../../state/ui/ui.actions';
import { breakpoints } from 'owlet-ui/breakpoints';

export interface UseDetailsFloatResponse {
  state: DetailsFloatState;
  updateState: (state: DetailsFloatState) => void;
  clearState: () => void;
}

/**
 * Hook for managing details float state
 */
export function useDetailsFloat(): UseDetailsFloatResponse {
  const dispatch = useDispatch();
  const state: DetailsFloatState = useSelector(selectDetailsFloatState);

  const updateState = useCallback((state: DetailsFloatState) => {
    if (!isTouchDevice()) {
      dispatch(setDetailsFloat(state));
    }
  }, []);

  const clearState = useCallback(() => {
    dispatch(clearDetailsFloat());
  }, []);

  return {
    state,
    updateState,
    clearState,
  };
}

const config = {
  topPadding: 60,
  movieWidthFactor: 1.8,
  moviePadding: 14,
  categoryWidthFactor: 1,
  categoryPadding: 30,
};

export const DETAILS_FLOAT_HIDDEN: CSSProperties = {
  left: '-9999px',
  width: 0,
};

export interface UseDetailsFloatPlacementResponse {
  style: CSSProperties;
  isVisible: boolean;
  isPlaceholder: boolean;
  handleMouseOver: MouseEventHandler;
  handleMouseLeave: MouseEventHandler;
  handleMouseScroll: WheelEventHandler;
}

/**
 * Hook for managing details float element placement, events, and visibility
 */
export function useDetailsFloatPlacement(): UseDetailsFloatPlacementResponse {
  const { state, clearState } = useDetailsFloat();

  const [style, setStyle] = useState<CSSProperties>(DETAILS_FLOAT_HIDDEN);
  const [isPlaceholder, setPlaceholder] = useState<boolean>(false);
  const [isVisible, setVisible] = useState<boolean>(false);
  const parentEl = useRef<HTMLElement>(null);
  const containerEl = useRef<HTMLUListElement>(null);
  const delayTimer = useRef<any>();
  const scrollBarHeight = useScrollBarHeight();
  const { asPath } = useRouter();

  const hide = useCallback(() => {
    setStyle(DETAILS_FLOAT_HIDDEN);
    clearTimeout(delayTimer.current);
  }, []);

  const getViewportWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

  const calculatePosition = useCallback(() => {
    if (parentEl.current) {
      const { wideParent } = state;
      const position = parentEl.current.getBoundingClientRect();

      const viewportWidth = getViewportWidth();
      const marginWidth = 0.04 * viewportWidth;

      const isSmallScreen = viewportWidth <= breakpoints.small;
      const isXsmallScreen = viewportWidth <= breakpoints.xsmall;

      let right = isXsmallScreen ? 30 : 0;
      const width = isXsmallScreen
        ? 0
        : position.width * (wideParent ? config.categoryWidthFactor : config.movieWidthFactor) +
          (wideParent ? config.categoryPadding : config.moviePadding);
      let left = isXsmallScreen ? 30 : position.left - (width - position.width) / 2;

      if (!isXsmallScreen) {
        if (left < marginWidth) {
          left = marginWidth;
        }

        const rightEdge = left + width;
        const availableWidth = viewportWidth - scrollBarHeight;

        if (availableWidth - rightEdge < marginWidth) {
          left = 0;
          right = marginWidth;
        }
      }

      // Below small breakpoint, display element 30px lower
      const top = window.scrollY + position.top - config.topPadding + (isSmallScreen ? 30 : 0);

      return {
        top: `${top}px`,
        left: left === 0 ? 'auto' : `${left}px`,
        right: right === 0 ? 'auto' : `${right}px`,
        width: width === 0 ? 'auto' : `${width}px`,
      };
    }

    return null;
  }, [state]);

  /*
   * 1. State changes, find parent element and start timer.
   *    When timer ends, set placeholder state.
   */
  useEffect(() => {
    const { parentId } = state;

    parentEl.current = document.getElementById(parentId);

    if (document.querySelectorAll(`#${parentId}`).length > 1) {
      console.error(`Multiple elements found with #${parentId}`);
    }

    if (parentEl.current) {
      containerEl.current = parentEl.current.closest('ul');

      setVisible(false);
      setPlaceholder(false);

      delayTimer.current = setTimeout(() => {
        if (isSafari()) {
          setVisible(true);
        } else {
          setPlaceholder(true);
        }
      }, DETAILS_FLOAT_DELAY);
    } else {
      hide();
    }

    return hide;
  }, [state]);

  /*
   * 2. When element becomes a "placeholder", open it in the same size and same position
   *    as its parent element to catch mouse events.
   */
  useEffect(() => {
    if (isPlaceholder && parentEl.current) {
      const position = parentEl.current.getBoundingClientRect();
      const viewportWidth = getViewportWidth();
      const availableWidth = viewportWidth - scrollBarHeight;

      setStyle({
        top: `${window.scrollY + position.top}px`,
        left: `${position.left}px`,
        width: `${availableWidth < position.right ? availableWidth - position.left : position.width}px`,
        height: `${position.height}px`,
      });
    }
  }, [isPlaceholder, scrollBarHeight]);

  /*
   * 3. If placeholder element catches mouseover events and makes the element visible,
   *    calculate its real size and update style.
   */
  useEffect(() => {
    if (isVisible) {
      setPlaceholder(false);

      const position = calculatePosition();

      if (position) {
        setStyle(position);
      }
    } else {
      hide();
    }
  }, [isVisible]);

  useEffect(() => {
    hide();
  }, [asPath]);

  const reposition = useCallback(() => {
    const position = calculatePosition();

    if (position) {
      setStyle(position);
    } else {
      hide();
    }
  }, []);

  const clearActiveState = useCallback(() => {
    clearState();
  }, []);

  useWindowEventListener('resize', reposition);
  useWindowEventListener('blur', clearActiveState);
  useWindowEventListener('mouseleave', clearActiveState);

  const handleMouseOver = useCallback(
    (evt) => {
      evt.stopPropagation();

      if (isPlaceholder) {
        setVisible(true);
      }
    },
    [isPlaceholder]
  );

  const handleMouseLeave = useCallback(() => {
    setVisible(false);
    hide();
    clearState();
  }, []);

  // Pass scroll events to the parent list to make it possible to scroll it
  const handleMouseScroll = usePassWheelEvent(containerEl);

  return { style, isVisible, isPlaceholder, handleMouseOver, handleMouseLeave, handleMouseScroll };
}
