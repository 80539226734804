import produce from 'immer';

import { ActionTypes } from './series.actions';

import { Category } from '../../models/category';
import { Asset } from '../../models/asset';

export interface SeriesState {
  category: Category;
  selectedAsset: Asset;
  selectedSeason: string | number;
  assets: { [key: string]: Asset[] } | {};
}

export const initialSeriesState: SeriesState = {
  category: null,
  selectedAsset: null,
  selectedSeason: '',
  assets: {},
};

const seriesReducer = (state = initialSeriesState, action: any) => {
  switch (action.type) {
    case ActionTypes.CATEGORY_SUCCESS:
      return produce(state, (draft) => {
        draft.category = action.payload.category;
      });
    case ActionTypes.SELECTED_ASSET_SUCCESS:
      return produce(state, (draft) => {
        draft.selectedAsset = action.payload.asset;
      });
    case ActionTypes.SELECTED_SEASON_SUCCESS:
      return produce(state, (draft) => {
        draft.selectedSeason = action.payload.seasonId;
      });
    case ActionTypes.SEASON_ASSETS_SUCCESS:
      return produce(state, (draft) => {
        draft.assets[action.payload.seasonId] = action.payload.assets;
      });
    case ActionTypes.RESET:
      return produce(state, (draft) => {
        draft.category = null;
        draft.selectedAsset = null;
        draft.selectedSeason = '';
        draft.assets = {};
      });
    default:
      return state;
  }
};

export default seriesReducer;
