import { User, UserProperty, OrderStatus } from '../../models/user';
import { Subprofile } from '../../models/subprofile';
import { AuthMethod, LoginModalCloseMethod, UserOrders } from './user.reducers';
import { LinkData } from '../../services/links';

export const ActionTypes = {
  AUTH_REQUEST: 'user/auth_request',
  AUTH_SUCCESS: 'user/auth_success',
  AUTH_FAIL: 'user/auth_fail',
  AUTH_RENEW: 'user/auth_renew',
  LOGIN_REQUEST: 'user/login_request',
  LOGIN_SUCCESS: 'user/login_success',
  LOGIN_FAIL: 'user/login_fail',
  LOGIN_MODAL_OPEN: 'user/login_modal_open',
  LOGIN_MODAL_CLOSE: 'user/login_modal_close',
  LOGIN_ERROR_RESET: 'user/login_error_reset',
  PROFILE_MODAL_OPEN: 'user/profile_modal_open',
  PROFILE_MODAL_CLOSE: 'user/profile_modal_close',
  CLEAR_LOGIN_REDIRECT: 'user/clear_login_redirect',
  SET_LOGIN_CLOSE_METHOD: 'user/set_login_close_method',
  SET_LOGIN_CLOSE_REDIRECT: 'user/set_login_close_redirect',
  LOGOUT_REQUEST: 'user/logout_request',
  LOGOUT_SUCCESS: 'user/logout_success',
  LOGOUT_FAIL: 'user/logout_fail',
  SEGMENTS_SUCCESS: '/user/segments_success',
  SET_AUTH_TOKEN: 'user/set_auth_token',
  SET_AUTH_METHOD: 'user/set_auth_method',
  SET_PROFILES: 'user/set_profiles',
  FETCH_PROFILES: 'user/fetch_profiles',
  DELETE_PROFILE: 'user/delete_profile',
  SET_SHOULD_PROFILE_REDIRECT: 'user/set_should_profile_redirect',
  SET_HAS_PREVIOUSLY_SELECTED_PROFILE: 'user/set_has_previously_selected_profile',
  USER_UPDATE: 'user/update',
  USER_UPDATE_SUCCESS: 'user/update_success',
  ORDERS_REQUEST: 'user/orders_request',
  ORDERS_SUCCESS: 'user/orders_success',
  ORDERS_FAIL: 'user/orders_fail',
  ORDERS_REFRESH: 'user/orders_refresh',
  POST_AUTH_REQUEST: 'user/post_auth_request',
  GEOBLOCK_REQUEST: 'user/geoblock_request',
  GEOBLOCK_SUCCESS: 'user/geoblock_success',
  GEOBLOCK_FAIL: 'user/geoblock_fail',
  USER_PROPERTY_UPDATE: 'user/property_update',
  USER_PROPERTY_UPDATE_SUCCESS: 'user/property_update_success',
  REMOVE_PROPERTY_UPDATE_SUCCESS: 'user/remove_property_update_success',
  USER_PROPERTY_UPDATE_REQUEST: 'user/property_update_request',
  USER_PROPERTY_REPLACE_ALL: 'user/property_replace_all',
  USER_PROPERTY_UPDATE_FAIL: 'user/property_update_fail',
  USER_PROPERTY_DELETE: 'user/property_delete',
  REMOVE_USER_PROPERTY_ERROR: 'user/set_user_property_error',
  CHANGE_SUBPROFILE: 'user/change_subprofile',
  ACTIVATE_SUBPROFILE: 'user/set_active_subprofile',
  REFRESH_SUBPROFILE: 'user/refresh_subprofile',
  SUBPROFILES_LOAD_FAILED: 'user/subprofiles_load_failed',
  SET_WELCOME_BACK_MODAL_SEEN_AT: 'user/welcome_back_modal_seen_at',
  SET_TELIA_VAS_PLAY_ERROR: 'user/set_telia_vas_play_error',
  UPDATE_SMALL_OPERATOR_PROPERTY: 'user/update_small_operator_property',
  SET_ORDER_STATUS: 'user/set_order_status',
  CLEAR_ORDER_STATUS: 'user/clear_order_status',
};

export const authRequest = (requestToken?: string) => ({
  type: ActionTypes.AUTH_REQUEST,
  payload: requestToken,
});

export const authSuccess = (user: User) => ({
  type: ActionTypes.AUTH_SUCCESS,
  payload: user,
});

export const setAuthToken = (authToken: string) => ({
  type: ActionTypes.SET_AUTH_TOKEN,
  payload: authToken,
});

export const setAuthMethod = (authMethod: AuthMethod) => ({
  type: ActionTypes.SET_AUTH_METHOD,
  payload: authMethod,
});

export const authFail = () => ({
  type: ActionTypes.AUTH_FAIL,
});

export const authRenew = () => ({
  type: ActionTypes.AUTH_RENEW,
});

export const loginRequest = (username: string, password: string) => ({
  type: ActionTypes.LOGIN_REQUEST,
  payload: {
    username,
    password,
  },
});

export const loginSuccess = () => ({
  type: ActionTypes.LOGIN_SUCCESS,
});

export const loginFail = (errorCode: string) => ({
  type: ActionTypes.LOGIN_FAIL,
  payload: errorCode,
});

export const loginModalOpen = (payload: { username?: string; redirectOnLogin?: LinkData } = {}) => ({
  type: ActionTypes.LOGIN_MODAL_OPEN,
  payload,
});

export const setLoginCloseMethod = (method: LoginModalCloseMethod) => ({
  type: ActionTypes.SET_LOGIN_CLOSE_METHOD,
  payload: method,
});

export const setLoginCloseRedirect = (path: string) => ({
  type: ActionTypes.SET_LOGIN_CLOSE_REDIRECT,
  payload: path,
});

export const loginModalClose = () => ({
  type: ActionTypes.LOGIN_MODAL_CLOSE,
});

export const profileModalOpen = () => ({
  type: ActionTypes.PROFILE_MODAL_OPEN,
});

export const profileModalClose = () => ({
  type: ActionTypes.PROFILE_MODAL_CLOSE,
});

export const loginErrorReset = () => ({
  type: ActionTypes.LOGIN_ERROR_RESET,
});

export const clearLoginRedirect = () => ({
  type: ActionTypes.CLEAR_LOGIN_REDIRECT,
});

export const setProfiles = (profiles: Subprofile[], profileId?: string) => ({
  type: ActionTypes.SET_PROFILES,
  payload: {
    profiles,
    profileId: profileId ? profileId : null,
  },
});

export const refreshSubprofile = (profile: Subprofile) => ({
  type: ActionTypes.REFRESH_SUBPROFILE,
  payload: {
    profile,
  },
});

export const setShouldProfileRedirect = (shouldProfileRedirect: boolean) => ({
  type: ActionTypes.SET_SHOULD_PROFILE_REDIRECT,
  payload: {
    shouldProfileRedirect,
  },
});

export const setHasPreviouslySelectedProfile = (hasPreviouslySelectedProfile: boolean) => ({
  type: ActionTypes.SET_HAS_PREVIOUSLY_SELECTED_PROFILE,
  payload: {
    hasPreviouslySelectedProfile,
  },
});

/**
 * If redirectAction is undefined, default action is used (redirect to site root).
 * If redirectAction is false, user will not be redirected after logout.
 */
export const logoutRequest = (redirectTarget?: LinkData | false) => ({
  type: ActionTypes.LOGOUT_REQUEST,
  payload: redirectTarget,
});

export const logoutSuccess = () => ({
  type: ActionTypes.LOGOUT_SUCCESS,
});

export const logoutFail = () => ({
  type: ActionTypes.LOGOUT_FAIL,
});

export const userUpdate = (user: User) => ({
  type: ActionTypes.USER_UPDATE,
  payload: { user },
});

export const ordersSuccess = (orders: UserOrders) => ({
  type: ActionTypes.ORDERS_SUCCESS,
  payload: orders,
});

export const ordersFail = () => ({
  type: ActionTypes.ORDERS_FAIL,
});

export const ordersRefresh = () => ({
  type: ActionTypes.ORDERS_REFRESH,
});

export const segmentsSuccess = (segments: string[]) => ({
  type: ActionTypes.SEGMENTS_SUCCESS,
  payload: segments,
});

export const geoBlockRequest = () => ({
  type: ActionTypes.GEOBLOCK_REQUEST,
});

export const geoBlockSuccess = (geoBlockStatus: boolean) => ({
  type: ActionTypes.GEOBLOCK_SUCCESS,
  payload: geoBlockStatus,
});

export const geoBlockFail = () => ({
  type: ActionTypes.GEOBLOCK_FAIL,
});

export const updateUserPropertyRequest = (name: string) => ({
  type: ActionTypes.USER_PROPERTY_UPDATE_REQUEST,
  payload: {
    name,
  },
});

export const removeUserPropertySuccess = () => ({
  type: ActionTypes.REMOVE_PROPERTY_UPDATE_SUCCESS,
});

export const updateUserPropertySuccess = (message: string, name: string) => ({
  type: ActionTypes.USER_PROPERTY_UPDATE_SUCCESS,
  payload: {
    message,
    name,
  },
});

/**
 * @param name
 * @param value
 * @param previousValue If updating the property fails, its value is reverted to this value
 * @param localOnly If true, changes are not sent to the server
 */
export const updateUserProperty = (
  name: string,
  value: any,
  previousValue?: any,
  localOnly: boolean = false,
  delayed: boolean = false
) => ({
  type: ActionTypes.USER_PROPERTY_UPDATE,
  payload: {
    name,
    value,
    previousValue,
    localOnly,
    delayed,
  },
});

export const updateUserPropertyFail = (error: string, name: string, revertedValue: any) => ({
  type: ActionTypes.USER_PROPERTY_UPDATE_FAIL,
  payload: {
    error,
    name,
    revertedValue,
  },
});

export const deleteUserProperty = (name: string) => ({
  type: ActionTypes.USER_PROPERTY_DELETE,
  payload: name,
});

/**
 * Only replaces properties in the local state.
 * @param properties
 */
export const replaceAllUserProperties = (properties: UserProperty[]) => ({
  type: ActionTypes.USER_PROPERTY_REPLACE_ALL,
  payload: properties,
});

export const removeUserPropertyError = (name: string) => ({
  type: ActionTypes.REMOVE_USER_PROPERTY_ERROR,
  payload: name,
});

export const postAuthRequest = (user: User, authToken: string) => ({
  type: ActionTypes.POST_AUTH_REQUEST,
  payload: { user, authToken },
});

export const changeSubprofile = (subProfileId: string, authToken: string, redirectPath?: string) => ({
  type: ActionTypes.CHANGE_SUBPROFILE,
  payload: {
    subProfileId,
    authToken,
    redirectPath,
  },
});

export const activateSubProfile = (subProfileId: string, authToken: string) => ({
  type: ActionTypes.ACTIVATE_SUBPROFILE,
  payload: {
    subProfileId,
    authToken,
  },
});

export const requestSubprofiles = (authToken: string) => ({
  type: ActionTypes.FETCH_PROFILES,
  payload: {
    authToken,
  },
});

export const deleteProfile = (subprofileId: string) => ({
  type: ActionTypes.DELETE_PROFILE,
  payload: {
    subprofileId,
  },
});

export const subprofilesLoadFailed = () => ({
  type: ActionTypes.SUBPROFILES_LOAD_FAILED,
});

export const setWelcomeBackModalSeenAt = (date: Date) => ({
  type: ActionTypes.SET_WELCOME_BACK_MODAL_SEEN_AT,
  payload: date,
});

export const setTeliaVasPlayError = (isError: boolean) => ({
  type: ActionTypes.SET_TELIA_VAS_PLAY_ERROR,
  payload: isError,
});

export const updateSmallOperatorProperty = (isSmallOperator: boolean) => ({
  type: ActionTypes.UPDATE_SMALL_OPERATOR_PROPERTY,
  payload: isSmallOperator,
});

export const setOrderStatus = (orderStatus: OrderStatus) => ({
  type: ActionTypes.SET_ORDER_STATUS,
  payload: orderStatus,
});

export const clearOrderStatus = () => ({
  type: ActionTypes.CLEAR_ORDER_STATUS,
});
