import { useSelector } from 'react-redux';
import map from 'lodash/map';
import { getConfig, getKatsomoServiceApiUrl } from './conf';
import { selectUserSegments, selectIsSegmentsCompleted } from '../state/user/user.selectors';
import { User } from '../models/user';
import { Order } from '../models/order';
import { fetchRaw } from './api';

interface UseSegments {
  segments: string[];
  segmentsCompleted: boolean;
}

export function useSegments(): UseSegments {
  const segments = useSelector(selectUserSegments);
  const segmentsCompleted = useSelector(selectIsSegmentsCompleted);
  return {
    segments,
    segmentsCompleted,
  };
}

export function parseSegmentDataUrl(url: string, segments: string[], activeOrders: Order[], user: User): string {
  const productIds = map(activeOrders || [], 'productId');
  const productGroupIds = map(activeOrders || [], 'productGroupId');

  const products = productIds ? productIds.join(',') : '';
  const productGroups = productGroupIds ? productGroupIds.join(',') : '';
  const segmentsString = segments ? segments.join(',') : '';
  const userId = user ? Number(user.id) : 0;

  const newUrl = url
    .replace('__SEGMENTS__', segmentsString)
    .replace('__PRODUCTS__', productGroups)
    .replace('__PRODUCTIDS__', products)
    .replace('__CLIENT__', 'web')
    .replace('__ABSEGMENT__', userId % 2 === 0 ? 'a' : 'b');

  return newUrl;
}

export async function loadSegments(authToken: string): Promise<string[]> {
  if (!getConfig().features.segmentsEnabled) {
    return [];
  }

  const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `${authToken}`,
  };

  try {
    const resp = await fetchRaw(`${getKatsomoServiceApiUrl()}/segments?site=cmore`, {
      withCredentials: true,
      cors: true,
      headers,
    });

    if (!resp.ok) {
      console.warn('Could not load user segments. Moving on without segments.');
      return [];
    }

    const data = await resp.json();
    return data;
  } catch (ex) {
    console.warn('Could not load user segments. Moving on without segments.', ex);
    return [];
  }
}
