import { BLACK, TELIAPURPLE, WHITE15 } from '../colors';
import rgba from 'color-alpha';
import { OwletTheme } from './index';

export const CMORE: OwletTheme = {
  colors: {
    main: TELIAPURPLE,
    background: '#e9e9e9',
    altBackground: BLACK,
    disabledColor: WHITE15,
  },
  baseMarginBottom: '2rem',
  horizontalMargin: '4vw',
  horizontalMarginFraction: 0.04,
  naviHeight: '72px',
  naviHeightSmall: '60px',
  naviDropShadow: `0 8px 16px 0 ${rgba(BLACK, 0.2)}`,
  dropShadow: `3px 3px 6px ${rgba(BLACK, 0.2)}`,
  dropShadowOnWhite: `3px 3px 6px ${rgba(BLACK, 0.2)}, -1px -1px 6px ${rgba(BLACK, 0.2)}`,
  textShadow: `1px 1px ${BLACK}`,
  stripeMargin: '10px',
  stripeHalfMargin: '5px',
};
