import React, { FunctionComponent, MouseEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconFavorite, IconFavoriteOn, LIGHTEN, UnstyledButton, WHITE } from 'owlet-ui';
import {
  addFavorite,
  FavoriteTypeParam,
  fetchFavoriteStatus,
  removeFavorite,
  useFavoritesUpdater,
} from '../../../services/favorites';
import { useAuth } from '../../../services/auth';
import { useDispatch } from 'react-redux';
import { loginModalOpen } from '../../../state/user/user.actions';
import isBoolean from 'lodash/isBoolean';
import { useFeedback } from '../../../services/feedback';
import classNames from 'classnames';
import useSWR from 'swr';
import { getConfig } from '../../../services/conf';
import { Category } from '../../../models/category';
import { Asset, AssetType } from '../../../models/asset';
import { isAsset } from '../../../services/assets';
import { buildEDDLEvent, EDDLEventType, useEDDL } from '../../../services/tracking/eddl-analytics';
import { ContentContext } from '../../../services/content-context';

interface DetailsFavoriteButtonProps {
  item: Asset | Category;
  contentContext: ContentContext;
  contentTitle?: string;
}

const LikeButton = styled(UnstyledButton)`
  display: block;
  border-radius: 0.25rem;
  padding: 0.25rem;
  flex-shrink: 0;
  transform: translateY(0.25rem);

  &:active {
    background: ${LIGHTEN};
  }

  svg {
    width: 2rem;
    color: ${WHITE};
    fill: ${WHITE};
  }

  &.loading {
    visibility: hidden;
  }
`;

export const DetailsFavoriteButton: FunctionComponent<DetailsFavoriteButtonProps> = ({
  item,
  contentContext,
  contentTitle,
}: DetailsFavoriteButtonProps) => {
  const itemIsAsset = isAsset(item);
  // If episode or sport asset, point favorite button to series
  const isEpisode = itemIsAsset && (item.type === AssetType.Episode || item.type === AssetType.Sport);
  const itemId = isEpisode ? (item as Asset)?.mainCategoryId : item?.id;
  const type = itemIsAsset && !isEpisode ? FavoriteTypeParam.AssetId : FavoriteTypeParam.CategoryId;

  const { favoritesEnabled, favoritesMouseoverEnabled } = getConfig().features;
  const isFavoriteButtonEnabled = favoritesEnabled !== false && favoritesMouseoverEnabled !== false;

  const { isAuthCompleted, isAuthenticated, authToken, profileIdentifier } = useAuth();
  const dispatch = useDispatch();
  const [isFavorite, setFavorite] = useState<boolean>(null);
  const { error: showError } = useFeedback();

  const { data: serverStatus, isValidating: loading } = useSWR(
    profileIdentifier && favoritesEnabled ? `/favorites/${profileIdentifier}/${type}/${itemId}` : null,
    () => fetchFavoriteStatus(itemId, type, authToken),
    {
      dedupingInterval: 15000, // Only fetch server data every 15 seconds
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const updateFavorites = useFavoritesUpdater();

  useEffect(() => {
    if (isBoolean(serverStatus)) {
      setFavorite(serverStatus);
    }
  }, [serverStatus]);

  const handleFavoriteEDDL = useEDDL(() => {
    return buildEDDLEvent(EDDLEventType.Favorite, contentContext, item, contentTitle);
  }, [item, contentContext]);

  const handleUnfavoriteEDDL = useEDDL(() => {
    return buildEDDLEvent(EDDLEventType.Unfavorite, contentContext, item, contentTitle);
  }, [item, contentContext]);

  const toggleFavorite = useCallback(async () => {
    if (itemId && type && authToken) {
      let isSuccess: boolean;
      let newStatus: boolean;

      if (isFavorite) {
        isSuccess = await removeFavorite(itemId, type, authToken);
        newStatus = false;
      } else {
        isSuccess = await addFavorite(itemId, type, authToken);
        newStatus = true;
      }

      if (isSuccess) {
        setFavorite(newStatus);
        if (newStatus) {
          handleFavoriteEDDL();
        } else {
          handleUnfavoriteEDDL();
        }
        updateFavorites({ id: itemId, type, status: newStatus });
      } else {
        const message = isFavorite
          ? 'Suosikeista poistaminen epäonnistui. Yritä myöhemmin uudelleen.'
          : 'Suosikkeihin lisääminen epäonnistui. Yritä myöhemmin uudelleen.';

        showError({
          message,
          timeout: 10000,
        });
      }
    }
  }, [itemId, type, isFavorite, authToken]);

  const handleClick = useCallback(
    (evt: MouseEvent) => {
      evt?.preventDefault();
      evt?.stopPropagation();

      if (loading || !isAuthCompleted) {
        return;
      }

      if (!isAuthenticated) {
        dispatch(loginModalOpen());
        return;
      }

      toggleFavorite();
    },
    [isAuthCompleted, isAuthenticated, itemId, loading, toggleFavorite]
  );

  if (!isFavoriteButtonEnabled) {
    return null;
  }

  const className = classNames({ loading: loading || !isAuthCompleted });

  return (
    <LikeButton
      onClick={handleClick}
      className={className}
      aria-label={isFavorite ? 'Poista suosikeista' : 'Lisää suosikkeihin'}
    >
      {isFavorite ? <IconFavoriteOn /> : <IconFavorite />}
    </LikeButton>
  );
};
