import React from 'react';
import { SVGProps } from '../utils';

const SvgLike = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 132" {...props}>
    <defs>
      <mask id="like_svg__a">
        <circle cx={19.2} cy={105.4} r={4.7} />
      </mask>
    </defs>
    <path d="M106.6 132c-23.1 0-56-10.8-56.5-11-3.4-1.3-5.2-3.8-5.2-7-.1-6.3-.3-53-.3-53.5 0-4.9 5.6-5.1 10.1-5.3.6 0 1.2 0 1.7-.1 3.3-3.8 16-21.8 19.3-28 .8-1.6 1.6-4.8 2.4-7.8 2.1-8.5 5-20.1 14.6-19.4 5.7.5 9.8 2.6 12.2 6.3 5.1 7.7 1.2 19.7-1.9 29.4-1.1 3.6-2.3 7.3-2.6 9.5 1.7.2 5.5-.1 8.1-.4 10.1-1 25.3-2.5 30.8 10.9l.2.4.1.4c1.5 7.5-1.3 11.6-3.1 14.4-.8 1.2-1.3 2-1.4 2.7-.1 1.1.2 1.5.8 2.6.9 1.6 2.2 3.9 1.8 7.9-.4 4.4-2.5 6.5-3.9 7.9-.6.6-.8.8-.8 1 0 .3.4 1.2.7 1.9.9 2.2 2.4 5.5 1 9.7-1.2 3.6-3 5.2-4.4 6.3-.9.8-1.4 1.1-1.8 2.3-.3.8-.3 2.1-.3 3.5.1 3.4.2 8.5-5.3 12.1l-.3.2c-3.9 2.2-9.6 3.1-16 3.1zm-53.7-18.5c6.8 2.6 52.7 15.4 65.9 8.5 1.5-1 1.6-1.9 1.6-5.1 0-2-.1-4.4.9-6.7 1.2-3 2.9-4.4 4.1-5.4 1-.8 1.4-1.2 1.9-2.6.4-1.2 0-2.2-.7-4-.8-1.8-1.7-3.9-1.2-6.5s2-4.2 3-5.2 1.4-1.4 1.6-3c.1-1.5-.2-2-.8-3.2-.9-1.5-2-3.6-1.8-7.1.2-2.8 1.5-4.8 2.7-6.6 1.5-2.2 2.7-4 2-8-3.2-6.9-11.7-6.7-22.5-5.6-6.5.6-11.7 1.1-14.8-1.7-1-.9-2.2-2.6-2.1-5.2 0-3.3 1.4-7.6 3-12.5 2.4-7.6 5.8-18.1 2.8-22.6-.4-.6-1.6-2.4-6.2-2.8L92 8c-2.8 0-4.8 8.3-6.1 13.3-.9 3.7-1.8 7.2-3.1 9.6-2.4 4.5-18.6 28.9-22.4 31.4-1.3.8-2.8.8-5.4.9-.6 0-1.5 0-2.3.1 0 10.2.1 44.2.2 50.2zM32.8 54.9H5.6c-2.9 0-5.2 2.3-5.2 5.2V117c0 2.9 2.3 5.2 5.2 5.2h27.2c2.9 0 5.2-2.3 5.2-5.2V60c0-2.8-2.4-5.1-5.2-5.1zM19.2 110c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.7 2.1 4.7 4.7-2.1 4.7-4.7 4.7z" />
    <path
      d="M29.5 116h-21c-.8 0-1.5-.7-1.5-1.5v-52c0-.8.7-1.5 1.5-1.5h21c.8 0 1.5.7 1.5 1.5v52c0 .8-.7 1.5-1.5 1.5z"
      mask="url(#like_svg__a)"
    />
  </svg>
);

export default SvgLike;
