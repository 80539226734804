import { FunctionComponent, useEffect } from 'react';
import { useAuth } from '../../services/auth';
import { setUser } from '../../services/sentry';

export const ErrorLoggerInit: FunctionComponent = () => {
  const { user } = useAuth();

  // Update the user attached to the error logger
  useEffect(() => {
    setUser(user);
  }, [user]);

  return null;
};
