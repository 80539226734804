import React from 'react';
import { SVGProps } from '../utils';

const SvgAvatar = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 434.59 434.64" {...props}>
    <path d="M217.32 434.64h-.07C97.43 434.62 0 337.15 0 217.35A217.27 217.27 0 01370.95 63.69a217.29 217.29 0 01-153.63 370.95m-.11-408.24A191 191 0 0026.4 217.35c0 105.25 85.6 190.87 190.79 190.88h.13c105.23 0 190.85-85.63 190.87-190.88a191 191 0 00-191-191" />
    <path d="M115.24 236a25.52 25.52 0 1125.52-25.53A25.56 25.56 0 01115.24 236m204.11 0a25.52 25.52 0 1125.52-25.53A25.56 25.56 0 01319.35 236m-102.07 96.38a115.26 115.26 0 01-93.91-48.91 13.2 13.2 0 0121.57-15.22 88.14 88.14 0 00144.61 0 13.2 13.2 0 1121.56 15.24 115.27 115.27 0 01-93.83 48.9" />
  </svg>
);

export default SvgAvatar;
