import get from 'lodash/get';
import getNextConfig from 'next/config';
import { Config } from '../models/config';
import { isSafari } from './browser';

export function getConfig(): Config {
  return getNextConfig().publicRuntimeConfig;
}

export function getConfigFeatures() {
  return getConfig().features;
}

export function getConfigDisabledFeatures() {
  return getConfig().disabledFeatures ? getConfig().disabledFeatures : undefined;
}

export function getConfigValue<T = any>(key: string): T {
  return get(getConfig(), key);
}

export function getMotherbirdApiUrl(): string {
  return shouldUseSameDomainApis()
    ? get(getConfig(), 'sameDomainApi.motherbirdUrl')
    : get(getConfig(), 'api.motherbirdUrl');
}

export function getKatsomoServiceApiUrl(): string {
  return shouldUseSameDomainApis()
    ? get(getConfig(), 'sameDomainApi.katsomoApiUrl')
    : get(getConfig(), 'api.katsomoApiUrl');
}

export function getVimondApiUrl(): string {
  return shouldUseSameDomainApis() ? get(getConfig(), 'sameDomainApi.vimondUrl') : get(getConfig(), 'api.vimondUrl');
}

export function getCookieSite(): string {
  return shouldUseSameDomainApis() ? get(getConfig(), 'sameDomainApi.cookieSite') : get(getConfig(), 'api.cookieSite');
}

export function shouldUseSameDomainApis(): boolean {
  return isSafari();
}

export const VIP_SUPPORT_URL = '/asiakaspalvelu/Ostaminen_ja_tilaukseni/K_yt_n_aloittaminen/VIP-ennakko';
export const TELIA_VAS_CUSTOMER_URL = 'https://www.telia.fi/OmatSivut/CMORE/cmore/directlink.do';
export const MTV_COMPANY_TERMS_URL = 'http://mtvyritys.fi/cmore/ehdot';
export const MTV_COMPANY_PRIVACY_POLICY_URL = 'http://mtvyritys.fi/cmore/tietosuoja';
export const MTV_PRIVACY_POLICY_URL = 'https://www.mtvyritys.fi/tietosuoja';
export const MTV_TERMS_URL = 'https://www.mtvyritys.fi/kayttoehdot';
export const CMORE_READ_MORE_URL = '/asiakaspalvelu/Ostaminen_ja_tilaukseni/K_yt_n_aloittaminen/C-More-siirtyy-MTV';
