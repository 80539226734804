export function saveToSession(key: string, value: string) {
  try {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      window.sessionStorage.setItem(key, value);
    }
  } catch (e) {
    console.error('Session storage unavailable for saving', key);
  }
}

export function getFromSession(key: string): string {
  try {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      return window.sessionStorage.getItem(key);
    }
  } catch (e) {
    console.error('Session storage unavailable for key', key);
    return null;
  }
}

export function removeFromSession(key: string) {
  try {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      window.sessionStorage.removeItem(key);
    }
  } catch (e) {
    console.error('Sessionstorage unavailable for removing key', key);
  }
}
