import React from 'react';
import { SVGProps } from '../utils';

const SvgMenu = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path d="M120 148h272a13 13 0 100-26H120a13 13 0 000 26zm272 95H120a13 13 0 100 26h272a13 13 0 100-26zm0 121H120a13 13 0 000 26h272a13 13 0 000-26z" />
  </svg>
);

export default SvgMenu;
