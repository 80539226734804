import { ClosedNotification, Notification, PersonalNotification } from '../../models/notification';
import { NotificationContext } from './notifications.reducers';

export const ActionTypes = {
  LOADED: 'notifications/loaded',
  NOTIFICATION_CENTER_SEEN: 'notifications/notification_center_seen',
  SET_CLOSED_NOTIFICATIONS: 'notifications/set_closed_notifications',
  APPEND_CLOSED_NOTIFICATION: 'notifications/append_closed_notification',
  REMOVE_CLOSED_NOTIFICATION: 'notifications/remove_closed_notification',
  CLEAR_CLOSED_NOTIFICATION: 'notifications/clear_closed_notification',
  CLOSE_NOTIFICATION: 'notifications/close_notification',
  PERSONAL_NOTIFICATIONS_REQUEST: 'notifications/personal_notifications_request',
  PERSONAL_NOTIFICATIONS_SUCCESS: 'notifications/personal_notifications_success',
  PERSONAL_NOTIFICATIONS_FAIL: 'notifications/personal_notifications_fail',
  NOTIFICATIONS_REQUEST: 'notifications/notifications_request',
  NOTIFICATIONS_SUCCESS: 'notifications/notifications_success',
  NOTIFICATIONS_FAIL: 'notifications/notifications_fail',
  SET_NOTIFICATION_CONTEXT: 'notifications/set_context',
  CLEAR_NOTIFICATION_CONTEXT: 'notifications/clear_context',
};

export const notificationsLoaded = () => ({
  type: ActionTypes.LOADED,
});

export const notificationCenterSeen = (seenAt: Date) => ({
  type: ActionTypes.NOTIFICATION_CENTER_SEEN,
  payload: seenAt,
});

export const setClosedNotifications = (notifications: ClosedNotification[]) => ({
  type: ActionTypes.SET_CLOSED_NOTIFICATIONS,
  payload: notifications,
});

export const appendClosedNotification = (notification: Notification) => ({
  type: ActionTypes.APPEND_CLOSED_NOTIFICATION,
  payload: notification,
});

export const removeClosedNotification = (notificationId: string) => ({
  type: ActionTypes.REMOVE_CLOSED_NOTIFICATION,
  payload: notificationId,
});

export const clearClosedNotifications = () => ({
  type: ActionTypes.CLEAR_CLOSED_NOTIFICATION,
});

export const requestNotifications = () => ({
  type: ActionTypes.NOTIFICATIONS_REQUEST,
});

export const notificationsSuccess = (notifications: Notification[]) => ({
  type: ActionTypes.NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

export const notificationsFail = () => ({
  type: ActionTypes.NOTIFICATIONS_FAIL,
});

export const requestPersonalNotifications = (authToken: string) => ({
  type: ActionTypes.PERSONAL_NOTIFICATIONS_REQUEST,
  payload: authToken,
});

export const personalNotificationsSuccess = (notifications: PersonalNotification[]) => ({
  type: ActionTypes.PERSONAL_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

export const personalNotificationsFail = () => ({
  type: ActionTypes.PERSONAL_NOTIFICATIONS_FAIL,
});

export const setNotificationContext = (ctx: NotificationContext) => ({
  type: ActionTypes.SET_NOTIFICATION_CONTEXT,
  payload: ctx,
});

export const clearNotificationContext = () => ({
  type: ActionTypes.CLEAR_NOTIFICATION_CONTEXT,
});
