import React from 'react';

export interface PaginationContextProps {
  pageCount: number;
  currentPage: number;
}

export const PaginationContext = React.createContext<PaginationContextProps>({
  pageCount: 0,
  currentPage: 0,
});
