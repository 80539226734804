import React from 'react';
import { SVGProps } from '../utils';

const SvgPlay = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path d="M93.51 467.42c-14.2 0-24.11-11.19-24.11-27.2V71.78c0-16 9.92-27.2 24.11-27.2A30.56 30.56 0 01108.83 49l319.08 184.23c9.34 5.39 14.7 13.69 14.7 22.77s-5.36 17.38-14.7 22.77L108.83 463a30.57 30.57 0 01-15.32 4.42zM95.79 72v368l318.76-184z" />
  </svg>
);

export default SvgPlay;
