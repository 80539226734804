import React, { ComponentType, FunctionComponent } from 'react';
import { UnstyledButton } from './styles';
import styled, { StyledComponentProps } from 'styled-components';
import { OwletTheme } from '../themes';

type IconButtonProps = StyledComponentProps<'button', OwletTheme, {}, never> & {
  icon: ComponentType;
};

const ContainerButton = styled(UnstyledButton)`
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const IconButton: FunctionComponent<IconButtonProps> = ({
  icon: IconComponent,
  type = 'button',
  ...props
}: IconButtonProps) => {
  return (
    <ContainerButton type={type} {...props}>
      <IconComponent />
    </ContainerButton>
  );
};
