import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { BLACK, IconClock, IconLive, RED, SIZE_SMALLER, WHITE, WHITE90 } from 'owlet-ui';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import { greaterThan } from 'owlet-ui/breakpoints';
import { dateToBadgeData } from '../../services/format/date';

export interface DateBadgeProps {
  date: Date;
  endDate?: Date;
  live?: boolean;
  showTime?: boolean;
  className?: string;
}

export const DateBadgeWrap = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  background: ${WHITE90};
  border-radius: 5px;
  font-size: ${SIZE_SMALLER};
  padding: 0 0.5rem;
  color: ${BLACK};
  white-space: nowrap;

  svg {
    display: block !important;
    filter: none !important;
  }

  &.live {
    background: ${RED};
    color: ${WHITE};
  }

  .small {
    display: none;

    ${greaterThan('small')`
      display: inline;
    `}
  }

  .large {
    display: none;

    ${greaterThan('large')`
      display: inline;
    `}
  }
`;

export const smallDateBadgeTextStyles = css`
  ${DateBadgeWrap} {
    font-size: 0.7rem;
    line-height: 0.5rem;
    padding: 0 0.25rem;
    svg {
      width: 0.8rem !important;
    }
  }
`;

const Icon = styled.div`
  margin: 0.25rem 0.25rem 0.25rem 0;

  .live > & {
    margin-left: 0;
  }
`;

const StyledIconClock = styled(IconClock)`
  &&& {
    width: 1rem;
    color: ${BLACK};
    fill: ${BLACK};
    overflow: visible;
  }
`;

const StyledIconLive = styled(IconLive)`
  &&& {
    width: 1.5rem;
    color: ${WHITE};
    fill: ${WHITE};
  }
`;

export const DateBadge: FunctionComponent<DateBadgeProps> = ({
  date: originalDate,
  endDate,
  live,
  showTime,
  className,
}: DateBadgeProps) => {
  if (!originalDate) {
    return null;
  }

  const cls = classNames(className, { live });

  const { relativeDate, date, isLongDate, shortWeekday, startTime, endTime } = dateToBadgeData(originalDate, {
    endDate,
  });

  return (
    <DateBadgeWrap className={cls}>
      <Icon>
        {live && <StyledIconLive data-test="DateBadge__IconLive" />}
        {!live && <StyledIconClock data-test="DateBadge__IconClock" />}
      </Icon>
      {capitalize(relativeDate)}
      {!relativeDate && (
        <>
          {!isLongDate && <span className="large">{capitalize(shortWeekday)}&nbsp;</span>}
          {date}
        </>
      )}
      {showTime && (
        <>
          <span className="small large wide">&nbsp;klo</span>
          <span className={relativeDate ? 'xxsmall' : undefined}>
            &nbsp;{startTime}
            {endTime && <span className="large">&nbsp;-&nbsp;{endTime}</span>}
          </span>
        </>
      )}
    </DateBadgeWrap>
  );
};
