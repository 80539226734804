import styled from 'styled-components';
import { SIZE_LARGE } from 'owlet-ui';

export const ErrorPageWrap = styled.div`
  max-width: 50rem;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ErrorText = styled.div`
  font-size: ${SIZE_LARGE};
`;
