import React, { ComponentType, FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IconCmore, IconFacebook, IconInstagram, IconTwitter } from 'owlet-ui';
import { TreePath } from '../../models/tree';
import { PathLink } from '../link/PathLink';
import Link from 'next/link';
import { useAuth } from '../../services/auth';
import { TermsLink } from '../terms/TermsLink';
import { ProfileType, useProfileType } from '../navi/naviUtils';
import { ExternalLink } from '../link/ExternalLink';
import { useConsentModal } from '../../services/consent-modal';
import { Column, Item, LinksContainer, LogoContainer, Wrap } from './styles';
import { getConfig } from '../../services/conf';
import { selectTree } from '../../state/app/app.selectors';

interface SocialMediaItem {
  label: string;
  href: string;
  icon: ComponentType;
}

const socialMediaItems: SocialMediaItem[] = [
  { label: 'Twitter', icon: IconTwitter, href: 'https://twitter.com/CMoreFI?lang=fi' },
  { label: 'Facebook', icon: IconFacebook, href: 'https://www.facebook.com/cmoresuomi' },
  { label: 'Instagram', icon: IconInstagram, href: 'https://www.instagram.com/cmorefi/' },
];

export const Footer: FunctionComponent = () => {
  const { isAuthenticated } = useAuth();
  const { enabled: isConsentEnabled } = getConfig().consent;
  const { openSettings } = useConsentModal();
  const profileType = useProfileType();
  const tree = useSelector(selectTree);

  const column2 = useMemo(
    () =>
      tree
        ?.filter(({ main }) => !main)
        .map(({ path, title }: TreePath, i) => (
          <Item key={i}>
            <PathLink path={path}>
              <a>{title}</a>
            </PathLink>
          </Item>
        )),
    [tree]
  );

  const logoContent = (
    <a aria-label="Etusivulle">
      <IconCmore />
    </a>
  );

  const authenticatedHome = profileType === ProfileType.Junior ? '/juniori' : '/etusivu';
  const shouldShowLinks = profileType !== ProfileType.Junior;

  return (
    <Wrap>
      <LogoContainer data-test="Footer__Logo">
        {isAuthenticated ? (
          <Link href={authenticatedHome} as={authenticatedHome} passHref>
            {logoContent}
          </Link>
        ) : (
          <Link href="/" as="/" passHref>
            {logoContent}
          </Link>
        )}
      </LogoContainer>
      {shouldShowLinks && (
        <LinksContainer>
          <Column>
            <Item>
              <Link href="/asiakaspalvelu" as="/asiakaspalvelu">
                <a>Asiakaspalvelu</a>
              </Link>
            </Item>
            <Item>
              <Link
                href="/asiakaspalvelu/[category]/[subcategory]"
                as="/asiakaspalvelu/Operaattorin_asiakkaalle/Yleista"
              >
                <a>Operaattoriasiakkaat</a>
              </Link>
            </Item>
            <Item>
              <TermsLink>Tietosuojaseloste</TermsLink>
            </Item>
            <Item>
              <TermsLink>Käyttöehdot</TermsLink>
            </Item>
            {isConsentEnabled && (
              <Item>
                <a onClick={openSettings} data-test="Footer__Consent">
                  Evästeasetukset
                </a>
              </Item>
            )}
            <Item>
              <Link href="/koodi" as="/koodi">
                <a>Onko sinulla koodi?</a>
              </Link>
            </Item>
            <Item>
              <Link href="https://www.mtvyritys.fi/">
                <a target="_blank">MTV yhtiönä</a>
              </Link>
            </Item>
          </Column>
          <Column>{column2}</Column>
          <Column>
            {socialMediaItems.map(({ label, href, icon: IconComponent }) => (
              <Item key={href}>
                <ExternalLink href={href}>
                  {IconComponent && <IconComponent />}
                  {label}
                </ExternalLink>
              </Item>
            ))}
          </Column>
        </LinksContainer>
      )}
    </Wrap>
  );
};
