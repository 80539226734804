import { FeedbackMessage } from '../../models/feedback';

export const ActionTypes = {
  ADD_FEEDBACK_MESSAGE: 'feedback/add_message',
  REMOVE_FEEDBACK_MESSAGE: 'feedback/remove_message',
  REMOVE_FEEDBACK_MESSAGE_BY_HANDLE: 'feedback/remove_message_by_handle',
  CLEAR_FEEDBACK_MESSAGES: 'feedback/clear_messages',
  CLEAR_FEEDBACK_MESSAGES_ON_NAVIGATION: 'feedback/clear_messages_on_navigation',
};

export const addFeedbackMessage = (message: FeedbackMessage) => ({
  type: ActionTypes.ADD_FEEDBACK_MESSAGE,
  payload: message,
});

export const removeFeedbackMessage = (message: FeedbackMessage) => ({
  type: ActionTypes.REMOVE_FEEDBACK_MESSAGE,
  payload: message,
});

export const removeFeedbackMessageByHandle = (handle: string) => ({
  type: ActionTypes.REMOVE_FEEDBACK_MESSAGE_BY_HANDLE,
  payload: handle,
});

export const clearFeedbackMessages = () => ({
  type: ActionTypes.CLEAR_FEEDBACK_MESSAGES,
});

export const clearFeedbackMessagesOnNavigation = () => ({
  type: ActionTypes.CLEAR_FEEDBACK_MESSAGES_ON_NAVIGATION,
});
