import { CmProduct } from '../../models/product';
import { ActionTypes } from './products.actions';
import produce from 'immer';

export interface ProductsState {
  products: CmProduct[];
  productsLoadedState: ProductsLoadedState;
}

export enum ProductsLoadedState {
  NotLoaded = 'notLoaded',
  Loaded = 'loaded',
  Error = 'error',
}

export const initialProductsState: ProductsState = {
  products: [],
  productsLoadedState: ProductsLoadedState.NotLoaded,
};

const productsReducer = (state = initialProductsState, action: any) => {
  switch (action.type) {
    case ActionTypes.PRODUCTS_SUCCESS:
      return produce(state, (draft) => {
        draft.products = action.payload;
        draft.productsLoadedState = ProductsLoadedState.Loaded;
      });
    case ActionTypes.PRODUCTS_FAIL:
      console.log('products: fail');
      return produce(state, (draft) => {
        draft.products = [];
        draft.productsLoadedState = ProductsLoadedState.Error;
      });
    default:
      return state;
  }
};

export default productsReducer;
