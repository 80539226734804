import produce from 'immer';

import map from 'lodash/map';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';

import { ActionTypes } from './user.actions';
import { User, UserProperty, OrderStatus } from '../../models/user';
import { Subprofile } from '../../models/subprofile';
import { Order } from '../../models/order';
import { LinkData } from '../../services/links';
import { OperatorProperties } from '../../models/operator';
import { getItem, Keys, removeItem } from '../../services/local-storage';
import { PROFILE_MODAL_SKIP_PATHS } from './user.sagas';

export enum AuthMethod {
  Cookie,
  Manual,
  Operator,
}

export enum LoginModalCloseMethod {
  Redirect,
  Manual,
}

export interface UserState {
  user: UserData;
  authStarted: boolean;
  authCompleted: boolean;
  authMethod: AuthMethod;
  subProfileLoaded: boolean;
  ordersStarted: boolean;
  ordersCompleted: boolean;
  ordersError: boolean;
  segmentsCompleted: boolean;
  isLoginModalOpen: boolean;
  isProfileModalOpen: boolean;
  shouldProfileRedirect: boolean;
  hasPreviouslySelectedProfile: boolean;
  loginModalUsername: string;
  loginRedirect: LinkData | null;
  isLoggingOut: boolean;
  subprofilesError: boolean;
  welcomeBackModalSeenAt: Date;
  loginModalCloseRedirect: string;
  loginModalCloseMethod: LoginModalCloseMethod;
  teliaVasPlayError: boolean; // Show error if access to an asset is denied for a VAS user
  smallOperatorPropertyUpdated: boolean; // True if small operator property has been automatically updated to reflect order status
  vipStatus: VIPStatus;

  // Error messages related to property storing
  propertyErrors: Record<string, string>;
  propertyUpdateSuccess: string;
  propertyUpdateProgress: { [propertyName: string]: boolean };
}

export interface UserOrders {
  active: Order[];
  future: Order[];
  history: Order[];
}

export interface UserData {
  authToken: string;
  profile: User;
  activeSubprofileId: string;
  subprofiles: Subprofile[];
  orders: UserOrders;
  loginError?: string;
  geoBlocked: boolean;
  segments: string[];
}

export enum VIPStatus {
  Unknown,
  Enabled,
  Disabled,
}

export const initialUserState: UserState = {
  user: {
    authToken: '',
    profile: null,
    activeSubprofileId: null,
    subprofiles: [],
    orders: {
      active: null,
      future: null,
      history: null,
    },
    geoBlocked: false,
    segments: [],
  },
  authStarted: false,
  subProfileLoaded: false,
  authCompleted: false,
  authMethod: null,
  ordersStarted: false,
  ordersCompleted: false,
  ordersError: false,
  segmentsCompleted: false,
  propertyErrors: {},
  propertyUpdateProgress: {},
  propertyUpdateSuccess: null,
  isLoginModalOpen: false,
  isProfileModalOpen: false,
  shouldProfileRedirect: true,
  hasPreviouslySelectedProfile: false,
  loginModalUsername: null,
  loginRedirect: null,
  isLoggingOut: false,
  subprofilesError: false,
  welcomeBackModalSeenAt: null,
  loginModalCloseRedirect: null,
  loginModalCloseMethod: null,
  teliaVasPlayError: false,
  smallOperatorPropertyUpdated: false,
  vipStatus: VIPStatus.Unknown,
};

type VimondProperties = UserProperty[] | UserProperty;

/**
 * Vimond may return properties as a single value, as an array of values or an empty value.
 * Fix the value to be always an array.
 *
 * @param properties
 */
const normalizeVimondProperties = (properties: VimondProperties): UserProperty[] => {
  if (!properties) {
    return [];
  }

  if (!isArray(properties)) {
    return [properties];
  }

  return [...properties];
};

const updateUserProperty = (draft, name, value): void => {
  const property = draft.user.profile.properties.property.find((p) => p.name === name);

  if (property && property.allowUserToUpdate !== false) {
    property.value = value;
  } else {
    draft.user.profile.properties.property.push({
      name,
      value,
    });
  }
};

// NOTE! Properties are only deleted from the state
// It's not possible to remove properties directly from the server
const deleteUserProperty = (draft, name): void => {
  draft.user.profile.properties.property = draft.user.profile.properties.property.filter((p) => p.name !== name);
};

const userReducer = (state = initialUserState, action: any) => {
  switch (action?.type) {
    case ActionTypes.AUTH_REQUEST:
      return produce(state, (draft) => {
        draft.authStarted = true;
        draft.authCompleted = false;
      });
    case ActionTypes.AUTH_SUCCESS:
      return produce(state, (draft) => {
        const user: User = action.payload;

        draft.user = {
          ...draft.user,
          profile: {
            ...user,
            properties: {
              property: normalizeVimondProperties(user?.properties?.property),
            },
          },
          loginError: null,
        };
        draft.authCompleted = true;
        draft.isLoginModalOpen = false;
      });
    case ActionTypes.AUTH_FAIL:
      return produce(state, (draft) => {
        draft.user = {
          ...initialUserState.user,
          orders: {
            active: [],
            future: [],
            history: [],
          },
        };
        draft.authCompleted = true;
        draft.ordersCompleted = true; // unauthenticated users don't have orders, just mark it as completed
        draft.segmentsCompleted = true; // unauthenticated users don't have segments, just mark it as completed
      });
    case ActionTypes.SET_AUTH_TOKEN:
      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          authToken: action.payload,
        };
      });
    case ActionTypes.SET_AUTH_METHOD:
      return produce(state, (draft) => {
        draft.authMethod = action.payload;
      });
    case ActionTypes.LOGIN_REQUEST:
      return produce(state, (draft) => {
        // Set orders not completed because they will be fetched when authenticated
        draft.ordersCompleted = false;
      });
    case ActionTypes.LOGIN_SUCCESS:
      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          loginError: null,
        };
      });
    case ActionTypes.LOGOUT_REQUEST:
      removeItem(Keys.PROFILE_ID);
      return produce(state, (draft) => {
        draft.isLoggingOut = true;
        draft.authCompleted = false;
        draft.hasPreviouslySelectedProfile = false;
      });
    case ActionTypes.LOGOUT_SUCCESS:
    case ActionTypes.LOGOUT_FAIL:
      return produce(state, (draft) => {
        draft.isLoggingOut = false;
        draft.ordersError = false;
      });
    case ActionTypes.LOGIN_FAIL:
      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          loginError: action.payload,
        };
        draft.ordersCompleted = true;
      });
    case ActionTypes.LOGIN_MODAL_OPEN:
      return produce(state, (draft) => {
        draft.isLoginModalOpen = true;

        const { username, redirectOnLogin } = action.payload || {};
        draft.loginModalUsername = username;
        draft.loginModalCloseMethod = null;
        draft.loginRedirect = redirectOnLogin;
      });
    case ActionTypes.LOGIN_MODAL_CLOSE:
      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          loginError: null,
        };
        draft.isLoginModalOpen = false;
        draft.loginModalUsername = null;
      });
    case ActionTypes.PROFILE_MODAL_OPEN:
      return produce(state, (draft) => {
        draft.isProfileModalOpen = true;
      });
    case ActionTypes.PROFILE_MODAL_CLOSE:
      return produce(state, (draft) => {
        draft.isProfileModalOpen = false;
      });
    case ActionTypes.SET_LOGIN_CLOSE_METHOD:
      return produce(state, (draft) => {
        draft.loginModalCloseMethod = action.payload;
      });
    case ActionTypes.LOGIN_ERROR_RESET:
      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          loginError: null,
        };
      });
    case ActionTypes.CLEAR_LOGIN_REDIRECT:
      return produce(state, (draft) => {
        draft.loginRedirect = null;
      });
    case ActionTypes.SET_LOGIN_CLOSE_REDIRECT:
      return produce(state, (draft) => {
        draft.loginModalCloseRedirect = action.payload;
      });
    case ActionTypes.SET_PROFILES:
      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          subprofiles: action.payload.profiles,
        };

        const lastProfileId = getItem(Keys.PROFILE_ID);
        const pathname = document.location.pathname;
        const shouldShowModal = PROFILE_MODAL_SKIP_PATHS.some((path) => pathname.includes(path)) === false;

        if (
          shouldShowModal &&
          !lastProfileId &&
          state.user.subprofiles.length === 0 &&
          action.payload.profiles.length > 1
        ) {
          draft.isProfileModalOpen = true;
          draft.shouldProfileRedirect = false;
        }

        if (action.payload.profileId || lastProfileId) {
          draft.user = {
            ...draft.user,
            activeSubprofileId: action.payload.profileId || lastProfileId,
          };
        }
      });
    case ActionTypes.SET_SHOULD_PROFILE_REDIRECT:
      return produce(state, (draft) => {
        draft.shouldProfileRedirect = action.payload.shouldProfileRedirect;
      });
    case ActionTypes.SET_HAS_PREVIOUSLY_SELECTED_PROFILE:
      return produce(state, (draft) => {
        draft.hasPreviouslySelectedProfile = action.payload.hasPreviouslySelectedProfile;
      });
    case ActionTypes.USER_UPDATE:
      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          profile: action.payload.user,
        };
      });
    case ActionTypes.REFRESH_SUBPROFILE:
      return produce(state, (draft) => {
        let newSubprofiles;

        if (action.payload.profile.id) {
          newSubprofiles = map(draft.user.subprofiles, (subprofile) => {
            if (subprofile.id === '__not-created__' && action.payload.kidsProfile) {
              return action.payload.profile;
            } else if (action.payload.profile.id === subprofile.id) {
              return action.payload.profile;
            } else {
              return subprofile;
            }
          });
        } else {
          newSubprofiles = [...draft.user.subprofiles, action.payload.profile];
        }

        draft.user = {
          ...draft.user,
          subprofiles: newSubprofiles,
        };
      });
    case ActionTypes.ORDERS_REQUEST:
      console.log('[orders] Request orders');
      return produce(state, (draft) => {
        draft.ordersStarted = true;
        draft.ordersCompleted = false;
        draft.ordersError = false;
        draft.user.orders = initialUserState.user.orders;
      });
    case ActionTypes.ORDERS_SUCCESS:
      console.log('[orders] Request succeeded');
      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          orders: action.payload,
        };
        draft.ordersCompleted = true;
      });
    case ActionTypes.ORDERS_FAIL:
      console.log('[orders] Request failed');
      return produce(state, (draft) => {
        draft.ordersError = true;
      });
    case ActionTypes.SEGMENTS_SUCCESS:
      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          segments: action.payload,
        };
        draft.segmentsCompleted = true;
      });
    case ActionTypes.GEOBLOCK_SUCCESS:
      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          geoBlocked: action.payload,
        };
      });
    case ActionTypes.GEOBLOCK_FAIL:
      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          geoBlocked: false,
        };
      });
    case ActionTypes.USER_PROPERTY_UPDATE_REQUEST:
      return produce(state, (draft) => {
        if (!draft.user.profile || !draft.user.profile.properties) {
          return;
        }

        const { name } = action.payload;
        draft.propertyUpdateProgress[name] = true;
      });

    case ActionTypes.USER_PROPERTY_UPDATE_SUCCESS:
      return produce(state, (draft) => {
        if (!draft.user.profile || !draft.user.profile.properties) {
          return;
        }

        const { name, message } = action.payload;

        draft.propertyUpdateSuccess = message;
        draft.propertyUpdateProgress[name] = false;
      });

    case ActionTypes.USER_PROPERTY_UPDATE:
      return produce(state, (draft) => {
        if (!draft.user.profile || !draft.user.profile.properties) {
          return;
        }

        const { name, value } = action.payload;

        updateUserProperty(draft, name, value);
        delete draft.propertyErrors[name];
      });
    case ActionTypes.USER_PROPERTY_DELETE:
      return produce(state, (draft) => {
        if (!draft.user.profile || !draft.user.profile.properties) {
          return;
        }

        const name = action.payload;

        deleteUserProperty(draft, name);
        delete draft.propertyErrors[name];
      });
    case ActionTypes.USER_PROPERTY_REPLACE_ALL:
      return produce(state, (draft) => {
        if (!draft.user.profile || !draft.user.profile.properties) {
          return;
        }

        draft.user.profile.properties.property = action.payload;
      });
    case ActionTypes.REMOVE_PROPERTY_UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.propertyUpdateSuccess = null;
      });
    case ActionTypes.USER_PROPERTY_UPDATE_FAIL:
      return produce(state, (draft) => {
        if (!draft.user.profile || !draft.user.profile.properties) {
          return;
        }

        const { error, name, revertedValue } = action.payload;

        updateUserProperty(draft, name, revertedValue);
        draft.propertyErrors[name] = error;
        draft.propertyUpdateProgress[name] = false;
      });
    case ActionTypes.REMOVE_USER_PROPERTY_ERROR:
      return produce(state, (draft) => {
        delete draft.propertyErrors[action.payload];
      });
    case ActionTypes.ACTIVATE_SUBPROFILE:
      return produce(state, (draft) => {
        draft.user.activeSubprofileId = action.payload.subProfileId;
        draft.user.authToken = action.payload.authToken;
        draft.subProfileLoaded = true;
      });
    case ActionTypes.DELETE_PROFILE:
      return produce(state, (draft) => {
        const newSubprofiles = filter(draft.user.subprofiles, (subprofile) => {
          return action.payload.subprofileId !== subprofile.id;
        });

        draft.user = {
          ...draft.user,
          subprofiles: newSubprofiles,
        };
      });
    case ActionTypes.SUBPROFILES_LOAD_FAILED:
      return produce(state, (draft) => {
        draft.subProfileLoaded = true;
        draft.subprofilesError = true;
      });
    case ActionTypes.SET_WELCOME_BACK_MODAL_SEEN_AT:
      return produce(state, (draft) => {
        draft.welcomeBackModalSeenAt = action.payload;
      });
    case ActionTypes.SET_TELIA_VAS_PLAY_ERROR:
      return produce(state, (draft) => {
        draft.teliaVasPlayError = action.payload;
      });
    case ActionTypes.UPDATE_SMALL_OPERATOR_PROPERTY:
      return produce(state, (draft) => {
        draft.smallOperatorPropertyUpdated = true;

        if (!draft.user.profile || !draft.user.profile.properties) {
          return;
        }

        const isSmallOperator = action.payload;

        updateUserProperty(draft, OperatorProperties.SmallOperator, isSmallOperator);
        delete draft.propertyErrors[OperatorProperties.SmallOperator];
      });
    case ActionTypes.SET_ORDER_STATUS:
      return produce(state, (draft) => {
        const orderStatus: OrderStatus = action.payload;

        if (isNil(orderStatus?.hasVip)) {
          draft.vipStatus = VIPStatus.Unknown;
        } else {
          draft.vipStatus = orderStatus.hasVip ? VIPStatus.Enabled : VIPStatus.Disabled;
        }
      });
    case ActionTypes.CLEAR_ORDER_STATUS:
      return produce(state, (draft) => {
        draft.vipStatus = VIPStatus.Unknown;
      });
    default:
      return state;
  }
};

export default userReducer;
