import { ContentWithImages } from './image';
import { ContentTag } from './content_tag';
import { AccessProductGroup } from './product';
import { GenrePath } from './genre';
import { Label } from './label';
import { ContentWithRelatedVideos } from './related-videos';

export enum CategoryType {
  Series = 'series',
  Movie = 'movie',
  Sport = 'sport',
  Channel = 'channel',
}

export enum CategorySubType {
  Default = 'default',
  Continuous = 'continuous',
}

export type Category = ContentWithRelatedVideos &
  ContentWithImages & {
    id: number;
    parentId: number;
    categoryPath: string;
    level: CategoryLevel;
    imdbId?: string;
    imdbRating?: number;

    // Titles and descriptions
    title: string;
    subtitle?: string;
    description?: string;
    seoMetaPageTitle?: string;
    seoMetaDescription?: string;

    type: CategoryType;
    subType?: CategorySubType;
    productionCountries: string;
    productionYear: number;
    productionYears?: string;
    isChildren: boolean;
    assetCount: number;
    actors: string;
    productGroups: AccessProductGroup[];
    genrePaths?: GenrePath[];
    labels?: Label[];
    parentalRating?: string;
    priority?: number;
    episodePreviewTimeText?: string;
    nextUpcomingAssetLiveBroadcastTime?: string;
    likes?: number;
    contentPackage?: string;

    // Seasons
    groups: Category[];
    defaultGroup?: number;
    season: number;

    // Languages
    audioLanguage: string;
    audioLanguages: string[];
    subtitleLanguages: string[];

    // Content tags
    contentTags?: ContentTag[];

    // Windowing
    vipCategory?: number;
    windowingType?: 'hvod' | 'vip';
  };

export enum CategoryLevel {
  Client = 'CLIENT',
  MainCategory = 'MAIN_CATEGORY',
  TopLevel = 'TOP_LEVEL',
  Show = 'SHOW',
}
