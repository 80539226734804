import { call, fork, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';

import {
  ActionTypes,
  categorySuccess,
  resetSeriesData,
  seasonAssetsSuccess,
  selectedAssetSuccess,
  selectedSeasonSuccess,
} from './series.actions';
import {
  getLatestAsset,
  loadCategoryProgresses,
  loadLatestAssetProgresses,
  transformProgress,
} from '../../services/viewing-history';
import {
  fetchSeasonAssets,
  getSelectedSportAsset,
  isCategoryContinuous,
  resolveDefaultSeason,
} from '../../services/categories';
import { fetchAssetById, fetchNextAssetById, isUpcoming } from '../../services/assets';
import { categoryLoaded, viewingHistoryError, viewingHistorySuccess } from '../viewing-history/viewing-history.actions';
import { selectAuthToken, selectIsHvodUser, selectUser, selectVipStatus } from '../user/user.selectors';
import { getSeasonAssets } from './series.selectors';

import { Category, CategorySubType, CategoryType } from '../../models/category';
import { Asset } from '../../models/asset';
import { ViewingHistoryItem } from '../../models/viewingHistoryItem';
import findLast from 'lodash/findLast';
import { getConfigFeatures } from '../../services/conf';
import { ViewingHistoryError } from '../../services/viewing-history/viewing-history';
import { VIPStatus } from '../user/user.reducers';

const getCategoryIds = (category: Category) => {
  const groupIds = map(category.groups, (category) => {
    return Number(category.id);
  });

  return [category.id, ...groupIds];
};

function* loadSeriesData(action): any {
  try {
    const { category, forcedAsset, forcedSeasonId, cacheEnabled } = action.payload;
    const { fetchUVHProgress } = getConfigFeatures();
    let selectedAsset: Asset;
    let latestProgresses: ViewingHistoryItem[];

    // 1. Place category into state
    yield put(resetSeriesData());
    yield put(categorySuccess(category));
    const user = yield select(selectUser);
    const authToken = yield select(selectAuthToken);

    // 2. Fetch last asset for category (if logged) and category is not sports
    if (forcedAsset) {
      selectedAsset = forcedAsset;
    } else if (fetchUVHProgress && user && authToken && category.type !== CategoryType.Sport) {
      const categoryIds = getCategoryIds(category);
      try {
        const rawLatestProgresses = yield call(loadLatestAssetProgresses, categoryIds, authToken);
        if (rawLatestProgresses === false) {
          yield put(viewingHistoryError(ViewingHistoryError.ASSET));
        }
        latestProgresses = map(rawLatestProgresses, (item) => transformProgress(item));
      } catch (err) {
        console.error(`Fetching viewing history failed: ${err}`);
      }

      const mostRecentlyWatched = getLatestAsset(latestProgresses, categoryIds);

      if (mostRecentlyWatched) {
        const { assetId, progress, completed } = mostRecentlyWatched;

        // 3. Resolve selected asset (if viewing history)
        if (completed && progress && progress.percentage === 100) {
          try {
            selectedAsset = yield call(fetchNextAssetById, assetId, true);
          } catch (err) {
            console.log('No next episode');
          }
        }

        if (progress && !selectedAsset) {
          try {
            selectedAsset = yield call(fetchAssetById, assetId);
          } catch (err) {
            console.error(`Asset not found with id ${assetId}: ${err}`);
          }
        }
      }
    }

    // 4. Resolve default season
    const defaultSeason = resolveDefaultSeason(category, selectedAsset, !!forcedAsset, forcedSeasonId);
    const subType: CategorySubType =
      get(defaultSeason, 'subType') || get(category, 'subType') || CategorySubType.Default;
    yield put(selectedSeasonSuccess(`${defaultSeason.id}`));

    // 5. Load season assets & progress (if logged in)
    const hvodOnly = yield select(selectIsHvodUser);
    const vipStatus: VIPStatus = yield select(selectVipStatus);

    const seasonAssets = yield call(fetchSeasonAssets, defaultSeason.id, {
      sort: subType === CategorySubType.Continuous ? 'newestFirst' : null,
      hvodOnly,
      vipOnly: vipStatus === VIPStatus.Enabled,
      cacheEnabled,
    });
    yield put(seasonAssetsSuccess(seasonAssets, `${defaultSeason.id}`));

    if (user && authToken) {
      if (fetchUVHProgress && category.type !== CategoryType.Sport) {
        const rawCategoryProgresses = yield call(loadCategoryProgresses, `${defaultSeason.id}`, authToken);
        if (!rawCategoryProgresses) {
          yield put(viewingHistoryError(ViewingHistoryError.CATEGORY));
        }
        const categoryProgresses = map(rawCategoryProgresses, (item) => transformProgress(item));
        const loadedProgresses = unionBy(latestProgresses, categoryProgresses, 'assetId');
        yield put(viewingHistorySuccess(loadedProgresses));
      }
      yield put(categoryLoaded(category.id));
    }

    // 6. Set selected asset (forced, from viewing history or first from default season assets)
    if (selectedAsset) {
      yield put(selectedAssetSuccess(selectedAsset));
    } else {
      if (category.type === CategoryType.Sport) {
        selectedAsset = getSelectedSportAsset(seasonAssets);
      } else if (isCategoryContinuous(category)) {
        // Continuous category: get last non-upcoming; if there are only upcoming assets, get first asset (next upcoming)
        selectedAsset = findLast(seasonAssets, (asset) => !isUpcoming(asset)) || head(seasonAssets);
      } else {
        selectedAsset = head(seasonAssets);
      }
      yield put(selectedAssetSuccess(selectedAsset));
    }
  } catch (e) {
    console.error('Error loading series page data: ', e);
  }
}

function* changeSeason(action): any {
  try {
    const { fetchUVHProgress } = getConfigFeatures();
    const user = yield select(selectUser);
    const authToken = yield select(selectAuthToken);

    const {
      seasonId,
      seasonType,
      cacheEnabled,
    }: { seasonId: number | string; seasonType: CategorySubType; cacheEnabled: boolean } = action.payload;

    yield put(selectedSeasonSuccess(`${seasonId}`));

    // If assets have been loaded, do not do additional data fetches
    const selectedSeasonAssets = yield select(getSeasonAssets);
    const hvodOnly = yield select(selectIsHvodUser);
    const vipStatus: VIPStatus = yield select(selectVipStatus);

    if (!selectedSeasonAssets) {
      const seasonAssets = yield call(fetchSeasonAssets, `${seasonId}`, {
        sort: seasonType === CategorySubType.Continuous ? 'newestFirst' : null,
        hvodOnly,
        vipOnly: vipStatus === VIPStatus.Enabled,
        cacheEnabled,
      });
      yield put(seasonAssetsSuccess(seasonAssets, `${seasonId}`));
    }

    if (fetchUVHProgress && user && authToken) {
      if (action.payload?.categoryType !== CategoryType.Sport) {
        const rawCategoryProgresses = yield call(loadCategoryProgresses, `${seasonId}`, authToken);
        const categoryProgresses = map(rawCategoryProgresses, (item) => transformProgress(item));
        yield put(viewingHistorySuccess(categoryProgresses));
      }
    }
  } catch (e) {
    console.error('');
  }
}

export function* watchSeriesLoad() {
  yield takeLatest(ActionTypes.CATEGORY_REQUEST, loadSeriesData);
}

export function* watchChangeSeason() {
  yield takeLeading(ActionTypes.SELECTED_SEASON_REQUEST, changeSeason);
}

const seriesSagas = [fork(watchSeriesLoad), fork(watchChangeSeason)];

export default seriesSagas;
