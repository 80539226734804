import React from 'react';
import { SVGProps } from '../utils';

const SvgCalendar = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 29"
    fill="none"
    fillRule="evenodd"
    stroke="#000"
    strokeWidth={2}
    {...props}
  >
    <g transform="translate(1 1)">
      <rect width={34} height={27} rx={2} />
      <path strokeLinecap="square" d="M1 7h33M1 17h33m-23 9V8m12 18V8" />
    </g>
  </svg>
);

export default SvgCalendar;
