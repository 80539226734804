import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useOwletModal } from 'owlet-ui';
import classNames from 'classnames';

const DEFAULT_VIDEO_DELAY = 2000;

export interface BaseContentVideoProps {
  videoUrl: string;
  delay?: number;
  controls?: boolean;
  pauseOnModal?: boolean;
  onPlayerStateChange?: (state: ContentVideoState) => void;
}

type ContentVideoProps = BaseContentVideoProps & {
  forcePause?: boolean;
  className?: string;
  muted?: boolean;
};

export enum ContentVideoState {
  Idle,
  Ready,
  Playing,
  Done,
}

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: top center;
  object-fit: cover;
  opacity: 0;
  transition: opacity 750ms ease;

  &.visible {
    opacity: 1;
  }
`;

function isVideoPlaying(video: HTMLVideoElement): boolean {
  return Boolean(video && video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
}

export const ContentVideo: FunctionComponent<ContentVideoProps> = ({
  videoUrl,
  delay = DEFAULT_VIDEO_DELAY,
  onPlayerStateChange,
  forcePause,
  className,
  muted = true,
  controls = false,
  pauseOnModal = true,
  children,
}: PropsWithChildren<ContentVideoProps>) => {
  const { isModalOpen } = useOwletModal();
  const videoRef = useRef<HTMLVideoElement>();
  const [state, setState] = useState<ContentVideoState>(ContentVideoState.Idle);
  const [delayTimerElapsed, setDelayTimerElapsed] = useState<boolean>(false);
  const delayTimer = useRef<any>();

  // Delay showing video
  useEffect(() => {
    delayTimer.current = setTimeout(() => {
      setDelayTimerElapsed(true);
    }, delay);

    return () => {
      clearTimeout(delayTimer.current);
    };
  }, [delay]);

  const handleLoaded = useCallback(() => {
    setState(ContentVideoState.Ready);
  }, []);

  const handlePlay = useCallback(() => {
    setState(ContentVideoState.Playing);
  }, []);

  const handleEnded = useCallback(() => {
    setState(ContentVideoState.Done);
  }, []);

  const handleError = useCallback((err) => {
    console.warn('Video player error', err, videoRef.current?.error);
    setState(ContentVideoState.Done);
  }, []);

  useEffect(() => {
    if (onPlayerStateChange) {
      onPlayerStateChange(state);
    }
  }, [state, onPlayerStateChange]);

  function playVideo() {
    const playPromise = videoRef.current?.play();

    // Older browsers may not return a value from play()
    if (playPromise) {
      playPromise.catch(() => {
        setState(ContentVideoState.Done);
      });
    }
  }

  // Play video when file is loaded and timer has elapsed
  useEffect(() => {
    if (state === ContentVideoState.Ready && delayTimerElapsed && !forcePause) {
      playVideo();
    }
  }, [state, delayTimerElapsed, forcePause]);

  // Handle modals and forcePause prop
  useEffect(() => {
    if (videoRef.current && state === ContentVideoState.Playing) {
      if (((pauseOnModal && isModalOpen) || forcePause) && isVideoPlaying(videoRef.current)) {
        // if video is playing, and modal is open or forcePause is true, pause the video
        videoRef.current.pause();
      } else if (!isModalOpen && !forcePause && videoRef.current.paused) {
        // if video is paused, and modal is closed and forcePause is false, resume playing
        playVideo();
      }
    }
  }, [state, isModalOpen, forcePause, pauseOnModal]);

  const videoClassname = classNames(className, {
    visible: state === ContentVideoState.Playing,
  });

  return (
    <Video
      ref={videoRef}
      muted={muted}
      playsInline={true}
      preload="auto"
      loop={false}
      onLoadedData={handleLoaded}
      onPlay={handlePlay}
      onEnded={handleEnded}
      onError={handleError}
      controls={controls}
      autoPlay={state !== ContentVideoState.Done}
      crossOrigin="anonymous"
      className={videoClassname}
    >
      <source src={videoUrl} type="video/mp4" />
      {children}
    </Video>
  );
};
