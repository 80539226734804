import styled from 'styled-components';
import { FONT_REGULAR, IconLock, IconPlay, WHITE, WHITE40, WHITE90 } from 'owlet-ui';

export const IconWrap = styled.div`
  overflow: hidden;
  border: 2px solid ${WHITE40};
  border-radius: 999px;
  padding: 0.5rem 0.5rem;
  flex-shrink: 0;
`;

export const StyledIconPlay = styled(IconPlay)`
  width: 1.4rem;
  display: block;
  fill: ${WHITE};
  transform: translateX(8%);
`;

export const StyledIconLock = styled(IconLock)`
  width: 1.4rem;
  display: block;
  fill: ${WHITE40};
`;

export const PlayButton = styled.button`
  appearance: none;
  padding: 0;
  margin: 0 0 1rem;
  border: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: ${WHITE};
  white-space: nowrap;
  max-width: 100%;
  font: ${FONT_REGULAR};

  &[disabled] {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    text-decoration: underline;

    ${IconWrap} {
      border-color: ${WHITE90};
    }
  }
`;

export const PlayLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.5rem;
`;

export const PlayInactive = styled.a`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 1rem;

  &&& {
    color: ${WHITE40};
  }
`;
