import React from 'react';
import { SVGProps } from '../utils';

const SvgChromecastOn = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path d="M406 424H294.29a13.2 13.2 0 110-26.4H406a27.65 27.65 0 0027.6-27.6V142a27.65 27.65 0 00-27.6-27.61H106A27.65 27.65 0 0078.4 142v39.7a13.2 13.2 0 01-26.4 0V142a54.08 54.08 0 0154-54h300a54.08 54.08 0 0154 54v228a54.08 54.08 0 01-54 54zm-312.3 0a41.7 41.7 0 1141.7-41.7A41.75 41.75 0 0193.7 424z" />
    <path d="M238 424a13.21 13.21 0 01-13-15.75 134.84 134.84 0 002.54-26c0-73.79-60-133.83-133.84-133.83a134.8 134.8 0 00-26 2.53 13.2 13.2 0 11-5.1-25.9 161.22 161.22 0 0131-3c88.36 0 160.24 71.88 160.24 160.23a161.31 161.31 0 01-3 31.05A13.2 13.2 0 01238 424z" />
    <path d="M178.54 424A13.21 13.21 0 01166 406.6 76.28 76.28 0 0069.41 310 13.2 13.2 0 0161 285a102.68 102.68 0 01130 130 13.21 13.21 0 01-12.46 9zm-75.03-281.49V194c95.89 4 173.45 80.16 179.78 175.45h125.2v-227z" />
  </svg>
);

export default SvgChromecastOn;
