import { DependencyList, useEffect, useRef, useState, RefObject, EffectCallback } from 'react';
import useSSR from 'use-ssr';
import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import isArray from 'lodash/isArray';
import isFinite from 'lodash/isFinite';
import omit from 'lodash/omit';
import { getConfig } from './conf';
import { useIsActive } from '../state/app/app.selectors';

const { env } = getConfig();

type ServerEffectCallback = (isServer?: boolean) => void | (() => void | undefined);

export function useServerEffect(effect: ServerEffectCallback, deps?: DependencyList) {
  const { isServer } = useSSR();

  // Next.js doesn't run useEffect on server
  useEffect(() => effect(false), deps);

  // Server-side, just run the effect function
  if (isServer) {
    effect(true);
  }
}

export function useOnDocumentVisibilityChange(callback: (visible: boolean) => void) {
  const isActive = useIsActive();

  useEffect(() => {
    callback(isActive);
  }, [isActive]);
}

export function convertToParsedUrlQuery(urlSearchParams: URLSearchParams): ParsedUrlQuery {
  const q: ParsedUrlQuery = {};
  urlSearchParams.forEach((value, key) => {
    // Key already exists, append value to existing array or turn it into an array
    if (q[key]) {
      q[key] = isArray(q[key]) ? [...(q[key] as string[]), value] : [q[key] as string, value];
    } else {
      q[key] = value;
    }
  });
  return q;
}

/**
 * Get query params as a map. Works around an issue where query returned by useRouter() is empty.
 * Note: Hook doesn't actively listen to changes in query params.
 */
export function useQueryParams(): ParsedUrlQuery {
  const { asPath, query, pathname } = useRouter();
  const [queryParams, setQueryParams] = useState<ParsedUrlQuery>(() => {
    const tokens = pathname
      .split('/')
      .map((s) => {
        const m = s.match(/\[{1,2}\.*(.+)\]{1,2}/); // match [lorem] and [...ipsum] and [[...dolor]]
        return m ? m[1] : null;
      })
      .filter(Boolean);

    return omit(query, tokens);
  });

  // When navigating browser-side, useRouter() query doesn't work (it probably should)
  // Parse query params from window.location instead
  useEffect(() => {
    const url = new URL(window.location.href);
    const q: ParsedUrlQuery = convertToParsedUrlQuery(url.searchParams);
    setQueryParams(q);
  }, [query, asPath, pathname]);

  return queryParams;
}

export function useIsMounted(): RefObject<boolean> {
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return mountedRef;
}

const CANCELLED_ERROR = 'CancelledError';

/**
 * The wrapped given promise is cancelled if the parent component has unmounted before resolve/reject.
 * The promise is rejected with CancelledError if the component unmounted. CancelledError can be ignored
 * in error handlers.
 *
 * See: isCancelledError
 */
export function cancelOnUnmount<T>(promise: PromiseLike<T>, mountedRef: RefObject<boolean>): PromiseLike<T> {
  const cancelError = new Error('cancelled');
  cancelError.name = CANCELLED_ERROR;
  return new Promise((resolve, reject) => {
    promise.then(
      (res) => (mountedRef.current ? resolve(res) : reject(cancelError)),
      (err) => (mountedRef.current ? reject(err) : reject(cancelError))
    );
  });
}

/**
 * Hook that allows promises to be cancelled when the component unmounts.
 */
export function useCancelOnUnmount() {
  const mountedRef = useIsMounted();

  function wrapPromise<T>(promise: PromiseLike<T>) {
    return cancelOnUnmount(promise, mountedRef);
  }

  return {
    cancelOnUnmount: wrapPromise,
  };
}

/**
 * Checks if the error is due to the promise being cancelled.
 * Cancelled errors can be ignored.
 *
 * See: cancelOnUnmount
 */
export function isCancelledError(error: Error) {
  return error.name === CANCELLED_ERROR;
}

export function assertNumericId(id: string | number): void {
  if (!isFinite(Number(id))) {
    throw new Error(`${id} is not a number`);
  }
}

/**
 * useEffect() hook that is only run once when predicate function returns true. Later calls are ignored, even if
 * the predicate function still returns true.
 *
 * @param effect
 * @param deps
 * @param predicate
 */
export function useEffectOnce(effect: EffectCallback, deps: DependencyList, predicate: () => boolean) {
  const isCalled = useRef<boolean>(false);

  useEffect(() => {
    if (!isCalled.current) {
      isCalled.current = predicate();

      if (isCalled.current) {
        return effect();
      }
    }
  }, [...deps, predicate]);
}

/**
 * Only log if code is executed in browser.
 *
 * @param attrs
 */
export function browserLog(...attrs): void {
  if (typeof window !== 'undefined') {
    console.log(...attrs);
  }
}

export function devLog(...attrs) {
  if (getConfig().env === 'dev') {
    console.log(...attrs);
  }
}

export function devTime(label: string): void {
  if (env === 'dev' && !isTestRun()) {
    console.time(label);
  }
}
export function devTimeEnd(label: string): void {
  if (env === 'dev' && !isTestRun()) {
    console.timeEnd(label);
  }
}

/**
 * useVisible hook for handling showing/hiding a component.
 * For example, dropdown lists etc.
 * Also handles the hiding of the referenced element when clicking outside.
 * @param initialIsVisible
 */
export function useVisible(initialIsVisible: boolean) {
  const [isVisible, setIsVisible] = useState<boolean>(initialIsVisible);
  const ref = useRef<any>(null);
  const actuatorRef = useRef<any>(null);

  const handleClickOutside = (event: Event) => {
    // If the click was on an actuator, skip this and
    // let the actuator do it's thing to handle showing/hiding
    if (actuatorRef.current && actuatorRef.current.contains(event.target)) {
      return;
    }

    // Otherwise check if user clicked outside of the referenced component
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { actuatorRef, ref, isVisible, setIsVisible };
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function isTestRun() {
  return Boolean(process.env.JEST_WORKER_ID);
}

export function equalsIgnoringCase(a: string, b: string): boolean {
  // Converting to string despite function signature because inputs might be numbers from API response etc.
  return `${a}`.toUpperCase() === `${b}`.toUpperCase();
}

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1024);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}
