import {
  addMinutes,
  addSeconds,
  format,
  isToday,
  isTomorrow,
  isValid,
  isYesterday,
  parseISO,
  subMinutes,
} from 'date-fns';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { fi } from 'date-fns/locale';
import { getCurrentDate } from '../time';
import padStart from 'lodash/padStart';

const LONG_DATE = 'd.M.yyyy';
const SHORT_DATE_WITH_WEEKDAY = 'EEEEEE d.M.';
const SHORT_DATE_WITH_LONG_WEEKDAY = 'cccc d.M.';
const SHORT_DATE = 'd.M.';
const MONTH_YEAR = 'MM/yy';
const SHORT_TIME = 'HH:mm';
const USER_PROPERTY_DATETIME = "yyyy-MM-dd'T'HH:mm:ss";

export function toDate(value: number | string | Date): Date | null {
  const verifyDate = (d: Date): Date | null => {
    if (!isValid(d)) {
      return null;
    }

    return d;
  };

  // Already received a Date, verify it's valid
  if (value instanceof Date) {
    return verifyDate(value);
  }

  // Received something else, create Date object if possible, otherwise return null
  return isNumber(value) || isString(value) ? verifyDate(new Date(value)) : null;
}

export function relativeDate(
  value: number | string | Date,
  showWeekday?: boolean,
  longWeekday?: boolean,
  useShortDate?: boolean,
  relativeWithDate?: boolean
): string {
  const d = toDate(value);

  const datePostfix = relativeWithDate ? ' ' + format(d, SHORT_DATE) : '';

  if (isToday(d)) {
    return `tänään${datePostfix}`;
  } else if (isTomorrow(d)) {
    return `huomenna${datePostfix}`;
  } else if (isYesterday(d)) {
    return `eilen${datePostfix}`;
  }

  const fallback = useShortDate ? SHORT_DATE : LONG_DATE;

  if (longWeekday) {
    return format(d, showWeekday ? SHORT_DATE_WITH_LONG_WEEKDAY : fallback, { locale: fi });
  }

  return format(d, showWeekday ? SHORT_DATE_WITH_WEEKDAY : fallback, { locale: fi });
}

export function longDateTime(value: number | string | Date): string {
  const d = toDate(value);

  return `${format(d, LONG_DATE, { locale: fi })} klo ${format(d, SHORT_TIME, { locale: fi })}`;
}

export function longDateOnly(value: number | string | Date): string {
  return format(toDate(value), LONG_DATE, { locale: fi });
}

export function shortDateOnly(value: number | string | Date): string {
  return format(toDate(value), SHORT_DATE, { locale: fi });
}

export function monthYearOnly(value: number | string | Date): string {
  return format(toDate(value), MONTH_YEAR, { locale: fi });
}

export function timeOnly(value: number | string | Date): string {
  return format(toDate(value), SHORT_TIME, { locale: fi });
}

export function propertyUTCDateTime(value: number | string | Date): string {
  const d = toDate(value);

  return format(addMinutes(d, d.getTimezoneOffset()), USER_PROPERTY_DATETIME);
}

export function parsePropertyUTCDateTime(value: string): Date {
  return subMinutes(parseISO(value), getCurrentDate().getTimezoneOffset());
}

export function longDateRange(start: number | string | Date, end: number | string | Date): string {
  const startDate = toDate(start);
  const endDate = toDate(end);

  if (!startDate || !endDate) {
    return null;
  }

  const sameMonth = startDate.getMonth() === endDate.getMonth();
  const sameYear = startDate.getFullYear() === endDate.getFullYear();

  return [
    [
      `${startDate.getDate()}.`,
      sameMonth && sameYear ? null : `${startDate.getMonth() + 1}.`,
      sameYear ? null : startDate.getFullYear(),
    ]
      .filter(Boolean)
      .join(''),
    longDateOnly(endDate),
  ].join('–');
}

/**
 *
 * @param periodInDays
 * @param paymentDaysInMonth Days in payment plan month
 */
export function formatPeriodLength(periodInDays: number, paymentDaysInMonth: number = 30): string {
  if (periodInDays % paymentDaysInMonth === 0) {
    return `${periodInDays / paymentDaysInMonth} kk`;
  } else if (periodInDays % 7 === 0) {
    return `${periodInDays / 7} vko`;
  }

  return `${periodInDays} pvä`;
}

export function secondsToISO8601Duration(duration: number): string {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor((duration % 3600) % 60);

  return `PT${hours}H${minutes}M${seconds}S`;
}

export function secondsToHumanDuration(duration: number, includeSeconds: boolean = false): string {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor((duration % 3600) % 60);

  let humanDuration: string = '';
  if (hours > 0) {
    humanDuration += `${hours}h `;
  }
  if (minutes > 0) {
    humanDuration += `${minutes}min`;
  }
  if (includeSeconds && seconds > 0) {
    humanDuration += ` ${seconds}s`;
  }

  return humanDuration;
}

export function secondsToPlayerFormat(duration: number): string {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor((duration % 3600) % 60);

  let playerFormat: string = '';
  playerFormat += `${hours}h`;
  playerFormat += `${minutes}m`;
  playerFormat += `${seconds}s`;

  return playerFormat;
}

export interface DateBadgeData {
  relativeDate: string;
  date: string;
  isLongDate: boolean;
  weekday: string;
  shortWeekday: string;
  startTime: string;
  endTime: string;
}

const EMPTY_DATA: DateBadgeData = {
  relativeDate: null,
  date: null,
  isLongDate: false,
  weekday: null,
  shortWeekday: null,
  startTime: null,
  endTime: null,
};

interface DateBadgeOpts {
  duration?: number;
  endDate?: number | string | Date;
}

export function dateToBadgeData(
  value: number | string | Date,
  { duration, endDate: endDateValue }: DateBadgeOpts = {}
): DateBadgeData {
  const d = toDate(value);

  if (!isValid(d)) {
    return EMPTY_DATA;
  }

  const now = new Date();

  const isLongDate = d.getFullYear() !== now.getFullYear();
  const date = isLongDate ? longDateOnly(d) : shortDateOnly(d);

  const getRelativeDate = () => {
    if (isToday(d)) {
      return `tänään`;
    } else if (isTomorrow(d)) {
      return `huomenna`;
    } else if (isYesterday(d)) {
      return `eilen`;
    }

    return null;
  };

  let endDate: Date = null;

  if (endDateValue) {
    endDate = toDate(endDateValue);
  } else if (duration) {
    endDate = addSeconds(d, duration);
  }

  const endTime = endDate ? timeOnly(endDate) : null;

  return {
    relativeDate: getRelativeDate(),
    date,
    isLongDate,
    weekday: format(d, 'cccc', { locale: fi }),
    shortWeekday: format(d, 'EEEEEE', { locale: fi }),
    startTime: timeOnly(d),
    endTime,
  };
}

interface DurationObject {
  days?: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function secondsToDuration(durationInSeconds, includeDays: boolean = true): DurationObject {
  const totalSeconds = Math.floor(durationInSeconds);

  let hours: number;
  let days: number;

  if (includeDays) {
    const divisorForHours = totalSeconds % (24 * 60 * 60);
    days = Math.floor(totalSeconds / (24 * 60 * 60));
    hours = Math.floor(divisorForHours / (60 * 60));
  } else {
    hours = Math.floor(totalSeconds / (60 * 60));
  }
  const divisorForMinutes = totalSeconds % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);
  const divisorForSeconds = divisorForMinutes % 60;
  const seconds = Math.ceil(divisorForSeconds);

  const durationObject: DurationObject = {
    hours,
    minutes,
    seconds,
  };

  if (includeDays) {
    durationObject.days = days;
  }

  return durationObject;
}

export const formatDuration = (duration) => {
  if (duration <= 0 || !duration) {
    return '0:00';
  } else {
    const { hours, minutes, seconds } = secondsToDuration(duration, false);

    const formattedMinutes = padStart(minutes.toString(), 2, '0');
    const formattedSeconds = padStart(seconds.toString(), 2, '0');

    return `${hours > 0 ? `${hours}:` : ''}${formattedMinutes}:${formattedSeconds}`;
  }
};

export function secondsToDays(seconds: number = 0): number {
  return seconds / 86400;
}
