import isNil from 'lodash/isNil';

export function formatNumber(value: any, decimals: number = 0): string {
  if (isNil(value)) {
    return value;
  }

  return Number(value).toFixed(decimals).replace('.', ',');
}

export function formatPrice(value: any): string {
  if (isNil(value)) {
    return value;
  }

  return `${formatNumber(value, 2)} €`;
}
