import styled from 'styled-components';
import { Title2 } from '../typography';
import { greaterThan } from '../breakpoints';

export const ModalTitle = styled(Title2)`
  padding: 0 1rem;
  overflow-wrap: break-word;
  margin-bottom: 1rem;

  ${greaterThan('small')`
    margin-bottom: 2rem;
  `}
`;

export const ModalBody = styled.div`
  flex: 1;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 1rem;

  ${greaterThan('small')`
    margin-top: 2rem;
  `}

  > * {
    margin: 0.5rem 1rem;
  }

  button {
    width: auto;
    min-width: 250px;
    max-width: 320px;
  }
`;
