import React from 'react';
import { SVGProps } from '../utils';

const SvgLikeActive = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 132" {...props}>
    <path d="M106.6 132c-23.1 0-56-10.8-56.5-11-3.4-1.3-5.2-3.8-5.2-7-.1-6.3-.3-53-.3-53.5 0-4.9 5.6-5.1 10.1-5.3.6 0 1.2 0 1.7-.1 3.3-3.8 16-21.8 19.3-28 .8-1.6 1.6-4.8 2.4-7.8 2.1-8.5 5-20.1 14.6-19.4 5.7.5 9.8 2.6 12.2 6.3 5.1 7.7 1.2 19.7-1.9 29.4-1.1 3.6-2.3 7.3-2.6 9.5 1.7.2 5.5-.1 8.1-.4 10.1-1 25.3-2.5 30.8 10.9l.2.4.1.4c1.5 7.5-1.3 11.6-3.1 14.4-.8 1.2-1.3 2-1.4 2.7-.1 1.1.2 1.5.8 2.6.9 1.6 2.2 3.9 1.8 7.9-.4 4.4-2.5 6.5-3.9 7.9-.6.6-.8.8-.8 1 0 .3.4 1.2.7 1.9.9 2.2 2.4 5.5 1 9.7-1.2 3.6-3 5.2-4.4 6.3-.9.8-1.4 1.1-1.8 2.3-.3.8-.3 2.1-.3 3.5.1 3.4.2 8.5-5.3 12.1l-.3.2c-3.9 2.2-9.6 3.1-16 3.1zM32.8 54.9H5.6c-2.9 0-5.2 2.3-5.2 5.2V117c0 2.9 2.3 5.2 5.2 5.2h27.2c2.9 0 5.2-2.3 5.2-5.2V60c0-2.8-2.4-5.1-5.2-5.1zM19.2 110c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.7 2.1 4.7 4.7-2.1 4.7-4.7 4.7z" />
  </svg>
);

export default SvgLikeActive;
