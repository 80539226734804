import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import { useWindowEventListener } from '../common/event-listener';

export interface StickyProps {
  forceVisible?: boolean;
  onVisibilityChange?: (event: { visible: boolean }) => void;
}

interface StickyContainerProps {
  visible?: boolean;
}

const StickyContainer = styled.div<StickyContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  will-change: transform;
  transition: transform 200ms ease-out;

  &.hidden {
    transform: translate3d(0, -100%, 0);
  }
`;

export interface StickyContextProps {
  portalRef: any;
  setPortalRef: (portalRef: any) => void;
}

export const StickyContext = createContext<StickyContextProps>({
  portalRef: null,
  setPortalRef: () => {},
});

export const Sticky: FunctionComponent<PropsWithChildren<StickyProps>> = ({
  forceVisible,
  onVisibilityChange,
  children,
}: PropsWithChildren<StickyProps>) => {
  const [visible, setVisible] = useState<boolean>(true);
  const [scrollState, setScrollState] = useState<{ scrollY: number; delta: number }>({ scrollY: 0, delta: 0 });
  const container = useRef<HTMLDivElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);

  const stickyContext = useContext(StickyContext);

  const scrollY = useRef<number>(0);

  useWindowEventListener(
    'scroll',
    throttle(() => {
      const oldScrollY = scrollY.current;
      const currentScrollY = window.scrollY;
      setScrollState({ scrollY: currentScrollY, delta: oldScrollY - currentScrollY });
      scrollY.current = currentScrollY;
    }, 50)
  );

  useEffect(() => {
    if (forceVisible) {
      setVisible(true);
      return;
    }

    const { scrollY, delta } = scrollState;

    if (container.current && scrollY <= container.current.offsetHeight) {
      setVisible(true);
      return;
    }

    if (delta > 6) {
      setVisible(true);
    } else if (delta < -6) {
      setVisible(false);
    }
  }, [scrollState, forceVisible, container]);

  useEffect(() => {
    if (onVisibilityChange) {
      onVisibilityChange({ visible });
    }
  }, [visible]);

  useEffect(() => {
    stickyContext.setPortalRef(portalRef);
  }, [portalRef]);

  return (
    <StickyContainer className={visible ? undefined : 'hidden'} ref={container}>
      {children}
      <div ref={portalRef} />
    </StickyContainer>
  );
};
