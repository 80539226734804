import { ErrorCodes, Voucher, VoucherDiscountType, VoucherType } from '../../models/voucher';
import { fetchRaw } from '../api';
import { getConfig, getVimondApiUrl } from '../conf';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { addSeconds, isAfter, isBefore } from 'date-fns';
import { CmProduct, PaymentType, ProductStatus } from '../../models/product';
import { getCurrentDate } from '../time';
import { Order } from '../../models/order';
import { findProductById } from './products';
import { formatPeriodLength, longDateOnly } from '../format/date';
import { formatPrice } from '../format/number';
import { getInitPeriodString } from './format';
import { useAsync } from 'owlet-ui/common';

export async function fetchVoucherByCode(code: string): Promise<Voucher> {
  if (!code) {
    return null;
  }

  const resp = await fetchRaw(`${getVimondApiUrl()}/api/voucher/${encodeURIComponent(code)}`, {
    headers: {
      Accept: 'application/json;v=2',
      'Content-Type': 'application/json',
    },
  });

  if (!resp.ok) {
    if (resp.status === 404) {
      // Voucher not found
      throw new Error(ErrorCodes.VoucherNotFound);
    }

    let error;
    try {
      error = await resp.clone().json();
    } catch (e) {
      error = await resp.text();
    }

    const errorCode = get(error, 'error.code', 'UNKNOWN');

    throw new Error(errorCode);
  }

  return resp.json();
}

export interface UseVoucherResponse {
  voucher: Voucher;
  error: Error;
  loading: boolean;
}

export function useVoucher(voucherCode: string): UseVoucherResponse {
  const { value, error, loading } = useAsync<Voucher>(() => {
    if (voucherCode) {
      return fetchVoucherByCode(voucherCode);
    }
  }, [voucherCode]);

  return {
    voucher: value,
    error,
    loading,
  };
}

export function getVoucherDescription(voucher: Voucher, product: CmProduct): string {
  if (!voucher) {
    return;
  }

  if (!product || voucher.voucherType === VoucherType.General) {
    throw new Error(ErrorCodes.InvalidVoucher);
  }

  const startDate = voucher.startDate ? new Date(voucher.startDate) : null;
  const expiryDate = voucher.expiry ? new Date(voucher.expiry) : null;
  const now = getCurrentDate();

  if (expiryDate && isBefore(expiryDate, now)) {
    throw new Error(ErrorCodes.VoucherExpired);
  }

  if (startDate && isAfter(startDate, now)) {
    throw new Error(ErrorCodes.VoucherNotYetValid);
  }

  if (voucher.product.productStatus === ProductStatus.Disabled) {
    throw new Error(ErrorCodes.ProductDisabled);
  }

  if (voucher.usages === 0) {
    throw new Error(ErrorCodes.VoucherUsed);
  }

  const {
    title: productName,
    product: { productGroupId, price, productPayments, minimumPeriods },
  } = product;
  const { Hvod } = getConfig().products.productGroupIds;
  const productPayment = productPayments.find((p) => p['@id'] === `${voucher.productPaymentId}`);

  if (!productPayment) {
    throw new Error(ErrorCodes.ProductPaymentNotFound);
  }

  const periodEnd = longDateOnly(addSeconds(getCurrentDate(), productPayment.initPeriod));
  const periodLength = formatPeriodLength(productPayment.initPeriod / 60 / 60 / 24);

  const isSubscription = isSubscriptionVoucher(voucher);
  const initPrice = !isNil(productPayment.initPrice) ? productPayment.initPrice : price;

  const minimumPeriodInfo =
    isSubscription && minimumPeriods > 1
      ? ` Tilaus on sitova määräaikainen ${minimumPeriods} kk, minkä jälkeen tilaus jatkuu etuhintaisena kestotilauksena.
       Määräaikaisen sopimuksen kokonaishinta on ${formatPrice(minimumPeriods * price)}.`
      : '';

  const getSubscriptionPrice = (): string => {
    return `${formatPrice(price)}/kk${productGroupId === Hvod ? ', muutamilla mainoksilla' : ''}`;
  };

  // discountType=FRACTION
  if (voucher.discountType === VoucherDiscountType.Fraction) {
    if (isSubscription) {
      if (voucher.discountFraction === 1) {
        return (
          `Koodi oikeuttaa ${productName} -tuotteen ilmaiseen katseluun ${periodLength} ajan ` +
          `(${periodEnd} saakka). Tilaus jatkuu tämän jälkeen automaattisesti maksullisena kestotilauksena ` +
          `(${getSubscriptionPrice()}), jos et ilmaisen jakson aikana peru tilausta.`
        );
      }

      const discountPrice = initPrice * (1 - voucher.discountFraction);
      const discount = `${voucher.discountFraction * 100}%`;

      return (
        `Koodi oikeuttaa ${discount} alennukseen ${productName} -tuotteesta ${periodLength} ajaksi. ` +
        `Alennettu hinta (${formatPrice(discountPrice)}) on voimassa ${periodEnd} saakka, jonka jälkeen tilaus ` +
        `jatkuu automaattisesti kestotilauksena (${formatPrice(price)}/kk), jos et alehintaisen jakson aikana peru ` +
        `tilaustasi.`
      );
    }
  }

  // discountType=FREE
  if (voucher.discountType === VoucherDiscountType.Free) {
    if (isSubscription && voucher.discountFraction === 0) {
      if (initPrice === 0) {
        return (
          `Koodi oikeuttaa ${productName} -tuotteen ilmaiseen katseluun ${periodLength} ajan ` +
          `(${periodEnd} saakka). Tilaus jatkuu tämän jälkeen automaattisesti maksullisena kestotilauksena ` +
          `(${getSubscriptionPrice()}), ` +
          `jos et ilmaisen jakson aikana peru tilausta.`
        );
      }

      // Voucher that only enables access to a product without giving actual discount
      return `Koodi oikeuttaa ${productName} -tuotteen kestotilaukseen hintaan ${getSubscriptionPrice()}.${minimumPeriodInfo}${getInitPeriodString(
        product
      )}`;
    } else {
      return (
        `Koodi oikeuttaa ${productName} -tuotteen ilmaiseen katseluun ${periodLength} ajan (${periodEnd} ` +
        `saakka). Tilaus on määräaikainen ja päättyy ilmaisen jakson jälkeen automaattisesti.`
      );
    }
  }

  // discountType=AMOUNT
  if (voucher.discountType === VoucherDiscountType.Amount) {
    const discount = formatPrice(voucher.discount);

    if (isSubscription) {
      return (
        `Koodi oikeuttaa ${discount} alennukseen tuotteesta ${productName}. ` +
        `Tilaus jatkuu automaattisesti maksullisena kestotilauksena (${getSubscriptionPrice()}).`
      );
    }

    return `Koodi oikeuttaa ${discount} alennukseen tuotteesta ${productName}. Tilaus on määräaikainen.`;
  }

  throw new Error(ErrorCodes.DiscountNotSupported);
}

export function isChainingEnabled(voucher: Voucher, products: CmProduct[]): boolean {
  if (!voucher) {
    return false;
  }

  if (isProductEnablingVoucher(voucher)) {
    return true;
  }

  const product = findProductById(voucher.product.id, products);

  return product?.whitelistedForCodes || false;
}

/**
 * Returns true if the voucher only enables access to a product instead of giving discount
 *
 * @param voucher
 */
export function isProductEnablingVoucher(voucher: Voucher): boolean {
  return (
    Boolean(voucher) &&
    voucher.discountType === VoucherDiscountType.Free &&
    isSubscriptionVoucher(voucher) &&
    voucher.discountFraction === 0
  );
}

/**
 * Returns true if the voucher allows ordering a new product even if user has a single-period product.
 * User must have an active order for a certain product and the voucher must be for a certain product, both
 * configured in `products.allowSinglePeriodSwitchWithVoucher` property.
 *
 * @param voucher
 * @param activeOrders
 */
export function isSinglePeriodSwitchAllowed(voucher: Voucher, activeOrders: Order[]): boolean {
  if (!activeOrders || activeOrders.length === 0 || !voucher) {
    return false;
  }

  const productPairs = getConfig().products.allowSinglePeriodSwitchWithVoucher;

  // Check that voucher matches the target product and one of the active orders matches the source product
  const matchFound = productPairs?.some(
    ({ from, to }) => voucher.product.id === to && activeOrders?.some(({ productId }) => productId === from)
  );

  return matchFound || false;
}

function isSubscriptionVoucher(voucher: Voucher): boolean {
  return voucher.product.paymentPlan.paymentType === PaymentType.Subscription;
}

export function isSmallOperatorVoucherCode(voucherCode: string): boolean {
  return voucherCode?.startsWith('POPE');
}
