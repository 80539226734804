import { Asset } from './asset';
import { Category } from './category';
import { ChannelData } from './channel';
import { Image } from './image';
import { CmProduct } from './product';
import { FunctionComponent } from 'react';
import { Conditions } from './conditions';

export enum CuratedComponentType {
  BenefitList = 'benefit-list',
  Button = 'button',
  CancellationButton = 'cancellation-button',
  CategoryLinks = 'category-links',
  Channels = 'channels',
  CxScore = 'cx-score',
  CodeWidget = 'code-widget',
  CustomerRating = 'customer-rating',
  Default = 'default',
  DeferredHero = 'deferred-hero',
  DeferredList = 'deferred-list',
  DeferredLiveList = 'deferred-live-list',
  DeferredTeaserListCover = 'deferred-teaser-list-cover',
  DeferredWidePromotion = 'deferred-wide-promotion',
  Favorites = 'favorites',
  Hero = 'hero',
  HeroCta = 'hero-cta',
  LeadooChat = 'leadoo-chat',
  LiveList = 'live-list',
  ProductsPpv = 'products_ppv',
  ProductsSubscription = 'products_subscription',
  PromotionList = 'promotion-list',
  Recommendations = 'recommendations',
  Resume = 'resume',
  TeaserListCover = 'teaser-list-cover',
  TextBlock = 'text-block',
  WidePromotion = 'wide-promotion',
}

export type CuratedItemType = CuratedAsset | CuratedCategory | CuratedStaticItem | HeroSlide | PromoItem | ChannelData;

export enum Theme {
  WhiteBackground = 'white-background',
  News = 'uutiset',
  Imdb = 'imdb',
  SportCalendar = 'sport-calendar',
  Rating = 'rating',
  Centered = 'centered',
}

export interface Conditional {
  conditions?: Conditions;
}

export interface CuratedComponent<T = CuratedItemType> extends Conditional {
  id: number;
  component: CuratedComponentType | string;
  items: T[];
  updated?: string;
  title?: string;
  actions?: Target[];
  target?: Target;
  targets?: CategoryTarget[];
  filter?: string;
  dataSource?: DeferredDataSource;
  targetDataPath?: DeferredDataPath;
  text?: string;
  products?: CmProduct[];
  theme?: Theme | string;
}

export type PropsWithContentId<P = {}> = P & {
  contentId: string;
};

export type PropsWithCuratedData<P = {}, T = CuratedItemType> = PropsWithContentId<
  P & {
    data: CuratedComponent<T>;
    testId?: string;
  }
>;

export type CuratedFunctionComponent<P = {}, T = CuratedItemType> = FunctionComponent<PropsWithCuratedData<P, T>>;

export interface CuratedAsset extends Conditional {
  title?: string;
  subtitle?: string;
  assetId: number;
  asset: Asset;
  image?: Image | string;
  images?: Image[];
  description?: string;
  highlightId?: number;
}

export interface CuratedCategory extends Conditional {
  title?: string;
  subtitle?: string;
  categoryId: number;
  category: Category;
  image?: Image | string;
  images?: Image[];
  description?: string;
  highlightId?: number;
}

export interface CuratedStaticItem extends Conditional {
  title?: string;
  subtitle?: string;
  description?: string;
  image?: Image | string;
  images?: Image[];
  target?: Target;
}

export interface Target {
  title: string;
  path: string;
  productId?: number | string;
  productGroupId?: number | string;
}

export interface CategoryTarget {
  title: string;
  path: string;
  images: Image[];
  description: string;
}

export interface HeroSlide extends Conditional {
  title: string;
  subtitle: string;
  description?: string;
  asset?: Asset;
  category?: Category;
  image: string;
  images: Image[];
  videoUrl?: string;
  target?: Target;
}

export interface PromoItem extends Conditional {
  title: string;
  subtitle?: string;
  description: string;
  categoryId?: number;
  category?: Category;
  asset?: Asset;
  target?: Target;
  image: string;
  images: Image[];
  videoUrl?: string;
}

export interface DeferredDataSource {
  dataUrl: string;
  needsAuthentication: boolean;
}

export interface DeferredDataPath {
  dataUrl: string;
  needsAuthentication: boolean;
  path: string;
  type: string;
  visibleUrl: string;
}
