import result from 'lodash/result';
import find from 'lodash/find';
import last from 'lodash/last';
import forEach from 'lodash/forEach';
import { getConfig } from './conf';
import {
  clearPlayedAsset,
  playAsset,
  setActiveSubtitle,
  setActiveAudioTrack,
  setAudioTracks,
  setChromecastAdBreakOnGoing,
  setChromecastAdBreakClipData,
  setChromecastHQFilter,
  setChromecastMediaFinished,
  setChromecastReceiverAvailability,
  setChromecastSession,
  setChromecastSessionName,
  setChromecastSessionStatus,
  setChromecastStreamType,
  setChromecastUHDCapability,
  setCurrentLivePosition,
  setCurrentPositionAndDuration,
  setCurrentMedia,
  setCurrentPosition,
  setLiveMedia,
  setNextAsset,
  setSubtitleFormat,
  setSubtitles,
  setChromecastMediaSeeking,
  enqueueChromecastAsset,
} from '../state/player/player.actions';
import { Keys, getItem, setItem } from './local-storage';
import { Asset } from '../models/asset';
import { fetchAssetById, getSubtitles, isHvodSubscriber } from './assets';
import { AudioTrack } from '../models/audiotrack';
import { Subtitle } from '../models/subtitle';
import { useSelector } from 'react-redux';
import {
  selectChromecastCurrentLivePosition,
  selectChromecastCurrentMedia,
  selectChromecastCurrentPosition,
  selectChromecastLiveMedia,
  selectChromecastMediaSeeking,
  selectIsLivePlaying,
  selectPauseTime,
  selectQueueMediaID,
} from '../state/player/player.selectors';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { getConsentGroups } from './consent';
import { PlayerState } from '../state/player/player.reducers';

let appStore: any;

export const afterScriptLoad = (store, loaded, error = false) => {
  if (loaded) {
    appStore = store;
    return initialize();
  }
  return handleError(error);
};

export const requestSession = async () => {
  try {
    console.info('Chromecast: Session requested...');

    const chrome = window['chrome'];
    chrome['cast'].requestSession(
      (session) => {
        console.info('Chromecast: Session request successful');
        newSession(session);
      },
      (error) => {
        console.error('Chromecast: Requesting session failed', error);
      }
    );
  } catch (error) {
    console.error('Chromecast: Error requesting chromecast session', error);
  }
};

export const enqueueAssetInChromecast = (asset) => {
  console.info('Chromecast: enqueue asset ', asset);
  appStore.dispatch(enqueueChromecastAsset(asset));
};

export const loadAssetInChromecast = (asset, opts: any = {}) => {
  console.info('Chromecast: Load asset ', asset, opts);
  appStore.dispatch(setNextAsset(null));
  appStore.dispatch(setAudioTracks([]));
  const { user } = appStore.getState().user;
  appStore.dispatch(playAsset(asset, true));
  return sendAssetToReceiver(asset, user, opts);
};

export const play = (currentMedia) => {
  console.info('Chromecast: Play');
  if (currentMedia) {
    currentMedia.play();
  }
};

export const pause = (currentMedia) => {
  console.info('Chromecast: Pause');
  if (currentMedia) {
    currentMedia.pause();
  }
};

export const seek = (currentMedia, seconds) => {
  console.info('Chromecast: Seek to ' + seconds + ' seconds');

  if (currentMedia) {
    const chrome = window['chrome'];
    const seekRequest = new chrome['cast'].media.SeekRequest();
    seekRequest.currentTime += seconds;
    appStore.dispatch(setChromecastMediaSeeking(true));

    currentMedia.seek(
      seekRequest,
      () => {
        console.info('Chromecast: Seek success');
      },
      () => {
        console.error('Chromecast: Seek failed');
        appStore.dispatch(setChromecastMediaSeeking(false));
      }
    );
  }
};

export const stop = (currentSession?) => {
  console.info('Chromecast: Stop');
  appStore.dispatch(clearPlayedAsset());
  appStore.dispatch(setNextAsset(null));
  appStore.dispatch(setCurrentPosition(null));
  appStore.dispatch(setChromecastAdBreakOnGoing(false));
  appStore.dispatch(setAudioTracks([]));

  const session = currentSession || appStore.getState().player.chromecast.session;

  if (session) {
    session.stop(
      () => {
        appStore.dispatch(setCurrentMedia(null));
        appStore.dispatch(setLiveMedia(null));
        appStore.dispatch(setCurrentLivePosition(null));
      },
      (err) => {
        console.error('Chromecast: Failed to stop application: ', err);
      }
    );
  }
};

export const restart = (opts) => {
  const {
    chromecast: { assetPlaying },
  }: PlayerState = appStore.getState().player;
  return loadAssetInChromecast(assetPlaying, opts);
};

export const setVolumeLevel = (volumeLevel, currentMedia) => {
  console.info('Chromecast: Set volume level to ', volumeLevel);
  const chrome = window['chrome'];
  const volume = new chrome['cast'].Volume(Math.max(0, Math.min(1, volumeLevel / 100)));
  volumeChange(volume, currentMedia);
};

export const setMuted = (muted: boolean, currentMedia) => {
  console.info('Chromecast: Set muted status to ', muted);
  const chrome = window['chrome'];
  const volume = new chrome['cast'].Volume(null, muted);
  volumeChange(volume, currentMedia);
};

export const fetchAssetSubtitles = async () => {
  const {
    chromecast: { assetPlaying, subtitleFormat },
  } = appStore.getState().player as PlayerState;

  if (assetPlaying) {
    const subtitles = await getSubtitles(assetPlaying.id, subtitleFormat as any);
    appStore.dispatch(setSubtitles(subtitles));

    const savedSubtitleLocale = getItem(Keys.CHROMECAST_LANGUAGE);

    if (savedSubtitleLocale) {
      const foundSubtitles = find(subtitles, (subtitle: any) => {
        return subtitle.locale === savedSubtitleLocale;
      });

      if (foundSubtitles) {
        selectSubtitle(foundSubtitles);
      }
    }
  }
};

export const selectSubtitle = (subtitle: Subtitle) => {
  console.info('chromecast: selectSubtitle', subtitle);
  if ((subtitle && subtitle.uri) || (subtitle && subtitle.trackContentType)) {
    appStore.dispatch(setActiveSubtitle(subtitle));
    setItem(Keys.CHROMECAST_LANGUAGE, subtitle.locale);
    sendMessage({ type: 'subtitleLanguageAndType', locale: subtitle.locale, kind: subtitle.type });
  } else {
    appStore.dispatch(setActiveSubtitle(null));
    setItem(Keys.CHROMECAST_LANGUAGE, null);
    disableSubtitles();
  }
};

export const selectAudioTrack = (audioTrack: AudioTrack) => {
  console.info('chromecast: selectAudioTrack', audioTrack);
  appStore.dispatch(setActiveAudioTrack(audioTrack));
  setItem(Keys.CHROMECAST_AUDIOLANGUAGE, audioTrack.language);
  sendMessage({ type: 'audioLanguage', language: audioTrack.language });
};

// PRIVATE

const disableSubtitles = () => {
  console.info('Chromecast: Disable subtitles');
  sendMessage({ type: 'disableCaptions' });
};

const handleError = (error) => {
  console.error(error);
};

const initialize = () => {
  const config = getConfig();
  const applicationId = config.video.chromecast.cafApplicationId;

  console.info(`Chromecast: Initializing with applicationId ${applicationId}`);
  const chrome = window['chrome'];
  const cast = chrome['cast'];
  const sessionRequest = new cast.SessionRequest(applicationId);
  const apiConfig = new cast.ApiConfig(sessionRequest, sessionListener, receiverListener);

  cast.initialize(
    apiConfig,
    () => {
      console.info('Chromecast: Initialized');
    },
    (err) => {
      console.info('Chromecast: Initialization failed', err);
    }
  );
};

const sessionListener = (session) => {
  newSession(session);
  if (session.media.length === 1) {
    mediaDiscovered(session.media);
  } else if (session.media.length > 1) {
    mediaDiscovered(session.media[0]);
  }
};

const newSession = (session) => {
  const config = getConfig();
  const { dispatch } = appStore;

  console.info('Chromecast: Starting new session ', session);
  dispatch(setChromecastSession(session));

  const chromecastSession = appStore.getState().player.chromecast.session;

  chromecastSession.addMessageListener(config.video.chromecast.messageNamespace, messageListener);
  sendMessage({ type: 'clientEnv', value: result(config, 'video.chromecast.environment', 'prod') });
  sendMessage({ type: 'consentString', value: Cookies.get('eupubconsent-v2') });
  sendMessage({ type: 'consentGroups', value: getConsentGroups() });
  const savedAudioLocale = getItem(Keys.CHROMECAST_AUDIOLANGUAGE);
  if (savedAudioLocale) {
    // send preferred audio choice in advance
    sendMessage({ type: 'audioLanguage', language: savedAudioLocale });
  }
  dispatch(setChromecastSessionStatus('available'));
  dispatch(setChromecastSessionName(chromecastSession.displayName, chromecastSession.friendlyName));

  chromecastSession.addUpdateListener(() => {
    const chrome = window['chrome'];

    if (chromecastSession.status !== chrome['cast'].SessionStatus.CONNECTED) {
      dispatch(setChromecastSessionStatus('unavailable'));
    }
  });

  console.log('Chromecast: Session ready');

  try {
    const videoElements = document.getElementsByTagName('video');
    forEach(videoElements, (videoElement) => {
      if (videoElement.paused && videoElement.src) {
        console.log('Fixing Chrome issue with Chromecast session initialization by resuming main video');
        videoElement.play();
      }
    });
  } catch (e) {
    console.log(e);
  }
};

const fetchAsset = async (assetId) => {
  try {
    if (assetId) {
      const asset = await fetchAssetById(assetId);
      appStore.dispatch(playAsset(asset, true));
    }
  } catch (err) {
    console.error(err);
  }
};

const fetchNextAsset = async (assetId) => {
  try {
    if (assetId) {
      const asset = await fetchAssetById(assetId);
      appStore.dispatch(setNextAsset(asset));
    }
  } catch (err) {
    console.error(err);
    appStore.dispatch(setNextAsset(null));
  }
};

const messageListener = async (namespace, message) => {
  try {
    const parsedMessage = JSON.parse(message);
    switch (parsedMessage.type) {
      case 'receiverVersion':
        console.info('Chromecast: Receiver version', parsedMessage.value);
        break;
      case 'assetId': {
        if (parsedMessage.value) {
          fetchAsset(parsedMessage.value);
        }

        // Reset live media if there was a live casting active
        appStore.dispatch(setCurrentLivePosition(null));
        appStore.dispatch(setLiveMedia(null));

        // add timeout to allow for session.media[0] to update itself
        const activeSessionMedia = find(appStore.getState().player.chromecast.session.media, (media) => {
          return media.playerState === 'PLAYING' || media.playerState === 'BUFFERING';
        });

        // apparently when new playback is started during ad break (prerolls or midrolls), session.media ID
        // is not updated correctly breaking visibility of CC controllers => fixing this by implicitly checking
        // what might be the current ongoing media session..
        // NOTE TO SELF: DO NOT REMOVE THIS HACK AS GOOGLE ALREADY BROKE AND FIXED THIS COUPLE OF TIMES
        setTimeout(() => {
          const media = activeSessionMedia || last(appStore.getState().player.chromecast.session.media);
          if (media) {
            appStore.dispatch(setCurrentMedia(media));
          }
        }, 1000);
        break;
      }
      case 'mediaFinished':
        appStore.dispatch(setChromecastMediaSeeking(false)); // This triggers also when CC has stopped loading the seeked position
        appStore.dispatch(setChromecastMediaFinished(parsedMessage.value));
        break;
      case 'progressData': {
        appStore.dispatch(setCurrentPosition(parsedMessage.position));
        const positionAndDuration = {
          position: parsedMessage.position,
          duration: parsedMessage.duration,
        };
        appStore.dispatch(setCurrentPositionAndDuration(positionAndDuration));
        break;
      }
      case 'liveProgressData': {
        const { currentMedia } = appStore.getState().player.chromecast;
        if (currentMedia && currentMedia.playerState !== 'PAUSED') {
          const { start, end } = parsedMessage.liveSeekableRange;
          const currentTime: number = parsedMessage.currentTime;
          const duration: number = end - start;
          const isAtLivePosition = end - currentTime < 30;
          const progress = ((currentTime - start) / duration) * 100;

          const liveMediaPayload = {
            range: {
              start,
              end,
              duration,
            },
            currentTime,
            progress,
            isAtLivePosition,
          };

          appStore.dispatch(setCurrentLivePosition(currentTime));
          appStore.dispatch(setLiveMedia(liveMediaPayload));
        }
        break;
      }
      case 'nextEpisodeAssetId':
        if (parsedMessage.value) {
          fetchNextAsset(parsedMessage.value);
        }
        break;
      case 'HQFilterEnabled':
        appStore.dispatch(setChromecastHQFilter(parsedMessage.value));
        break;
      case 'subtitleFormat':
        appStore.dispatch(setSubtitleFormat(parsedMessage.value));
        break;
      case 'streamType':
        appStore.dispatch(setChromecastStreamType(parsedMessage.value));
        break;
      case 'adBreakClipStarted': {
        appStore.dispatch(setChromecastAdBreakOnGoing(true));
        const adClipData = {
          index: parsedMessage.index,
          total: parsedMessage.total,
        };
        appStore.dispatch(setChromecastAdBreakClipData(adClipData));
        break;
      }
      case 'adBreakEnded':
        appStore.dispatch(setChromecastAdBreakOnGoing(false));
        break;
      case 'uhdCapable':
        appStore.dispatch(setChromecastUHDCapability(parsedMessage.value));
        break;
      case 'availableCaptions':
        appStore.dispatch(setSubtitles(parsedMessage.liveCaptions));
        break;
      case 'availableAudioTracks':
        appStore.dispatch(setAudioTracks(parsedMessage.value));
        break;
      case 'activeAudioTrack':
        appStore.dispatch(setActiveAudioTrack(parsedMessage.value));
    }
  } catch (e) {
    // ignore, invalid message
  }
};

const sendMessage = (message) => {
  const config = getConfig();
  const chromecastSession = appStore.getState().player.chromecast.session;

  if (chromecastSession) {
    chromecastSession.sendMessage(config.video.chromecast.messageNamespace, message);
  } else {
    console.error(`Chromecast: Can't send message. No session`, message);
  }
};

const receiverListener = (event) => {
  console.info('Chromecast: Receive listener is: ', event);
  const { dispatch } = appStore;
  const chrome = window['chrome'];
  if (event === chrome['cast'].ReceiverAvailability.AVAILABLE) {
    dispatch(setChromecastReceiverAvailability(true));
  } else if (event === chrome['cast'].ReceiverAvailability.UNAVAILABLE) {
    dispatch(setChromecastReceiverAvailability(false));
  }
};

const mediaDiscovered = (currentMedia) => {
  console.info('Chromecast: Media discovered', currentMedia);

  if (currentMedia && currentMedia.media) {
    appStore.dispatch(setCurrentMedia(currentMedia));
  }
};

const volumeChange = (volume, currentMedia) => {
  if (currentMedia) {
    const chrome = window['chrome'];
    const volumeRequest = new chrome['cast'].media.VolumeRequest(volume);
    console.info('Chromecast: Set volume to:', volume);
    currentMedia.setVolume(volumeRequest);
    currentMedia.getStatus();
  }
};

const sendAssetToReceiver = (asset, user, opts) => {
  const chromecastSession = appStore.getState().player.chromecast.session;
  sendMessage({ type: 'assetId', value: asset.id });

  if (user && user.authToken) {
    sendMessage({ type: 'authorizationToken', value: user.authToken });
  }

  const mediaInfo = createMediaInfo(asset);
  const chrome = window['chrome'];

  const loadRequest = new chrome['cast'].media.LoadRequest(mediaInfo);

  const filterHQSetting = asset.live ? getItem(Keys.CHROMECAST_HQ_FILTER) : getItem(Keys.CHROMECAST_VOD_HQ_FILTER);
  loadRequest.customData = { foo: 'bar', filterHQ: false };
  if (filterHQSetting !== undefined && filterHQSetting === 'true') {
    loadRequest.customData.filterHQ = true;
  }

  // Only load position data for started assets
  if (opts['position'] && opts['position']) {
    loadRequest.currentTime = opts['position'] * 1000;
  }

  if (opts['filterHQ'] !== undefined) {
    if (asset.live) {
      setItem(Keys.CHROMECAST_HQ_FILTER, opts['filterHQ']);
    } else {
      setItem(Keys.CHROMECAST_VOD_HQ_FILTER, opts['filterHQ']);
    }
    loadRequest.customData.filterHQ = opts['filterHQ'];
  }

  const userOrders = appStore.getState().user.user.orders.active;
  const isHVOD = isHvodSubscriber(userOrders);
  if (isHVOD) {
    loadRequest.customData.isHvodSubscriber = true;
  }
  chromecastSession.loadMedia(
    loadRequest,
    (media) => {
      console.info('loadMedia success', loadRequest, media);
      mediaDiscovered(media);
    },
    (err) => {
      console.error('loadMedia failed', err);
    }
  );
};

const createMediaInfo = (asset: Asset) => {
  // Receiver gets the manifest from API, but chromecast needs just some random manifest url to trigger
  // the loadMedia process in the receiver.
  const chrome = window['chrome'];
  const cast = chrome['cast'];
  const mediaInfo = new cast.media.MediaInfo('http://mtvvod.katsomo.fi/dummy.ism/Manifest', 'text/xml');
  const metadata = new cast.media.GenericMediaMetadata();
  metadata.title = asset.title;
  metadata.subtitle = asset.subtitle;
  mediaInfo.metadata = metadata;
  mediaInfo.customData = { assetId: asset.id };
  return mediaInfo;
};

export function useChromeCastProgress() {
  const queueMediaID = useSelector(selectQueueMediaID);
  const liveMedia = useSelector(selectChromecastLiveMedia);
  const isLivePlaying = useSelector(selectIsLivePlaying);
  const currentMedia = useSelector(selectChromecastCurrentMedia);
  const currentPosition = useSelector(selectChromecastCurrentPosition);
  const currentLivePosition = useSelector(selectChromecastCurrentLivePosition);
  const isSeeking = useSelector(selectChromecastMediaSeeking);
  const timeOnPause = useSelector(selectPauseTime);

  const [progress, setProgress] = useState(0);
  const [isLiveMoment, setIsLiveMoment] = useState(false);

  let duration, position;
  const shouldStartFromBeginning = liveMedia && currentMedia?.media?.customData?.assetId === queueMediaID;

  if (isLivePlaying && liveMedia) {
    duration = liveMedia?.range?.duration;
    position = currentLivePosition;
  } else {
    duration = currentMedia?.media?.duration;
    position = currentPosition;
  }

  const getSeekPosition = (difference) => {
    if (isLivePlaying) {
      return currentLivePosition + difference + timeOnPause * 1000;
    } else {
      return currentPosition + difference;
    }
  };

  const mediaIsLive = (position) => {
    if (!liveMedia) return false;
    const diff = liveMedia.range.end - position;
    return diff >= 0 && diff < 30;
  };

  useEffect(() => {
    const currentPosition = getSeekPosition(0);

    // isSeeking prevents progress bar from jumping when Chromecast is still loading the desired position
    if (currentPosition && currentPosition > 0 && !isSeeking) {
      let currentProgress;

      if (isLivePlaying && liveMedia) {
        const timeOnPausePercentage = (timeOnPause / duration) * 100;
        currentProgress = shouldStartFromBeginning ? 0 : (liveMedia.progress - timeOnPausePercentage) / 100;
        setIsLiveMoment(!shouldStartFromBeginning && mediaIsLive(currentPosition));
      } else {
        currentProgress = currentPosition / duration;
      }
      setProgress(currentProgress);
    }
  }, [currentPosition, currentLivePosition, isSeeking, timeOnPause]);

  const handleProgressClick = (seekedPosition) => {
    const isLive = isLivePlaying && liveMedia;

    const seekTo = seekedPosition / duration;
    setProgress(seekTo);

    if (isLive && !mediaIsLive(seekedPosition)) {
      setIsLiveMoment(false);
    }

    const seekOffset = isLive ? liveMedia.range.start : 0;
    seek(currentMedia, seekedPosition + seekOffset);
  };

  return {
    handleProgressClick,
    getSeekPosition,
    duration,
    position,
    progress,
    setProgress,
    isLiveMoment,
    setIsLiveMoment,
    mediaIsLive,
  };
}
