import React, { FunctionComponent, useEffect } from 'react';
import styled, { ThemeProps } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeedbackMessages } from '../../state/feedback/feedback.selectors';
import { FeedbackLevel, FeedbackMessage } from '../../models/feedback';
import { clearFeedbackMessagesOnNavigation } from '../../state/feedback/feedback.actions';
import { BLACK, BURGUNDYRED, IconButton, IconClose, LIGHTGREEN, LIGHTRED, OwletTheme, TELIAORANGE } from 'owlet-ui';
import { Router } from 'next/router';
import { useFeedback } from '../../services/feedback';

interface FeedbackMessagesProps {
  className?: string;
}

const CloseButton = styled(IconButton)`
  margin-left: 1rem;

  svg {
    fill: ${BLACK};
    width: 26px;
  }
`;

const Message = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 0.25rem ${(props: ThemeProps<OwletTheme>) => props.theme.horizontalMargin};
  padding: 0.45rem 1rem 0.45rem 2rem;
  background: ${TELIAORANGE};
  color: ${BLACK};
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${(props: ThemeProps<OwletTheme>) => props.theme.dropShadow};
  position: relative;
  z-index: 5;

  &.success {
    background: ${LIGHTGREEN};
  }

  &.error,
  &.fatal {
    background: ${LIGHTRED};
    color: ${BURGUNDYRED};

    svg {
      fill: ${BURGUNDYRED};
    }
  }
`;

export const FeedbackMessages: FunctionComponent<FeedbackMessagesProps> = ({ className }: FeedbackMessagesProps) => {
  const { remove } = useFeedback();
  const dispatch = useDispatch();
  const messages: FeedbackMessage[] = useSelector(selectFeedbackMessages);

  useEffect(() => {
    const handleRouteChangeStart = () => dispatch(clearFeedbackMessagesOnNavigation());
    Router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, []);

  if (!messages || messages.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      {messages.map((msg, i) => (
        <Message
          className={msg.level}
          tabIndex={-1}
          role={msg.level === FeedbackLevel.Error || msg.level === FeedbackLevel.Fatal ? 'alert' : 'status'}
          key={i}
          data-test="FeedbackMessages__Message"
        >
          {msg.message} <CloseButton icon={IconClose} aria-label="Sulje" onClick={() => remove(msg)} />
        </Message>
      ))}
    </div>
  );
};
