import React, { FunctionComponent, PropsWithChildren, AnchorHTMLAttributes, EventHandler, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { convertTargetPathToRoutable, getWebPathData, isSameOrigin, isWebPath } from '../../services/target-links';
import { selectPaths } from '../../state/app/app.selectors';
import { Target } from '../../models/curated';
import { getOrigin } from '../../services/api';

export interface TargetLinkProps {
  item: { target?: Target };
  anchorComponent: React.ComponentType<AnchorHTMLAttributes<HTMLAnchorElement>>;
  onClick?: EventHandler<any>;
}

export const TargetLink: FunctionComponent<PropsWithChildren<TargetLinkProps>> = ({
  item,
  anchorComponent: AnchorComponent,
  onClick,
  children,
}: PropsWithChildren<TargetLinkProps>) => {
  const paths = useSelector(selectPaths);
  const handleClick = useCallback(
    (evt) => {
      if (onClick) {
        onClick(evt);
      }
    },
    [onClick]
  );

  // Handle overriden target
  if (item.target) {
    const target = item.target;

    // Normalize urls in the same origin to include only the path part
    // Example: https://www.cmore.fi/abc -> /abc
    const normalizedPath = isSameOrigin(target.path) ? target.path.replace(getOrigin(), '') : target.path;

    // External web url
    if (isWebPath(normalizedPath)) {
      const webPath = getWebPathData(normalizedPath);
      return (
        <AnchorComponent href={webPath.url} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
          {children}
        </AnchorComponent>
      );
    }

    // Path that requires conversion "/category/123 -> /ohjelma/123/xyz" or a normal path
    const linkData = convertTargetPathToRoutable(normalizedPath, paths);

    return (
      <Link href={linkData.href} as={linkData.as} passHref>
        <AnchorComponent onClick={handleClick}>{children}</AnchorComponent>
      </Link>
    );
  }
  return null;
};
