import React from 'react';
import { SVGProps } from '../utils';

const SvgSearch = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510.236 510.236" {...props}>
    <path d="M469.221 417.565S350.616 299.076 349.936 298.572c42.117-66.117 32.59-154.055-22.852-209.508-31.793-31.782-74.051-49.278-118.993-49.278-44.977 0-87.234 17.508-119.004 49.325-31.781 31.734-49.289 73.98-49.301 118.945 0 44.965 17.508 87.223 49.301 119.016 31.781 31.794 74.051 49.302 119.027 49.302 32.192 0 63.435-9.117 90.423-26.379.469.609 119.039 119.297 119.039 119.297 4.406 4.395 10.277 6.809 16.523 6.809 6.27 0 12.152-2.426 16.559-6.844l18.586-18.621c4.418-4.406 6.832-10.277 6.82-16.535 0-6.27-2.425-12.141-6.843-16.536zM208.091 66.177c37.888 0 73.524 14.754 100.337 41.543 55.301 55.336 55.289 145.395-.012 200.766-26.777 26.754-62.391 41.497-100.302 41.497-37.934 0-73.57-14.767-100.371-41.567-26.801-26.812-41.566-62.449-41.566-100.36 0-37.887 14.766-73.5 41.566-100.289 26.801-26.812 62.438-41.59 100.348-41.59zM335.24 320.92l113.203 113.192-14.343 14.367-113.204-113.215 14.344-14.344z" />
  </svg>
);

export default SvgSearch;
