import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IconAspa, IconSearch, Sticky, UnstyledButton, WHITE } from 'owlet-ui';
import { NaviLogo } from './NaviLogo';
import { selectTree } from '../../state/app/app.selectors';
import { Tree, TreePath } from '../../models/tree';
import { NaviLogin } from './NaviLogin';
import { NaviUser } from './NaviUser';
import { NaviMenuTrigger } from './NaviMenuTrigger';
import { NaviMenu } from './NaviMenu';
import { ProfileType, useOpaqueBackground, useProfileType } from './naviUtils';
import { NaviIconLink } from './NaviIconLink';
import { NaviChromecast } from './NaviChromecast';
import { cleanUrl } from '../../services/format';
import { usePathRouter } from '../../services/router/router';
import classNames from 'classnames';
import { NaviContainer } from './styles';
import { useAuth } from '../../services/auth';
import { ExtraItems, MainItems, NaviItemsContainer } from './NaviItemsContainer';
import { FeedbackMessages } from '../feedback/FeedbackMessages';

const MAIN_ITEMS_COUNT = 5;
const CAMPAIGN_REGEX = /^\/kampanja\/.+/;

const LogoWrap = styled.div`
  padding-right: 1rem;
  display: flex;
  align-items: center;
  align-self: stretch;
`;

const StyledNaviUser = styled(NaviUser)`
  cursor: pointer;
  padding: 4px 0 4px 4px;
`;

const StyledNaviMenuTrigger = styled(NaviMenuTrigger)`
  display: none;
`;

const RightWrap = styled.div`
  align-self: stretch;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 1rem;
`;

const NaviIcons = styled.div`
  align-self: stretch;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  a {
    display: block;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }
  }

  svg {
    fill: ${WHITE};
  }
`;

const Nav = styled(NaviContainer)`
  // Hide main and extra items when initializing and with simple layout
  &.navi-init,
  body:not(.navi-force-show) &.navi-simple {
    ${MainItems}, ${ExtraItems}, ${StyledNaviMenuTrigger} {
      visibility: hidden;
    }
  }

  // Extra items hidden (compact layout)
  &.navi-compact {
    ${ExtraItems} {
      visibility: hidden;
    }

    ${StyledNaviUser} {
      display: none;
    }

    ${StyledNaviMenuTrigger} {
      display: block;
    }
  }

  // Main items and extra items hidden (mobile layout)
  &.navi-mobile {
    ${MainItems} {
      visibility: hidden;
    }
  }

  // Simple navi for landing and campaign pages (simple layout)
  body:not(.navi-force-show) &.navi-simple {
    ${StyledNaviMenuTrigger} {
      display: none;
    }

    &.navi-compact,
    &.navi-mobile {
      ${StyledNaviUser} {
        display: none;
      }

      ${StyledNaviMenuTrigger} {
        display: block;
      }
    }
  }
`;

const StyledFeedbackMessages = styled(FeedbackMessages)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
`;

export interface ChoppedTree {
  home: TreePath;
  main: TreePath[];
  extra: TreePath[];
}

function chopTree(tree: Tree): ChoppedTree {
  const nonHomePaths = tree.filter((path) => !path.main);

  return {
    home: tree.find((path) => path.main),
    main: nonHomePaths.slice(0, MAIN_ITEMS_COUNT),
    extra: nonHomePaths.slice(MAIN_ITEMS_COUNT, nonHomePaths.length),
  };
}

const SEARCH_ITEM: TreePath = { path: '/hae', title: 'Haku' };
const CUSTOMER_SUPPORT_ITEM: TreePath = { path: '/asiakaspalvelu', title: 'Asiakaspalvelu' };

export const Navi = React.memo(() => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [containerClassNames, setContainerClassNames] = useState<Record<string, boolean>>(null);

  const { currentPathConfig, asPath } = usePathRouter();

  const isSimpleNaviPage = () => {
    const path = cleanUrl(asPath);
    const isLandingPage = currentPathConfig?.visibleUrl === '/kansi';
    const isCampaignPage = CAMPAIGN_REGEX.test(path);

    return isLandingPage || isCampaignPage;
  };

  const [isSimpleNavi, setSimpleNavi] = useState<boolean>(isSimpleNaviPage());

  const navRef = useRef<HTMLElement>(null);
  const { isAuthenticated } = useAuth();
  const profileType = useProfileType();

  const tree: Tree = useSelector(selectTree);
  const [items] = useState<ChoppedTree>(chopTree(tree));

  const getFilteredItems = (): ChoppedTree => {
    if (profileType === ProfileType.Junior) {
      return {
        ...items,
        main: items.main.filter(({ path }) => path === '/juniori'),
        extra: [],
      };
    }

    return items;
  };

  const [filteredItems, setFilteredItems] = useState<ChoppedTree>(getFilteredItems);
  const isOpaqueBackground = useOpaqueBackground();

  useEffect(() => {
    setFilteredItems(getFilteredItems());
  }, [profileType]);

  useEffect(() => {
    setSimpleNavi(isSimpleNaviPage());
  }, [currentPathConfig, asPath]);

  const toggleMenu = useCallback((evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    setMenuOpen((v) => !v);
  }, []);

  const handleOutsideClick = useCallback(() => setMenuOpen(false), []);

  return (
    <Sticky
      forceVisible={menuOpen}
      onVisibilityChange={({ visible }) => document.documentElement.classList.toggle('navi-visible', visible)}
    >
      <Nav
        className={classNames(containerClassNames, {
          'navi-simple': isSimpleNavi,
          'navi-opaque': isOpaqueBackground,
        })}
        data-test="Navi"
        ref={navRef}
      >
        <LogoWrap>
          <NaviLogo isAuthenticated={isAuthenticated} profileType={profileType} />
        </LogoWrap>
        <NaviItemsContainer
          items={filteredItems}
          profileType={profileType}
          onClassNamesChange={setContainerClassNames}
          navRef={navRef}
        />
        <RightWrap>
          <NaviIcons>
            <NaviChromecast />
            <NaviIconLink item={SEARCH_ITEM} icon={IconSearch} />
            {profileType !== ProfileType.Junior && <NaviIconLink item={CUSTOMER_SUPPORT_ITEM} icon={IconAspa} />}
          </NaviIcons>
          <UnstyledButton
            onClick={toggleMenu}
            aria-label={menuOpen ? 'Sulje valikko' : 'Avaa valikko'}
            data-test="Navi__MenuToggle"
          >
            <StyledNaviUser />
            <StyledNaviMenuTrigger isMenuOpen={menuOpen} />
          </UnstyledButton>
          <NaviLogin />
        </RightWrap>
        <NaviMenu items={filteredItems} isMenuOpen={menuOpen} onOutsideClick={handleOutsideClick} />
      </Nav>
      <StyledFeedbackMessages />
    </Sticky>
  );
});

Navi.displayName = 'Navi';
Navi.whyDidYouRender = true;
