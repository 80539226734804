import { CuratedAsset, CuratedCategory, HeroSlide } from '../../models/curated';
import { ActionTypes } from './ui.actions';
import produce from 'immer';
import { ContentContext } from '../../services/content-context';

export interface UIState {
  detailsFloat: DetailsFloatState;
  previewVideosMuted: boolean;
}

export interface DetailsFloatState {
  item: CuratedAsset | CuratedCategory | HeroSlide;
  parentId: string;
  contentContext?: ContentContext;
  wideParent?: boolean;
  subtitleAsDescription?: boolean;
}

export const initialUIState: UIState = {
  detailsFloat: {
    item: null,
    parentId: null,
    contentContext: null,
    wideParent: true,
    subtitleAsDescription: false,
  },
  previewVideosMuted: true,
};

const uiReducer = (state = initialUIState, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_DETAILS_FLOAT:
      return produce(state, (draft) => {
        const {
          item,
          parentId,
          contentContext,
          wideParent,
          subtitleAsDescription,
        } = action.payload as DetailsFloatState;

        draft.detailsFloat = {
          item,
          parentId,
          contentContext,
          wideParent,
          subtitleAsDescription,
        };
      });
    case ActionTypes.CLEAR_DETAILS_FLOAT:
      return produce(state, (draft) => {
        draft.detailsFloat = {
          ...initialUIState.detailsFloat,
        };
      });
    case ActionTypes.SET_PREVIEW_VIDEOS_MUTED:
      return produce(state, (draft) => {
        draft.previewVideosMuted = action.payload;
      });
    default:
      return state;
  }
};

export default uiReducer;
