import { DependencyList, useCallback, useEffect, useRef, useState } from 'react';

export interface AsyncState<T> {
  value: T | undefined;
  loading: boolean;
  error: Error | undefined;
}

export type UseAsyncResponse<T> = AsyncState<T> & { retry: () => void };

/**
 * Generic hook for async operations.
 *
 * @param fn
 * @param deps
 */
export function useAsync<T>(fn: () => Promise<T>, deps: DependencyList = []): UseAsyncResponse<T> {
  const [state, setState] = useState<AsyncState<T>>({
    value: undefined,
    loading: false,
    error: undefined,
  });

  const callback = useCallback(() => {
    setState((prevState) => ({ ...prevState, loading: true }));

    fn().then(
      (value) => setState({ value, loading: false, error: undefined }),
      (error) => setState({ value: undefined, loading: false, error })
    );
  }, deps);

  useEffect(() => {
    callback();
  }, [callback]);

  return { ...state, retry: callback };
}

/**
 * Automatic retry hook built on top of useAsync(). Will attempt function given amount of times with the given delay.
 *
 * @param fn
 * @param deps
 * @param retryCount
 * @param delay Delay in milliseconds
 */
export function useRetry<T>(
  fn: () => Promise<T>,
  deps: DependencyList = [],
  retryCount = 10,
  delay = 800
): AsyncState<T> {
  const [attempt, setAttempt] = useState<number>(0);
  const [finalError, setFinalError] = useState<Error>();
  const timer = useRef<any>();

  const state = useAsync<T>(fn, deps);

  useEffect(() => {
    const { error, loading, retry } = state;

    if (error && !loading) {
      if (attempt < retryCount) {
        timer.current = setTimeout(() => {
          console.log('[useRetry] Retrying...', attempt + 1);
          setAttempt((n) => n + 1);
          retry();
        }, delay);
      } else {
        console.log('[useRetry] Exhausted, stopping');
        setFinalError(error);
        clearTimeout(timer.current);
      }
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [state]);

  return { ...state, error: finalError };
}
