import { CustomerSupportArticle, CustomerSupportCategory } from '../../models/customerSupport';

export const ActionTypes = {
  ARTICLES_REQUEST: 'customer_support/articles_request',
  ARTICLES_SUCCESS: 'customer_support/articles_success',
  ARTICLES_FAIL: 'customer_support/articles_fail',
  CATEGORIES_REQUEST: 'customer_support/categories_request',
  CATEGORIES_SUCCESS: 'customer_support/categories_success',
  CATEGORIES_FAIL: 'customer_support/categories_fail',
};

export const articlesRequest = () => ({
  type: ActionTypes.ARTICLES_REQUEST,
});

export const articlesSuccess = (articles: CustomerSupportArticle[]) => ({
  type: ActionTypes.ARTICLES_SUCCESS,
  payload: articles,
});

export const articlesFail = () => ({
  type: ActionTypes.ARTICLES_FAIL,
});

export const categoriesRequest = () => ({
  type: ActionTypes.CATEGORIES_REQUEST,
});

export const categoriesSuccess = (categories: CustomerSupportCategory[]) => ({
  type: ActionTypes.CATEGORIES_SUCCESS,
  payload: categories,
});

export const categoriesFail = () => ({
  type: ActionTypes.CATEGORIES_FAIL,
});
