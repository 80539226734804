import { DependencyList, useCallback } from 'react';
import { isBrowser } from '../utils';
import { Asset } from '../../models/asset';
import { Category } from '../../models/category';
import { CuratedAsset, CuratedCategory, HeroSlide, PromoItem } from '../../models/curated';
import { isAsset } from '../assets';
import { ContentContext } from '../content-context';

export enum EDDLEventType {
  Details = 'details',
  Play = 'play',
  RateUp = 'rateup',
  RateDown = 'ratedown',
  Favorite = 'favorite',
  Unfavorite = 'unfavorite',
}

export enum EDDLComponentType {
  Grid = 'grid',
  List = 'list',
  DetailsHero = 'details-hero',
}

export interface EDDLContext {
  section?: number;
  sectionTitle?: string;
  title?: string;
  nth?: number;
  component?: string; // Coremedia component
}

export interface EDDLBasePayload {
  context?: EDDLContext;
}

type EDDLAssetPayload = EDDLBasePayload & {
  asset?: Asset;
};

type EDDLCategoryPayload = EDDLBasePayload & {
  category?: Category;
};

type EDDLPayload = EDDLAssetPayload | EDDLCategoryPayload;

export type EDDLEvent = { event: EDDLEventType } & EDDLPayload;

function createEDDLContext(title, context: ContentContext): EDDLBasePayload {
  const { componentId: section, sectionTitle, index: nth, component } = context || {};
  return {
    context: {
      title,
      section,
      sectionTitle,
      nth,
      component,
    },
  };
}

export function buildCuratedEDDLMoreinfoEvent(
  context: ContentContext,
  item: CuratedCategory | CuratedAsset | PromoItem | HeroSlide
) {
  const titleSource = 'asset' in item ? item.asset : item.category;
  const title = item.title || titleSource?.title;

  const event = {
    event: EDDLEventType.Details,
    ...createEDDLContext(title, context),
  };

  const content = 'asset' in item ? { asset: item.asset as Asset } : { category: item.category as Category };

  return { ...event, ...content };
}

export function buildEDDLPlayEvent(context: ContentContext, item: Asset | Category, title: string = null): EDDLEvent {
  return buildEDDLEvent(EDDLEventType.Play, context, item, title);
}

export function buildEDDLMoreinfoEvent(
  context: ContentContext,
  item: Asset | Category,
  title: string = null
): EDDLEvent {
  return buildEDDLEvent(EDDLEventType.Details, context, item, title);
}

export function buildEDDLCategoryMoreinfoEvent(context: ContentContext, title: string): EDDLEvent {
  return {
    event: EDDLEventType.Details,
    ...createEDDLContext(title, context),
    category: null,
  };
}

export const buildEDDLEvent = (
  event: EDDLEventType,
  context: ContentContext,
  item: Asset | Category,
  title: string = null
): EDDLEvent => {
  const content = isAsset(item) ? { asset: item as Asset } : { category: item as Category };

  return {
    event,
    ...createEDDLContext(title || item?.title, context),
    ...content,
  };
};

export function sendEDDLEvent(event: EDDLEvent) {
  if (event && isBrowser()) {
    // Make sure window._DL is initialized
    window['_DL'] = window['adobeDataLayer'] = window['adobeDataLayer'] || [];

    (window['_DL'] as EDDLEvent[]).push(event);
  }
}

/**
 * Custom hook that returns a DOM event handler for collecting event-driven data layer (EDDL) tracking events.
 *
 * @param eventFactory Function that returns an EDDLEvent
 * @param deps Dependencies of `eventFactory` function
 */
export function useEDDL(eventFactory: () => EDDLEvent, deps: DependencyList = []): () => void {
  return useCallback(() => {
    sendEDDLEvent(eventFactory());
  }, deps);
}
