import '../../lib/wdyr';

import React from 'react';
import { Provider } from 'react-redux';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
import { ThemeProvider } from 'styled-components';
import { CMORE, GlobalFontStyles, ModalProvider } from 'owlet-ui';

import createStore from '../state/store';
import { pathsFail, pathsSuccess, treeFail, treeSuccess } from '../state/app/app.actions';
import { AuthenticationHandler } from '../components/auth/AuthenticationHandler';
import { ErrorLoggerInit } from '../components/errors/ErrorLoggerInit';
import { PageContext } from '../models/context';
import ErrorPage from './_error';
import { SvodModalTheme } from '../components/modal/SvodModalTheme';
import { productsFail, productsSuccess } from '../state/products/products.actions';
import { Store } from 'redux';
import { TargetLinkContextProvider } from '../services/target-links';
import { fetchPaths, fetchTree } from '../services/site';
import { devLog } from '../services/utils';
import { fetchAllProducts } from '../services/products/fetch';

type AppContextWithStore = AppContext & { ctx: PageContext };

type AppPropsExtra = {
  serverError: boolean;
  currentPath: string;
  store: Store;
};

function CmoreApp({ Component, pageProps, store, serverError }: AppProps & AppPropsExtra) {
  return (
    <ThemeProvider theme={CMORE}>
      <GlobalFontStyles />
      <Provider store={store}>
        {serverError && <ErrorPage errorCode={500} />}
        {!serverError && (
          <ModalProvider {...SvodModalTheme}>
            <ErrorLoggerInit />
            <AuthenticationHandler />
            <TargetLinkContextProvider>
              <Component {...pageProps} />
            </TargetLinkContextProvider>
          </ModalProvider>
        )}
      </Provider>
    </ThemeProvider>
  );
}

CmoreApp.getInitialProps = async (appContext: AppContextWithStore): Promise<AppInitialProps & AppPropsExtra> => {
  let serverError = false;
  const {
    ctx: { store, req, res },
  } = appContext;

  if (req) {
    try {
      devLog('Loading paths, tree, all-products');

      const { dispatch } = store;

      await Promise.all([
        fetchPaths()
          .then((paths) => (paths ? dispatch(pathsSuccess(paths)) : dispatch(pathsFail())))
          .catch(() => dispatch(pathsFail())),
        fetchTree()
          .then((tree) => (tree ? dispatch(treeSuccess(tree)) : dispatch(treeFail())))
          .catch(() => dispatch(treeFail())),
        fetchAllProducts()
          .then((allProducts) => (allProducts ? dispatch(productsSuccess(allProducts)) : dispatch(productsFail())))
          .catch(() => dispatch(productsFail())),
      ]);
    } catch (e) {
      serverError = true;
      console.error(e);
      if (res) {
        res.statusCode = 500;
      }
    }
  }

  const appProps = await App.getInitialProps(appContext);

  return { ...appProps, store, serverError, currentPath: req ? req.url : null };
};

export default withRedux(createStore)(withReduxSaga(CmoreApp));
