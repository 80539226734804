/* istanbul ignore file */

import { all } from 'redux-saga/effects';
import UserSagas from './user/user.sagas';
import FeedbackSagas from './feedback/feedback.sagas';
import viewingHistorySagas from './viewing-history/viewing-history.sagas';
import SeriesSagas from './series/series.sagas';
import NotificationsSagas from './notifications/notifications.sagas';
import CustomerSupportSagas from './customer-support/customer-support.sagas';
import PurchaseSagas from './purchase/purchase.sagas';

export default function* rootSaga() {
  try {
    yield all([
      ...UserSagas,
      ...FeedbackSagas,
      ...viewingHistorySagas,
      ...SeriesSagas,
      ...NotificationsSagas,
      ...CustomerSupportSagas,
      ...PurchaseSagas,
    ]);
  } catch (err) {
    console.log(err);
  }
}
