/* istanbul ignore file */

import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer, { combinedInitialState } from './reducers';
import rootSaga from './sagas';
import { ModalContext } from 'owlet-ui';

const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

function configureStore(initialState: any = combinedInitialState, { req = null, isServer }) {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      modals: ModalContext,
    },
  });
  const store = createStore(rootReducer, initialState, bindMiddleware([sagaMiddleware]));

  if (req || !isServer) {
    // @ts-ignore
    store.sagaTask = sagaMiddleware.run(rootSaga);
  }

  return store;
}

export default configureStore;
