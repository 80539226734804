import React, { FunctionComponent } from 'react';
import { Asset, AssetType } from '../../models/asset';
import { DateBadge } from './DateBadge';
import { isLive } from '../../services/assets';
import { addSeconds, isValid } from 'date-fns';

interface AssetDateBadgeProps {
  asset: Asset;
  className?: string;
}

export const AssetDateBadge: FunctionComponent<AssetDateBadgeProps> = ({ asset, className }: AssetDateBadgeProps) => {
  if (!asset) {
    return null;
  }

  const { duration, epgLiveBroadcastTime } = asset;
  const date = new Date(epgLiveBroadcastTime);

  if (!epgLiveBroadcastTime || !isValid(date)) {
    return null;
  }

  const live = isLive(asset);

  let endDate: Date;
  if (duration && (asset.type === AssetType.Sport || live)) {
    endDate = addSeconds(date, duration);
  }

  return <DateBadge date={date} endDate={endDate} live={live} showTime={true} className={className} />;
};
