import { Product } from './product';

export const SHOPPING_CART_EXPIRY_MILLIS = 1000 * 60 * 60 * 24;

export interface SerializedShoppingCart {
  product: {
    id: number;
    productGroupId: number;
  };
  startingPointUrl: string;
  context: {
    id: number;
    type: 'asset' | 'category';
  };
  voucherCode: string;
}

export enum SwitchType {
  Upgrade = 'upgrade',
  Downgrade = 'downgrade',
  Coexist = 'coexist',
}

export interface VimondOrder {
  paymentObject: {
    url: string;
  };
}

export enum VimondUpgradeOption {
  Discount = 'DISCOUNT',
  ExtendedTime = 'EXTENDED_TIME',
}

export type VimondOrderData =
  | {
      // new purchase
      userId: number;
      currency: 'EUR';
      productPaymentId: number;
      voucher?: {
        voucherCode: string;
      };
      payment?: {
        paymentMethod: 'DIRECT_BANK';
        paymentMethodAction: string;
        terminalDesign: 'netbankRedirect';
      };
      callbackUrl: string;
      startDate?: string;
    }
  | {
      // purchase with password
      userId: number;
      productPaymentId: number;
      userPaymentMethod: {
        id: string;
      };
      payment: {
        paymentMethod: 'ONE_CLICK_BUY';
        password: string;
      };
      voucher?: {
        voucherCode: string;
      };
    }
  | {
      // downgrade with password
      userId: number;
      productPaymentId: number;

      payment: {
        paymentMethod: 'CONVERT_ORDER_FOR_NEXT_RENEWAL';
        orderId: number;
        password: string;
      };

      voucher?: {
        voucherCode: string;
      };
    }
  | {
      // upgrdade with password
      userId: number;
      productPaymentId: number;
      payment: {
        paymentMethod: 'ONE_CLICK_BUY';
        password: string;
      };
      upgradeOption: VimondUpgradeOption;
      upgradeOrderId: number;
      userPaymentMethod: {
        id: number;
      };
      callbackUrl?: string;
    }
  | {
      // upgrdade with new card
      userId: number;
      currency: 'EUR';
      productPaymentId: number;
      upgradeOption: VimondUpgradeOption;
      upgradeOrderId: number;
      callbackUrl?: string;
    }
  | {
      // voucher-only purchase
      userId: number;
      payment: {
        paymentMethod: 'VOUCHER';
        voucher: string;
      };
    };

export interface CancelFlowContentConfig {
  path: string;
  title: string;
  buttonLabel: string;
}

export interface VimondOrderConversionResponse {
  restOrderConversionList: VimondOrderConversion[];
}

export interface VimondOrderConversion {
  orderId: number;
  restOrderConversionProductGroupList: Array<{
    restOrderConversionProductList: Array<{
      restProduct: Product;
      orderConversionProductPaymentList: VimondOrderConversionPayment[];
    }>;
  }>;
}

export interface VimondOrderConversionPayment {
  discount: number;
  extendedTime: number;
}

export enum NetBank {
  Nordea = 'PaytrailNordea',
  OP = 'PaytrailOP',
  DanskeBank = 'PaytrailDanskeBank',
  Handelsbanken = 'PaytrailHandelsbanken',
  SPankki = 'PaytrailSPankki',
  Saastopankki = 'PaytrailSaastopankki',
  POP = 'PaytrailPOP',
  Aktia = 'PaytrailAktia',
  Alandsbanken = 'PaytrailAlandsbanken',
}
