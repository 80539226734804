import { DetailsFloatState } from './ui.reducers';

export const ActionTypes = {
  SET_DETAILS_FLOAT: 'ui/set_details_float',
  CLEAR_DETAILS_FLOAT: 'ui/clear_details_float',
  SET_PREVIEW_VIDEOS_MUTED: 'ui/set_preview_videos_muted',
};

export const setDetailsFloat = (stete: DetailsFloatState) => ({
  type: ActionTypes.SET_DETAILS_FLOAT,
  payload: stete,
});

export const clearDetailsFloat = () => ({
  type: ActionTypes.CLEAR_DETAILS_FLOAT,
});

export const setPreviewVideosMuted = (muted: boolean) => ({
  type: ActionTypes.SET_PREVIEW_VIDEOS_MUTED,
  payload: muted,
});
