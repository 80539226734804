import React, { FunctionComponent, useCallback } from 'react';
import { Asset } from '../../models/asset';
import styled from 'styled-components';
import { NaviContainer, NaviIconCmore } from '../navi/styles';
import { DynamicHead } from '../seo-metadata/DynamicHead';
import { GlobalStyles } from '../../layouts/globalStyles';
import { itemLink } from '../../services/links';
import Link from 'next/link';
import { redirectToApplications, redirectToStore } from '../../services/browser';
import { Button } from 'owlet-ui';

interface OpenNativePlayerProps {
  asset: Asset;
}

const MinimalNaviContainer = styled(NaviContainer)`
  align-items: center;
`;

const FullPage = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  body.with-modal & {
    filter: blur(2.5rem);
  }
`;

const MinimalNavi: FunctionComponent = () => {
  return (
    <MinimalNaviContainer>
      <NaviIconCmore />
    </MinimalNaviContainer>
  );
};

const Wrap = styled.div`
  margin: calc(-1 * var(--navi-height)) ${(props) => props.theme.horizontalMargin};
  text-align: center;
  display: flex;
  flex-flow: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const NativePlayerLinks: FunctionComponent<OpenNativePlayerProps> = ({ asset }: OpenNativePlayerProps) => {
  const handleApplicationsClick = useCallback(() => redirectToApplications(asset.id, false), [asset]);
  const handleStoreClick = useCallback(() => redirectToStore(asset.id), [asset]);

  return (
    <>
      <p>
        <Button onClick={handleApplicationsClick}>Avaa C More -sovelluksessa</Button>
      </p>
      <p>
        <a onClick={handleStoreClick}>Ei sovellusta asennettuna? Lataa sovellus &gt;</a>
      </p>
    </>
  );
};

export const OpenNativePlayer: FunctionComponent<OpenNativePlayerProps> = ({ asset }: OpenNativePlayerProps) => {
  const { href, as } = itemLink(asset) || { href: '/', as: '/' };

  return (
    <FullPage>
      <DynamicHead />
      <GlobalStyles />
      <MinimalNavi />
      <Wrap>
        <NativePlayerLinks asset={asset} />
        <Link href={href} as={as}>
          <a>Jatka selaimessa cmore.fi:hin &gt;</a>
        </Link>
      </Wrap>
    </FullPage>
  );
};
