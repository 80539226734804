import * as Sentry from '@sentry/node';
import { getConfig } from './conf';
import { User } from '../models/user';
import filter from 'lodash/filter';
import each from 'lodash/each';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import some from 'lodash/some';
import flatten from 'lodash/flatten';
import versionInfo from './version-info.json';

const BLACKLIST_URLS = [
  /salesforceliveagent\.com/,
  /mtv\.my\.salesforce\.com/,
  /static\.lightning\.force\.com/,
  /assets\.adobedtm\.com/,
  /cdn\.cookielaw\.org/,
  /analytics\.edgekey\.net/,
  /file:\/\//,
  /embed-rockery/,
  /mtv-player/,
];

const config = getConfig();

export function initSentry() {
  if (!config.sentry) {
    throw new Error('Sentry configuration is missing.');
  }

  if (config.sentry.enabled) {
    if (Sentry.getCurrentHub()?.getClient()) {
      // Sentry already initialized, skip Sentry.init()
      return;
    }

    Sentry.init({
      enabled: config.sentry.enabled,
      dsn: config.sentry.url,
      environment: config.env,
      release: versionInfo.commit,
      beforeSend: (event: Sentry.Event) => {
        // Skip Sentry if error originates from a blacklisted file
        if (isEventBlacklisted(event)) {
          return null;
        }
        let validEvent = true;

        const xhrEvents = filter(event.breadcrumbs, (breadcrumb) => {
          return breadcrumb.category === 'xhr';
        });
        each(xhrEvents, (breadcrumb) => {
          if (breadcrumb.data && breadcrumb.data.status_code) {
            const statusCode = breadcrumb.data.status_code;
            if (
              statusCode === 0 ||
              statusCode === 404 ||
              statusCode === 400 ||
              statusCode === 403 ||
              statusCode === 410
            ) {
              validEvent = false;
            }
          }
        });

        // Skip illegal invocation errors caused by Facebook
        // https://github.com/aFarkas/lazysizes/issues/520
        if (
          /illegal invocation/i.test(get(event, 'message')) &&
          includes(get(event, 'request.query_string'), 'fbclid=')
        ) {
          validEvent = false;
        }

        if (!validEvent) {
          return null;
        }

        return event;
      },
    });

    // Add version info to sentry errors. Info is read from version-info.json that is overriden in Jenkins when the docker
    // image is built.
    if (versionInfo.commit) {
      Sentry.configureScope((scope: Sentry.Scope) => {
        scope.setTag('version', versionInfo.version);
        scope.setTag('commit', versionInfo.commit);
        scope.setTag('branch', versionInfo.branch);
        scope.setTag('commitTime', versionInfo.commitTime);
        scope.setTag('buildTime', versionInfo.buildTime);
      });
    }
    console.info('Sentry initialized');
  } else {
    console.info('Sentry is disabled');
  }
}

export function captureException(ex) {
  Sentry.captureException(ex);
}

export function captureMessage(msg) {
  Sentry.captureMessage(msg);
}

export function setUser(user: User) {
  Sentry.configureScope((scope: Sentry.Scope) => {
    if (user) {
      scope.setUser({
        id: user.id.toString(),
      });
    } else {
      scope.setUser(null);
    }
  });
}

export function isEventBlacklisted(event: Sentry.Event): boolean {
  const lastFrames = map(get(event?.exception, 'values'), (value) => get(value, 'stacktrace.frames'));

  const sourceFiles = flatten(
    map(flatten(lastFrames), (frame) => {
      // Production has the relevant domain name in abs_path, local development in filename...
      const { abs_path, filename } = frame || {};
      return [abs_path, filename];
    })
  );

  return some(BLACKLIST_URLS, (url) => some(sourceFiles, (filename) => url.test(filename)));
}
