import { Tree } from '../../models/tree';
import { Paths } from '../../models/paths';
import { MetatagValues } from '../../models/metatag';
import { ChatStatuses } from './app.reducers';

export const ActionTypes = {
  TREE_SUCCESS: 'app/tree_success',
  TREE_FAIL: 'app/tree_fail',
  PATHS_SUCCESS: 'app/paths_success',
  PATHS_FAIL: 'app/paths_fail',
  ROUTE_CHANGE: 'app/route_change',
  TITLE_UPDATE: 'app/title_update',
  TITLE_RESET: 'app/title_reset',
  METATAG_UPDATE: 'app/metatag_update',
  METATAG_RESET: 'app/metatag_reset',
  CANONICAL_URL_UPDATE: 'app/canonical_url_update',
  CANONICAL_URL_RESET: 'app/canonical_url_reset',
  SET_CHAT_STATUS: 'app/set_chat_status',
  SET_MOBILE_LAYOUT: 'app/set_mobile_layout',
  SET_ACTIVE: 'app/set_active',
  SET_INVISIBILITY_START: 'app/set_invisibility_start',
};

export const treeSuccess = (tree: Tree) => ({
  type: ActionTypes.TREE_SUCCESS,
  payload: tree,
});

export const treeFail = () => ({
  type: ActionTypes.TREE_FAIL,
});

export const pathsSuccess = (paths: Paths) => ({
  type: ActionTypes.PATHS_SUCCESS,
  payload: paths,
});

export const pathsFail = () => ({
  type: ActionTypes.PATHS_FAIL,
});

export const routeChange = (pathName: string) => ({
  type: ActionTypes.ROUTE_CHANGE,
  payload: pathName,
});

export const titleUpdate = (title: string) => ({
  type: ActionTypes.TITLE_UPDATE,
  payload: title,
});

export const titleReset = () => ({
  type: ActionTypes.TITLE_RESET,
});

export const metatagUpdate = (metatags: MetatagValues) => ({
  type: ActionTypes.METATAG_UPDATE,
  payload: metatags,
});

export const metatagReset = () => ({
  type: ActionTypes.METATAG_RESET,
});

export const canonicalUrlUpdate = (canonicalUrl: string) => ({
  type: ActionTypes.CANONICAL_URL_UPDATE,
  payload: canonicalUrl,
});

export const canonicalUrlReset = () => ({
  type: ActionTypes.CANONICAL_URL_RESET,
});

export const setChatStatus = (newStatus: ChatStatuses) => ({
  type: ActionTypes.SET_CHAT_STATUS,
  payload: newStatus,
});

export const setMobileLayout = (isMobileLayout: boolean) => ({
  type: ActionTypes.SET_MOBILE_LAYOUT,
  payload: isMobileLayout,
});

export const setActive = (isActive: boolean) => ({
  type: ActionTypes.SET_ACTIVE,
  payload: { isActive },
});

export const setInvisibilityStart = (date: Date) => ({
  type: ActionTypes.SET_INVISIBILITY_START,
  payload: { date },
});
