import { CmProduct } from '../../models/product';
import { ActionTypes } from './purchase.actions';
import produce from 'immer';
import { LinkData } from '../../services/links';
import { Voucher } from '../../models/voucher';
import { Asset } from '../../models/asset';
import { Category } from '../../models/category';
import { ProductPromo } from '../../models/product-promo';

export interface PurchaseState {
  product: CmProduct;
  startingPoint: LinkData;
  context: Asset | Category;
  voucher: Voucher;
  isCompleted: boolean;
  isExitInProgress: boolean;
  previousProduct: CmProduct;
  productPromos: ProductPromo[];
  productPromosLoadedState: ProductPromosLoadedState;
}

export enum ProductPromosLoadedState {
  NotLoaded = 'notLoaded',
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
}

export const initialPurchaseState: PurchaseState = {
  product: null,
  startingPoint: null,
  context: null,
  voucher: null,
  isCompleted: false,
  isExitInProgress: false,
  previousProduct: null,
  productPromos: [],
  productPromosLoadedState: ProductPromosLoadedState.NotLoaded,
};

// Remove hash from the path
const HASH_PATTERN = /^([^#]+)/;
const cleanStartingPoint = (path: LinkData): LinkData => {
  if (!path) {
    return initialPurchaseState.startingPoint;
  }

  return {
    href: path.href.match(HASH_PATTERN)[1],
    as: path.as.match(HASH_PATTERN)[1],
  };
};

const purchaseReducer = (state = initialPurchaseState, action: any) => {
  switch (action.type) {
    case ActionTypes.PURCHASE_INIT:
      return produce(state, (draft) => {
        const { product, startingPoint, context, voucher } = action.payload;
        draft.product = product;
        draft.startingPoint = cleanStartingPoint(startingPoint);
        draft.context = context;
        draft.voucher = voucher;
        draft.isCompleted = true;
      });
    case ActionTypes.PURCHASE_SELECT_PRODUCT:
      return produce(state, (draft) => {
        draft.product = action.payload;
      });
    case ActionTypes.PURCHASE_SET_STARTING_POINT:
      return produce(state, (draft) => {
        draft.startingPoint = cleanStartingPoint(action.payload);
      });
    case ActionTypes.PURCHASE_SET_CONTEXT:
      return produce(state, (draft) => {
        draft.context = action.payload;
      });
    case ActionTypes.PURCHASE_SET_VOUCHER:
      return produce(state, (draft) => {
        draft.voucher = action.payload;
      });
    case ActionTypes.PURCHASE_STATE_COMPLETED:
      return produce(state, (draft) => {
        draft.isCompleted = true;
      });
    case ActionTypes.PURCHASE_CLEAR:
      return produce(state, (draft) => {
        const { product, startingPoint, context, voucher } = initialPurchaseState;
        draft.product = product;
        draft.startingPoint = startingPoint;
        draft.context = context;
        draft.voucher = voucher;
      });
    case ActionTypes.PURCHASE_SET_EXIT_IN_PROGRESS:
      return produce(state, (draft) => {
        draft.isExitInProgress = action.payload;
      });
    case ActionTypes.PURCHASE_SET_PREVIOUS_PRODUCT:
      return produce(state, (draft) => {
        draft.previousProduct = action.payload;
      });
    case ActionTypes.PURCHASE_CLEAR_PREVIOUS_PRODUCT:
      return produce(state, (draft) => {
        draft.previousProduct = null;
      });
    case ActionTypes.PURCHASE_SET_PRODUCT_PROMOS:
      return produce(state, (draft) => {
        draft.productPromos = action.payload;
      });
    case ActionTypes.PURCHASE_SET_PRODUCT_PROMOS_LOADED_STATE:
      return produce(state, (draft) => {
        draft.productPromosLoadedState = action.payload;
      });
    default:
      return state;
  }
};

export default purchaseReducer;
