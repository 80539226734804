import { ProductPromosLoadedState, PurchaseState } from './purchase.reducers';
import { createSelector, Selector } from 'reselect';
import { CmProduct } from '../../models/product';
import { ProductPromo } from '../../models/product-promo';

const selectPurchaseState = () => (state: any) => state.purchase;

export const selectShoppingCart = createSelector(
  selectPurchaseState(),
  ({ isCompleted, product, startingPoint, context, voucher }: PurchaseState) => {
    return { isCompleted, product, startingPoint, context, voucher };
  }
);

export const selectExitInProgress: Selector<PurchaseState, boolean> = createSelector(
  selectPurchaseState(),
  ({ isExitInProgress }: PurchaseState) => {
    return isExitInProgress;
  }
);

export const selectPreviousProduct: Selector<PurchaseState, CmProduct> = createSelector(
  selectPurchaseState(),
  ({ previousProduct }: PurchaseState) => previousProduct
);

export const selectProductPromos: Selector<PurchaseState, ProductPromo[]> = createSelector(
  selectPurchaseState(),
  ({ productPromos }: PurchaseState) => productPromos
);

export const selectProductPromosLoadedState: Selector<PurchaseState, ProductPromosLoadedState> = createSelector(
  selectPurchaseState(),
  ({ productPromosLoadedState }: PurchaseState) => productPromosLoadedState
);
