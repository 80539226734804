import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Link = styled.a`
  color: #e0001c;
`;

interface TermsLinkProps {}

export const TermsLink: FunctionComponent<TermsLinkProps> = ({ children }) => {
  return (
    <Link href="https://www.mtv.fi/tietosuojakaytantomme" target="_blank" rel="noreferrer">
      {children}
    </Link>
  );
};
