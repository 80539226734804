import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { authRenew } from '../../state/user/user.actions';
import { useActivity, useInterval } from 'owlet-ui';
import { useAuth } from '../../services/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectAssetPlaying } from '../../state/player/player.selectors';

// Renew authentication every 3 hours
export const AUTH_RENEW_INTERVAL = 3 * 60 * 60 * 1000;
// Don't retry auth renewals more often than every 5 minutes
export const AUTH_RENEW_TIMEOUT = 5 * 60 * 1000;

export const AuthRenewal: FunctionComponent = () => {
  const [lastAuthRenew, setLastAuthRenew] = useState<number>(0);
  const dispatch = useDispatch();
  const assetPlaying = useSelector(selectAssetPlaying);
  const { isAuthenticated } = useAuth();
  const { online } = useActivity();
  const lastOnlineStatus = useRef<boolean>(online);

  const renewAuthentication = (renewSource: string) => {
    if (assetPlaying) {
      console.log('auth: not renewing, player is active');
      return;
    }

    if (!online) {
      console.log('auth: not renewing, offline');
      return;
    }

    const shouldRenew = Date.now() - lastAuthRenew > AUTH_RENEW_TIMEOUT;
    if (shouldRenew) {
      console.log(renewSource);
      setLastAuthRenew(Date.now());
      dispatch(authRenew());
    } else {
      console.log('auth: not renewing, previous attempt too close');
    }
  };

  const [renewDelay, setRenewDelay] = useState<number>(null);
  useInterval(() => {
    renewAuthentication('auth: renew authentication (interval)');
  }, renewDelay);

  useEffect(() => {
    if (!isAuthenticated) {
      if (renewDelay) {
        console.log('auth: stopping auth renewal');
        setRenewDelay(null);
      }
    } else {
      if (!renewDelay) {
        console.log(`auth: starting auth renewal (interval ${AUTH_RENEW_INTERVAL}ms)`);
        setRenewDelay(AUTH_RENEW_INTERVAL);
      }
    }
  }, [isAuthenticated]);

  // Renew authentication if user was offline and became online
  useEffect(() => {
    const wasOffline = !lastOnlineStatus.current;

    if (wasOffline && online && isAuthenticated) {
      renewAuthentication(`auth: renew authentication (was offline)`);
    }

    lastOnlineStatus.current = online;
  }, [online]);

  return null;
};
