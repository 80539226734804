import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { BLACK, clampLines, DARKESTPURPLE, SIZE_SMALL, WHITE } from 'owlet-ui';
import { getCuratedImageUrl } from '../../../services/images';
import { ImageOrientation, ImageSize } from '../../../models/image';
import { CuratedAsset, CuratedCategory, HeroSlide, Target } from '../../../models/curated';
import { WideTitleWrap } from '../stripes';
import rgba from 'color-alpha';
import { categoryLink, episodeLink, LinkData, movieLink, slug } from '../../../services/links';
import { useTargetLinkHandler } from '../../../services/target-links';
import Link from 'next/link';
import { Asset, AssetType } from '../../../models/asset';
import { isUpcoming } from '../../../services/assets';
import { CategoryPlay } from './CategoryPlay';
import { Category } from '../../../models/category';
import { DetailsMeta } from './DetailsMeta';
import { AssetPlay } from './AssetPlay';
import { hasOnlyUpcomingEpisodes } from '../../../services/categories';
import { useRouter } from 'next/router';
import { DetailsImage } from './DetailsImage';
import classNames from 'classnames';
import { DetailsTitle } from './DetailsTitle';
import { useDetailsFloat, useDetailsFloatPlacement } from './hooks';
import { AdsLabel } from '../../labels/AdsLabel';
import { DetailsFavoriteButton } from './DetailsFavoriteButton';
import { getConfig } from '../../../services/conf';
import { buildEDDLMoreinfoEvent, useEDDL } from '../../../services/tracking/eddl-analytics';

export const DETAILS_FLOAT_DELAY = 300;

interface CategoryFloatProps {}

const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: -9999px;
  z-index: 10;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px ${BLACK};
  overflow: hidden;
  background: ${DARKESTPURPLE};

  a {
    text-decoration: none;
    color: ${WHITE};

    &:hover {
      text-decoration: none;
    }
  }

  &.placeholder {
    opacity: 0;
  }

  &.has-target {
    cursor: pointer;
  }
`;

const Subtitle = styled.p`
  margin: 0.125rem 0 0;
  ${clampLines(2)}
`;

const ImageContainer = styled.a`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; // 16:9
  z-index: 1;
  overflow: hidden;
  border-radius: 0.25rem 0.25rem 0 0;
  line-height: 1.2;
  display: block;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      ${rgba(DARKESTPURPLE, 0)} 0%,
      ${rgba(DARKESTPURPLE, 0)} 30%,
      ${DARKESTPURPLE} 100%
    );
  }
`;

const Description = styled.div`
  padding: 0 1rem 1rem;
  font-size: ${SIZE_SMALL};
  min-height: 3rem;
`;

const TitleWrap = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  gap: 0.5rem;
  align-items: end;
  max-width: 100%;

  > :first-child {
    min-width: 0;
  }
`;

const DescriptionText = styled.div`
  ${clampLines(5)};
`;

const TargetWrap = styled(Wrap)`
  &&&& a {
    text-decoration: none;
  }
`;

export interface DetailsFloatContent {
  title: string;
  subtitle?: string;
  imageUrl: string;
  description?: string;
  asset?: Asset;
  category?: Category;
  highlightMp4Url?: string;
  target?: Target;
  targetLink?: LinkData;
}

const CONTENT_ID_PREFIX = 'stripe-mouseover';

function getContentId(data: DetailsFloatContent): string {
  if (data.asset) {
    return `${CONTENT_ID_PREFIX}-asset-${data.asset.id}`;
  } else if (data.category) {
    return `${CONTENT_ID_PREFIX}-category-${data.category.id}`;
  } else if (data.target) {
    return `${CONTENT_ID_PREFIX}-target-${slug(data.target.title || data.target.path)}`;
  } else {
    return `${CONTENT_ID_PREFIX}-unknown-content`;
  }
}

function getImageAlt(data: DetailsFloatContent): string {
  if (data.title) {
    return data.title;
  } else if (data.target?.title) {
    return data.target.title;
  } else if (data.asset) {
    return data.asset.type === AssetType.Episode ? data.asset.subtitle : data.asset.title;
  }

  return data.category?.title || null;
}

export const DetailsFloat: FunctionComponent<CategoryFloatProps> = () => {
  const { state, clearState } = useDetailsFloat();
  const [linkData, setLinkData] = useState<LinkData>(null);
  const { asPath } = useRouter();
  const {
    style,
    isVisible,
    isPlaceholder,
    handleMouseOver,
    handleMouseLeave,
    handleMouseScroll,
  } = useDetailsFloatPlacement();

  const wrapRef = useRef<HTMLDivElement>(null);

  const { convertToLinkData, handleTargetClick } = useTargetLinkHandler();

  /*
   * State changes, update content data
   */
  const data = useMemo<DetailsFloatContent>(() => {
    const { item, subtitleAsDescription } = state;

    if (item) {
      const asset = (item as CuratedAsset)?.asset;
      const category = (item as CuratedCategory)?.category;

      const itemData: DetailsFloatContent = {
        title: item.title || asset?.title || category?.title,
        imageUrl: getCuratedImageUrl(item, ImageOrientation.Landscape, ImageSize.Medium),
        asset,
        category,
        highlightMp4Url: asset?.highlightMp4Url || category?.highlightMp4Url,
      };

      if ((item as HeroSlide).target) {
        itemData.subtitle = null;
        itemData.description = item.subtitle || item.description;
        itemData.target = (item as HeroSlide).target;
        itemData.targetLink = convertToLinkData(itemData.target);
      } else {
        itemData.description = asset?.description || category?.description;

        if (subtitleAsDescription) {
          itemData.subtitle = null;
          itemData.description = item.subtitle;
        } else if (asset?.type === AssetType.Movie) {
          // With movies, subtitle is always null
          itemData.subtitle = null;
        } else {
          itemData.subtitle = item.subtitle || asset?.subtitle || category?.subtitle;
        }
      }

      return itemData;
    }

    return null;
  }, [state]);

  useEffect(() => {
    if (!data) {
      setLinkData(null);
      return;
    }

    if (data?.category) {
      setLinkData(categoryLink(data.category));
    } else if (data?.asset?.type === AssetType.Movie) {
      setLinkData(movieLink(data?.asset));
    } else if (data?.asset) {
      setLinkData(episodeLink(data?.asset));
    } else {
      setLinkData(null);
    }
  }, [data]);

  useEffect(() => {
    clearState();
  }, [asPath]);

  const handleEDDL = useEDDL(
    () => buildEDDLMoreinfoEvent(state.contentContext, data?.category || data?.asset, data.title),
    [data, state]
  );

  const className = classNames({
    placeholder: isPlaceholder,
    'has-target': data?.target,
  });

  if (!data) {
    return null;
  }

  const contentId = getContentId(data);

  const { favoritesEnabled, favoritesMouseoverEnabled } = getConfig().features;
  const isFavoriteButtonEnabled = favoritesEnabled !== false && favoritesMouseoverEnabled !== false;

  const favoriteButton =
    (data.asset || data.category) && isVisible && isFavoriteButtonEnabled ? (
      <DetailsFavoriteButton
        item={data.asset || data.category}
        contentContext={state.contentContext}
        contentTitle={data.title}
      />
    ) : null;

  const image = isPlaceholder ? (
    <></>
  ) : (
    <>
      <DetailsImage
        item={data.asset || data.category}
        imageUrl={data.imageUrl}
        alt={getImageAlt(data)}
        key={data.asset?.id || data.category?.id}
      />
      <WideTitleWrap>
        <TitleWrap>
          <DetailsTitle data={data} />
          {favoriteButton}
        </TitleWrap>
        {data.subtitle && <Subtitle>{data.subtitle}</Subtitle>}
      </WideTitleWrap>
      <AdsLabel item={data.asset || data.category} />
    </>
  );

  if (data.target) {
    const content = (
      <>
        <ImageContainer as="div">{image}</ImageContainer>
        <Description>{data.description && <DescriptionText>{data.description}</DescriptionText>}</Description>
      </>
    );

    return (
      <TargetWrap
        id={contentId}
        className={className}
        style={style}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onWheel={handleMouseScroll}
        onClick={data.targetLink ? undefined : () => handleTargetClick(data.target)}
        ref={wrapRef}
        data-test="DetailsFloat"
      >
        {data.targetLink ? (
          <Link href={data.targetLink.href} as={data.targetLink.as}>
            <a>{content}</a>
          </Link>
        ) : (
          content
        )}
      </TargetWrap>
    );
  }

  return (
    <Wrap
      id={contentId}
      className={className}
      style={style}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onWheel={handleMouseScroll}
      ref={wrapRef}
      data-test="DetailsFloat"
    >
      {linkData && (
        <Link href={linkData.href} as={linkData.as} passHref>
          <ImageContainer data-test="DetailsFloat__ImageLink" onClick={handleEDDL}>
            {image}
          </ImageContainer>
        </Link>
      )}
      {!linkData && <ImageContainer as="div">{image}</ImageContainer>}
      <Description>
        {isVisible && (data.asset || data.category) && (
          <>
            {data.asset && <AssetPlay asset={data.asset} isUpcoming={isUpcoming(data.asset)} key={data.asset.id} />}
            {data.category && (
              <CategoryPlay
                category={data.category}
                isUpcoming={hasOnlyUpcomingEpisodes(data.category)}
                key={data.category.id}
              />
            )}
          </>
        )}
        {data.description && <DescriptionText>{data.description}</DescriptionText>}
        {!isPlaceholder && (data.asset || data.category) && <DetailsMeta item={data.asset || data.category} />}
      </Description>
    </Wrap>
  );
};
