import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import { Button, DARKEN, WHITE } from 'owlet-ui';
import { useAuth } from '../../services/auth';
import { useDispatch } from 'react-redux';
import { loginModalOpen } from '../../state/user/user.actions';
import { greaterThan, lessThan } from 'owlet-ui/breakpoints';

interface NaviLoginProps {}

const LoginButton = styled(Button)`
  background: ${DARKEN};
  border-color: ${WHITE};
  margin-left: 0.5rem;
  padding: 0.75rem 1.5rem;
  position: relative;
  z-index: 101;

  ${lessThan('medium')`
    padding: 0.5rem 1rem;
  `}

  ${greaterThan('small')`
    margin-left: 1rem;
  `}
`;

export const NaviLogin: FunctionComponent<NaviLoginProps> = React.memo(() => {
  const dispatch = useDispatch();
  const { isAuthenticated, isAuthCompleted } = useAuth();

  const openModal = useCallback(() => {
    dispatch(loginModalOpen());
  }, []);

  if (isAuthenticated || !isAuthCompleted) {
    return null;
  }

  return (
    <LoginButton onClick={openModal} data-test="Navi__Login">
      Kirjaudu
    </LoginButton>
  );
});

NaviLogin.displayName = 'NaviLogin';
