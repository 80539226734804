import { RefObject, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import useSSR from 'use-ssr';

/**
 * Helper for using a ref inside a forwardRef component.
 * https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
 *
 * @usage
 * forwardRef((props, ref) => {
 *   const innerRef = useRef(ref);
 *   const combinedRef = useCombinedRefs<HTMLDivElement>(ref, innerRef);
 *
 *   return <div ref={combinedRef} />;
 * });
 *
 * @param refs
 */
export function useCombinedRefs<T>(...refs: any[]): RefObject<T> {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(targetRef.current);
        } else {
          ref.current = targetRef.current;
        }
      }
    });
  }, [refs]);

  return targetRef;
}

/**
 * useInterval hook by Dan Abramov (https://overreacted.io/making-setinterval-declarative-with-react-hooks/)
 * Can be used instead of useEffect which should make use of setInterval. Using setInterval inside
 * useEffect- hook introduces potetential for unexpected behavious. Read more from link above.
 * Callback function and delay interval can also be changed dynamically.
 * The interval can also be paused with not providing a 'delay' parameter.
 * @param callback  Callback function to be called after the amount of milliseconds declared in delay
 * @param delay     Time in milliseconds after which the callback function should be fired
 */
export function useInterval(callback: Function, delay: number | null) {
  const savedCallback = useRef<Function>();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay]);
}

interface UseMutationObserverProps {
  target: RefObject<Element | Node>;
  options: MutationObserverInit;
  callback: MutationCallback;
}

/**
 * Custom hook for using MutationObserver. Based on https://tobbelindstrom.com/blog/useMutationObserver/
 *
 * @param target
 * @param options
 * @param callback
 */
export function useMutationObserver({ target, options, callback }: UseMutationObserverProps): void {
  const { isBrowser } = useSSR();

  const observer = useMemo(
    () =>
      isBrowser
        ? new MutationObserver((mutationRecord, mutationObserver) => {
            callback?.(mutationRecord, mutationObserver);
          })
        : null,
    [callback]
  );

  useEffect(() => {
    if (observer && target.current) {
      observer.observe(target.current, options);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [target, observer, options]);
}

/**
 * SSR-compatible version of useLayoutEffect() that doesn't print warnings.
 *
 * https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a
 */
export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
