import React, { FunctionComponent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { lessThan } from 'owlet-ui/breakpoints';

export enum TitleImageStyle {
  Hero = 'hero',
  Stripe = 'stripe',
}

interface TitleImageProps {
  imageUrl: string;
  imageStyle: TitleImageStyle;
  alt?: string;
}

const Image = styled.img`
  display: block;
  width: auto;
  height: 100%;
  pointer-events: none;

  // Force aspect-ratio in webkit
  object-fit: contain;
  object-position: 0 100%;

  &.hero {
    max-width: 65%;
    max-height: 7rem;
    margin-bottom: 0.5rem;

    ${lessThan('large')`
      max-height: 5rem;
    `}

    ${lessThan('small')`
      max-height: 3rem;
      max-width: 80%;
    `}
  }

  &.stripe {
    height: auto;
    max-height: 3.5rem;
    max-width: 65%;
    margin-bottom: 0.25rem;
  }
`;

export const TitleImage: FunctionComponent<TitleImageProps> = ({
  imageUrl,
  imageStyle = TitleImageStyle.Hero,
  alt,
}: TitleImageProps) => {
  const [error, setError] = useState<boolean>(false);
  const handleError = useCallback(() => setError(true), []);

  if (error) {
    return <>{alt}</>;
  }

  return <Image src={imageUrl} alt={alt} className={imageStyle} onError={handleError} />;
};
