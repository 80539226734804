import produce from 'immer';
import { FeedbackMessage } from '../../models/feedback';
import { ActionTypes } from './feedback.actions';

export interface FeedbackState {
  messages: FeedbackMessage[];
}

export const initialFeedbackState: FeedbackState = {
  messages: [],
};

const feedbackReducer = (state = initialFeedbackState, action: any) => {
  switch (action.type) {
    case ActionTypes.ADD_FEEDBACK_MESSAGE:
      if (!action.payload.id) {
        console.error('Skipping feedback message: id is missing', action.payload);
        return state;
      }

      console.log('[feedback] Show message', action.payload);
      return produce(state, (draft) => {
        draft.messages.push(action.payload);
      });
    case ActionTypes.REMOVE_FEEDBACK_MESSAGE:
      return produce(state, (draft) => {
        const idx = draft.messages.findIndex((m) => m.id === action.payload.id);

        if (idx >= 0) {
          draft.messages.splice(idx, 1);
        }
      });
    case ActionTypes.REMOVE_FEEDBACK_MESSAGE_BY_HANDLE:
      return produce(state, (draft) => {
        // Empty or null handle should not remove messages
        if (action.payload) {
          draft.messages = draft.messages.filter((m) => m.handle !== action.payload);
        }
      });
    case ActionTypes.CLEAR_FEEDBACK_MESSAGES:
      return produce(state, (draft) => {
        draft.messages = [];
      });
    default:
      return state;
  }
};

export default feedbackReducer;
