const CACHE = new Map<string, { data: unknown; expires: number }>();

export const cache = {
  set: (key: string, value: unknown, ttlInSeconds: number): void => {
    CACHE.set(key, {
      data: value,
      expires: Date.now() + ttlInSeconds * 1000,
    });
  },
  get: <T>(key: string, disableExpireCheck: boolean = false): T | undefined => {
    if (CACHE.has(key)) {
      const now = Date.now();
      const { data, expires } = CACHE.get(key);

      if (disableExpireCheck || expires > now) {
        return data as T;
      }
    }

    return;
  },
  clear: () => {
    CACHE.clear();
  },
};
