import { call, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import unionBy from 'lodash/unionBy';
import map from 'lodash/map';

import { ActionTypes, categoryLoaded, viewingHistoryError, viewingHistorySuccess } from './viewing-history.actions';
import {
  getLatestAsset,
  loadAssetProgress,
  loadCategoryProgresses,
  loadLatestAssetProgresses,
  transformProgress,
} from '../../services/viewing-history';
import { selectAuthToken } from '../user/user.selectors';
import { getConfigFeatures } from '../../services/conf';
import { ViewingHistoryError } from '../../services/viewing-history/viewing-history';

function* fetchSeriesProgress(action): any {
  try {
    const authToken = yield select(selectAuthToken);
    const rawLatestProgresses = yield call(loadLatestAssetProgresses, action.payload.categoryIds, authToken);
    const latestProgresses = map(rawLatestProgresses, (item) => transformProgress(item));

    // Determinate default season (category) by fetching latest watched asset from each group
    const mostRecentlyWatched = getLatestAsset(latestProgresses, action.payload.categoryIds);

    if (mostRecentlyWatched) {
      const { categoryId } = mostRecentlyWatched;

      // Load progresses for default season (category) assets
      const rawCategoryProgresses = yield call(loadCategoryProgresses, categoryId, authToken);
      const categoryProgresses = map(rawCategoryProgresses, (item) => transformProgress(item));

      // Combine all loaded assets into one array
      const loadedProgresses = unionBy(latestProgresses, categoryProgresses, 'assetId');

      // Save to state
      yield put(viewingHistorySuccess(loadedProgresses));
      yield put(categoryLoaded(action.payload.mainCategoryId));
    }
  } catch (e) {
    console.error('Loading series progress failed.', e);
  }
}

// Loads progress for specific category (currently only used on player page)
export function* fetchViewingHistoryForCategory(action): any {
  const { fetchUVHProgress } = getConfigFeatures();
  try {
    if (fetchUVHProgress) {
      const authToken = yield select(selectAuthToken);
      const rawCategoryProgresses = yield call(loadCategoryProgresses, action.payload.categoryId, authToken);
      // Used on player page, does not make sense yield viewingHistoryError here like in fetchViewingHistoryForAsset
      const categoryProgresses = map(rawCategoryProgresses, (item) => transformProgress(item));
      yield put(viewingHistorySuccess(categoryProgresses));
    }
    yield put(categoryLoaded(action.payload.categoryId));
  } catch (err) {
    console.error('Loading category progress failed.');
  }
}

// Loads progress for specific asset
export function* fetchViewingHistoryForAsset(action): any {
  const { fetchUVHProgress } = getConfigFeatures();
  try {
    let categoryProgresses;
    let rawAssetProgress;
    if (fetchUVHProgress) {
      const authToken = yield select(selectAuthToken);
      rawAssetProgress = yield call(loadAssetProgress, action.payload.assetId, authToken);
      if (!rawAssetProgress) {
        yield put(viewingHistoryError(ViewingHistoryError.ASSET));
      }
    }

    if (rawAssetProgress && Object.keys(rawAssetProgress).length > 0) {
      categoryProgresses = [transformProgress(rawAssetProgress)];
    } else {
      categoryProgresses = [
        {
          assetId: action.payload.assetId,
          notStarted: true,
        },
      ];
    }

    yield put(viewingHistorySuccess(categoryProgresses));
  } catch (err) {
    console.error('Loading asset progress failed.', err);
  }
}

export function* watchSeriesProgressLoad() {
  yield takeLeading(ActionTypes.SERIES_UVH_REQUEST, fetchSeriesProgress);
}

export function* watchCategoryProgressLoad() {
  yield takeEvery(ActionTypes.CATEGORY_UVH_REQUEST, fetchViewingHistoryForCategory);
}

export function* watchAssetProgressLoad() {
  yield takeEvery(ActionTypes.ASSET_UVH_REQUEST, fetchViewingHistoryForAsset);
}

const viewingHistorySagas = [
  fork(watchSeriesProgressLoad),
  fork(watchCategoryProgressLoad),
  fork(watchAssetProgressLoad),
];

export default viewingHistorySagas;
