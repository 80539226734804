import _parseInt from 'lodash/parseInt';

export const getUserAgent = () => (typeof window !== 'undefined' ? window.navigator.userAgent : '');

export const isChrome = () => {
  return /.*Chrome.*/.test(getUserAgent()) && !isEdge();
};

export const isFirefox = () => {
  return /Firefox/.test(getUserAgent());
};

export const isEdge = () => {
  return /.*Edge\/\d+.*/.test(getUserAgent());
};

export const isChromiumEdge = () => {
  return /.*Edg\/\d+.*/.test(getUserAgent());
};

export const isIE = () => {
  return /.*MSIE.*Windows.(NT|XP|ME|9).*|.*Windows.(NT|XP|ME|9).*rv:11.0.*/.test(getUserAgent());
};

export const isSafari = () => {
  return /.*Safari.*/.test(getUserAgent()) && !isChrome() && !isEdge();
};

export const isSafari8 = () => {
  return /.*Version\/8.*/.test(getUserAgent()) && isSafari();
};

export const isSafari9 = () => {
  return /.*Version\/9.*/.test(getUserAgent()) && isSafari();
};

export const isSafari10 = () => {
  return /.*Version\/10.*/.test(getUserAgent()) && isSafari();
};

export const isSafari11 = () => {
  return /.*Version\/11.*/.test(getUserAgent()) && isSafari();
};

export const isSafari12 = () => {
  return /.*Version\/12.*/.test(getUserAgent()) && isSafari();
};

export const safariVersion = () => {
  const safariVersionMatch = getUserAgent().match(/.*Version\/(\d+)/);
  if (safariVersionMatch) {
    return parseInt(safariVersionMatch[1], 10);
  }
  return undefined;
};

export const isSafari11OrNewer = () => {
  return isSafari() && safariVersion() >= 11;
};

export const isAndroid = () => {
  return !isWP() && /Android/.test(getUserAgent());
};

export const isIos = () => {
  const isIos = /iPhone|iPad|iPod/.test(getUserAgent());
  if (isIos) {
    return true;
  } else {
    // Workaround for detecting iPad whose UA now claims to be desktop Mac
    const isSafariUA = isSafari();
    const maxTouchPoints = typeof window !== 'undefined' ? window.navigator && window.navigator.maxTouchPoints : 0;

    // iOS should have 5 maxTouchPoints whereas desktop returns 0 or 1
    return isTouchDevice() && isSafariUA && maxTouchPoints > 1;
  }
};

export const hasNativePlayer = () => isAndroid() || isIos();

export const isWP = () => {
  return /Windows Phone|.*Microsoft Build.*Mobile/.test(getUserAgent());
};

export const getBrowserVersion = (): number[] => {
  try {
    if (isChromiumEdge()) {
      return readVersion(/Edg\/([0-9|.]+)\b/);
    }
    if (isChrome()) {
      return readVersion(/Chrome\/([0-9|.]+)\b/);
    }
    if (isFirefox()) {
      return readVersion(/Firefox\/([0-9|.]+)\b/);
    }
    if (isSafari()) {
      return readVersion(/Version\/([0-9|.]+)\b/);
    }
    if (isEdge()) {
      return readVersion(/Edge\/([0-9|.]+)\b/);
    }
    if (isIE()) {
      const rv = readVersion(/rv:([0-9|.]+)\b/);
      return rv || readVersion(/MSIE ([0-9|.]+)\b/);
    }
  } catch (ex) {
    console.error('Could not detect browser version', ex);
  }
  return null;
};

export function getBrowserInfo(): string {
  const info: string[] = [];

  if (isIos()) {
    info.push('iOS');
  } else if (isAndroid()) {
    info.push('Android');
  } else {
    info.push('Web');
  }

  if (isChromiumEdge()) {
    info.push('Edge (Chromium)');
  } else if (isChrome()) {
    info.push('Chrome');
  } else if (isFirefox()) {
    info.push('Firefox');
  } else if (isSafari()) {
    info.push('Safari');
  } else if (isEdge()) {
    info.push('Edge');
  } else if (isIE()) {
    info.push('IE');
  }

  const version = getBrowserVersion();
  if (version) {
    info.push(version.join('.'));
  }

  return info.join(' ');
}

export const supportsCSSLineClamping = () => {
  if (!CSS || typeof CSS.supports === 'undefined') {
    return false;
  }
  return CSS.supports('-webkit-line-clamp: 1') || CSS.supports('line-clamp: 1');
};

export const redirectToApplications = (assetId: number | string, openStore: boolean = true) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (isAndroid()) {
    if (isFirefox()) {
      if (openStore) {
        handleStoreRedirection('market://details?id=fi.mtv.svod');
      }
      window.location.href = `cmore://playvideo/${assetId}`;
      return;
    }

    window.location.href = `intent://playvideo/${assetId}#Intent;scheme=cmore;package=fi.mtv.svod;end`;
    return;
  } else if (isIos()) {
    if (openStore) {
      handleStoreRedirection('itms-apps://apps.apple.com/fi/app/c-more-suomi/id1195588309?mt=8');
    }
    window.location.href = `cmore://playvideo/${assetId}`;
  }
};

export function redirectToStore(assetId: number | string) {
  if (isAndroid()) {
    if (isFirefox()) {
      window.location.href = 'market://details?id=fi.mtv.svod';
      return;
    }

    window.location.href = `intent://playvideo/${assetId}#Intent;scheme=cmore;package=fi.mtv.svod;end`;
  } else if (isIos()) {
    window.location.href = 'itms-apps://apps.apple.com/fi/app/c-more-suomi/id1195588309?mt=8';
  }
}

export function isTouchDevice(): boolean {
  if (typeof window === 'undefined') return false;
  return 'ontouchstart' in window;
}

// Private

const readVersion = (matcher: RegExp) => {
  if (typeof window === 'undefined') return null;

  const match = getUserAgent().match(matcher);
  return match ? match[1].split('.').map(_parseInt) : null;
};

const handleStoreRedirection = (redirectTo) => {
  if (typeof window === 'undefined') {
    return;
  }

  const now = new Date().valueOf();
  window.setTimeout(() => {
    if (new Date().valueOf() - now > 200) {
      return;
    }
    window.location.replace(redirectTo);
  }, 100);
};
