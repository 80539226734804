import { useEffect, useRef, EventHandler, RefObject } from 'react';

/**
 * Hook for adding an event listener. The event listener will be automatically removed on component unmount.
 */
export function useEventListener(
  eventName: string,
  handler: EventHandler<any>,
  element: Node | Window,
  once: boolean = false
) {
  const savedHandler = useSavedHandler(handler);

  useEffect(() => {
    return setupEventListener(eventName, savedHandler, element, once);
  }, [eventName, element, once]);
}

/**
 * SSR-safe hook for adding an event listener to window.
 */
export function useWindowEventListener(eventName: string, handler: EventHandler<any>, once: boolean = false) {
  const savedHandler = useSavedHandler(handler);

  useEffect(() => {
    return setupEventListener(eventName, savedHandler, window, once);
  }, [eventName, once]);
}

/**
 * SSR-safe hook for adding an event listener to document.
 */
export function useDocumentEventListener(eventName: string, handler: EventHandler<any>, once: boolean = false) {
  const savedHandler = useSavedHandler(handler);

  useEffect(() => {
    return setupEventListener(eventName, savedHandler, document, once);
  }, [eventName, once]);
}

function setupEventListener(
  eventName: string,
  handler: RefObject<EventHandler<any>>,
  element: Node | Window,
  once: boolean = false
): () => void {
  if (eventName && element?.addEventListener && handler.current) {
    const eventListener = (event: Event) => handler.current!(event);

    element.addEventListener(eventName, eventListener, { once });

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  } else {
    return () => {};
  }
}

export function useSavedHandler(handler: EventHandler<any>): RefObject<EventHandler<any>> {
  const savedHandler = useRef<EventHandler<any>>(() => {});

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  return savedHandler;
}
