import React from 'react';
import { SVGProps } from '../utils';

const SvgEmail = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510.236 510.236" {...props}>
    <path d="M427.215 399.231H83.013c-17.59 0-31.9-14.312-31.9-31.905v-215.51c0-17.593 14.311-31.905 31.9-31.905h344.202c17.588 0 31.896 14.312 31.896 31.905v215.51c0 17.593-14.308 31.905-31.896 31.905zM83.013 146.312c-2.981 0-5.5 2.521-5.5 5.505v215.51c0 2.984 2.519 5.505 5.5 5.505h344.202c2.979 0 5.496-2.521 5.496-5.505v-215.51c0-2.984-2.518-5.505-5.496-5.505H83.013z" />
    <path d="M435.164 387.979a13.157 13.157 0 01-9.334-3.866L319.955 278.239l-55.504 55.504a13.195 13.195 0 01-9.333 3.866c-3.5 0-6.857-1.391-9.334-3.866l-55.502-55.504L84.409 384.113c-5.154 5.154-13.512 5.154-18.668 0-5.154-5.155-5.154-13.513 0-18.668l115.207-115.208a13.195 13.195 0 019.334-3.866c3.5 0 6.857 1.391 9.334 3.866l55.502 55.504 55.503-55.504c5.154-5.154 13.512-5.154 18.668 0l115.209 115.208c5.154 5.155 5.154 13.513 0 18.668a13.164 13.164 0 01-9.334 3.866z" />
    <path d="M255.118 337.609c-3.5 0-6.857-1.391-9.334-3.866L65.741 153.697c-5.154-5.155-5.154-13.513 0-18.668 5.155-5.155 13.513-5.153 18.668 0l170.708 170.712L425.83 135.029c5.154-5.154 13.514-5.154 18.668 0 5.154 5.155 5.154 13.513 0 18.668L264.451 333.743a13.193 13.193 0 01-9.333 3.866z" />
  </svg>
);

export default SvgEmail;
