import { call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  ActionTypes,
  articlesFail,
  articlesSuccess,
  categoriesFail,
  categoriesSuccess,
} from './customer-support.actions';
import { fetchJSON } from '../../services/api';
import { getConfig } from '../../services/conf';
import { CustomerSupportArticle, CustomerSupportCategory } from '../../models/customerSupport';

export function* fetchArticles(): any {
  console.log('customer support: fetching articles...');

  try {
    const content: CustomerSupportArticle[] = yield call(
      fetchJSON,
      `${getConfig().customerSupport.contentBaseUrl}/katsomo-articles.json`,
      { throwErrors: false }
    );
    console.log('customer support: articles success');
    yield put(articlesSuccess(content));
  } catch (e) {
    console.error(e);
    yield put(articlesFail());
  }
}

export function* fetchCategories(): any {
  console.log('customer support: fetching categories...');

  try {
    const content: CustomerSupportCategory[] = yield call(
      fetchJSON,
      `${getConfig().customerSupport.contentBaseUrl}/katsomo-categories.json`,
      { throwErrors: false }
    );
    console.log('customer support: categories success');
    yield put(categoriesSuccess(content));
  } catch (e) {
    console.error(e);
    yield put(categoriesFail());
  }
}

export function* watchArticlesRequest() {
  yield takeLatest(ActionTypes.ARTICLES_REQUEST, fetchArticles);
}

export function* watchCategoriesRequest() {
  yield takeLatest(ActionTypes.CATEGORIES_REQUEST, fetchCategories);
}

const customerSupportSagas = [fork(watchArticlesRequest), fork(watchCategoriesRequest)];

export default customerSupportSagas;
