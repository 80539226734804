import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import { Category } from '../../../models/category';
import { Asset } from '../../../models/asset';
import { VisibilityTrackedContentVideo } from '../../video/VisibilityTrackedContentVideo';
import { getPlaceholderImageUrl } from '../../../services/images';
import { ImageOrientation, ImageSize } from '../../../models/image';
import { ContentImage } from '../../image/ContentImage';
import { useVideoHighlight } from '../../../services/video';

const VIDEO_DELAY = 1200;

interface DetailsImageProps {
  item: Asset | Category;
  imageUrl: string;
  alt: string;
}

export const Image = styled(ContentImage)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit: cover;
`;

export const DetailsImage: FunctionComponent<DetailsImageProps> = React.memo(
  ({ item, imageUrl, alt }: DetailsImageProps) => {
    const { canPlay, videoUrl } = useVideoHighlight(item);
    const placeholderSrc = useCallback(() => getPlaceholderImageUrl(ImageOrientation.Landscape, ImageSize.Medium), []);

    if (!item) {
      return <Image src={imageUrl} placeholderSrc={placeholderSrc} alt={alt} />;
    }

    return (
      <>
        <Image src={imageUrl} placeholderSrc={placeholderSrc} alt={alt} />
        {canPlay && videoUrl && (
          <VisibilityTrackedContentVideo videoUrl={videoUrl} delay={VIDEO_DELAY} lazyload={true} />
        )}
      </>
    );
  }
);

DetailsImage.displayName = 'DetailsImage';
