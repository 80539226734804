import React, { FunctionComponent } from 'react';
import { PathLink } from '../link/PathLink';
import { TreePath } from '../../models/tree';
import { useActivePathClassName } from './naviUtils';

export interface NaviTextLinkProps {
  item: TreePath;
}

export const NaviTextLink: FunctionComponent<NaviTextLinkProps> = React.memo(({ item }: NaviTextLinkProps) => {
  const className = useActivePathClassName(item);

  return (
    <PathLink path={item.path}>
      <a className={className}>{item.title}</a>
    </PathLink>
  );
});

NaviTextLink.displayName = 'NaviTextLink';
