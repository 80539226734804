import React, { ReactElement, useEffect, useState } from 'react';
import { useSWRConfig } from 'swr';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';
import { ScopedMutator } from 'swr/dist/types';
import styled from 'styled-components';
import assign from 'lodash/assign';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import { DEFAULT_BREAKPOINTS, SIZE_LARGE, Title3 } from 'owlet-ui';
import { DeferredData, SortOption, SortType } from '../models/deferred';
import { buildURL, fetchJSON } from './api';
import { useAuth } from './auth';
import { SortOptionsMenu } from '../components/grid/SortOptionsMenu';
import { usePathRouter } from './router/router';
import { getCachebusterUrl } from './url';
import { breakpoints } from 'owlet-ui/breakpoints';
import { useConsent } from './consent';
import { useIntersectionObserver } from 'react-intersection-observer-hook';
import isFinite from 'lodash/isFinite';
import { Asset, AssetType } from '../models/asset';
import { Category } from '../models/category';
import { ContentItemContextProvider, ContentSectionContextProvider } from './content-context';
import { isAsset } from './assets';
import { GridMovie } from '../components/grid/GridMovie';
import { GridCategory } from '../components/grid/GridCategory';
import { GridAsset } from '../components/grid/GridAsset';
import { CuratedAsset, CuratedCategory } from '../models/curated';
import { EDDLComponentType } from './tracking/eddl-analytics';

const GridWrap = styled.div`
  margin: 0 ${(props) => props.theme.horizontalMargin} 3rem;
`;

export const NoDataWrap = styled.div`
  font-size: ${SIZE_LARGE};
  text-align: center;
  margin: 3rem ${(props) => props.theme.horizontalMargin};
`;

const ScrollArea = styled.div``;

const AssetContainer = styled.ul<{ slotBreakpoints: any }>((props) => {
  const columns = keys(props.slotBreakpoints)
    .filter((key) => key !== 'default')
    .map(
      (key: any) =>
        `@media (min-width: ${breakpoints[key]}px) { grid-template-columns: repeat(${props.slotBreakpoints[key]}, 1fr); }`
    )
    .join('\n');

  return `
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(${props.slotBreakpoints.default}, 1fr);

    ${columns}
  `;
});

const TitleWrap = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: auto max-content;
  grid-template-areas: 'title sort';
  align-items: end;
`;

const Title = styled(Title3)`
  margin: 1rem 0;
  justify-self: flex-start;
  grid-area: title;
`;

const SortMenu = styled.div`
  grid-area: sort;
`;

export interface GridItemOptions {
  sort?: SortType;
}

interface GridProps<T> {
  dataUrl: string;
  itemMapper: (
    item: T,
    index: number,
    key: string,
    mutate?: Function,
    options?: GridItemOptions
  ) => ReactElement | undefined;
  pageSize: number;
  title?: string;
  authenticated?: boolean;
  emptyNotification?: string;
  dataUrlCacheBuster?: boolean;
}

interface UseGridResponse {
  grid: ReactElement;
}

export function useGrid<T>({
  dataUrl,
  itemMapper,
  pageSize,
  title: defaultTitle,
  authenticated,
  emptyNotification = 'Sivua ei löytynyt',
  dataUrlCacheBuster = true,
}: GridProps<T>): UseGridResponse {
  const { query } = usePathRouter();
  const [title, setTitle] = useState<string>(defaultTitle);
  const [sortOptions, setSortOptions] = useState<SortOption[]>(null);
  const [noData, setNoData] = useState<boolean>(false);
  const {
    authToken,
    isAuthenticated,
    isAuthCompleted,
    user,
    activeSubprofile,
    subprofileError,
    isSubProfileLoaded,
  } = useAuth();
  const [supportsPagination, setSupportsPagination] = useState<boolean>(false);
  const { consentGroups } = useConsent();

  const fetcher = (url) => {
    const options =
      authToken && authenticated
        ? {
            withCredentials: true,
            headers: {
              authorization: authToken,
            },
          }
        : {};
    return fetchJSON(url, options);
  };

  const getKey = (pageIndex, previousPageData) => {
    const authReqAndValid = isAuthCompleted && isAuthenticated && authToken && (isSubProfileLoaded || subprofileError);

    if ((!authReqAndValid && authenticated) || (previousPageData && !previousPageData.result.length) || !dataUrl) {
      return null;
    }

    const params: Record<string, any> = {
      size: pageSize,
      start: pageIndex || 0,
    };

    // Add consentGroups when authentication is required
    if (authReqAndValid && authenticated) {
      params.consentGroups = consentGroups;
    }

    if (query.sort) {
      params.sort = query.sort;
    }

    const url = buildURL(dataUrl, params, { dedupeParams: true });

    if (dataUrlCacheBuster) {
      return getCachebusterUrl(url, user, activeSubprofile);
    }

    return url;
  };

  const { data, error, mutate, size, setSize, isValidating }: SWRInfiniteResponse = useSWRInfinite(getKey, fetcher, {
    initialSize: 1,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateFirstPage: true,
  });

  useEffect(() => {
    if (data?.[0]?.metadata) {
      const deferredData: DeferredData<T> = data[0];
      const { title: metadataTitle, sortOptions, numberOfHits } = deferredData.metadata;

      if (metadataTitle) {
        setTitle(metadataTitle);
      }
      if (sortOptions) {
        setSortOptions(sortOptions);
      }

      setSupportsPagination(!isNil(numberOfHits) && numberOfHits !== pageSize);
    } else {
      setSupportsPagination(false);
    }
  }, [data]);

  useEffect(() => {
    setNoData(false);
  }, [dataUrl]);

  useEffect(() => {
    if (defaultTitle !== undefined) {
      setTitle(defaultTitle);
    }
  }, [defaultTitle]);

  const [targetRef, { entry }] = useIntersectionObserver();

  const pages: DeferredData<T>[] = data;
  const isLoadingInitialData = !pages && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && pages && typeof pages[size - 1] === 'undefined');
  const isEmpty = pages?.[0]?.result.length === 0;
  const isReachingEnd =
    isEmpty ||
    (pages &&
      pages[pages.length - 1] &&
      pages[pages.length - 1].result &&
      pages[pages.length - 1].result.length < pageSize);

  const setNextSize = () => {
    if (supportsPagination && !isValidating && !isReachingEnd && !isLoadingMore) {
      setSize((v) => v + 1);
    }
  };

  useEffect(() => {
    if (entry?.isIntersecting) {
      setNextSize();
    }
  }, [entry]);

  if (noData || isEmpty) {
    return {
      grid: <NoDataWrap>{emptyNotification}</NoDataWrap>,
    };
  }

  let sort = get(query, 'sort');
  if (isArray(sort)) {
    sort = sort[0];
  }

  let sortMenu = null;
  if (sortOptions) {
    sortMenu = (
      <SortMenu>
        <SortOptionsMenu sortOptions={sortOptions} sortValue={sort} />
      </SortMenu>
    );
  }

  // Grids use otherwise the same breakpoints as stripes, but the smallest width has 2 slots instead of 3.
  // This is because grids should only have even slot amounts, otherwise wide slots leave empty spaces.
  const gridBreakpoints = assign({}, DEFAULT_BREAKPOINTS, { default: 2 });

  if (!data) {
    return { grid: null };
  }

  return {
    grid: (
      <ContentSectionContextProvider
        componentId={null}
        sectionTitle={title}
        index={null}
        component={EDDLComponentType.Grid}
      >
        <GridWrap>
          {(title || sortOptions) && (
            <TitleWrap>
              {title && <Title data-test="Grid__Title">{title}</Title>}
              {sortMenu}
            </TitleWrap>
          )}
          <AssetContainer slotBreakpoints={gridBreakpoints}>
            {Array.isArray(pages) &&
              pages?.map((items, pageIndex) => {
                return items.result.map((item, itemIndex) =>
                  itemMapper(item, (pageIndex + 1) * itemIndex, `${pageIndex}_${itemIndex}`, mutate, {
                    sort: sort as SortType,
                  })
                );
              })}
          </AssetContainer>
          {supportsPagination && <ScrollArea ref={targetRef} />}
        </GridWrap>
      </ContentSectionContextProvider>
    ),
  };
}

/**
 * Returns a function to update all SWR-cached data with a key that starts with the given prefix.
 */
export function useMutateWithPrefix() {
  const { cache, mutate } = useSWRConfig();

  return (prefix: string, ...args) => {
    if (!(cache instanceof Map)) {
      throw new Error('useMutateWithPrefix requires the cache provider to be a Map instance');
    }

    const mutations: ReturnType<ScopedMutator>[] = Array.from(cache.keys()).reduce((arr, key) => {
      if (new RegExp(`^${prefix}`).test(key)) {
        arr.push(mutate(key, ...args));
      }

      return arr;
    }, []);

    return Promise.all(mutations);
  };
}

export function parseIndex(index: number | string): number {
  if (isFinite(index)) {
    return Number(index);
  }

  const [pageIndex, itemIndex] = String(index).split('_');
  const parsedIndex = ((Number(pageIndex) || 0) + 1) * Number(itemIndex);

  return isFinite(parsedIndex) ? parsedIndex : undefined;
}

export function gridItemMapper(
  item: Asset | Category,
  index: number,
  mutate?: Function,
  options?: GridItemOptions
): ReactElement {
  return (
    <ContentItemContextProvider index={index} key={`${index}-${item.id}`}>
      {isAsset(item) ? (
        item.type === AssetType.Movie ? (
          <GridMovie asset={item as Asset} options={options} />
        ) : (
          <GridAsset asset={item as Asset} options={options} />
        )
      ) : (
        <GridCategory category={item as Category} options={options} />
      )}
    </ContentItemContextProvider>
  );
}

export const curatedGridItemMapper = (item: CuratedAsset | CuratedCategory, index: number) => {
  return gridItemMapper(get(item, 'asset') || get(item, 'category'), index);
};
