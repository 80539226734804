import React, { FunctionComponent } from 'react';
import { Category } from '../../models/category';
import { Asset } from '../../models/asset';
import { BottomRightLabels } from '../stripe/stripes';
import styled from 'styled-components';
import { IconLike, SIZE_TINY, WHITE } from 'owlet-ui';
import { SortType } from '../../models/deferred';
import { ImdbRating } from '../imdb/ImdbRating';
import { Theme } from '../../models/curated';

interface BottomRightLabelsProps {
  item: Asset | Category;
  ratingType?: Theme | SortType | string;
}

const shadow = '0 4px 4px rgba(0, 0, 0, 0.25)';

const LikesWrap = styled.div`
  padding: 0.5rem;
  font-size: ${SIZE_TINY};
  display: flex;
  align-items: center;
  text-shadow: ${shadow};
  svg {
    filter: drop-shadow(${shadow});
  }
`;

const StyledImdbRating = styled(ImdbRating)`
  margin: 0.25rem 0.5rem;
  font-size: ${SIZE_TINY};
  text-shadow: ${shadow};
  &&& {
    svg {
      width: 2rem;
      margin-right: 0.5rem;
    }
  }
`;

const LikesIcon = styled(IconLike)`
  width: 1.15rem;
  margin-right: 0.25rem;
  fill: ${WHITE};
  display: inline-block;
  vertical-align: top;
`;

const LikesText = styled.div`
  display: inline-block;
  line-height: 1rem;
  color: ${WHITE};
`;

export const RatingLabels: FunctionComponent<BottomRightLabelsProps> = ({
  item,
  ratingType,
}: BottomRightLabelsProps) => {
  const { likes, imdbRating } = item;
  const showLikes = (ratingType === SortType.Likes || ratingType === Theme.Rating) && likes;
  const showImdb = (ratingType === SortType.Imdb || ratingType === Theme.Imdb) && imdbRating;

  if (!showLikes && !showImdb) {
    return null;
  }

  return (
    <BottomRightLabels>
      {showLikes && (
        <LikesWrap>
          <LikesIcon />
          <LikesText data-test="RatingLabel__Likes">{likes}%</LikesText>
        </LikesWrap>
      )}
      {showImdb && <StyledImdbRating onlyRating item={item} />}
    </BottomRightLabels>
  );
};
