import React, { ComponentType, FunctionComponent } from 'react';
import { PathLink } from '../link/PathLink';
import { IconLink } from './styles';
import { useActivePathClassName } from './naviUtils';
import { TreePath } from '../../models/tree';
import styled from 'styled-components';
import { lessThan } from 'owlet-ui/breakpoints';

export interface NaviIconLinkProps {
  item: TreePath;
  icon: ComponentType;
}

const StyledIconLink = styled(IconLink)`
  ${lessThan('small')`
    &&& {
      display: none;
    }
  `}

  .navi-mobile &,
  body:not(.navi-force-show) .navi-simple & {
    visibility: hidden;
  }
`;

export const NaviIconLink: FunctionComponent<NaviIconLinkProps> = React.memo(
  ({ item, icon: IconComponent }: NaviIconLinkProps) => {
    const className = useActivePathClassName(item);

    return (
      <PathLink path={item.path} passHref>
        <StyledIconLink className={className} aria-label={item.title}>
          <IconComponent />
        </StyledIconLink>
      </PathLink>
    );
  }
);

NaviIconLink.displayName = 'NaviIconLink';
