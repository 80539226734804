import { addDays, differenceInDays } from 'date-fns';
import { useAuth } from './auth';
import { useAsync } from 'owlet-ui/common';
import { fetchTransactions, isOrderStopped, useOrders } from './orders';
import { OrderTransaction, OrderTransactionStatus } from '../models/order';
import { useEffect, useState } from 'react';

const WARNING_DAYS = 15;

export const useCreditCardError = (): boolean => {
  const { authToken } = useAuth();
  const [difference, setDifference] = useState<number>();
  const order = useOrders().activeOrders?.[0];

  useEffect(() => {
    if (order) {
      const expirationDate = order?.userPaymentMethod?.expireDate
        ? new Date(order?.userPaymentMethod?.expireDate)
        : addDays(new Date(), 30);
      setDifference(differenceInDays(expirationDate, new Date()));
    }
  }, [order]);

  const { value: transactions } = useAsync<OrderTransaction[]>(async () => {
    if (order && authToken) {
      return !isOrderStopped(order) ? fetchTransactions(order, authToken) : null;
    }
  }, [order, authToken]);

  const transactionFailed = transactions?.[0]?.status === OrderTransactionStatus.Failed;
  if (transactionFailed || difference <= WARNING_DAYS) {
    return true;
  }

  return false;
};
