import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import { getPlaceholderImageUrl } from '../../services/images';
import { ImageOrientation, ImageSize } from '../../models/image';
import { ContentImage } from '../image/ContentImage';

export interface StripeImageProps {
  url: string;
  orientation: 'portrait' | 'landscape';
  alt: string;
}

const Image = styled(ContentImage)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit: cover;
  border-radius: 0.25rem;
  transition: 0.3s opacity ease-out;

  &.lazyload {
    opacity: 0;
  }
`;

export const StripeImage: FunctionComponent<StripeImageProps> = ({ url, orientation, alt }: StripeImageProps) => {
  const placeholderSrc = useCallback(
    () =>
      getPlaceholderImageUrl(
        orientation === 'landscape' ? ImageOrientation.Landscape : ImageOrientation.Portrait,
        ImageSize.Medium
      ),
    [orientation]
  );

  return (
    <>
      <noscript>
        <Image src={url} alt={alt || ''} loading="lazy" />
      </noscript>
      <Image src={url} alt={alt || ''} lazyload={true} placeholderSrc={placeholderSrc} />
    </>
  );
};
