import Router, { NextRouter, useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { UrlObject } from 'url';
import { IncomingMessage, ServerResponse } from 'http';
import find from 'lodash/find';
import keys from 'lodash/keys';
import isString from 'lodash/isString';
import startsWith from 'lodash/startsWith';
import { Path } from '../../models/paths';
import { selectPaths } from '../../state/app/app.selectors';
import { cleanUrl } from '../format';
import { useAuth } from '../auth';
import { LinkData } from '../links';
import { GetServerSideProps } from 'next';
import { devLog } from '../utils';

type Url = UrlObject | string;

export interface PathRouter extends NextRouter {
  configForPath: (path: string) => Path;
  getLink: (pathConfig: Path, isAuthenticated?: boolean) => { href: string; as: string };
  getPagePath: (path: string, isAuthenticated?: boolean) => string;
  push: (path: string | Path) => Promise<boolean>;
  replace: (path: string | Path) => Promise<boolean>;
  nextPush: (url: Url, as?: Url, options?: {}) => Promise<boolean>;
  nextReplace: (url: Url, as?: Url, options?: {}) => Promise<boolean>;
  currentPathConfig: Path;
}

// Resolves NextJS path to page template (ie. returns href for <Link> component by using 'as' string.
export function getPagePath(path: string, isAuthenticated?: boolean): string {
  let page;

  const topLevelPages = [
    'asiakaspalvelu',
    'category',
    'collection',
    'etusivu',
    'hae',
    'ilmoitukset',
    'jatka',
    'kampanja',
    'kanavat',
    'kansi',
    'katso',
    'koodi',
    'lista',
    'elokuva',
    'ohjelma',
    'poiminnat',
    'profiili',
    'omatili',
    'profiilit',
    'suosikit',
    'suosittelut',
    'telia-vas',
    'tilaa',
    'tulossa',
    'uusi-salasana',
  ];

  const pathParts = path.split('?')[0].split('/');
  const firstPath = pathParts[1];

  if (cleanUrl(path) === '/') {
    // If we know for sure that user is authenticated, we can return /etusivu path
    if (isAuthenticated) {
      page = '/etusivu';
    } else {
      page = '/';
    }
  } else if (topLevelPages.indexOf(firstPath) === -1) {
    page = `/[...slug]`;
  } else if (firstPath === 'ohjelma') {
    page = '/ohjelma/[...path]';
  } else if (firstPath === 'category') {
    page = '/category/[id]';
  } else if (firstPath === 'elokuva') {
    if (pathParts.length === 4) {
      page = '/elokuva/[id]/[slug]';
    } else {
      page = '/elokuva/[id]';
    }
  } else if (firstPath === 'kampanja') {
    page = '/kampanja/[campaignSlug]';
  } else if (firstPath === 'katso') {
    const secondPath = pathParts[2];
    if (secondPath === 'kuitti') {
      page = '/omatili/kuitti/[id]';
    } else {
      page = `/omatili/${secondPath}`;
    }
  } else if (firstPath === 'asiakaspalvelu') {
    const secondPath = pathParts[2];
    if (secondPath === 'haku') {
      page = '/asiakaspalvelu/haku';
    } else {
      if (pathParts[2] && !pathParts[3]) {
        page = '/asiakaspalvelu/[category]';
      } else if (pathParts[3] === 'article') {
        page = '/asiakaspalvelu/[category]/article/[article]';
      } else if (pathParts[3] && !pathParts[4]) {
        page = '/asiakaspalvelu/[category]/[subCategory]';
      } else if (pathParts[4]) {
        page = '/asiakaspalvelu/[category]/[subCategory]/[article]';
      } else {
        page = '/asiakaspalvelu';
      }
    }
  } else if (firstPath === 'lista') {
    page = '/lista/[slug]';
  } else if (firstPath === 'poiminnat') {
    page = '/poiminnat/[slug]';
  } else if (firstPath === 'tulossa') {
    page = '/tulossa/[[...slug]]';
  } else {
    page = `/${firstPath}`;
  }

  return page;
}

function getLink(pathConfig: Path, isAuthenticated = false): LinkData {
  if (!pathConfig) {
    return;
  }

  let as = pathConfig.visibleUrl;

  if (pathConfig.visibleUrl === '/kansi' || pathConfig.visibleUrl === '/etusivu') {
    // If we know for sure that user is authenticated, we can return /etusivu path
    if (isAuthenticated && pathConfig.visibleUrl === '/etusivu') {
      as = '/etusivu';
    } else {
      as = '/';
    }
  } else if (startsWith(pathConfig.visibleUrl, '/collection/')) {
    return {
      href: getPagePath(as),
      as: as.replace(/^\/collection\//, '/poiminnat/'),
    };
  } else {
    const m = pathConfig.visibleUrl.match(/^(\/[^/]+)\/poiminnat$/);

    if (m) {
      as = m[1];
    }
  }

  return { href: getPagePath(as), as };
}

export const usePathRouter = (): PathRouter => {
  const router = useRouter();
  const { isAuthenticated } = useAuth();
  const paths = useSelector(selectPaths);

  const configForPath = (path: string): Path => {
    return getPathData(paths, path, isAuthenticated);
  };

  const push = (path: string | Path): Promise<boolean> => {
    const pathConfig = isString(path) ? configForPath(path) : path;

    if (pathConfig) {
      const { href, as } = getLink(pathConfig);

      // If visibleUrl from config is similar to given path, use the given path
      // This will keep query parameters intact when applicable
      return router.push(href, isString(path) && startsWith(path, as) ? path : as);
    }

    return router.push(path);
  };

  const replace = (path: string | Path): Promise<boolean> => {
    const pathConfig = isString(path) ? configForPath(path) : path;

    if (pathConfig) {
      const { href, as } = getLink(pathConfig);
      return router.replace(href, isString(path) && startsWith(path, as) ? path : as);
    }

    return router.replace(path);
  };

  return {
    ...router,
    configForPath,
    getLink,
    getPagePath,
    push,
    replace,
    nextPush: router.push,
    nextReplace: router.replace,
    get currentPathConfig(): Path {
      return configForPath(router.asPath);
    },
  };
};

export function getPathData(paths, path, isAuthenticated = false): Path {
  const visibleUrl = cleanUrl(path);

  if (visibleUrl === '' || visibleUrl === '/') {
    return find(paths, { visibleUrl: isAuthenticated ? '/etusivu' : '/kansi' });
  }

  // Collection pages use path /collection/ in the Coremedia content and /poiminnat/ in the UI
  if (startsWith(visibleUrl, '/collection/')) {
    return find(paths, { path: visibleUrl });
  }

  let pathConfig = find<Path>(paths, { visibleUrl });

  // TODO make this smarter, only try again if accessing main-level items
  if (!pathConfig) {
    pathConfig = find<Path>(paths, { visibleUrl: `${visibleUrl}/poiminnat` });
  }

  // Find "event page" vanity URLs
  if (!pathConfig) {
    pathConfig = find<Path>(paths, (p) => new RegExp(`^${p.visibleUrl}(?:/|$)`).test(visibleUrl));
  }

  return pathConfig;
}

interface RedirectOptions {
  url: string;
  as?: string;
  res?: ServerResponse;
  statusCode?: number;
  scroll?: boolean;
}

/**
 * Server-side compatible redirect
 * @type {object}
 * @property {string} url - Next.js page path
 * @property {string} as - Visible path
 * @property {object} res - ServerResponse for server-side redirects
 * @property {number} statusCode - HTTP status code for server-side redirects
 */
export function redirect({ url, as, res, statusCode = 302, scroll }: RedirectOptions) {
  devLog('Redirect to', as || url);
  if (res) {
    res.writeHead(statusCode, {
      Location: as || url,
    });
    res.end();
  } else if (typeof window !== 'undefined') {
    Router.push(url, as || url, { scroll });
  } else {
    console.error('Unable to redirect');
  }
}

/**
 * Redirect but keep querystring (server-side only) if one exists.
 *
 * @param url
 * @param as
 * @param res
 * @param req
 * @param statusCode
 */
export function redirectWithQueryString(
  url: string,
  as: string,
  res: ServerResponse,
  req: IncomingMessage,
  statusCode: number = 302
): void {
  const qs = req && req.url.indexOf('?') !== -1 ? `?${req.url.split('?')[1]}` : '';

  redirect({
    url,
    as: `${as}${qs}`,
    res,
    statusCode,
  });
}

/**
 * Takes destination path as parameter and returns a getServerSideProps-compatible function that redirects
 * to the given destination. Can parse colon-prefixed placeholders and replace them with the source path
 * params, e.g.:
 *
 * /category/[id].tsx -> '/ohjelma/:id'
 *
 * The placeholder must match the source path param, but it doesn't have to match the destination path param
 * (the example will still work if the destination is `/ohjelma/[someOtherParamName].tsx`).
 *
 * @param path
 */
export function redirectProps(path: string): GetServerSideProps {
  return async ({ resolvedUrl, params }) => {
    // Host is required by URL API, but irrelevant in parsing, so http://localhost will do just as fine
    const url = new URL(resolvedUrl, 'http://localhost');
    const parsedPath = params
      ? keys(params).reduce((path, key) => {
          const value = `${params[key]}`;
          return path.replace(new RegExp(`:${key}($|[/?])`), `${value}$1`);
        }, path)
      : path;
    const destination = `${parsedPath}${url.search || ''}`;

    return {
      redirect: {
        destination,
        permanent: true,
      },
    };
  };
}
