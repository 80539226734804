import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TextLabel } from './StripeLabel';
import { BLACK, WHITE90 } from 'owlet-ui';
import { Asset } from '../../models/asset';
import { ContentTag } from '../../models/content_tag';
import { useSelector } from 'react-redux';
import { selectIsHvodUser } from '../../state/user/user.selectors';
import { Category } from '../../models/category';

interface AdsLabelProps {
  item: Asset | Category;
}

export const AdsLabelContent = styled(TextLabel)`
  position: absolute;
  top: 0;
  right: 0;
  background: ${WHITE90};
  text-transform: none;
  color: ${BLACK};
  pointer-events: none;
  user-select: none;
`;

export const AdsLabel: FunctionComponent<AdsLabelProps> = ({ item }: AdsLabelProps) => {
  const isHvodUser = useSelector(selectIsHvodUser);

  if (!isHvodUser || !item?.contentTags?.includes(ContentTag.AdsBadge)) {
    return null;
  }

  return <AdsLabelContent className="top-right">Mainoksia</AdsLabelContent>;
};
