import { RefObject, useCallback, useMemo, WheelEvent, WheelEventHandler } from 'react';
import get from 'lodash/get';
import includes from 'lodash/includes';
import result from 'lodash/result';
import toArray from 'lodash/toArray';
import { useIsomorphicLayoutEffect } from './utils';

/**
 * Hook for getting the current scrollbar height.
 */
export const useScrollBarHeight = () =>
  useMemo(() => {
    if (typeof window === 'undefined') {
      return 15;
    }

    // http://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript/13382873#13382873
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.height = '100px';
    outer.style['msOverflowStyle'] = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    const heightNoScroll = outer.offsetHeight;

    // force scrollbars
    outer.style.overflow = 'scroll';

    // add innerdiv
    const inner = document.createElement('div');
    inner.style.height = '100%';
    outer.appendChild(inner);

    const heightWithScroll = inner.offsetHeight;

    // remove divs
    if (outer.parentNode) {
      outer.parentNode.removeChild(outer);
    }

    return heightNoScroll - heightWithScroll;
  }, []);

/**
 * Lock body scrolling and release it when component is unmounted. Useful with modals.
 */
export function useLockBodyScroll() {
  useIsomorphicLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);
}

export function useBodyClassName(className: string) {
  useIsomorphicLayoutEffect(() => {
    document.body.classList.add(className);

    return () => {
      document.body.classList.remove(className);
    };
  }, []);
}

/**
 * Get margin width in pixels.
 *
 * @param themeContext
 */
export const getMarginWidth = (themeContext: any): number => {
  const { documentElement, body } = document;

  const width = get(documentElement.getBoundingClientRect(), 'width') || get(body.getBoundingClientRect(), 'width');

  return width * themeContext.horizontalMarginFraction;
};

/**
 * Polyfill for MouseEvent#path.
 */
export function eventPath(e: MouseEvent): EventTarget[] {
  if ('composedPath' in (e as any)) {
    return result(e, 'composedPath');
  }

  if ('path' in (e as any)) {
    return result(e, 'path');
  }

  const path = [];
  let currentElem: Element | null = e.target as Element;
  while (currentElem) {
    path.push(currentElem);
    currentElem = currentElem.parentElement;
  }

  return path;
}

/**
 * Method for finding the next matching element from an array of elements, related to the given source element.
 *
 * For example if the DOM tree contains the elements in the order of `[el1, el2, srcElement, el3]`,
 * the method will return `el3`.
 *
 * @param elements All matching elements
 * @param srcElement Source element (e.g. link or button)
 */
export function findNextElement(
  elements: Array<Node | Element> | NodeListOf<any>,
  srcElement: Node | Element
): Element {
  const allElems = [
    // matching elements
    // if our link is inside one of them, remove it
    ...toArray(elements).filter((el) => !el.contains(srcElement)),
    srcElement,
  ];

  // create selector string from Element
  // e.g. <div id="id0" class="class1 class2"> -> div#id0.class1.class2
  const toSelector = (el: HTMLElement): string => {
    const tagName = el.tagName.toLowerCase();
    const id = el.id ? `#${el.id}` : '';
    const classes = el.classList.length > 0 ? `.${toArray(el.classList).join('.')}` : '';

    return `${tagName}${id}${classes}`;
  };

  const selectors = allElems.map(toSelector).join(', ');
  const [, nextEl]: Element[] = toArray(
    // get elements in the order they are in DOM
    document.querySelectorAll(selectors)
  )
    // remove elements that are extra
    .filter((el) => includes(allElems, el))
    // find element that follows the link
    .reduce((previousAndCurrent, el) => {
      if (previousAndCurrent.length < 2 || previousAndCurrent[0] !== srcElement) {
        previousAndCurrent.push(el);

        if (previousAndCurrent.length === 3) {
          previousAndCurrent.shift();
        }
      }

      return previousAndCurrent;
    }, [] as Element[]);

  return nextEl;
}

export const getFullPageHeight = (): number => {
  const body = document.body;
  const html = document.documentElement;
  return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
};

// Type that matches any HTML*Element type
// There has to be a better way to define this but couldn't find any
export type AnyHTMLElement<T extends HTMLElement = HTMLElement> = T extends HTMLElement ? T : HTMLElement;

export type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T;
};

/**
 * Pass scroll wheel event to a referenced element. Makes it possible to scroll another element through an element
 * residing elsewhere in the DOM tree.
 *
 * @param containerRef
 */
export function usePassWheelEvent(containerRef: RefObject<AnyHTMLElement>): WheelEventHandler<HTMLElement> {
  return useCallback((evt: WheelEvent) => {
    evt.persist();

    const el = containerRef.current;

    if (el) {
      if (el.scrollBy) {
        el.scrollBy(evt.deltaX, evt.deltaY);
      } else {
        // Use alternative method on Edge 18 and earlier
        el.scrollLeft += evt.deltaX;
        el.scrollTop += evt.deltaY;
      }
    }
  }, []);
}

export function convertRemToPixels(rem: number): number {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
