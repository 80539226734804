export enum FeedbackLevel {
  Success = 'success',
  Info = 'info',
  Error = 'error',
  Fatal = 'fatal',
}

export interface FeedbackMessage {
  id?: string; // Auto-generated by useFeedback hook
  message: string;
  level?: FeedbackLevel; // Defaults to info
  timeout?: number; // Milliseconds after which the message will be closed automatically
  closeOnNavigation?: boolean; // If `false`, feedback message will stay open when navigating
  handle?: string; // Unique or non-unique handle for removing messages from the queue
}
