import { CmProduct } from '../../models/product';
import { Voucher } from '../../models/voucher';
import { LinkData } from '../../services/links';
import { Asset } from '../../models/asset';
import { Category } from '../../models/category';
import { ProductPromo } from '../../models/product-promo';
import { ProductPromosLoadedState } from './purchase.reducers';

export const ActionTypes = {
  PURCHASE_INIT: 'purchase/purchase_init',
  PURCHASE_SELECT_PRODUCT: 'purchase/select_product',
  PURCHASE_SET_STARTING_POINT: 'purchase/set_starting_point',
  PURCHASE_SET_VOUCHER: 'purchase/set_voucher',
  PURCHASE_SET_CONTEXT: 'purchase/set_context',
  PURCHASE_STATE_COMPLETED: 'purchase/state_completed',
  PURCHASE_CLEAR: 'purchase/purchase_clear',
  PURCHASE_SET_EXIT_IN_PROGRESS: 'purchase/set_exit_in_progress',
  PURCHASE_SET_PREVIOUS_PRODUCT: 'purchase/set_previous_product',
  PURCHASE_CLEAR_PREVIOUS_PRODUCT: 'purchase/clear_previous_product',
  PURCHASE_SET_PRODUCT_PROMOS: 'products/set_product_promos',
  PURCHASE_SET_PRODUCT_PROMOS_LOADED_STATE: 'products/set_product_promos_loaded_state',
};

export const purchaseInit = (shoppingCart: {
  product: CmProduct;
  voucher: Voucher;
  startingPoint: LinkData;
  context: Asset | Category;
}) => ({
  type: ActionTypes.PURCHASE_INIT,
  payload: shoppingCart,
});

export const purchaseSelectProduct = (product: CmProduct) => ({
  type: ActionTypes.PURCHASE_SELECT_PRODUCT,
  payload: product,
});

export const purchaseSetVoucher = (voucher: Voucher) => ({
  type: ActionTypes.PURCHASE_SET_VOUCHER,
  payload: voucher,
});

export const purchaseSetStartingPoint = (linkData: LinkData) => ({
  type: ActionTypes.PURCHASE_SET_STARTING_POINT,
  payload: linkData,
});

export const purchaseSetContext = (context: Asset | Category) => ({
  type: ActionTypes.PURCHASE_SET_CONTEXT,
  payload: context,
});

export const purchaseSetStateCompleted = () => ({
  type: ActionTypes.PURCHASE_STATE_COMPLETED,
});

export const purchaseClear = () => ({
  type: ActionTypes.PURCHASE_CLEAR,
});

export const purchaseSetExitInProgress = (isInProgress: boolean) => ({
  type: ActionTypes.PURCHASE_SET_EXIT_IN_PROGRESS,
  payload: isInProgress,
});

export const purchaseSetPreviousProduct = (product: CmProduct) => ({
  type: ActionTypes.PURCHASE_SET_PREVIOUS_PRODUCT,
  payload: product,
});

export const purchaseClearPreviousProduct = () => ({
  type: ActionTypes.PURCHASE_CLEAR_PREVIOUS_PRODUCT,
});

export const setProductPromos = (productPromos: ProductPromo[]) => ({
  type: ActionTypes.PURCHASE_SET_PRODUCT_PROMOS,
  payload: productPromos,
});

export const setProductPromosLoadedState = (state: ProductPromosLoadedState) => ({
  type: ActionTypes.PURCHASE_SET_PRODUCT_PROMOS_LOADED_STATE,
  payload: state,
});
