import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import { Title5 } from 'owlet-ui';
import { Section } from './styles';
import { SubProfileListItem } from './SubProfileListItem';
import { ProfileType, useProfileType } from './naviUtils';
import { NaviMenuLink, NaviMenuLinkItem } from './NaviMenuLink';
import { useAuth } from '../../services/auth';

const ProfilesTitle = styled(Title5)`
  padding: 0.75rem 1.25rem;
  text-transform: uppercase;
`;

export const NaviMenuSubProfiles: FunctionComponent = () => {
  const { isAuthenticated, subprofiles } = useAuth();
  const profileType = useProfileType();

  const editProfilesItem = useMemo<NaviMenuLinkItem>(
    () => ({
      path: '/profiilit',
      title: 'Muokkaa profiileja',
      image: null,
    }),
    []
  );

  if (!isAuthenticated || !subprofiles?.length) {
    return null;
  }

  return (
    <>
      <ProfilesTitle>Profiilit</ProfilesTitle>
      <Section>
        <ul>
          {subprofiles.map((subprofile) => (
            <SubProfileListItem subprofile={subprofile} key={subprofile.id} />
          ))}
          {profileType !== ProfileType.Junior && <NaviMenuLink {...editProfilesItem} key={editProfilesItem.path} />}
        </ul>
      </Section>
    </>
  );
};
