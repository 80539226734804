import { css, ThemedCssFunction } from 'styled-components';
import { OwletTheme } from './themes';

/**
 * Media query helper methods for styled-components.
 * Based on styled-media-query, https://github.com/morajabi/styled-media-query
 *
 * @example
 *
 * import styled from 'styled-components';
 * import { greaterThan } from 'owlet-ui/breakpoints';
 *
 * const Wrap = styled.div`
 *   display: flex;
 *   flex-flow: column nowrap;
 *
 *   ${greaterThan('medium')`
 *     flex-flow: row nowrap;
 *   `}
 * `;
 */

export interface OwletBreakpoints {
  xxsmall: number;
  xsmall: number;
  small: number;
  medium: number;
  large: number;
  larger: number;
  xlarge: number;
  xxlarge: number;
  xxxlarge: number;
}

export const breakpoints: OwletBreakpoints = {
  xxsmall: 350,
  xsmall: 480,
  small: 640,
  medium: 832,
  large: 1024,
  larger: 1280,
  xlarge: 1376,
  xxlarge: 1600,
  xxxlarge: 1920,
};

export function lessThan(breakpoint: keyof OwletBreakpoints) {
  return (...args: Parameters<ThemedCssFunction<OwletTheme>>) => css`
    @media (max-width: ${breakpoints[breakpoint]}px) {
      ${css(...args)}
    }
  `;
}

export function greaterThan(breakpoint: keyof OwletBreakpoints) {
  return (...args: Parameters<ThemedCssFunction<OwletTheme>>) => css`
    @media (min-width: ${breakpoints[breakpoint] + 1}px) {
      ${css(...args)}
    }
  `;
}

export function between(from: keyof OwletBreakpoints, to: keyof OwletBreakpoints) {
  return (...args: Parameters<ThemedCssFunction<OwletTheme>>) => css`
    @media (min-width: ${breakpoints[from] + 1}px) and (max-width: ${breakpoints[to]}px) {
      ${css(...args)}
    }
  `;
}
