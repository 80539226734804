import React from 'react';
import { SVGProps } from '../utils';

const SvgClock = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle stroke="currentColor" strokeWidth={2} cx={22.5} cy={22.5} r={21.5} />
      <path
        d="M22.16 22.734l.276-.205a1 1 0 011.242.036l9.784 8.248c.381.321.43.89.109 1.272a.902.902 0 01-.15.141l-.275.205a1 1 0 01-1.243-.036l-9.784-8.248a.902.902 0 01-.108-1.272.902.902 0 01.15-.141z"
        fill="currentColor"
      />
      <rect fill="currentColor" x={21.25} y={11} width={2.5} height={13} rx={1} />
    </g>
  </svg>
);

export default SvgClock;
