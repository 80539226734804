import React, { FunctionComponent, useCallback } from 'react';
import { Asset, AssetType } from '../../../models/asset';
import { IconWrap, PlayButton, PlayLabel, StyledIconPlay } from './styles';
import { useRouter } from 'next/router';
import { AssetDateBadge } from '../../datetime-badge/AssetDateBadge';
import { useAuth } from '../../../services/auth';
import { useAssetPlay, useAssetRights } from '../../../services/player';
import { itemLink } from '../../../services/links';
import { NoAccess } from './NoAccess';
import { useDetailsFloat } from './hooks';
import { buildEDDLPlayEvent, useEDDL } from '../../../services/tracking/eddl-analytics';

interface AssetPlayProps {
  asset: Asset;
  isUpcoming: boolean;
}

export const AssetPlay: FunctionComponent<AssetPlayProps> = ({ asset, isUpcoming }: AssetPlayProps) => {
  const { asPath } = useRouter();
  const { isAuthCompleted, isAuthenticated } = useAuth();
  const { play, loginAndPlay } = useAssetPlay(asset);
  const { isAccessible } = useAssetRights(asset);

  const { state } = useDetailsFloat();
  const sendEDDLPlayEvent = useEDDL(() => buildEDDLPlayEvent(state.contentContext, asset), [asset, state]);

  const handleClick = useCallback(() => {
    if (isAuthenticated) {
      play(asPath);
    } else {
      loginAndPlay(asPath);
    }

    // Analytics
    sendEDDLPlayEvent();
  }, [asset, isAuthenticated, sendEDDLPlayEvent]);

  if (isUpcoming) {
    return <AssetDateBadge asset={asset} />;
  }

  if (!isAuthCompleted) {
    return null;
  }

  // User has no rights to see this content
  if (isAuthenticated && !isAccessible) {
    const linkData = itemLink(asset);
    return <NoAccess linkData={linkData} />;
  }

  const title = asset.type === AssetType.Episode ? asset.subtitle : asset.title;

  return (
    <PlayButton onClick={handleClick} aria-label={`Toista ${title}`}>
      <IconWrap>
        <StyledIconPlay />
      </IconWrap>
      <PlayLabel>{asset.humanDuration ? <>Kesto: {asset.humanDuration}</> : <>Katso {title}</>}</PlayLabel>
    </PlayButton>
  );
};
