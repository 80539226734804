import React, { FunctionComponent, SyntheticEvent, useRef } from 'react';
import styled, { ThemeProps } from 'styled-components';
import { ChoppedTree } from './Navi';
import { NaviMenuLink, NaviMenuLinkItem } from './NaviMenuLink';
import { TreePath } from '../../models/tree';
import { bold, DARKPURPLE, eventPath, OwletTheme, useDocumentEventListener, WHITE, WHITE15 } from 'owlet-ui';
import { NaviLogout } from './NaviLogout';
import classNames from 'classnames';
import some from 'lodash/some';
import result from 'lodash/result';
import { ProfileType, useProfileType } from './naviUtils';
import { CompactSection, MenuImage, Section } from './styles';
import { breakpoints, greaterThan } from 'owlet-ui/breakpoints';
import { NaviMenuSubProfiles } from './NaviMenuSubProfiles';
import { NaviMenuNotifications } from './NaviMenuNotifications';
import { NaviMenuCustomerSupport } from './NaviMenuCustomerSupport';
import { NaviMenuContinue } from './NaviMenuContinue';
import { NaviMenuFavorites } from './NaviMenuFavorites';
import { NaviMenuComingSoon } from './NaviMenuComingSoon';
import { NaviMenuProfileLink } from './NaviMenuProfileLink';
import { NaviMenuSearch } from './NaviMenuSearch';
import { useCreditCardError } from '../../services/credit-card-errors';

interface NaviMenuProps {
  items: ChoppedTree;
  isMenuOpen: boolean;
  onOutsideClick: () => void;
}

const Search = styled(CompactSection)`
  border-top: none;
  align-items: center;
  min-height: ${(props: ThemeProps<OwletTheme>) => props.theme.naviHeightSmall};

  ${greaterThan('large')`
    min-height: ${(props: ThemeProps<OwletTheme>) => props.theme.naviHeight};
  `}

  .navi-compact & {
    display: flex;

    ${greaterThan('small')`
      grid-column-end: span 2;
    `}
  }
`;

const Wrap = styled.div`
  position: absolute;
  z-index: 100;
  right: 0;
  top: 100%;
  display: flex;
  flex-flow: column nowrap;
  background: ${DARKPURPLE};
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  padding-bottom: 0.5rem;
  max-height: calc(100vh - 100px);
  overflow: auto;
  transition: opacity 100ms, transform 200ms ease-in-out;
  box-shadow: ${(props: ThemeProps<OwletTheme>) => props.theme.naviDropShadow};
  user-select: none;

  .navi-compact & {
    top: 0;
    display: grid;
    width: 100%;

    ${greaterThan('small')`
      grid-template-columns: 1fr 1fr;
      width: ${breakpoints.small}px;
    `}
  }

  &.hidden {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  // Applying blur filter to FullPage in Main.tsx when modals are open make the page wider than it should
  // be in mobile Chrome. Hiding the NaviMenu entirely will prevent that.
  body.with-modal & {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: ${WHITE};
    padding: 0.75rem 1.25rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    opacity: 0.9;
    text-decoration: none;

    &.active,
    &:hover {
      opacity: 1;
      text-decoration: none;
    }

    &.active {
      ${bold()};
    }

    img {
      margin-right: 1rem;
    }

    img,
    svg {
      display: none;

      .navi-compact & {
        display: block;
      }
    }
  }

  body:not(.navi-force-show) &.navi-simple {
    display: none;
  }
`;

const UserColumn = styled.div`
  > *:nth-child(1) {
    border-top: none;
  }

  .navi-compact & {
    ${greaterThan('small')`
      border-left: 1px solid ${WHITE15};
    `}

    > *:nth-child(1) {
      border-top: 1px solid ${WHITE15};
    }
  }
`;

const handleImageError = (evt: SyntheticEvent) => {
  (evt.target as HTMLElement).style.display = 'none';
};

const pathToLinkItem = ({ path, title, image }: TreePath): NaviMenuLinkItem => ({
  path,
  title,
  image: <MenuImage src={image} loading="lazy" onError={handleImageError} alt={title} width="26" height="26" />,
});

export const NaviMenu: FunctionComponent<NaviMenuProps> = React.memo(
  ({ items, isMenuOpen = false, onOutsideClick = () => {} }: NaviMenuProps) => {
    const profileType = useProfileType();
    const creditCardError = useCreditCardError();
    const containerRef = useRef<HTMLDivElement>(null);

    // Click outside to close
    useDocumentEventListener('click', (event: MouseEvent) => {
      if (isMenuOpen && containerRef.current && onOutsideClick) {
        // Close menu if clicked outside menu area or clicked on a link inside the menu
        const containsLink = () => some(eventPath(event), (el) => result(el, 'nodeName.toLowerCase') === 'a');

        if (!containerRef.current.contains(event.target as Node) || containsLink()) {
          onOutsideClick();
        }
      }
    });

    return (
      <Wrap
        className={classNames({
          hidden: !isMenuOpen,
        })}
        ref={containerRef}
      >
        <Search>
          <ul>
            <NaviMenuSearch />
          </ul>
        </Search>
        <div>
          <CompactSection>
            {items?.home && (
              <ul>
                <NaviMenuLink
                  {...pathToLinkItem(items.home)}
                  key={items.home.path}
                  hasCreditCardError={creditCardError}
                />
              </ul>
            )}
          </CompactSection>
          <CompactSection>
            <ul>
              {items.main.map((item) => (
                <NaviMenuLink {...pathToLinkItem(item)} key={item.path} />
              ))}
              {items.extra.map((item) => (
                <NaviMenuLink {...pathToLinkItem(item)} key={item.path} />
              ))}
            </ul>
          </CompactSection>
        </div>
        <UserColumn>
          <NaviMenuSubProfiles />
          <Section>
            <ul>
              <NaviMenuContinue />
              <NaviMenuFavorites />
              <NaviMenuComingSoon />
              <NaviMenuProfileLink />
              <NaviMenuNotifications />
              <NaviMenuCustomerSupport />
            </ul>
          </Section>
          {profileType !== ProfileType.Junior && (
            <Section>
              <ul>
                <NaviLogout />
              </ul>
            </Section>
          )}
        </UserColumn>
      </Wrap>
    );
  }
);

NaviMenu.displayName = 'NaviMenu';
