import produce from 'immer';
import unionBy from 'lodash/unionBy';

import { ActionTypes } from './viewing-history.actions';
import { ViewingHistoryItem } from '../../models/viewingHistoryItem';
import { ViewingHistoryError } from '../../services/viewing-history/viewing-history';

export interface ViewingHistoryState {
  history: ViewingHistoryItem[] | [];
  categoriesLoaded: string[];
  error: ViewingHistoryError;
}

export const initialViewingHistoryState: ViewingHistoryState = {
  history: [],
  categoriesLoaded: [],
  error: null,
};

const viewingHistoryReducer = (state = initialViewingHistoryState, action: any) => {
  switch (action.type) {
    case ActionTypes.UVH_ASSET_SUCCESS:
      // This is asset history UPDATE success
      return produce(state, (draft: any) => {
        const assetId = Number(action.payload.historyItem.assetId);
        const index = draft.history.findIndex((v) => Number(v.assetId) === assetId);
        if (index === -1) {
          draft.history.push(action.payload.historyItem);
        } else {
          draft.history[index] = action.payload.historyItem;
        }
      });
    case ActionTypes.UVH_SUCCESS:
      return produce(state, (draft) => {
        draft.history = unionBy(action.payload.items, draft.history, 'assetId');
        draft.error = null;
      });
    case ActionTypes.UVH_CATEGORY_LOADED:
      return produce(state, (draft) => {
        draft.categoriesLoaded.push(action.payload.mainCategoryId);
        draft.error = null;
      });
    case ActionTypes.UVH_ERROR:
      return produce(state, (draft) => {
        draft.error = action.payload.errorType;
      });
    case ActionTypes.UVH_RESET:
      return produce(state, (draft) => {
        draft.history = [];
        draft.categoriesLoaded = [];
        draft.error = null;
      });
    case ActionTypes.REMOVE_ASSET_PROGRESS:
      return produce(state, (draft: any) => {
        const assetId = Number(action.payload.assetId);
        const index = draft.history.findIndex((v) => Number(v.assetId) === assetId);
        if (index !== -1) {
          draft.history.splice(index, 1);
        }
      });
    default:
      return state;
  }
};

export default viewingHistoryReducer;
