import styled, { css } from 'styled-components';
import {
  BLACK,
  bold,
  clampLines,
  ratio,
  SIZE_SMALL,
  TELIAPURPLE,
  Title4,
  WHITE,
  WHITE15,
  WideSlotItem,
} from 'owlet-ui';
import rgba from 'color-alpha';
import { FONT_BOLD_ALT } from '../../layouts/globalStyles';

export const ImageDimmer = css`
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.6) 100%);
    transition: background 250ms ease;
    pointer-events: none;
  }
`;

export const Container = styled.a`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.theme.stripeHalfMargin};
  right: ${(props) => props.theme.stripeHalfMargin};
  border-radius: 0.25rem;
  background: ${WHITE15};
  line-height: 1.2;
  overflow: hidden;

  &.with-dimmer {
    ${ImageDimmer};
  }
`;

export const NonInteractiveContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.theme.stripeHalfMargin};
  right: ${(props) => props.theme.stripeHalfMargin};
  border-radius: 0.25rem;
  background: ${WHITE15};
  overflow: hidden;

  &.with-dimmer {
    ${ImageDimmer};
  }
`;

const NaviCtrl = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${rgba(BLACK, 0.7)};
  color: ${WHITE};
  // Add 0.5px to fix rounding errors that caused underlying element to peak outside the NaviCtrl element
  width: calc(${(props) => props.theme.horizontalMargin} - ${(props) => props.theme.stripeHalfMargin} + 0.5px);
  z-index: 10;

  &::after {
    content: '';
    border: 3px solid transparent;
    border-top-color: ${WHITE};
    border-left-color: ${WHITE};
    border-radius: 3px 0 0 0;
    width: 1.2vw;
    height: 1.2vw;
    transform-origin: 50% 50%;
  }

  .has-items > & {
    cursor: pointer;

    &:hover::after {
      border-top-color: ${TELIAPURPLE};
      border-left-color: ${TELIAPURPLE};
    }
  }
`;

export const NaviCtrlPrev = styled(NaviCtrl)`
  &::after {
    transform: rotate(-45deg) translate3d(0.3vw, 0.3vw, 0);
  }
`;

export const NaviCtrlNext = styled(NaviCtrl)`
  &::after {
    transform: rotate(135deg) translate3d(0.3vw, 0.3vw, 0);
  }
`;

export const Labels = styled.div`
  transition: opacity 250ms ease;
`;

const TextLabels = styled(Labels)`
  z-index: 1;
  position: absolute;
  max-width: calc(100% - 0.5rem);
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`;

export const TopRightLabels = styled(TextLabels)`
  top: 0;
  right: 0;
  align-items: flex-end;
`;

export const TopLeftLabels = styled(TextLabels)`
  top: 0;
  left: 0;
`;

export const BottomRightLabels = styled(TextLabels)`
  bottom: 0;
  right: 0;
  z-index: 2;
  align-items: flex-end;
`;

export const IconLabels = styled(Labels)`
  margin-bottom: 0.25rem;
`;

export const AssetDescription = styled.div`
  background: ${WHITE15};
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: ${SIZE_SMALL};
  cursor: pointer;
  line-height: 1.1;
`;

export const FloatingAssetDescription = styled(AssetDescription)`
  background: none;
  position: absolute;
  z-index: 99;
  box-shadow: ${(props) => props.theme.naviDropShadow};
  overflow: hidden;
  border-radius: 0;
`;

export const FloatingAssetContent = styled.div`
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  background: ${WHITE15};
  overflow: hidden;
  border-radius: 5px;
`;

export const DescriptionArrow = styled.span`
  display: block;
  flex-shrink: 0;
  margin-left: 0.5rem;

  svg {
    fill: ${WHITE};
    width: 1.6rem;
    display: block;
  }
`;

// Category-style slots

export const WideWrap = styled(WideSlotItem)`
  padding-bottom: ${ratio(4, 2.75)};
`;

export const WideTitleWrap = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  color: ${WHITE};
  text-shadow: ${(props) => props.theme.textShadow};
  transition: opacity 250ms ease;
  z-index: 2;
  &.centered {
    text-align: center;
  }
`;

export const WideContainer = styled(Container)`
  ${ImageDimmer};

  &::after {
    border-radius: 0.25rem;
  }
`;

export const ImageOverlayContent = styled.div`
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const CategoryTitle = styled(Title4)`
  font: ${FONT_BOLD_ALT};
  font-size: 1.2rem;
  opacity: 0.9;
  text-transform: uppercase;
  padding-top: 0.25rem; // Fixes issue where umlaut dots are not visible
`;

export const StripeTitle = styled(CategoryTitle)`
  ${clampLines(2)};
`;

export const StripeSubtitle = styled.p`
  margin: 0.125rem 0 0;
  &:not(.regular-font) {
    ${bold()};
  }
  ${clampLines(2)};
`;

export const ImageFallbackLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
`;
