import { SubscriptionStatus } from './paths';

export interface Notification {
  color: string;
  closed?: () => void;
  conditions?: {
    browsers?: string[];
    categories?: string[];
    loginStatus?: LoginStatus;
    paths?: string[];
    productGroups?: string[];
    productIds?: string[];
    subscriptionStatus?: SubscriptionStatus;
    paymentFlow?: boolean;
    minAppVersion?: number;
    maxAppVersion?: number;
    assets?: string[];
  };
  description?: string;
  id: string;
  image?: NotificationImage;
  images?: NotificationImage[];
  plainText?: string;
  published: string;
  target?: {
    path: string;
    title: string;
  };
  assetId?: number;
  title?: string;
  subtitle?: string;
  shortSubtitle?: string;
  type: 'emergency' | 'info' | 'overlay';
}

export interface NotificationImage {
  url: string;
  width?: number;
  height?: number;
}

export interface ClosedNotification {
  id: string;
  published: string;
}

export enum LoginStatus {
  LoggedIn = 'LOGGED_IN',
  LoggedOut = 'LOGGED_OUT',
}

export interface PersonalNotification {
  id: number;
  title: string;
  text: string;
  path?: string;
  assetId: number;
  categoryId: number;
  linkText?: string;
  createdAt: string;
  expirationDate: string;
  imageUrl?: string;
}
