import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Category } from '../../models/category';
import { hasOnlyUpcomingEpisodes } from '../../services/categories';
import { DateBadge } from '../datetime-badge/DateBadge';

interface CategoryUpcomingBadgeProps {
  category: Category;
  className?: string;
}

const BadgeContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  transition: opacity 250ms ease;
  opacity: 0.9;
`;

export const CategoryUpcomingBadge: FunctionComponent<CategoryUpcomingBadgeProps> = ({ category, className }) => {
  const onlyUpcomingEpisodes = hasOnlyUpcomingEpisodes(category);

  if (!onlyUpcomingEpisodes) {
    return null;
  }

  const nextEpisodeUp = new Date(category.nextUpcomingAssetLiveBroadcastTime);

  return (
    <BadgeContainer className={className}>
      <DateBadge date={nextEpisodeUp} showTime={true} />
    </BadgeContainer>
  );
};
