import styled, { ThemeProps } from 'styled-components';
import { DARKESTPURPLE, OwletTheme, SIZE_SMALL, WHITE, WHITE15 } from 'owlet-ui';
import { greaterThan, lessThan } from 'owlet-ui/breakpoints';

export const Wrap = styled.footer`
  background-color: ${DARKESTPURPLE};
  color: ${WHITE};
  font-size: ${SIZE_SMALL};
  padding-top: 2rem;

  ${greaterThan('small')`
    padding-top: 4rem;
  `}
`;

export const LogoContainer = styled.div`
  margin: 0 ${(props: ThemeProps<OwletTheme>) => props.theme.horizontalMargin};
  border-bottom: 1px solid ${WHITE15};
  padding-bottom: 1rem;

  ${greaterThan('small')`
    margin-bottom: 1rem;
  `}

  a {
    display: inline-block;
  }

  svg {
    fill: ${WHITE};
    width: 120px;
    display: block;

    ${greaterThan('large')`
      width: 140px;
    `}
  }
`;

export const LinksContainer = styled.div`
  margin: 0 ${(props: ThemeProps<OwletTheme>) => props.theme.horizontalMargin};
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem 0 3rem;

  ${greaterThan('small')`
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 0 2rem;
  `}
`;

export const Column = styled.ul`
  list-style: none;
  padding: 0 0 1rem;
  margin: 0;

  ${lessThan('small')`
    & + & {
      padding-top: 1rem;
      border-top: 1px solid ${WHITE15};
    }
  `}

  ${greaterThan('small')`
    border-bottom: 0;
    margin-top: .5rem;
  `}
`;

export const Item = styled.li`
  margin-bottom: 0.25rem;

  svg {
    fill: ${WHITE};
    height: 20px;
    width: 20px;
    margin-right: 8px;
    display: block;
  }

  a {
    display: inline-flex;
    align-items: center;
    color: ${WHITE};
    text-decoration: none;

    &:hover {
      color: ${WHITE};

      svg {
        fill: ${WHITE};
      }
    }
  }
`;
