import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationResponse, RegisterUserData, User } from '../models/user';
import { authRequest } from '../state/user/user.actions';
import {
  selectActiveSubProfile,
  selectAuthMethod,
  selectAuthToken,
  selectIsAuthCompleted,
  selectIsAuthStarted,
  selectIsLoggingOut,
  selectIsSubprofileLoaded,
  selectSubProfiles,
  selectSubprofilesErrorStatus,
  selectUser,
} from '../state/user/user.selectors';
import { Subprofile } from '../models/subprofile';
import { getCookieSite, getKatsomoServiceApiUrl, getVimondApiUrl, shouldUseSameDomainApis } from './conf';
import { setTimeDifference } from './time';
import { buildURL, fetchRaw } from './api';
import { VimondError } from '../models/error';
import get from 'lodash/get';
import { getItem, Keys, removeItem, setItem } from './local-storage';
import { getProfileIdentifier } from './user';
import { useTermsPending } from './terms';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useOrders } from './orders';
import { AuthMethod } from '../state/user/user.reducers';

export const OPERATOR_LOGIN_USER_FETCH_DELAY = 5000;

export const getAuthToken = (headers: Headers): string => {
  const auth = headers.get('Authorization');
  return auth ? auth.split(/, +/)[0] : '';
};

export const getServerDate = (headers: Headers): Date => {
  const dateHeader = headers.get('Date');
  return dateHeader ? new Date(Date.parse(dateHeader)) : new Date();
};

/**
 * @param {string} requestToken Bearer token (optional, used in operator login)
 */
export const authenticate = async (): Promise<AuthenticationResponse> => {
  return null;
};

export const loginSubprofile = async (requestToken: string, subProfileId: string): Promise<{ ssoToken: string }> => {
  return null;

  if (!requestToken || !subProfileId) {
    return null;
  }

  try {
    const headers: HeadersInit = {
      Accept: 'application/json;charset=utf-8',
      'Content-type': 'application/json;v=2',
      Authorization: requestToken,
    };

    const data = `"${subProfileId}"`;

    const authUrl: string = `${getKatsomoServiceApiUrl()}/authentication/subprofiles`;

    const resp = await fetch(authUrl, {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
      headers,
      body: data,
    });

    if (resp.status === 200) {
      const authToken = getAuthToken(resp.headers);

      if (authToken) {
        return {
          ssoToken: authToken,
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

interface LoginOptions {
  rememberMe: boolean;
  withCredentials: boolean;
}

export const login = async (
  username: string,
  password: string,
  { rememberMe, withCredentials }: LoginOptions = {
    rememberMe: true,
    withCredentials: true,
  }
): Promise<{ authToken: string } | null> => {
  const data = new URLSearchParams({
    username,
    password,
    ...(rememberMe ? { rememberMe: 'true' } : {}),
  });

  let loginUrl: string;
  if (shouldUseSameDomainApis()) {
    loginUrl = `${getKatsomoServiceApiUrl()}/authentication/login?site=cmore&cookieSite=${getCookieSite()}`;
  } else {
    loginUrl = `${getVimondApiUrl()}/api/authentication/user/login`;
  }

  const response = await fetchRaw(loginUrl, {
    method: 'POST',
    withCredentials,
    cors: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data.toString(),
  });

  const body = await response.json();

  if (body && body.response && body.response.code === 'AUTHENTICATION_OK') {
    const authToken = getAuthToken(response.headers);

    if (authToken) {
      return { authToken };
    } else {
      console.error('Fetching user after login failed');
      return null;
    }
  }

  console.error('Authentication failed', body);
  return body && body.response ? body.response : null;
};

export const logout = async () => {
  const logoutUrl: string = `${getKatsomoServiceApiUrl()}/authentication/logout`;

  const response = await fetchRaw(logoutUrl, {
    method: 'DELETE',
    withCredentials: true,
    cors: true,
  });

  return response.status === 200;
};

export class UserExistsError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, UserExistsError.prototype);
  }
}

export async function register(userData: RegisterUserData, params: Record<string, any> = {}): Promise<User> {
  const url = `${getKatsomoServiceApiUrl()}/user`;
  const body = {
    user: userData,
    site: 'cmore',
  };

  const response = await fetchRaw(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params,
    withCredentials: true,
  });

  const { user, error }: { user?: User; error?: VimondError } = await response.json();

  if (error) {
    const errorType = get(error, 'errors.0.code');

    if (errorType === 'USER_INVALID_EMAIL' || errorType === 'USER_INVALID_USERNAME') {
      throw new UserExistsError();
    }

    throw new Error(error.code);
  }

  return user;
}

export async function sendVerificationEmail(user: User): Promise<boolean> {
  const url = `${getVimondApiUrl()}/api/mobileapp/user/${user.id}/resendConfirmEmail.json`;

  const response = await fetchRaw(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  return response.ok;
}

export const clearCookies = async () => {
  const url = buildURL(`${getKatsomoServiceApiUrl()}/authentication/clear-cookies`, {
    site: 'cmore',
    noredirect: 'true',
  });

  const response = await fetchRaw(url, {
    method: 'GET',
    withCredentials: true,
    cors: true,
  });

  return response.ok;
};

export interface UseAuthResponse {
  isAuthCompleted: boolean;
  isAuthLoading: boolean;
  isAuthenticated: boolean;
  isSubProfileLoaded: boolean;
  isTermsPending: boolean;
  isLoggingOut: boolean;
  user: User;
  authToken: string;
  subprofiles: Subprofile[];
  activeSubprofile: Subprofile;
  subprofileError: boolean;
  profileIdentifier: string | null;
  handleAuthentication: (requestToken?: string) => void;
}

export const useAuth = (): UseAuthResponse => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const authToken = useSelector(selectAuthToken);
  const subprofiles = useSelector(selectSubProfiles);
  const activeSubprofile = useSelector(selectActiveSubProfile);
  const isAuthStarted = useSelector(selectIsAuthStarted);
  const isAuthCompleted = useSelector(selectIsAuthCompleted);
  const isLoggingOut = useSelector(selectIsLoggingOut);
  const isSubProfileLoaded = useSelector(selectIsSubprofileLoaded);
  const subprofileError = useSelector(selectSubprofilesErrorStatus);

  const isTermsPending = useTermsPending(user);

  const profileIdentifier = useMemo<string | null>(() => getProfileIdentifier(user, activeSubprofile), [
    user,
    activeSubprofile,
    subprofileError,
  ]);

  const handleAuthentication = (requestToken?: string) => {
    dispatch(authRequest(requestToken));
  };

  return {
    isAuthenticated: user !== null && !isTermsPending,
    isAuthCompleted,
    isSubProfileLoaded,
    isAuthLoading: isAuthStarted && !isAuthCompleted,
    isTermsPending,
    isLoggingOut,
    user,
    authToken,
    subprofiles,
    activeSubprofile,
    subprofileError,
    profileIdentifier,
    handleAuthentication,
  };
};

export function getTokenFromLocalStorage(): string {
  return getItem(Keys.USER_TOKEN);
}

export function storeTokenToLocalStorage(authToken: string): void {
  setItem(Keys.USER_TOKEN, authToken, { expireMillis: 1000 * 60 * 60 }); // Store token for an hour
}

export function removeTokenFromLocalStorage(): void {
  removeItem(Keys.USER_TOKEN);
}

export async function checkPassword(username: string, password: string): Promise<boolean> {
  const url = `${getVimondApiUrl()}/api/authentication/user/login/entitlements`;

  const data = new URLSearchParams({
    username,
    password,
    platform: 'web',
  });

  const resp = await fetchRaw(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data.toString(),
  });

  return resp.ok;
}

export function useJustLoggedIn(): { justLoggedIn: boolean } {
  const [justLoggedIn, setJustLoggedIn] = useState<boolean>(false);

  const authMethod = useSelector(selectAuthMethod);
  const isOriginallyLoggedIn = useRef<boolean>(null);
  const { isAuthCompleted, isAuthenticated } = useAuth();
  const { isOrdersCompleted } = useOrders();

  useEffect(() => {
    if (isOriginallyLoggedIn.current === null) {
      if (isAuthCompleted) {
        isOriginallyLoggedIn.current = isAuthenticated;
      }

      return;
    }

    if (
      isAuthCompleted &&
      isAuthenticated &&
      isOrdersCompleted &&
      authMethod === AuthMethod.Manual &&
      isOriginallyLoggedIn.current === false
    ) {
      setJustLoggedIn(true);
    }
  }, [isAuthCompleted, isAuthenticated, authMethod, isOrdersCompleted, isOriginallyLoggedIn.current]);

  useEffect(() => {
    if (!isAuthenticated) {
      isOriginallyLoggedIn.current = null;
    }
  }, [isAuthenticated]);

  return { justLoggedIn };
}
