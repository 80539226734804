import { AudioTrack } from '../../models/audiotrack';
import { Subtitle } from '../../models/subtitle';
import { Asset } from '../../models/asset';

export const ActionTypes = {
  PLAY_ASSET: 'player/play_asset',
  ENQUEUE_CHROMECAST_ASSET: 'player/enqueue_chromecast_asset',
  CLEAR_CHROMECAST_QUEUED_ASSET: 'player/clear_chromecast_queued_asset',
  QUEUE_MEDIA_FROM_START: 'player/queue_media_from_start',
  CLEAR_ASSET: 'player/clear_asset',
  SET_NEXT_ASSET: 'player/next_asset',
  SET_SUBTITLE_FORMAT: 'player/set_subtitle_format',
  SET_CHROMECAST_ADBREAK_ONGOING: 'player/set_chromecast_adbreak_ongoing',
  SET_CHROMECAST_ADBREAK_CLIP_DATA: 'player/set_chromecast_adbreak_clip_data',
  SET_CHROMECAST_ACTIVE_AUDIOTRACK: 'player/set_chromecast_active_audiotrack',
  SET_CHROMECAST_AUDIO_TRACKS: 'player/set_chromecast_audiotracks',
  SET_CHROMECAST_AVAILABILITY: 'player/set_chromecast_availability',
  SET_CHROMECAST_MEDIA_FINISHED: 'player/set_chromecast_media_finished',
  SET_CHROMECAST_MEDIA_SEEKING: 'player/set_chromecast_media_seeking',
  SET_CHROMECAST_RECEIVER_AVAILABILITY: 'player/set_chromecast_receiver_availability',
  SET_CHROMECAST_SESSION: 'player/set_chromecast_session',
  SET_CHROMECAST_SESSION_STATUS: 'player/set_chromecast_session_status',
  SET_CHROMECAST_SESSION_NAME: 'player/set_chromecast_session_name',
  SET_CHROMECAST_CURRENT_MEDIA: 'player/set_chromecast_current_media',
  SET_CHROMECAST_LIVE_MEDIA: 'player/set_chromecast_live_media',
  SET_CHROMECAST_SUBTITLES: 'player/set_chromecast_subtitles',
  SET_CHROMECAST_ACTIVE_SUBTITLE: 'player/set_chromecast_active_subtitle',
  SET_CHROMECAST_HQ_FILTER: 'player/set_chromecast_hq_filter',
  SET_CHROMECAST_UHD_CAPABILITY: 'player/set_chromecast_uhd_capability',
  SET_CHROMECAST_CURRENT_POSITION: 'player/set_chromecast_current_position',
  SET_CHROMECAST_CURRENT_LIVE_POSITION: 'player/set_chromecast_current_live_position',
  SET_CHROMECAST_CURRENT_POSITION_AND_DURATION: 'player/set_chromecast_current_position_and_duration',
  SET_PLAYER_LOADED_STATUS: 'player/set_player_loaded_status',
  TOGGLE_LIVECENTER_CAMERA_SELECTOR: 'player/livecenter_toggle_camera_selector',
  SET_LIVECENTER_CAMERA_SELECTOR_STATUS: 'player/livecenter_set_camera_selector_status',
  SET_LIVECENTER_ACTIVE_INSTANCE: 'player/livecenter_set_active_instance',
  SET_LIVECENTER_PLAYER_ASSET: 'player/livecenter_set_player_asset',
  SET_CHROMECAST_STREAM_TYPE: 'player/set_chromecast_stream_type',
  INCREASE_CHROMECAST_PAUSE_TIME: 'player/increase_chromecast_pause_time',
  RESET_CHROMECAST_PAUSE_TIME: 'player/reset_chromecast_pause_time',
};

export const playAsset = (asset: Asset, playInChromecast: boolean) => {
  return {
    type: ActionTypes.PLAY_ASSET,
    payload: {
      asset,
      playInChromecast,
    },
  };
};

export const enqueueChromecastAsset = (asset: Asset) => {
  return {
    type: ActionTypes.ENQUEUE_CHROMECAST_ASSET,
    payload: { asset },
  };
};

export const clearChromecastQueuedAsset = () => {
  return {
    type: ActionTypes.CLEAR_CHROMECAST_QUEUED_ASSET,
    payload: {},
  };
};

export const setQueueMediaID = (queueMediaID: number | null) => {
  return {
    type: ActionTypes.QUEUE_MEDIA_FROM_START,
    payload: queueMediaID,
  };
};

export const clearPlayedAsset = () => {
  return {
    type: ActionTypes.CLEAR_ASSET,
  };
};

export const setNextAsset = (nextAsset: Asset) => {
  return {
    type: ActionTypes.SET_NEXT_ASSET,
    payload: {
      nextAsset,
    },
  };
};

export const setSubtitleFormat = (format: string) => {
  return {
    type: ActionTypes.SET_SUBTITLE_FORMAT,
    payload: {
      format,
    },
  };
};

export const setSubtitles = (subtitles: Subtitle[]) => {
  return {
    type: ActionTypes.SET_CHROMECAST_SUBTITLES,
    payload: {
      subtitles,
    },
  };
};

export const setActiveAudioTrack = (audioTrack: AudioTrack) => {
  return {
    type: ActionTypes.SET_CHROMECAST_ACTIVE_AUDIOTRACK,
    payload: {
      audioTrack,
    },
  };
};

export const setAudioTracks = (audioTracks: AudioTrack[]) => {
  return {
    type: ActionTypes.SET_CHROMECAST_AUDIO_TRACKS,
    payload: {
      audioTracks,
    },
  };
};

export const setChromecastAvailability = (status) => {
  return {
    type: ActionTypes.SET_CHROMECAST_AVAILABILITY,
    payload: {
      status,
    },
  };
};

export const setChromecastReceiverAvailability = (status) => {
  return {
    type: ActionTypes.SET_CHROMECAST_RECEIVER_AVAILABILITY,
    payload: {
      status,
    },
  };
};

export const setChromecastSession = (session) => {
  return {
    type: ActionTypes.SET_CHROMECAST_SESSION,
    payload: {
      session,
    },
  };
};

export const setChromecastSessionStatus = (status: string) => {
  return {
    type: ActionTypes.SET_CHROMECAST_SESSION_STATUS,
    payload: {
      status,
    },
  };
};

export const setChromecastSessionName = (appName, displayName) => {
  return {
    type: ActionTypes.SET_CHROMECAST_SESSION_NAME,
    payload: {
      appName,
      displayName,
    },
  };
};

export const setChromecastMediaFinished = (isFinished: boolean) => {
  return {
    type: ActionTypes.SET_CHROMECAST_MEDIA_FINISHED,
    payload: {
      isFinished,
    },
  };
};

export const setChromecastMediaSeeking = (seeking: boolean) => {
  return {
    type: ActionTypes.SET_CHROMECAST_MEDIA_SEEKING,
    payload: {
      seeking,
    },
  };
};

export const setCurrentMedia = (currentMedia) => {
  return {
    type: ActionTypes.SET_CHROMECAST_CURRENT_MEDIA,
    payload: {
      currentMedia,
    },
  };
};

export const setCurrentPosition = (position) => {
  return {
    type: ActionTypes.SET_CHROMECAST_CURRENT_POSITION,
    payload: {
      position,
    },
  };
};

export const setCurrentPositionAndDuration = (positionAndDuration) => {
  return {
    type: ActionTypes.SET_CHROMECAST_CURRENT_POSITION_AND_DURATION,
    payload: {
      positionAndDuration,
    },
  };
};

export const setCurrentLivePosition = (position) => {
  return {
    type: ActionTypes.SET_CHROMECAST_CURRENT_LIVE_POSITION,
    payload: {
      position,
    },
  };
};

export const setLiveMedia = (liveMedia) => {
  return {
    type: ActionTypes.SET_CHROMECAST_LIVE_MEDIA,
    payload: {
      liveMedia,
    },
  };
};

export const setActiveSubtitle = (subtitle: Subtitle) => {
  return {
    type: ActionTypes.SET_CHROMECAST_ACTIVE_SUBTITLE,
    payload: {
      subtitle,
    },
  };
};

export const setChromecastHQFilter = (status: boolean) => {
  return {
    type: ActionTypes.SET_CHROMECAST_HQ_FILTER,
    payload: {
      status,
    },
  };
};

export const toggleLivecenterCameraSelector = (playerNumber: number = null, forChromecast: boolean = false) => {
  return {
    type: ActionTypes.TOGGLE_LIVECENTER_CAMERA_SELECTOR,
    payload: {
      forChromecast,
      playerNumber,
    },
  };
};

export const setLiveCenterCameraSelectorStatus = (
  status: boolean,
  playerNumber: number = null, // eslint-disable-line
  forChromecast: boolean = false
) => {
  return {
    type: ActionTypes.SET_LIVECENTER_CAMERA_SELECTOR_STATUS,
    payload: {
      status,
      forChromecast,
    },
  };
};

export const setLiveCenterPlayerAsset = (player: number, assetId: number) => {
  return {
    type: ActionTypes.SET_LIVECENTER_PLAYER_ASSET,
    payload: {
      player,
      assetId,
    },
  };
};

export const setLivecenterActivePlayer = (instance: any, videoModel: any) => {
  return {
    type: ActionTypes.SET_LIVECENTER_ACTIVE_INSTANCE,
    payload: {
      instance,
      videoModel,
    },
  };
};

export const setPlayerLoadedStatus = (status: boolean) => {
  return {
    type: ActionTypes.SET_PLAYER_LOADED_STATUS,
    payload: {
      status,
    },
  };
};

export const setChromecastStreamType = (type: string) => {
  return {
    type: ActionTypes.SET_CHROMECAST_STREAM_TYPE,
    payload: {
      type,
    },
  };
};

export const setChromecastUHDCapability = (status: boolean) => {
  return {
    type: ActionTypes.SET_CHROMECAST_UHD_CAPABILITY,
    payload: {
      status,
    },
  };
};

export const increaseChromecastPauseTime = () => {
  return {
    type: ActionTypes.INCREASE_CHROMECAST_PAUSE_TIME,
  };
};

export const resetChromecastPauseTime = () => {
  return {
    type: ActionTypes.RESET_CHROMECAST_PAUSE_TIME,
  };
};

export const setChromecastAdBreakOnGoing = (status: boolean) => {
  return {
    type: ActionTypes.SET_CHROMECAST_ADBREAK_ONGOING,
    payload: {
      status,
    },
  };
};

export const setChromecastAdBreakClipData = (adBreakClipData) => {
  return {
    type: ActionTypes.SET_CHROMECAST_ADBREAK_CLIP_DATA,
    payload: {
      adBreakClipData,
    },
  };
};
