import keys from 'lodash/keys';
import startsWith from 'lodash/startsWith';

interface Options {
  suffix?: string;
  expireMillis?: number;
}

export enum Keys {
  DEFERRED_PATHS = 'cmore-deferred-paths',
  DISABLE_NOTIFICATION_REDIRECT = 'disable-notification-redirect',
  SEARCH_HISTORY = 'search-history',
  NOTIFICATION = 'cmore-notification',
  NOTIFICATION_CENTER_OPENED = 'notification-center-opened',
  CHROMECAST_HQ_FILTER = 'chromecast_hq_filter',
  CHROMECAST_VOD_HQ_FILTER = 'chromecast_vod_hq_filter',
  CHROMECAST_LANGUAGE = 'MTVHTMLPlayer_subtitlesLanguage',
  CHROMECAST_KIND = 'MTVHTMLPlayer_subtitlesKind',
  CHROMECAST_AUDIOLANGUAGE = 'MTVHTMLPlayer_audioLanguage',
  WELCOME_BACK_TIME = 'welcome-back-time',
  LIVE_CENTER_LAYOUT = 'live-center-layout',
  LIVE_CENTER_CAMERA_SELECTION = 'live-center-camera-selection',
  HIGHLIGHTS_ENABLED = 'highlights-enabled',
  CURRENT_SUBPROFILE = 'current-subprofile',
  LAST_APP_OPEN = 'cmore-last-open',
  USER_TOKEN = 'user-token',
  SHOPPING_CART = 'shopping-cart',
  PASSWORD_RETURN_URL = 'cmore-return-url',
  FEATURE_PHONE_VERIFICATION = 'feature-phone-verification',
  FEATURE_HERO_STRIPE_HIDDEN = 'feature-hero-stripe-hidden',
  PLAYER_DEBUG = 'cmore-player-debug',
  PROFILE_ID = 'user-profile-id',
}

const hasLocalStorage = () => {
  return typeof localStorage !== 'undefined';
};

export function setItem(key: Keys, value: string, { suffix, expireMillis }: Options = {}): void {
  try {
    if (!hasLocalStorage()) return;

    const fullKey = getFullKey(key, suffix);
    localStorage.setItem(fullKey, value);

    if (expireMillis) {
      setExpiry(fullKey, expireMillis);
    }
  } catch (e) {
    console.error('Unable to set item to localStorage', key, value);
  }
}

export function getItem(key: Keys, { suffix }: Options = {}): string | undefined {
  try {
    if (!hasLocalStorage()) return undefined;

    const fullKey = getFullKey(key, suffix);

    if (hasExpired(fullKey)) {
      removeWithFullKey(fullKey);
      return undefined;
    }

    return localStorage.getItem(fullKey);
  } catch (e) {
    console.error('Unable to get item from localStorage', key);
    return undefined;
  }
}

export function removeItem(key: Keys, { suffix }: Options = {}): void {
  const fullKey = getFullKey(key, suffix);
  removeWithFullKey(fullKey);
}

export function getKeys(prefix: Keys): string[] {
  try {
    if (!hasLocalStorage()) return [];

    return keys(localStorage).filter((key) => startsWith(key, prefix));
  } catch (e) {
    console.error('Unable to get keys from localStorage');
    return [];
  }
}

export function clear(): void {
  try {
    hasLocalStorage() && localStorage.clear();
  } catch (e) {
    console.error('Unable to clear localStorage');
  }
}

export function updateExpiry(key: Keys, { suffix, expireMillis }: Options = {}): void {
  const fullKey = getFullKey(key, suffix);
  setExpiry(fullKey, expireMillis);
}

function getFullKey(key: Keys, suffix?: string): string {
  return suffix ? `${key}${suffix}` : key;
}

function removeWithFullKey(fullKey: string) {
  try {
    if (hasLocalStorage()) {
      localStorage.removeItem(fullKey);
      localStorage.removeItem(getExpiryKey(fullKey));
    }
  } catch (e) {
    console.error('Unable to remove item from localStorage', fullKey);
  }
}

function setExpiry(key, expireMillis): void {
  try {
    if (hasLocalStorage()) {
      localStorage.setItem(getExpiryKey(key), `${Date.now() + expireMillis}`);
    }
  } catch (e) {
    console.error('Unable to set expiry', key);
  }
}

function hasExpired(key): boolean {
  try {
    if (!hasLocalStorage()) {
      return false;
    }

    const expiryKey = getExpiryKey(key);
    const expiryTime = localStorage.getItem(expiryKey) ? Number(localStorage.getItem(expiryKey)) : null;

    if (expiryTime) {
      return expiryTime < Date.now();
    }
  } catch (e) {
    console.error('Unable to check expiration', key);
  }

  return false;
}

function getExpiryKey(key): string {
  return `expiry:${key}`;
}
