import { useSelector } from 'react-redux';
import { ProgressItem } from '../../models/progress';
import { getMotherbirdApiUrl, getVimondApiUrl } from '../conf';
import { buildURL, fetchJSON, fetchRaw } from '../api';
import { useEffect } from 'react';
import { selectViewingHistoryError } from '../../state/viewing-history/viewing-history.selectors';
import { useFeedback } from '../feedback';
import { User } from '../../models/user';
import { Subprofile } from '../../models/subprofile';
import { getCachebusterUrl } from '../url';
import { VimondResults } from '../../models/results';
import { useAuth } from '../auth';
import useSWR from 'swr';
import { useMutateWithPrefix } from '../swr-grid';

export const PROGRESS_URL = `/v3/svod/web/viewinghistory/progress`;

interface ProgressItemLoaderOpts {
  filter?: string;
  hvodOnly?: boolean;
  vipOnly?: boolean;
}

export interface UseProgressItemsResponse {
  items: ProgressItem[];
  loading: boolean;
  update: () => void;
}

/**
 * Fetch a single page of viewing history items. Requires user and subprofile to create cachebuster param.
 *
 * @param size
 * @param page
 * @param user
 * @param subprofile
 * @param authToken
 * @param opts
 */
export async function fetchProgressItems(
  size: number,
  page: number = 0,
  user: User,
  subprofile: Subprofile,
  authToken: string,
  opts: ProgressItemLoaderOpts = {}
): Promise<ProgressItem[]> {
  const { filter, hvodOnly, vipOnly }: ProgressItemLoaderOpts = Object.assign(
    {
      filter: null,
      hvodOnly: false,
      vipOnly: false,
    },
    opts
  );

  const params: Record<string, any> = {
    size,
    start: page,
  };

  if (filter) {
    params.filter = filter;
  }

  if (hvodOnly) {
    params.hvodOnly = 'true';
  }

  if (vipOnly) {
    params.vipOnly = 'true';
  }

  const url = getCachebusterUrl(buildURL(`${getMotherbirdApiUrl()}${PROGRESS_URL}`, params), user, subprofile);

  const resp = await fetchJSON<VimondResults<ProgressItem>>(url, {
    headers: {
      Authorization: authToken,
    },
    throwErrors: true,
  });

  return resp.result || [];
}

export function useProgressItems(size: number = 20, opts: ProgressItemLoaderOpts = {}): UseProgressItemsResponse {
  const { user, authToken, activeSubprofile, isSubProfileLoaded, profileIdentifier } = useAuth();
  const mutateWithPrefix = useMutateWithPrefix();

  // identifier formed
  // subprofile loaded or loading failed
  const allowFetch = profileIdentifier && isSubProfileLoaded;

  const key = allowFetch
    ? `/progress/${profileIdentifier}${opts?.filter ? `/${opts?.filter}` : ''}${opts?.hvodOnly ? `/hvodOnly` : ''}${
        opts?.vipOnly ? `/vipOnly` : ''
      }`
    : null;

  const { data, isValidating } = useSWR(
    key,
    () => fetchProgressItems(size, 0, user, activeSubprofile, authToken, opts),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    items: data || [],
    loading: isValidating,
    update: () => {
      if (profileIdentifier) {
        mutateWithPrefix(`/progress/${profileIdentifier}`);
        mutateWithPrefix(`${getMotherbirdApiUrl()}${PROGRESS_URL}`);
      }
    },
  };
}

export async function removeAssetFromProgress(assetId: number, authToken: string): Promise<boolean> {
  if (!assetId || !authToken) {
    return false;
  }

  try {
    const baseUrl = getVimondApiUrl();
    const resp = await fetchRaw(`${baseUrl}/viewinghistory/${assetId}`, {
      method: 'DELETE',
      headers: {
        authorization: authToken,
      },
    });

    return resp.status >= 200 && resp.status < 300;
  } catch (e) {
    // skip error handling
  }

  return false;
}

export enum ViewingHistoryError {
  ASSET = 'asset',
  CATEGORY = 'category',
}

export function useViewingHistoryError(errorType: ViewingHistoryError) {
  const historyError = useSelector(selectViewingHistoryError);
  const { error } = useFeedback();

  useEffect(() => {
    if (historyError === errorType) {
      error({ message: 'Katseluhistorian lataus epäonnistui, ole hyvä ja yritä myöhemmin uudelleen.', timeout: 10000 });
    }
  }, [historyError]);
}
