import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Asset } from '../../models/asset';
import { GridItemOptions } from '../../services/swr-grid';
import { clampLines, WHITE, WHITE15 } from 'owlet-ui';
import { episodeLink } from '../../services/links';
import { useSelector } from 'react-redux';
import { selectIsMobileLayout } from '../../state/app/app.selectors';
import { useContentLabels } from '../../services/labels';
import { useDetailsFloat } from '../stripe/float/hooks';
import classNames from 'classnames';
import Link from 'next/link';
import { ContentItemContext, ContentSectionContext } from '../../services/content-context';
import { buildEDDLMoreinfoEvent, useEDDL } from '../../services/tracking/eddl-analytics';
import { getImageUrl, useTitleImage } from '../../services/images';
import { ImageOrientation, ImageSize } from '../../models/image';
import { CuratedAsset } from '../../models/curated';
import { StripeImage } from '../stripe/StripeImage';
import { CategoryTitle, IconLabels, StripeSubtitle, TopLeftLabels, TopRightLabels } from '../stripe/stripes';
import { isSpeakFinnish } from '../../services/assets';
import { TitleImageStyle } from '../title-image/TitleImage';
import { SpeakFinLabel } from '../labels/SpeakFinLabel';
import { ProductLockLabel } from '../stripe/product-lock/ProductLockLabel';

interface GridAssetProps {
  asset: Asset;
  options?: GridItemOptions;
}

const AssetGridItemContainer = styled.a`
  display: block;
  padding-bottom: ${(100 / 4) * 2.75}%;
  position: relative;
  color: ${WHITE};

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.6) 100%);
    transition: background 250ms ease;
    border-radius: 0.25rem;
    pointer-events: none;
  }
`;

const AssetGridItemContent = styled.div`
  opacity: 1;
  position: absolute;
  z-index: 2;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  transition: opacity 250ms ease;
  text-shadow: ${(props) => props.theme.textShadow};
`;

const AssetGridItem = styled.li`
  display: block;
  grid-column: span 2 / auto;
  overflow: hidden;
  position: relative;
  margin: 0 ${(props) => props.theme.stripeHalfMargin} 3rem;
  background: ${WHITE15};
`;

const Title = styled(CategoryTitle)`
  ${clampLines(2)}
`;

const StyledSpeakFinLabel = styled(SpeakFinLabel)`
  white-space: nowrap;
`;

export const GridAsset: FunctionComponent<GridAssetProps> = ({ asset }: GridAssetProps) => {
  const isMobile = useSelector(selectIsMobileLayout);
  const { topLeftLabels, topRightLabels, iconLabels } = useContentLabels(asset, 'normal');
  const title = useTitleImage(asset, asset.title, TitleImageStyle.Stripe);

  const id = `grid-asset-${asset.id}`;
  const { state, updateState } = useDetailsFloat();

  const curatedItem = useMemo<CuratedAsset>(
    () => ({
      asset,
      assetId: asset.id,
    }),
    [asset]
  );

  const imageUrl = getImageUrl(asset, ImageOrientation.Landscape, ImageSize.Medium);
  const linkData = episodeLink(asset);

  const { index } = useContext(ContentItemContext);
  const contentSectionContext = useContext(ContentSectionContext);

  const handleMouseOver = useCallback(() => {
    if (state?.item !== curatedItem) {
      updateState({
        item: curatedItem,
        parentId: id,
        contentContext: {
          ...contentSectionContext,
          index,
        },
        wideParent: true,
      });
    }
  }, [id, state, index, updateState, curatedItem]);

  const handleEDDL = useEDDL(() => buildEDDLMoreinfoEvent({ ...contentSectionContext, index }, asset), [
    asset,
    index,
    contentSectionContext,
  ]);

  const className = classNames({ mobile: isMobile });

  return (
    <AssetGridItem className={className} onMouseOver={handleMouseOver} id={id}>
      <Link href={linkData.href} as={linkData.as} passHref>
        <AssetGridItemContainer onClick={handleEDDL}>
          <StripeImage url={imageUrl} orientation={ImageOrientation.Landscape} alt={asset.title} />
          {topLeftLabels?.length > 0 && <TopLeftLabels>{topLeftLabels}</TopLeftLabels>}
          <TopRightLabels>
            <ProductLockLabel item={asset}>
              {isSpeakFinnish(asset) && <StyledSpeakFinLabel />}
              {topRightLabels}
            </ProductLockLabel>
          </TopRightLabels>
          <AssetGridItemContent>
            {iconLabels?.length > 0 && <IconLabels>{iconLabels}</IconLabels>}
            <Title>{title}</Title>
            {asset.subtitle && <StripeSubtitle>{asset.subtitle}</StripeSubtitle>}
          </AssetGridItemContent>
        </AssetGridItemContainer>
      </Link>
    </AssetGridItem>
  );
};
