import React, { FunctionComponent } from 'react';
import { Title1 } from 'owlet-ui';
import styled from 'styled-components';
import { ErrorPageWrap, ErrorText } from './styles';

const TryAgainLink = styled.a`
  display: inline-block;
  margin-top: 2rem;
`;

export const GenericError: FunctionComponent = () => {
  return (
    <ErrorPageWrap>
      <Title1>Sisällön lataus epäonnistui</Title1>
      <ErrorText>
        <TryAgainLink href="/">Yritä uudelleen</TryAgainLink>
      </ErrorText>
    </ErrorPageWrap>
  );
};
