import { createSelector, Selector } from 'reselect';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import { AuthMethod, LoginModalCloseMethod, UserState, VIPStatus } from './user.reducers';
import { isAfter } from 'date-fns';
import { LEGACY_ORDER_END_DATE, Order } from '../../models/order';
import { UserProperty } from '../../models/user';
import { getConfig } from '../../services/conf';
import { LinkData } from '../../services/links';
import { Subprofile } from '../../models/subprofile';
import { OperatorProperties } from '../../models/operator';
import { useSelector } from 'react-redux';

export const selectUserState = () => (state: any) => state.user;

export const selectUser = createSelector(selectUserState(), (userState: UserState) => {
  return userState.user.profile;
});

export const selectAuthToken = createSelector(selectUserState(), (userState: UserState) => {
  return userState.user.authToken;
});

export const selectLoginError = createSelector(selectUserState(), (userState: UserState) => {
  return userState.user.loginError;
});

export const selectIsLoginModalOpen = createSelector(selectUserState(), (userState: UserState) => {
  return userState.isLoginModalOpen;
});

export const selectIsProfileModalOpen = createSelector(selectUserState(), (userState: UserState) => {
  return userState.isProfileModalOpen;
});
export const useIsProfileModalOpen = () => useSelector(selectIsProfileModalOpen);

export const selectLoginModalUsername: Selector<UserState, string> = createSelector(
  selectUserState(),
  (userState: UserState) => userState.loginModalUsername
);

export const selectLoginRedirect: Selector<UserState, LinkData> = createSelector(
  selectUserState(),
  (userState: UserState) => userState.loginRedirect
);

export const selectShouldProfileRedirect: Selector<UserState, boolean> = createSelector(
  selectUserState(),
  (userState: UserState) => userState.shouldProfileRedirect
);
export const useShouldProfileRedirect = () => useSelector(selectShouldProfileRedirect);

export const selectHasPreviouslySelectedProfile: Selector<UserState, boolean> = createSelector(
  selectUserState(),
  (userState: UserState) => userState.hasPreviouslySelectedProfile
);
export const useHasPreviouslySelectedProfile = () => useSelector(selectHasPreviouslySelectedProfile);

export const selectLoginModalCloseRedirect: Selector<UserState, string> = createSelector(
  selectUserState(),
  (userState: UserState) => userState.loginModalCloseRedirect
);

export const selectLoginModalCloseMethod: Selector<UserState, LoginModalCloseMethod> = createSelector(
  selectUserState(),
  (userState: UserState) => userState.loginModalCloseMethod
);

export const selectRawSubprofiles: Selector<UserState, Subprofile[]> = createSelector(
  selectUserState(),
  (userState: UserState) => userState.user?.subprofiles || []
);

export const selectSubProfiles: Selector<UserState, Subprofile[]> = createSelector(
  selectUserState(),
  (userState: UserState) => {
    if (!userState.user.subprofiles || userState.user.subprofiles?.length === 0) {
      return null;
    }

    const mainProfile = find(userState.user.subprofiles, { defaultProfile: true });

    if (!mainProfile) {
      return null;
    }

    const otherProfiles = filter(userState.user.subprofiles, { kidsProfile: false, defaultProfile: false });
    const kidsProfile = find(userState.user.subprofiles, { kidsProfile: true });

    const subprofiles = [mainProfile, ...otherProfiles];

    if (kidsProfile) {
      subprofiles.push(kidsProfile);
    }

    return subprofiles;
  }
);

export const useSubprofiles = () => useSelector<UserState, Subprofile[]>(selectSubProfiles, isEqual);

export const selectActiveSubProfile = createSelector(selectUserState(), (userState: UserState) => {
  const activeProfileId = userState.user.activeSubprofileId;
  return find(userState.user.subprofiles, { id: activeProfileId });
});

export const useActiveSubprofile = () => useSelector(selectActiveSubProfile, isEqual);

export const selectIsAuthStarted = createSelector(selectUserState(), (userState: UserState) => {
  return userState.authStarted && !userState.authCompleted;
});

export const selectIsAuthCompleted = createSelector(selectUserState(), (userState: UserState) => {
  return userState.authCompleted;
});

export const selectIsSubprofileLoaded = createSelector(selectUserState(), (userState: UserState) => {
  return userState.subProfileLoaded;
});

export const selectIsLoggingOut = createSelector(selectUserState(), (userState: UserState) => {
  return userState.isLoggingOut;
});

export const selectAuthMethod: Selector<UserState, AuthMethod> = createSelector(
  selectUserState(),
  (userState: UserState) => {
    return userState.authMethod;
  }
);

export const selectIsOrdersCompleted = createSelector(selectUserState(), (userState: UserState) => {
  return userState.ordersCompleted;
});

export const selectIsOrdersError = createSelector(selectUserState(), (userState: UserState) => {
  return userState.ordersError;
});

export const selectActiveOrders = createSelector(selectUserState(), (userState: UserState): Order[] => {
  return userState.user.orders ? userState.user.orders.active : null;
});

export const selectHasActiveOrders = createSelector(selectActiveOrders, (activeOrders: Order[]): boolean => {
  return Boolean(activeOrders && activeOrders.length > 0);
});

export const selectFutureOrders = createSelector(selectUserState(), (userState: UserState): Order[] => {
  return userState.user.orders ? userState.user.orders.future : null;
});

export const selectOrderHistory = createSelector(selectUserState(), ({ user: { orders } }: UserState): Order[] => {
  if (!orders || !orders.history) {
    return null;
  }

  if (orders.history.length === 0) {
    return orders.history;
  }

  // Filter out legacy Katsomo orders
  return orders.history.filter(
    (order: Order) => Boolean(order) && isAfter(new Date(order.endDate), LEGACY_ORDER_END_DATE)
  );
});

export const selectAllOrders = createSelector(
  [selectActiveOrders, selectFutureOrders, selectOrderHistory],
  (activeOrders, futureOrders, orderHistory) => {
    if (!activeOrders || !futureOrders || !orderHistory) {
      return null;
    }

    return orderBy(
      [...activeOrders, ...futureOrders, ...orderHistory],
      [(o: Order) => new Date(o.startDate).getTime()],
      ['desc']
    );
  }
);

export const selectIsReturningUser = createSelector(
  [selectActiveOrders, selectFutureOrders, selectOrderHistory],
  (activeOrders, futureOrders, orderHistory) => {
    return activeOrders.length === 0 && futureOrders.length === 0 && orderHistory.length > 0;
  }
);

export const selectGeoBlockStatus = createSelector(selectUserState(), (userState: UserState) => {
  return userState.user.geoBlocked;
});

export const selectUserOperator: Selector<UserState, string> = createSelector(
  selectUserState(),
  ({ user: { profile } }: UserState) => {
    const properties: UserProperty[] = profile?.properties?.property;

    if (properties) {
      const operatorProperty = find(properties, { name: OperatorProperties.Operator });

      if (operatorProperty) {
        return operatorProperty.value || null;
      }
    }

    return null;
  }
);

export const selectIsOperatorUser: Selector<UserState, boolean> = createSelector(
  [selectUserOperator],
  (operator) => !isNil(operator)
);

export const selectPropertyUpdateSuccess = createSelector(selectUserState(), ({ propertyUpdateSuccess }: UserState):
  | string
  | null => {
  return propertyUpdateSuccess;
});

export const selectPropertyUpdateProgress = createSelector(selectUserState(), ({ propertyUpdateProgress }: UserState): {
  [propertyName: string]: boolean;
} | null => {
  return propertyUpdateProgress;
});

export const selectPropertyErrors = createSelector(
  selectUserState(),
  ({ propertyErrors }: UserState): Record<string, string> => {
    return propertyErrors;
  }
);

export const selectUserSegments = createSelector(selectUserState(), (userState: UserState) => {
  return userState.user.segments;
});

export const selectIsSegmentsCompleted = createSelector(selectUserState(), (userState: UserState) => {
  return userState.segmentsCompleted;
});

export const selectSubprofilesErrorStatus = createSelector(selectUserState(), (userState: UserState) => {
  return userState.subprofilesError;
});

export const selectIsHvodUser: Selector<UserState, boolean> = createSelector([selectActiveOrders], (activeOrders) => {
  if (!activeOrders) {
    return false;
  }

  return activeOrders.some(({ productGroupId }) => productGroupId === getConfig().products.productGroupIds.Hvod);
});

export const selectWelcomeBackModalSeenAt: Selector<UserState, Date> = createSelector(
  selectUserState(),
  (userState: UserState) => {
    return userState.welcomeBackModalSeenAt;
  }
);

export const selectTeliaVasPlayError: Selector<UserState, boolean> = createSelector(
  selectUserState(),
  (userState: UserState) => userState.teliaVasPlayError
);

export const selectSmallOperatorPropertyUpdated: Selector<UserState, boolean> = createSelector(
  selectUserState(),
  (userState: UserState) => userState.smallOperatorPropertyUpdated
);

export const selectVipStatus: Selector<UserState, VIPStatus> = createSelector(
  selectUserState(),
  (userState: UserState) => userState.vipStatus
);
