import deburr from 'lodash/deburr';

import { Asset, AssetType } from '../models/asset';
import { Category } from '../models/category';
import { Path, Paths, PathType } from '../models/paths';
import { isAsset } from './assets';
import { EpgItem } from '../models/channel';

export enum PathPrefixes {
  Asset = '/asset',
  Category = '/category',
  Favorites = '/favorites',
  Collection = '/collection',
  History = '/history',
  Search = '/search',
  Group = '/group',
  Etusivu = '/etusivu',
  External = '/web',
  Register = '/rekisterointi',
  PurchaseFlow = '/tilaa',
  Play = '/play',
}

export interface LinkData {
  href: string;
  as: string;
  query?: { [key: string]: string };
}

export interface WebPathData {
  url: string;
  style: string;
}

export function movieLink(asset: Asset): LinkData {
  if (!asset) {
    return null;
  }

  return {
    href: `/elokuva/[id]/[slug]`,
    as: `/elokuva/${asset.id}/${slug(asset.title)}`,
  };
}

export function categoryLink(category: Category): LinkData {
  if (!category) {
    return null;
  }

  return categoryIdLink(category.id, slug(category.title));
}

export function categoryIdLink(categoryId: number | string, categorySlug?: string): LinkData {
  if (!categoryId) {
    return null;
  }

  return {
    href: '/ohjelma/[...path]',
    as: `/ohjelma/${categoryId}${categorySlug ? `/${categorySlug}` : ''}`,
  };
}

export function episodeLink(asset: Asset, category?: Category): LinkData {
  const categorySlug = slug(category ? category.title : asset.title);

  return {
    href: `/ohjelma/[...path]`,
    as: `/ohjelma/${asset.categoryId}/${categorySlug}/${asset.id}/${slug(asset.subtitle)}`,
  };
}

export function epgItemLink(epgItem: EpgItem): LinkData {
  if (!epgItem?.categoryId) {
    return null;
  }

  const episodeSlug = slug(epgItem.subtitle);

  return {
    href: '/ohjelma/[...path]',
    as: `/ohjelma/${epgItem.categoryId}/${slug(epgItem.title)}/${epgItem.id}/${episodeSlug}`,
  };
}

/**
 * Method for getting the correct link for (almost) any kind of an item (movies, episodes, categories).
 *
 * @param item
 */
export function itemLink(item: Asset | Category): LinkData {
  if (isAsset(item)) {
    if (item.type === AssetType.Movie) {
      return movieLink(item);
    } else if (item.type === AssetType.Episode) {
      return episodeLink(item);
    }
  }

  return categoryLink(item as Category);
}

/**
 * searchLink() parses search link out of /search/term/
 * so that split is limited to 3 and last item in the array is used.
 * Whatever comes after last '/' is ignored.
 */
export function searchLink(path, limit = 3): LinkData {
  const split = path.split('/', limit);
  if (split.length === limit) {
    const searchQuery = split[limit - 1];
    const href = `/hae?q=${searchQuery}`;
    return { href, as: href };
  }
  return { href: '/hae', as: '/hae' };
}

export function playerLink(assetId: number | string): LinkData {
  if (!assetId) {
    return null;
  }

  return {
    href: '/katso/[id]/[[...slug]]',
    as: `/katso/${assetId}`,
  };
}

export function slug(s: string): string {
  return deburr(`${s}`.toLowerCase())
    .replace(/[:]/g, '')
    .replace(/[\s.]+/g, '-')
    .replace(/[^-a-z0-9]/g, '')
    .replace(/-+/g, '-')
    .replace(/-+$/, '');
}

export function isEventPath(categoryId: number, paths: Paths): boolean {
  return Boolean(findEventPathByCategoryId(categoryId, paths));
}

export function getVisibleUrl(categoryId: number, paths: Paths): string {
  const path = findPathByCategoryId(categoryId, paths);

  return path && path.visibleUrl ? path.visibleUrl : null;
}

export function findPathByCategoryId(categoryId: number | string, paths: Paths): Path {
  return paths?.find((p) => p.path === `/category/${categoryId}`);
}

export function findEventPathByCategoryId(categoryId: number | string, paths: Paths): Path {
  const path = findPathByCategoryId(categoryId, paths);
  return path?.type === PathType.Event ? path : undefined;
}
