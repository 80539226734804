import { getFromSession, removeFromSession } from './session';
import { SurveyStatus, SurveyType } from '../models/cx-score';

// This file is separated from cx-score.ts due to mocking issues
// https://medium.com/@DavideRama/mock-spy-exported-functions-within-a-single-module-in-jest-cdf2b61af642

export const CX_SURVEY_KEY = 'show-cx-survey';
export const CX_SURVEY_STATUS_KEY = 'cx-survey-status';

export function cleanCachedSurveyData() {
  removeFromSession(CX_SURVEY_STATUS_KEY);
  removeFromSession(CX_SURVEY_KEY);
}

export function getCachedSurveyType() {
  return getFromSession(CX_SURVEY_KEY) as SurveyType;
}

export function getCachedSurveyStatus() {
  return getFromSession(CX_SURVEY_STATUS_KEY) as SurveyStatus;
}
