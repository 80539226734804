import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';
import { MetatagValues } from '../../models/metatag';
import entries from 'lodash/entries';
import isNil from 'lodash/isNil';
import { selectCanonicalUrl, selectMetatags, selectTitle } from '../../state/app/app.selectors';

interface MetatagsProps {}

/**
 * Sets the <title> and <meta> tags in the
 */
export const DynamicHead: FunctionComponent<MetatagsProps> = () => {
  const title: string = useSelector(selectTitle);
  const metatags: MetatagValues = useSelector(selectMetatags);
  const canonicalUrl: string = useSelector(selectCanonicalUrl);

  const meta = entries(metatags).map(([name, data]) => {
    if (isNil(data)) {
      return null;
    }

    const [propName, value] = Object.entries(data)[0];

    const props = {
      [propName]: name,
      content: value,
    };

    return <meta {...props} key={name} />;
  });

  const canonicalUrlLink = canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null;

  return (
    <Head>
      <title>{title}</title>
      {meta}
      {canonicalUrlLink}
    </Head>
  );
};
