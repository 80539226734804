import { useSelector } from 'react-redux';
import { useAuth } from '../../services/auth';
import { Subprofile } from '../../models/subprofile';
import { selectActiveSubProfile, selectSubprofilesErrorStatus } from '../../state/user/user.selectors';
import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useWindowEventListener } from 'owlet-ui';
import get from 'lodash/get';
import { TreePath } from '../../models/tree';
import { usePathRouter } from '../../services/router/router';
import { NaviMenuLinkItem } from './NaviMenuLink';
import { Operators } from '../../models/operator';

export enum ProfileType {
  None = 'none',
  Regular = 'regular',
  Junior = 'junior',
}

export function useProfileType(): ProfileType {
  const { isAuthenticated, subprofiles } = useAuth();
  const activeSubProfile: Subprofile = useSelector(selectActiveSubProfile);
  const subprofilesError: boolean = useSelector(selectSubprofilesErrorStatus);

  if (!isAuthenticated || !subprofiles || subprofiles.length === 0 || subprofilesError) {
    return ProfileType.None;
  }

  return activeSubProfile?.kidsProfile ? ProfileType.Junior : ProfileType.Regular;
}

export function useOpaqueBackground(): boolean {
  const [isOpaqueBackground, setOpaqueBackground] = useState<boolean>(false);

  useWindowEventListener(
    'scroll',
    debounce(() => {
      setOpaqueBackground(window.scrollY > 0);
    }, 50)
  );

  return isOpaqueBackground;
}

export function useActivePathClassName(item: TreePath | NaviMenuLinkItem): string {
  const { asPath, currentPathConfig } = usePathRouter();

  const getClassName = () => {
    if (!item) {
      return undefined;
    }

    const isActive =
      asPath === item.path ||
      get(currentPathConfig, 'visibleUrl') === item.path ||
      new RegExp(`^${item.path}[/?#]`).test(asPath);

    return isActive ? 'active' : undefined;
  };

  const [className, setClassName] = useState<string>(getClassName());

  useEffect(() => {
    setClassName(getClassName());
  }, [item, asPath, currentPathConfig]);

  return className;
}

export function hasUserProfileAccess(userOperator: string, profileType: ProfileType): boolean {
  return (!userOperator || userOperator === Operators.TeliaVas) && profileType !== ProfileType.Junior;
}
