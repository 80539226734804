import { createSelector, Selector } from 'reselect';
import { ProductsState } from './products.reducers';
import { CmProduct, PaymentType } from '../../models/product';
import { Order } from '../../models/order';
import {
  findAlternativeProduct,
  isDigitaProductGroupId,
  isProductEnabled,
  isProductHidden,
  isSinglePeriodProduct,
} from '../../services/products/products';
import {
  selectActiveOrders,
  selectFutureOrders,
  selectIsOrdersCompleted,
  selectOrderHistory,
} from '../user/user.selectors';
import { UserState } from '../user/user.reducers';
import produce from 'immer';

export const selectProductsState = () => (state: any) => state.products;

export const selectValidProducts: Selector<ProductsState, CmProduct[]> = createSelector(
  selectProductsState(),
  ({ products }: ProductsState) => {
    return products.filter((p) => p && p.product);
  }
);

export const selectSubscriptionProducts: Selector<ProductsState, CmProduct[]> = createSelector(
  selectValidProducts,
  (products: CmProduct[]) => {
    return products.filter(
      ({
        product: {
          paymentPlan: { paymentType },
        },
      }) => paymentType === PaymentType.Subscription
    );
  }
);

export const selectSinglePeriodProducts: Selector<ProductsState, CmProduct[]> = createSelector(
  selectValidProducts,
  (products: CmProduct[]) => {
    return products.filter(isSinglePeriodProduct);
  }
);

export const selectPPVProducts: Selector<ProductsState, CmProduct[]> = createSelector(
  [selectValidProducts],
  (validProducts: CmProduct[]) => {
    // Group by product group id
    const productGroups = validProducts.reduce((result, product) => {
      const {
        product: { productGroupId },
      } = product;

      if (result.has(productGroupId)) {
        result.set(productGroupId, [...result.get(productGroupId), product]);
      } else {
        result.set(productGroupId, [product]);
      }

      return result;
    }, new Map<number, CmProduct[]>());

    return (
      Array.from(productGroups.values())
        // Filter groups that contain 1 single-period product and nothing else
        .filter((products) => products.length === 1 && isSinglePeriodProduct(products[0]))
        // Flatten
        .reduce((result, products) => [...result, ...products], [])
    );
  }
);

export const selectPPVOrders: Selector<UserState, Order[]> = createSelector(
  [selectActiveOrders, selectPPVProducts],
  (activeOrders, ppvProducts = []) => {
    if (!activeOrders) {
      return null;
    }

    const ppvFilter = (order: Order) => ppvProducts.some(({ product: { id } }) => order.productId === id);

    return activeOrders.filter(ppvFilter);
  }
);

export const selectNonPPVOrders: Selector<UserState, { active: Order[]; future: Order[] }> = createSelector(
  [selectActiveOrders, selectFutureOrders, selectPPVProducts],
  (activeOrders, futureOrders, ppvProducts = []) => {
    if (!activeOrders || !futureOrders) {
      return { active: null, future: null };
    }

    const nonPPVFilter = (order: Order) => !ppvProducts.some(({ product: { id } }) => order.productId === id);

    return {
      active: activeOrders.filter(nonPPVFilter),
      future: futureOrders.filter(nonPPVFilter),
    };
  }
);

export interface AvailableProductsResponse {
  subscription: CmProduct[];
  ppv: CmProduct[];
}

export const selectProductsAvailableForUser = createSelector(
  [
    selectValidProducts,
    selectPPVProducts,
    selectIsOrdersCompleted,
    selectActiveOrders,
    selectFutureOrders,
    selectOrderHistory,
  ],
  (
    validProducts: CmProduct[],
    ppvProducts: CmProduct[],
    isOrdersCompleted: boolean,
    activeOrders: Order[] = [],
    futureOrders: Order[] = [],
    historyOrders: Order[] = []
  ): AvailableProductsResponse => {
    // include only enabled products OR products that user have an active subscription for
    const activeAndFutureOrders = isOrdersCompleted ? [...(activeOrders || []), ...(futureOrders || [])] : [];

    const matchesOrder = ({ product }: CmProduct): boolean => {
      return activeAndFutureOrders.some(
        ({ productId, productGroupId }) => productId === product.id && productGroupId === product.productGroupId
      );
    };

    // Check if user has an active order for a Digita product
    const hasDigitaOrder = (activeOrders || []).some((order) => isDigitaProductGroupId(order.productGroupId));

    const availableProductsByGroup: Map<number, CmProduct> = validProducts
      // Filter out all products that have been recognized as PPV products
      .filter(
        ({ product: { productGroupId } }) =>
          !ppvProducts.some(({ product: ppvProduct }) => ppvProduct.productGroupId === productGroupId)
      )
      // Group by product group id
      .reduce((acc, cmProduct) => {
        const { product } = cmProduct;
        const { productGroupId: groupId } = product;

        // Active order takes precedence, otherwise add product to the list only if there's not yet
        // another product in the same group and check for alternatives
        if (matchesOrder(cmProduct)) {
          acc.set(groupId, cmProduct);
        } else {
          // If Digita customer, also include hidden Digita products
          const forceDigita = hasDigitaOrder && isDigitaProductGroupId(groupId) && isProductHidden(cmProduct);

          if ((isProductEnabled(cmProduct) || forceDigita) && !acc.has(groupId)) {
            const alternative = findAlternativeProduct(
              cmProduct,
              validProducts,
              activeOrders,
              futureOrders,
              historyOrders
            );
            acc.set(groupId, alternative || cmProduct);
          }
        }

        return acc;
      }, new Map());

    const activeProduct = availableProductsByGroup.get(activeOrders?.[0]?.productGroupId);
    // If Digita customer, only return other Digita products
    const digitaFilter = (product: CmProduct) => isDigitaProductGroupId(product.product.productGroupId);
    const othersFilter = (product: CmProduct) => !isDigitaProductGroupId(product.product.productGroupId);
    const removeCurrentFilter = (pid: CmProduct) => (product: CmProduct) => {
      return product.product.productGroupId !== pid?.product.productGroupId;
    };

    const digitaProducts = Array.from(availableProductsByGroup.values()).filter(digitaFilter);
    const otherProducts = Array.from(availableProductsByGroup.values()).filter(othersFilter);
    const allProducts = [...otherProducts, ...digitaProducts].filter(removeCurrentFilter(activeProduct));
    const visibleProducts = activeProduct ? [activeProduct, ...allProducts] : allProducts;

    return {
      subscription: visibleProducts,
      ppv: ppvProducts.filter((cmProduct) => matchesOrder(cmProduct) || isProductEnabled(cmProduct)),
    };
  }
);
