import React, { FunctionComponent, ReactNode, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { StickyContext, StickyContextProps } from 'owlet-ui';
import classNames from 'classnames';
import { Navi } from '../components/navi/Navi';
import { Footer } from '../components/footer/Footer';
import { GlobalStyles } from './globalStyles';
import { DynamicHead } from '../components/seo-metadata/DynamicHead';
import { useAuth } from '../services/auth';
import { NoScriptWarning } from '../components/noscript/NoScriptWarning';
import { PrefetchContent } from '../components/head/PrefetchContent';
import { DetailsFloat } from '../components/stripe/float/DetailsFloat';
import OverlayNotification from '../components/notifications/OverlayNotification';

export interface MainProps {
  navi?: ReactNode;
  footer?: ReactNode;
  dataAttributes?: Record<string, string>;
}

const FullPage = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  body.with-modal & {
    filter: blur(2.5rem);
  }
`;

const MainContent = styled.main`
  flex: 1;

  &.hidden {
    visibility: hidden;
  }
`;

const Wrap = styled.div`
  min-height: 65vh;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
`;

const defaultNavi = <Navi />;
export const defaultFooter = <Footer />;

const Main: FunctionComponent<MainProps> = ({
  children,
  navi = defaultNavi,
  footer = defaultFooter,
  dataAttributes = {},
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [stickyPortalRef, setStickyPortalRef] = useState<any>();
  const { isLoggingOut } = useAuth();

  const stickyContextValue = useMemo<StickyContextProps>(
    () => ({
      portalRef: stickyPortalRef,
      setPortalRef: setStickyPortalRef,
    }),
    [stickyPortalRef, setStickyPortalRef]
  );

  const mainClassName = classNames({
    hidden: isLoggingOut,
  });

  return (
    <StickyContext.Provider value={stickyContextValue}>
      <DynamicHead />
      <PrefetchContent />
      <GlobalStyles />
      <FullPage ref={wrapperRef}>
        {navi}
        <MainContent className={mainClassName}>
          <Wrap {...dataAttributes}>
            <NoScriptWarning />
            {children}
            <OverlayNotification />
          </Wrap>
        </MainContent>
        {footer}
      </FullPage>
      <DetailsFloat />
    </StickyContext.Provider>
  );
};

export default Main;
