import { fork, select, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './purchase.actions';
import { selectShoppingCart } from './purchase.selectors';
import { PurchaseState } from './purchase.reducers';
import { SerializedShoppingCart, SHOPPING_CART_EXPIRY_MILLIS } from '../../models/purchase';
import { isAsset } from '../../services/assets';
import { Keys, setItem } from '../../services/local-storage';

export function* serializeShoppingCart() {
  const { product, startingPoint, context, voucher }: PurchaseState = yield select(selectShoppingCart);

  const serialized: SerializedShoppingCart = {
    product: product ? { id: product.product.id, productGroupId: product.product.productGroupId } : null,
    startingPointUrl: startingPoint ? startingPoint.as || startingPoint.href : '/',
    context: context ? { id: context.id, type: isAsset(context) ? 'asset' : 'category' } : null,
    voucherCode: voucher ? voucher.code : null,
  };

  setItem(Keys.SHOPPING_CART, JSON.stringify(serialized), { expireMillis: SHOPPING_CART_EXPIRY_MILLIS });
}

export function* watchPurchaseProduct() {
  yield takeLatest(ActionTypes.PURCHASE_SELECT_PRODUCT, serializeShoppingCart);
}

export function* watchPurchaseContext() {
  yield takeLatest(ActionTypes.PURCHASE_SET_CONTEXT, serializeShoppingCart);
}

export function* watchPurchaseStartingPoint() {
  yield takeLatest(ActionTypes.PURCHASE_SET_STARTING_POINT, serializeShoppingCart);
}

export function* watchPurchaseVoucher() {
  yield takeLatest(ActionTypes.PURCHASE_SET_VOUCHER, serializeShoppingCart);
}

export function* watchPurchaseClear() {
  yield takeLatest(ActionTypes.PURCHASE_CLEAR, serializeShoppingCart);
}

const purchaseSagas = [
  fork(watchPurchaseProduct),
  fork(watchPurchaseContext),
  fork(watchPurchaseStartingPoint),
  fork(watchPurchaseVoucher),
  fork(watchPurchaseClear),
];

export default purchaseSagas;
