import React from 'react';
import { SVGProps } from '../utils';

const Svg404 = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1966 918" {...props}>
    <path d="M849 472.4c-15.9 0-30.9-6.3-42.3-17.8-5.1-5.2-5.1-13.6.1-18.7 5.2-5.1 13.6-5.1 18.7.1 6.3 6.4 14.6 9.9 23.4 9.9 8.8 0 17.1-3.5 23.4-9.9 5.1-5.2 13.5-5.3 18.7-.1 5.2 5.1 5.3 13.5.1 18.7-11.1 11.5-26.1 17.8-42.1 17.8zm204.2 0c-15.9 0-30.9-6.3-42.2-17.8-5.1-5.2-5.1-13.6.1-18.7 5.2-5.1 13.6-5.1 18.7.1 6.3 6.4 14.6 9.9 23.4 9.9 8.8 0 17.1-3.5 23.4-9.9 5.1-5.2 13.5-5.3 18.7-.1 5.2 5.1 5.3 13.5.1 18.7-11.3 11.5-26.3 17.8-42.2 17.8zm-152.6 97.9c-2.6 0-5.3-.8-7.6-2.4-6-4.2-7.4-12.4-3.2-18.4 14.1-20 37-31.9 61.2-31.9 24.3 0 47.2 11.9 61.3 31.9 4.2 6 2.8 14.2-3.2 18.4-6 4.2-14.2 2.8-18.4-3.2-9.2-13-24-20.7-39.7-20.7-15.7 0-30.5 7.8-39.7 20.7-2.5 3.7-6.6 5.6-10.7 5.6z" />
    <path d="M951.2 676.5s-.1 0 0 0c-119.9 0-217.3-97.5-217.3-217.3 0-119.8 97.4-217.3 217.2-217.4 58.1 0 112.7 22.6 153.7 63.7 41 41 63.6 95.6 63.6 153.7 0 58-22.6 112.6-63.6 153.6-41 41.1-95.6 63.7-153.6 63.7zm-.2-408.2c-105.2 0-190.8 85.7-190.8 191 0 105.2 85.6 190.9 190.8 190.9h.2c105.2 0 190.8-85.6 190.9-190.9 0-51-19.9-98.9-55.9-135-36.2-36.1-84.1-56-135.2-56zM629.7 661c-7.2 0-13-5.8-13-13v-98.5H426c-4.9 0-9.4-2.8-11.6-7.2-2.2-4.4-1.8-9.6 1.2-13.6l203.7-274.5c3.3-4.5 9.2-6.4 14.5-4.6 5.3 1.8 8.9 6.7 8.9 12.3v261.5H704c7.2 0 13 5.8 13 13s-5.8 13-13 13h-61.3V648c0 7.2-5.8 13-13 13zM451.8 523.5h164.8V301.3L451.8 523.5zM1402.9 661c-7.2 0-13-5.8-13-13v-98.5h-190.7c-4.9 0-9.4-2.8-11.6-7.2s-1.8-9.6 1.2-13.6l203.7-274.5c3.3-4.5 9.2-6.4 14.5-4.6 5.3 1.8 8.9 6.7 8.9 12.3v261.5h61.3c7.2 0 13 5.8 13 13s-5.8 13-13 13h-61.3V648c0 7.2-5.8 13-13 13zm-177.8-137.5h164.8V301.3l-164.8 222.2z" />
  </svg>
);

export default Svg404;
