import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CmProduct, PaymentProvider, ProductPayment } from '../../models/product';
import { Voucher } from '../../models/voucher';
import { Asset } from '../../models/asset';
import { Category } from '../../models/category';
import { useRouter } from 'next/router';
import { isAsset } from '../assets';
import { selectExitInProgress, selectShoppingCart } from '../../state/purchase/purchase.selectors';
import { LinkData } from '../links';
import { User } from '../../models/user';
import {
  NetBank,
  VimondOrder,
  VimondOrderConversionPayment,
  VimondOrderConversionResponse,
  VimondOrderData,
  VimondUpgradeOption,
} from '../../models/purchase';
import { buildURL, fetchJSON, fetchRaw, getOrigin } from '../api';
import { getConfig, getVimondApiUrl } from '../conf';
import * as Sentry from '@sentry/node';
import { Order, UserPaymentMethod } from '../../models/order';
import {
  purchaseClear,
  purchaseClearPreviousProduct,
  purchaseSelectProduct,
  purchaseSetContext,
  purchaseSetStartingPoint,
  purchaseSetVoucher,
} from '../../state/purchase/purchase.actions';
import { isPast } from 'date-fns';
import assign from 'lodash/assign';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import { VimondError } from '../../models/error';
import { isProductEnablingVoucher } from './vouchers';
import { cleanUrl } from '../format';
import { storeTokenToLocalStorage, useAuth } from '../auth';
import { getCreditCardPayment, isDigitaProductGroupId } from './products';
import { selectAuthMethod } from '../../state/user/user.selectors';
import { AuthMethod } from '../../state/user/user.reducers';
import { getItem, Keys } from '../local-storage';

export interface UseShoppingCartResponse {
  isShoppingCartCompleted: boolean;
  product: CmProduct;
  startingPoint: LinkData;
  context: Asset | Category;
  voucher: Voucher;
  openPurchaseFlowWithProduct: (product: CmProduct, voucher?: Voucher) => void;
  openSwitchFlow: (order: Order, product: CmProduct, voucher?: Voucher) => void;
  openCancelFlow: (order: Order, product: CmProduct) => void;
  openPurchaseFlowWithContext: (context: Asset | Category) => void;
  openVoucherFlow: (voucher: Voucher, product: CmProduct) => void;
  openReactivateFlow: (order: Order, product: CmProduct) => void;
  setStartingPoint: () => void;
  clearShoppingCart: () => void;
  initShoppingCart: (linkData?: LinkData) => void;
  exitPurchaseFlow: (opts?: ExitPurchaseFlowOpts) => Promise<boolean>;
  isExitInProgress: boolean;
}

interface ExitPurchaseFlowOpts {
  target?: LinkData;
  reload?: boolean;
}

const defaultPurchaseStart = { href: '/omatili', as: '/omatili' };

export function useShoppingCart(): UseShoppingCartResponse {
  const { isCompleted, product, startingPoint, context, voucher } = useSelector(selectShoppingCart);
  const dispatch = useDispatch();
  const { asPath, pathname, push } = useRouter();
  const isExitInProgress = useSelector(selectExitInProgress);
  const { authToken } = useAuth();

  const openPurchaseFlowWithProduct = useCallback((product: CmProduct, voucher?: Voucher) => {
    dispatch(purchaseSelectProduct(product));

    if (voucher) {
      dispatch(purchaseSetVoucher(voucher));
    }

    push('/tilaa/vahvista', `/tilaa/vahvista`);
  }, []);

  const openSwitchFlow = useCallback((order: Order, product: CmProduct, voucher?: Voucher): void => {
    dispatch(purchaseSelectProduct(product));

    // Switch flow is only accessible with a product enabling voucher (0% discount)
    if (isProductEnablingVoucher(voucher)) {
      dispatch(purchaseSetVoucher(voucher));
    }

    push('/tilaa/vaihda/[orderId]', `/tilaa/vaihda/${order.id}`);
  }, []);

  const openCancelFlow = useCallback((order: Order, product: CmProduct): void => {
    dispatch(purchaseSelectProduct(product));
    push('/tilaa/peruuta/[orderId]', `/tilaa/peruuta/${order.id}`);
  }, []);

  const openPurchaseFlowWithContext = useCallback((context: Asset | Category): void => {
    dispatch(purchaseSetContext(context));

    if (isAsset(context)) {
      push('/tilaa/asset/[assetId]', `/tilaa/asset/${context.id}`);
    } else {
      push('/tilaa/category/[categoryId]', `/tilaa/category/${context.id}`);
    }
  }, []);

  const openVoucherFlow = useCallback((voucher: Voucher, product: CmProduct): void => {
    dispatch(purchaseSelectProduct(product));
    dispatch(purchaseSetVoucher(voucher));

    push('/tilaa/koodi', `/tilaa/koodi`);
  }, []);

  const openReactivateFlow = useCallback((order: Order, product: CmProduct): void => {
    dispatch(purchaseSelectProduct(product));
    push('/tilaa/jatka/[orderId]', `/tilaa/jatka/${order.id}`);
  }, []);

  const setStartingPoint = useCallback(() => {
    dispatch(purchaseSetStartingPoint({ href: pathname, as: asPath }));
  }, [pathname, asPath]);

  const clearShoppingCart = useCallback((): void => {
    dispatch(purchaseClear());
    dispatch(purchaseClearPreviousProduct());
  }, []);

  const initShoppingCart = useCallback(
    (linkData: LinkData = defaultPurchaseStart) => {
      clearShoppingCart();
      dispatch(purchaseSetStartingPoint(linkData));
    },
    [clearShoppingCart]
  );

  const exitPurchaseFlow = useCallback(
    async (opts?: ExitPurchaseFlowOpts): Promise<boolean> => {
      const { target, reload } = assign<ExitPurchaseFlowOpts, ExitPurchaseFlowOpts>(
        {
          reload: false,
        },
        opts
      );

      if (!isExitInProgress) {
        console.log('[purchase] exit', reload ? 'with reload' : 'with router', target);

        if (reload) {
          storeTokenToLocalStorage(authToken);
          window.location.href = target?.as || '/tilaa';
          return;
        }

        const success =
          target?.href && target?.as ? await push(target.href, target.as) : await push('/tilaa', '/tilaa');

        if (success) {
          setTimeout(() => {
            clearShoppingCart();
          });
        }

        return success;
      } else {
        console.log('[purchase] exit already called, trying with target:', target);
      }

      return true;
    },
    [isExitInProgress, authToken, clearShoppingCart]
  );

  return useMemo<UseShoppingCartResponse>(
    () => ({
      isShoppingCartCompleted: isCompleted,
      product,
      startingPoint,
      context,
      voucher,
      openPurchaseFlowWithProduct,
      openSwitchFlow,
      openCancelFlow,
      openPurchaseFlowWithContext,
      openVoucherFlow,
      openReactivateFlow,
      setStartingPoint,
      clearShoppingCart,
      initShoppingCart,
      exitPurchaseFlow,
      isExitInProgress,
    }),
    [
      isCompleted,
      product,
      startingPoint,
      context,
      voucher,
      openPurchaseFlowWithProduct,
      openSwitchFlow,
      openCancelFlow,
      openPurchaseFlowWithContext,
      openVoucherFlow,
      openReactivateFlow,
      setStartingPoint,
      clearShoppingCart,
      initShoppingCart,
      exitPurchaseFlow,
      isExitInProgress,
    ]
  );
}

function buildCallbackUrl(
  { product }: CmProduct,
  user: User,
  productPayment: ProductPayment,
  redirectPath: string,
  voucher?: Voucher,
  order?: Order
) {
  // Switch from Digita product to another Digita product
  const isDigitaSwitch =
    order && isDigitaProductGroupId(order.productGroupId) && isDigitaProductGroupId(product.productGroupId);

  return buildURL(`${getOrigin()}${getConfig().products.netsRedirectPath}`, {
    p: product.id,
    pg: product.productGroupId,
    u: user.id,
    pp: productPayment['@id'],
    r: cleanUrl(redirectPath),
    ...(voucher ? { v: voucher.code } : {}),
    ...(order ? { o: order.id } : {}),

    // Redirect to Digita if matching product, but not when switching between Digita products
    ...(isDigitaProductGroupId(product.productGroupId) && !isDigitaSwitch ? { digita: 'true' } : {}),
  });
}

export async function prepareOrder(
  product: CmProduct,
  productPayment: ProductPayment,
  user: User,
  redirectPath: string,
  authToken: string,
  voucher?: Voucher,
  bank?: NetBank,
  order?: Order // If co-exist order, pass previous order
): Promise<VimondOrder> {
  if (!authToken) {
    return null;
  }

  const callbackUrl = buildCallbackUrl(product, user, productPayment, redirectPath, voucher, order);

  const orderData: VimondOrderData = {
    userId: user.id,
    currency: 'EUR',
    productPaymentId: Number(productPayment['@id']),
    callbackUrl,
    ...(voucher ? { voucherCode: voucher.code } : {}),
  };

  // Bank payment
  if (bank && productPayment.paymentProviderId === PaymentProvider.Bank) {
    orderData.payment = {
      paymentMethod: 'DIRECT_BANK',
      paymentMethodAction: JSON.stringify([{ PaymentMethod: bank }]),
      terminalDesign: 'netbankRedirect',
    };
  }

  return doPrepareOrder(orderData, authToken);
}

export async function prepareUpgradeOrder(
  product: CmProduct, // Product we're upgrading to
  order: Order, // Order we're upgrading from
  productPayment: ProductPayment,
  user: User,
  upgradeOption: VimondUpgradeOption,
  redirectPath: string,
  authToken: string,
  voucher?: Voucher
): Promise<VimondOrder> {
  if (!authToken) {
    return null;
  }

  const callbackUrl = buildCallbackUrl(product, user, productPayment, redirectPath, voucher, order);

  const orderData: VimondOrderData = {
    userId: user.id,
    currency: 'EUR',
    productPaymentId: Number(productPayment['@id']),
    upgradeOption,
    upgradeOrderId: order.id,
    callbackUrl,
    ...(voucher ? { voucherCode: voucher.code } : {}),
  };

  return doPrepareOrder(orderData, authToken);
}

export async function prepareFutureOrder(
  product: CmProduct, // Product we're downgrading to
  order: Order, // Order we're downgrading from
  productPayment: ProductPayment,
  user: User,
  redirectPath: string,
  authToken: string,
  voucher?: Voucher
): Promise<VimondOrder> {
  if (!authToken) {
    return null;
  }

  const callbackUrl = buildCallbackUrl(product, user, productPayment, redirectPath, voucher, order);

  const orderData: VimondOrderData = {
    userId: user.id,
    currency: 'EUR',
    productPaymentId: Number(productPayment['@id']),
    startDate: order.endDate,
    callbackUrl,
    ...(voucher ? { voucherCode: voucher.code } : {}),
  };

  return doPrepareOrder(orderData, authToken);
}

export class UpgradeDiscountError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, UpgradeDiscountError.prototype);
  }
}

async function doPrepareOrder(orderData: VimondOrderData, authToken: string): Promise<VimondOrder> {
  const url = `${getVimondApiUrl()}/api/web/order`;
  const body = {
    order: orderData,
  };

  const handleError = async (response: Response) => {
    const body: VimondError = await response.json();

    console.error('Preparing order failed', body);

    if (body.description === 'Upgrade discount not available.') {
      throw new UpgradeDiscountError(body.code);
    }

    throw new Error(response.statusText);
  };

  try {
    const response = await fetchRaw(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json;v=2',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });

    if (!response.ok) {
      await handleError(response);

      return null;
    }

    return response.json();
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setFingerprint(['Creating Vimond order failed']);
      scope.setTag('purchaseFlow', 'yes');
      Sentry.captureException(e);
    });

    throw e;
  }
}

export async function upgradeWithPassword(
  product: CmProduct, // Product we're upgrading to
  order: Order, // Order we're upgrading from
  userPaymentMethod: UserPaymentMethod, // User's stored payment method
  user: User,
  password: string,
  upgradeOption: VimondUpgradeOption,
  authToken: string,
  voucher?: Voucher
): Promise<Order> {
  const creditCardPayment = getCreditCardPayment(product);

  const orderData: VimondOrderData = {
    userId: user.id,
    productPaymentId: Number(creditCardPayment['@id']),
    userPaymentMethod: {
      id: userPaymentMethod.id,
    },
    payment: {
      password,
      paymentMethod: 'ONE_CLICK_BUY',
    },
    upgradeOrderId: order.id,
    upgradeOption,
    ...(voucher ? { voucherCode: voucher.code } : {}),
  };

  return doSinglePurchase(orderData, authToken);
}

export async function downgradeWithPassword(
  product: CmProduct, // Product we're upgrading to
  order: Order, // Order we're upgrading from
  user: User,
  password: string,
  authToken: string,
  voucher?: Voucher
): Promise<Order> {
  const creditCardPayment = getCreditCardPayment(product);

  const orderData: VimondOrderData = {
    userId: user.id,
    productPaymentId: Number(creditCardPayment['@id']),
    payment: {
      password,
      paymentMethod: 'CONVERT_ORDER_FOR_NEXT_RENEWAL',
      orderId: order.id,
    },
    ...(voucher ? { voucherCode: voucher.code } : {}),
  };

  return doSinglePurchase(orderData, authToken);
}

export async function purchaseWithPassword(
  product: CmProduct, // Product we're upgrading to
  userPaymentMethod: UserPaymentMethod, // User's stored payment method
  user: User,
  password: string,
  authToken: string,
  voucher?: Voucher
): Promise<Order> {
  const creditCardPayment = getCreditCardPayment(product);

  const orderData: VimondOrderData = {
    userId: user.id,
    productPaymentId: Number(creditCardPayment['@id']),
    userPaymentMethod: {
      id: userPaymentMethod.id,
    },
    payment: {
      password,
      paymentMethod: 'ONE_CLICK_BUY',
    },
    ...(voucher ? { voucherCode: voucher.code } : {}),
  };

  return doSinglePurchase(orderData, authToken);
}

export async function purchaseWithVoucher(voucher: Voucher, user: User, authToken: string): Promise<Order> {
  const orderData: VimondOrderData = {
    userId: user.id,
    payment: {
      voucher: voucher.code,
      paymentMethod: 'VOUCHER',
    },
  };

  return doSinglePurchase(orderData, authToken);
}

export class PurchaseError extends Error {
  data: VimondError;

  constructor(message: string, data: VimondError) {
    super(message);
    this.data = data;
    Object.setPrototypeOf(this, PurchaseError.prototype);
  }
}

async function doSinglePurchase(orderData: VimondOrderData, authToken: string): Promise<Order> {
  let json: Order | VimondError;

  try {
    const response: Response = await fetchRaw(`${getVimondApiUrl()}/api/web/order`, {
      method: 'PUT',
      body: JSON.stringify({ order: orderData }),
      headers: {
        Accept: 'application/json;v=2',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });

    json = await response.json();

    if (response.ok) {
      return json as Order;
    }
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setFingerprint(['Purchase failed']);
      scope.setTag('purchaseFlow', 'yes');
      Sentry.captureException(e);
    });
  }

  const error = json as VimondError;
  console.error('Purchase failed', error);

  const code = get(json, 'code', 'UNKNOWN');
  throw new PurchaseError(code, error);
}

export async function getOrderConversionPayment(
  order: Order,
  targetProduct: CmProduct,
  user: User,
  authToken: string
): Promise<VimondOrderConversionPayment> {
  const url = buildURL(`${getVimondApiUrl()}/api/web/user/${user.id}/orders/conversions`, {
    productGroupId: targetProduct.product.productGroupId,
  });

  const json = await fetchJSON<VimondOrderConversionResponse>(url, {
    method: 'GET',
    headers: {
      Authorization: authToken,
    },
    throwErrors: false,
  });

  if (json && json.restOrderConversionList) {
    const conversion = json.restOrderConversionList.find((c) => c.orderId === order.id);

    if (
      conversion &&
      conversion.restOrderConversionProductGroupList &&
      conversion.restOrderConversionProductGroupList.length > 0
    ) {
      for (const group of conversion.restOrderConversionProductGroupList) {
        for (const { restProduct, orderConversionProductPaymentList } of group.restOrderConversionProductList) {
          if (
            restProduct.id === targetProduct.product.id &&
            orderConversionProductPaymentList &&
            orderConversionProductPaymentList.length > 0
          ) {
            return orderConversionProductPaymentList[0];
          }
        }
      }
    }
  }

  return null;
}

export async function getUserPaymentMethods(user: User, authToken: string): Promise<UserPaymentMethod[]> {
  const url = `${getVimondApiUrl()}/api/web/user/${user.id}/userpaymentmethod`;

  const result = await fetchJSON<UserPaymentMethod[]>(url, {
    method: 'GET',
    headers: {
      Authorization: authToken,
    },
    throwErrors: false,
  });

  if (!result || !isArray(result)) {
    return [];
  }

  return result;
}

export async function getActiveCreditCards(user: User, authToken: string): Promise<UserPaymentMethod[]> {
  const result = await getUserPaymentMethods(user, authToken);

  const hasExpired = (expireDate: Date): boolean => isPast(expireDate);

  const activeCreditCards = result.filter(
    (card) =>
      card.allowOneClickBuy && card.userPaymentMethodType === 'CREDIT_CARD' && !hasExpired(new Date(card.expireDate))
  );
  const sortedCards = sortBy(activeCreditCards, (card) => card.registered);

  return sortedCards.reverse();
}

// Pattern for paths user shouldn't be redirected after a succesful purchase
const NO_RETURN_PATH = /^\/(?:koodi|tilaa)(?:\/|$)/;

export function getSuccesfulPurchasePath(startingPoint: LinkData, product: CmProduct, voucher?: Voucher): string {
  const [path, querystring] = startingPoint?.as?.split('?') || ['/etusivu', ''];

  const params = new URLSearchParams(querystring);
  params.append('maksu', 'maksettu');
  params.append('tuote', `${product.product.id}`);
  params.append('tuoteryhma', `${product.product.productGroupId}`);

  if (voucher) {
    params.append('voucher', voucher.code);
  }

  return `${NO_RETURN_PATH.test(path) ? '/etusivu' : path}?${params.toString()}`;
}

/**
 * Custom hook for performing side effects when user logs in during the component is visible.
 * Specifically meant for purchase flow. Also returns a boolean if signup form should be shown instead of
 * the actual page contents, and a method for setting the boolean from the outside.
 */
export function useJustLoggedInPurchase(): {
  justLoggedIn: boolean;
  showSignupForm: boolean;
  setShowSignupForm: (showSignupForm: boolean) => void;
} {
  const [justLoggedIn, setJustLoggedIn] = useState<boolean>(false);
  const [showSignupForm, setShowSignupForm] = useState<boolean>(false);

  const authMethod = useSelector(selectAuthMethod);
  const isOriginallyLoggedIn = useRef<boolean>(null);
  const { isAuthCompleted, isAuthenticated } = useAuth();

  // Identify if user logged in during the purchase flow
  useEffect(() => {
    if (isOriginallyLoggedIn.current === null) {
      if (isAuthCompleted) {
        isOriginallyLoggedIn.current = isAuthenticated;
        setShowSignupForm(!isAuthenticated);
      }

      return;
    }

    if (
      isAuthCompleted &&
      isAuthenticated &&
      authMethod === AuthMethod.Manual &&
      isOriginallyLoggedIn.current === false
    ) {
      setJustLoggedIn(true);
      setShowSignupForm(false);
    }
  }, [isAuthCompleted, isAuthenticated, authMethod, isOriginallyLoggedIn.current]);

  return { justLoggedIn, showSignupForm, setShowSignupForm };
}

export class PurchaseVerificationError extends Error {
  vimondError: VimondError;
  statusCode: number;

  constructor(vimondError: VimondError, message?: string, statusCode?: number) {
    super(message);
    Object.setPrototypeOf(this, PurchaseVerificationError.prototype);

    this.vimondError = vimondError;
    this.statusCode = statusCode;
  }
}

export async function verifyPurchase(transactionId: string, includeAuthParam: boolean = false): Promise<Order> {
  const params: Record<string, any> = {
    transactionId,
    responseCode: 'OK',
  };

  // In some cases (0 EUR payments), NETS will pass auth=true param into the request. it signifies that the
  // credit card token will be only stored for later use, and will the transaction will not be charged at
  // present time. Regular transactions are charged immediately (and no auth parameter is passed).
  // (this information is passed down orally, and is not officially documented by Vimond)
  if (includeAuthParam) {
    params.auth = 'true';
  }

  const url = buildURL(`${getVimondApiUrl()}/api/web/order/callback`, params);

  const authToken = getItem(Keys.USER_TOKEN);
  const authHeaders = authToken
    ? {
        Authorization: authToken,
      }
    : {};

  const resp = await fetchRaw(url, {
    headers: {
      Accept: 'application/json;v=2',
      'Content-Type': 'application/json',
      ...authHeaders,
    },
    withCredentials: true,
  });

  if (!resp.ok) {
    throw handleVimondErrorResponse(await resp.text(), resp.status);
  }

  return await resp.json();
}

function handleVimondErrorResponse(responseText: string, statusCode: number): PurchaseVerificationError {
  let error: VimondError = null;
  let message: string;

  try {
    const vimondError: VimondError = JSON.parse(responseText);
    error = vimondError;
    message = vimondError.description;
  } catch (e) {
    message = responseText;
  }

  return new PurchaseVerificationError(error, message, statusCode);
}
