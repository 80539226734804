import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';
import { selectPaths, selectTree } from '../../state/app/app.selectors';
import { Tree } from '../../models/tree';
import { Paths } from '../../models/paths';

// Clean path:
// /foo -> /foo
// /bar/poiminnat -> /bar
const POIMINNAT_PATTERN = /^(\/[^/]+)(?:\/poiminnat|)$/;
const cleanPath = (path) => path.match(POIMINNAT_PATTERN)?.[1];

export const PrefetchContent: FunctionComponent = React.memo(() => {
  const tree: Tree = useSelector(selectTree);
  const paths: Paths = useSelector(selectPaths);

  const [urls] = useState<string[]>(() => {
    const treePaths = tree.map(({ path }) => path);

    return paths.filter(({ path }) => treePaths.includes(cleanPath(path))).map(({ dataUrl }) => dataUrl);
  });

  return (
    <Head>
      {urls.map((url) => (
        <link rel="prefetch" href={url} crossOrigin="anonymous" as="fetch" key={url} />
      ))}
    </Head>
  );
});

PrefetchContent.displayName = 'PrefetchContent';
