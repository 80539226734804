import React, { FunctionComponent } from 'react';
import { useAuth } from '../../services/auth';
import { useSelector } from 'react-redux';
import { selectUserOperator } from '../../state/user/user.selectors';
import { hasUserProfileAccess, useProfileType } from './naviUtils';
import { NaviMenuLink } from './NaviMenuLink';
import { MenuIcon } from './styles';
import { IconAvatar } from 'owlet-ui';
import { useCreditCardError } from '../../services/credit-card-errors';

const icon = (
  <MenuIcon>
    <IconAvatar />
  </MenuIcon>
);

export const NaviMenuProfileLink: FunctionComponent = () => {
  const { isAuthenticated } = useAuth();
  const userOperator: string = useSelector(selectUserOperator);
  const profileType = useProfileType();
  const hasProfileAccess = hasUserProfileAccess(userOperator, profileType);
  const creditCardError = useCreditCardError();
  const errorBadge = creditCardError ? ' ! ' : '';

  if (!isAuthenticated || !hasProfileAccess) {
    return null;
  }

  return <NaviMenuLink path="/omatili" title="Oma tili" image={icon} badge={errorBadge} />;
};
