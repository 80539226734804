import { Target } from './curated';

export interface DeferredData<T> {
  metadata?: DeferredMetadata;
  result: T[];
}

export interface DeferredMetadata {
  numberOfHits?: number;
  title?: string;
  target?: Target;
  componentId?: string;
  filename?: string;
  sortOptions?: SortOption[];
}

export interface SortOption {
  name: string;
  value: string;
}

export enum SortType {
  Likes = 'likes',
  Imdb = 'imdb',
}
