import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { PathLink } from '../link/PathLink';
import styled from 'styled-components';
import { useAuth } from '../../services/auth';
import deburr from 'lodash/deburr';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import { useSelector } from 'react-redux';
import { selectUserOperator } from '../../state/user/user.selectors';
import { useActivePathClassName } from './naviUtils';
import { RED, SIZE_SMALL, TELIAPURPLE, WHITE } from 'owlet-ui';
import { useRouter } from 'next/router';
import { cleanUrl } from '../../services/format';
import { useOrders } from '../../services/orders';

export interface NaviMenuLinkItem {
  path: string;
  title: string;
  image: ReactElement;
  badge?: string | number;
  hash?: string;
}

type NaviMenuLinkProps = NaviMenuLinkItem & {
  authenticatedOnly?: boolean;
  operatorUsers?: boolean | string[];
  hasCreditCardError?: boolean;
};

const MenuLabel = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const MenuBadge = styled.span`
  display: inline-block;
  vertical-align: middle;
  background: ${TELIAPURPLE};
  color: ${WHITE};
  font-size: ${SIZE_SMALL};
  border-radius: 10rem;
  padding: 0.1rem 0.4rem;
  margin-left: 0.5rem;
`;

const AlertBadge = styled(MenuBadge)`
  background: ${RED};
`;

export const NaviMenuLink: FunctionComponent<NaviMenuLinkProps> = React.memo(
  ({
    path,
    title,
    image,
    badge,
    hash,
    authenticatedOnly = false,
    operatorUsers = true,
    hasCreditCardError,
  }: NaviMenuLinkProps) => {
    const { isAuthLoading, isAuthenticated } = useAuth();
    const operator = useSelector(selectUserOperator);
    const { asPath } = useRouter();

    const order = useOrders().activeOrders?.[0];
    const getWrapClassName = () => deburr(path).replace(/\W/gi, '').toLowerCase();

    const [wrapClassName, setWrapClassName] = useState<string>(getWrapClassName());
    useEffect(() => {
      setWrapClassName(getWrapClassName());
    }, [path]);

    const scrollToTopIfNeeded = useCallback(() => {
      if (!path.includes('#') && cleanUrl(asPath) === path) {
        window.scrollTo(0, 0);
      }
    }, [path, asPath]);

    const className = useActivePathClassName(hash ? undefined : { path, title, image, badge, hash });

    // Display link only when we know user is authenticated
    if (authenticatedOnly && (isAuthLoading || !isAuthenticated)) {
      return null;
    }

    // Hide link if it's not available for operator users
    if (
      Boolean(operator) &&
      (operatorUsers === false || (isArray(operatorUsers) && !includes(operatorUsers, operator)))
    ) {
      return null;
    }

    return (
      <li className={wrapClassName}>
        <PathLink path={path} hash={hash}>
          <a
            className={className}
            onClick={scrollToTopIfNeeded}
            data-test={`Navi__MenuLink__${title?.replace(' ', '')}`}
          >
            {image}
            <MenuLabel>
              {title}
              {badge && !hasCreditCardError && <MenuBadge>{badge}</MenuBadge>}
              {badge && hasCreditCardError && new Date(order?.earliestEndDate) >= new Date() && (
                <MenuBadge>{badge}</MenuBadge>
              )}
              {badge && hasCreditCardError && new Date(order?.earliestEndDate) <= new Date() && (
                <AlertBadge>{badge}</AlertBadge>
              )}
            </MenuLabel>
          </a>
        </PathLink>
      </li>
    );
  }
);

NaviMenuLink.displayName = 'NaviMenuLink';
