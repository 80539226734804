// Client-side only
export function isMobileBrowser(): boolean {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') {
    return false;
  }

  return isUserAgentMobile(navigator.userAgent);
}

// Can also be used with req.headers['user-agent']
export function isUserAgentMobile(userAgent: string): boolean {
  if (!userAgent) {
    return false;
  }

  const isAndroid = /Android/.test(userAgent);
  const isIos = /iPhone|iPad|iPod/.test(userAgent);
  const isWP = /Windows Phone|.*Microsoft Build.*Mobile/.test(userAgent);

  return isAndroid || isIos || isWP;
}
