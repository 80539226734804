import styled, { css, ThemeProps } from 'styled-components';
import rgba from 'color-alpha';
import { bold, DARKPURPLE, FONT_MEDIUM, IconCmore, OwletTheme, TELIAPURPLE, WHITE, WHITE15 } from 'owlet-ui';
import { greaterThan } from 'owlet-ui/breakpoints';
import { ContentImage } from '../image/ContentImage';

export const NAVI_ICON_WIDTH = '32px';

export const NaviContainer = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: flex-start;
  white-space: nowrap;
  overflow: hidden;
  background: linear-gradient(to bottom, ${rgba(DARKPURPLE, 0.45)}, ${rgba(DARKPURPLE, 0)});
  padding: 0 ${(props) => props.theme.horizontalMargin};
  font: ${FONT_MEDIUM};
  color: ${WHITE};
  text-shadow: ${(props: ThemeProps<OwletTheme>) => props.theme.textShadow};
  height: ${(props: ThemeProps<OwletTheme>) => props.theme.naviHeightSmall};
  transition: background-color 150ms ease-in;

  ${greaterThan('large')`
    height: ${(props: ThemeProps<OwletTheme>) => props.theme.naviHeight};
  `}

  &.navi-opaque {
    background: ${DARKPURPLE};
  }
`;

export const NaviIconCmore = styled(IconCmore)`
  display: block;
  fill: ${WHITE};
  width: 120px;

  ${greaterThan('large')`
    width: 140px;
  `}
`;

export const Items = styled.ul`
  visibility: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: ${(props: ThemeProps<OwletTheme>) => props.theme.naviHeightSmall};

  ${greaterThan('large')`
    height: ${(props: ThemeProps<OwletTheme>) => props.theme.naviHeight};
  `}

  li {
    align-self: stretch;
    display: flex;
    align-items: center;
  }

  a {
    align-self: stretch;
    color: ${WHITE};
    text-decoration: none;
    display: flex;
    align-items: center;
    opacity: 0.9;
    position: relative;
    padding: 0.5rem 0.75rem;

    &.active {
      ${bold()};
    }

    &:hover {
      text-decoration: none;
      opacity: 1;
    }

    &:hover,
    &.active {
      &::after {
        content: '';
        position: absolute;
        left: 2px;
        right: 2px;
        bottom: 8px;
        height: 2px;
        background: ${WHITE};
        box-shadow: ${(props: ThemeProps<OwletTheme>) => props.theme.textShadow};
      }
    }

    &:hover::after {
      opacity: 0.3;
    }

    &.active::after {
      opacity: 1 !important;
    }
  }

  ${greaterThan('small')`
    visibility: visible;
  `}
`;

export const MenuTrigger = styled.div`
  cursor: pointer;
  z-index: 101;
  position: relative;
  padding: 4px 0 4px 4px;

  svg {
    width: ${NAVI_ICON_WIDTH};
    display: block;
    fill: ${WHITE};
    filter: drop-shadow(1px 1px 0 #000);
  }

  &.hidden {
    visibility: hidden;
  }
`;

export const NaviIconWrap = styled.div`
  width: ${NAVI_ICON_WIDTH};
`;

export const PaddedLink = styled.a`
  padding: 4px;
  cursor: pointer;
  display: block;
`;

export const IconLink = styled(PaddedLink)`
  svg {
    width: ${NAVI_ICON_WIDTH};
    display: block;
    filter: drop-shadow(1px 1px 0 #000);
  }
`;

export const NotificationIndicator = styled.div`
  position: absolute;
  top: 6px;
  right: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${TELIAPURPLE};
  border: 2px solid white;
`;

const menuIconStyle = () => css`
  width: 26px;
  margin-right: 1rem;
  display: block;
`;

export const MenuIcon = styled.span`
  svg {
    ${menuIconStyle};
    fill: ${WHITE};
  }
`;

export const MenuStrokeIcon = styled(MenuIcon)`
  &&&& svg {
    fill: none;
    stroke: ${WHITE};
  }
`;

export const MenuImage = styled(ContentImage)`
  ${menuIconStyle};
`;

export const naviLinkStyles = css`
  color: ${WHITE};
  padding: 0.75rem 1.25rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  opacity: 0.9;
  text-decoration: none;
`;

export const ProfileIcon = styled.span`
  display: flex;
  margin-right: 0.5rem;
  margin-top: 2px;

  > span {
    width: 26px;
    height: 26px;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${WHITE};
    text-transform: uppercase;
    font-size: 12px;
    font-family: ${FONT_MEDIUM};
  }

  &&&& img {
    width: 26px;
    height: 26px;
    margin: 0;
    display: block;
  }
`;

//
// Navi menu
//

export const Section = styled.div`
  border-top: 1px solid ${WHITE15};
`;

// Section that is only shown when not all items fit on screen
export const CompactSection = styled(Section)`
  display: none;

  .navi-compact & {
    display: block;
  }
`;
