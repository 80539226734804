import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { LabelProperties } from '../../models/label';
import { SIZE_TINY, TELIADARKPURPLE, WHITE } from 'owlet-ui';

interface StripeLabelProps {
  label: LabelProperties;
}

export const TextLabel = styled.div`
  padding: 0.25rem 0.4rem;
  background: ${TELIADARKPURPLE};
  text-transform: uppercase;
  font-size: ${SIZE_TINY};
  line-height: 1.1;
  color: ${WHITE};
  text-shadow: none;
  pointer-events: none;

  &.top-left {
    border-top-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  &.top-right {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
`;

const Image = styled.img`
  display: block;
  height: 1.15rem;
  width: auto;
`;

export const StripeLabel: FunctionComponent<StripeLabelProps> = ({ label }: StripeLabelProps) => {
  if (!label) {
    return null;
  }

  if (label.icon) {
    const { url, width, height } = label.icon;

    return <Image src={url} width={width} height={height} alt={label.text} />;
  }

  return (
    <TextLabel
      style={{
        color: label.textColor,
        background: label.color,
      }}
      className={label.position}
    >
      {label.text}
    </TextLabel>
  );
};

interface DescriptionStripeLabelProps {
  description: string;
}

export const DescriptionStripeLabel: FunctionComponent<DescriptionStripeLabelProps> = ({
  description,
}: DescriptionStripeLabelProps) => {
  return <TextLabel className="top-left">{description}</TextLabel>;
};
