import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { DetailsFloatContent } from './DetailsFloat';
import { useTitleImage } from '../../../services/images';
import { TitleImageStyle } from '../../title-image/TitleImage';
import { clampLines } from 'owlet-ui';
import { CategoryTitle } from '../stripes';

export interface DetailsTitleProps {
  data: DetailsFloatContent;
}

export const Title = styled(CategoryTitle)`
  ${clampLines(3)}
`;

export const DetailsTitle: FunctionComponent<DetailsTitleProps> = ({ data }: DetailsTitleProps) => {
  const title = useTitleImage(
    data?.asset || data?.category,
    data?.title || data.asset?.title || data.category?.title,
    TitleImageStyle.Stripe
  );

  if (!data) {
    return null;
  }

  return <Title>{title}</Title>;
};
