import { Asset } from '../models/asset';
import { Category } from '../models/category';
import { fetchJSON } from './api';
import { getVimondApiUrl } from './conf';
import isArray from 'lodash/isArray';
import { PlayData } from '../models/player';
import { useAsync } from 'owlet-ui/common';
import { ContentWithRelatedVideos } from '../models/related-videos';
import { getItem, Keys, setItem } from './local-storage';
import { useAuth } from './auth';
import { useEffect, useState } from 'react';
import { useReducedMotion } from 'owlet-ui/common/mediaQuery';

export interface UseVideoDetailsResponse {
  videoUrl: string;
  hasSound: boolean;
}

export function isContentVideoEnabled(): boolean {
  const enabledValue = getItem(Keys.HIGHLIGHTS_ENABLED);

  // Is enabled unless value is specifically set to false
  return enabledValue !== 'false';
}

export interface UseVideoSettingsResponse {
  enabled: boolean;
  userEnabled: boolean;
  prefersReducedMotion: boolean;
}

export function useVideoSettings(): UseVideoSettingsResponse {
  const userEnabled = isContentVideoEnabled();
  const prefersReducedMotion = useReducedMotion();

  return {
    enabled: userEnabled && !prefersReducedMotion,
    userEnabled,
    prefersReducedMotion,
  };
}

export function setContentVideoEnabled(enabled: boolean) {
  setItem(Keys.HIGHLIGHTS_ENABLED, `${enabled}`);
}

export function useVideoHighlight(item: Asset | Category) {
  const { isAuthCompleted, isAuthenticated } = useAuth();
  const [videoUrl, setVideoUrl] = useState(null);
  const [canPlay, setCanPlay] = useState(false);
  const { enabled, prefersReducedMotion } = useVideoSettings();

  useEffect(() => {
    if (!item || !enabled) {
      setVideoUrl(null);
    } else if (item.highlightMp4Url) {
      setVideoUrl(item.highlightMp4Url);
    } else {
      setVideoUrl(null);
      (async () => {
        setVideoUrl(await fetchHighlightUrl(item));
      })();
    }
  }, [item]);

  useEffect(() => {
    if (isAuthCompleted && videoUrl) {
      if (isAuthenticated) {
        setCanPlay(enabled && videoUrl);
      } else {
        setCanPlay(!prefersReducedMotion);
      }
    } else {
      setCanPlay(false);
    }
  }, [isAuthCompleted, isAuthenticated, videoUrl]);

  return {
    videoUrl,
    canPlay,
  };
}

export function useVideoDetails(item: ContentWithRelatedVideos) {
  const { enabled: isVideoEnabledInSettings } = useVideoSettings();

  const { value: videoDetails } = useAsync<UseVideoDetailsResponse>(async () => {
    if (item && isVideoEnabledInSettings) {
      if (item.previewMp4Url || item.previewId) {
        return {
          videoUrl: await fetchPreviewUrl(item),
          hasSound: true,
        };
      } else if (item.highlightMp4Url || item.highlightId) {
        return {
          videoUrl: await fetchHighlightUrl(item),
          hasSound: false,
        };
      }
    }

    return {
      videoUrl: null,
      hasSound: false,
    };
  }, [item, isVideoEnabledInSettings]);

  return videoDetails;
}

export async function fetchHighlightUrl(item: ContentWithRelatedVideos): Promise<string | null> {
  if (!item) {
    return null;
  }

  if ((item as Asset | Category).highlightMp4Url) {
    return (item as Asset | Category).highlightMp4Url;
  }

  return fetchMp4UrlWithAssetId(item.highlightId);
}

export async function fetchPreviewUrl(item: ContentWithRelatedVideos): Promise<string | null> {
  if (!item) {
    return null;
  }

  return item.previewMp4Url || fetchMp4UrlWithAssetId(item.previewId);
}

// --- Private interface

async function fetchMp4UrlWithAssetId(assetId: number | string): Promise<string> {
  return null;

  if (!assetId) {
    return null;
  }

  try {
    const playData: PlayData = await fetchJSON(`${getVimondApiUrl()}/api/web/asset/${assetId}/play.json?protocol=mp4`);

    const foundItem = readPlaybackItems(playData, 'mp4');
    if (foundItem) {
      return foundItem;
    }
  } catch (ex) {
    console.error(`Failed to fetch the MP4 video url: ${ex.message}`);
  }

  return null;
}

function readPlaybackItems(playData: PlayData, mediaFormat: string): string | null {
  if (!playData) {
    return null;
  }

  if (playData.playback.items && playData.playback.items.item) {
    const itemData = playData.playback.items.item;
    if (isArray(itemData)) {
      const videoItem = itemData.find((item) => item.mediaFormat === mediaFormat);
      if (videoItem) {
        return videoItem.url;
      }
    } else {
      if (itemData.mediaFormat === mediaFormat) {
        return itemData.url;
      }
    }
  }
  return null;
}
