import React, { FunctionComponent } from 'react';
import { IconClose, IconMenu } from 'owlet-ui';
import { MenuTrigger, NotificationIndicator } from './styles';
import { useNotifications } from '../../services/notifications';

interface NaviMenuTriggerProps {
  isMenuOpen: boolean;
  className?: string;
}

export const NaviMenuTrigger: FunctionComponent<NaviMenuTriggerProps> = ({
  isMenuOpen = false,
  className,
}: NaviMenuTriggerProps) => {
  const { menuUnseenNotificationsCount } = useNotifications();

  return (
    <MenuTrigger className={className}>
      {!isMenuOpen && <IconMenu />}
      {isMenuOpen && <IconClose />}
      {menuUnseenNotificationsCount > 0 && !isMenuOpen && <NotificationIndicator />}
    </MenuTrigger>
  );
};
