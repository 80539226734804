import produce from 'immer';
import { ActionTypes } from './notifications.actions';
import { Notification, PersonalNotification } from '../../models/notification';
import { Category } from '../../models/category';

interface ClosedNotification {
  id: string;
  published: string;
}

export interface NotificationsState {
  loaded: boolean;
  notificationCenterLastOpened: Date | null;
  notifications: Notification[];
  personalNotifications: PersonalNotification[];
  closedNotifications: ClosedNotification[];
  notificationCenterLastOpenedChecked: boolean;
  closedNotificationsChecked: boolean;
  notificationContext: NotificationContext;
}

export interface NotificationContext {
  category: Category;
}

export const initialNotificationsState: NotificationsState = {
  loaded: false,
  notificationCenterLastOpened: null,
  notifications: [],
  personalNotifications: [],
  closedNotifications: [],
  notificationCenterLastOpenedChecked: false,
  closedNotificationsChecked: false,
  notificationContext: null,
};

const notificationReducer = (state = initialNotificationsState, action: any) => {
  switch (action.type) {
    case ActionTypes.LOADED:
      return produce(state, (draft) => {
        draft.loaded = true;
      });
    case ActionTypes.NOTIFICATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.notifications = action.payload;
      });
    case ActionTypes.NOTIFICATION_CENTER_SEEN:
      return produce(state, (draft) => {
        draft.notificationCenterLastOpened = action.payload;
        draft.notificationCenterLastOpenedChecked = true;
      });
    case ActionTypes.SET_CLOSED_NOTIFICATIONS:
      return produce(state, (draft) => {
        draft.closedNotifications = action.payload;
        draft.closedNotificationsChecked = true;
      });
    case ActionTypes.APPEND_CLOSED_NOTIFICATION:
      return produce(state, (draft) => {
        const { id, published } = action.payload as Notification;

        draft.closedNotifications.push({ id, published });
      });
    case ActionTypes.REMOVE_CLOSED_NOTIFICATION:
      return produce(state, (draft) => {
        const idx = draft.closedNotifications.findIndex(({ id }) => id === action.payload);

        if (idx >= 0) {
          draft.closedNotifications.splice(idx, 1);
        }
      });
    case ActionTypes.CLEAR_CLOSED_NOTIFICATION:
      return produce(state, (draft) => {
        draft.closedNotifications = [];
      });
    case ActionTypes.SET_NOTIFICATION_CONTEXT:
      return produce(state, (draft) => {
        draft.notificationContext = action.payload;
      });
    case ActionTypes.CLEAR_NOTIFICATION_CONTEXT:
      return produce(state, (draft) => {
        draft.notificationContext = null;
      });
    case ActionTypes.PERSONAL_NOTIFICATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.personalNotifications = action.payload;
      });
    default:
      return state;
  }
};

export default notificationReducer;
