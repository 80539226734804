export enum ImageOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export enum ImageSize {
  Medium = 'medium',
  Large = 'large',
}

export interface Image {
  url: string;
  width: number;
  height: number;
}

export interface ImageCollection {
  portrait?: Image[];
  landscape?: Image[];
}

export interface TitleImageData {
  url: string;
  width: number;
}

export interface ResponsiveImage {
  width: number;
  url: string;
}

export interface ContentWithImages {
  images?: Image[] | ImageCollection;
  image?: string;
  titleImages?: TitleImageData[];
}
