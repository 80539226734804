import { useEventListener, useWindowEventListener } from '../common/event-listener';
import debounce from 'lodash/debounce';
import { useState } from 'react';
import useSSR from 'use-ssr';
import uniqueId from 'lodash/uniqueId';
import noop from 'lodash/noop';

const _cache = new Map<string, number>();

export function useScrollCache(key: string | undefined, containerElement: HTMLElement | null): number | undefined {
  const { isBrowser } = useSSR();

  const urlizeKey = () => (isBrowser ? `${key || uniqueId('scroll-cache')}-${location.pathname}` : '');
  const get = () => (isBrowser ? _cache.get(urlizeKey()) : undefined);
  const put = isBrowser ? (value: number) => _cache.set(urlizeKey(), value) : noop;
  const remove = isBrowser ? () => _cache.delete(urlizeKey()) : noop;

  const [initialCachedValue] = useState<number | undefined>(() => (key ? get() : undefined));

  // Scrolling container element updates cache
  useEventListener(
    'scroll',
    () => {
      if (key && containerElement) {
        const { scrollLeft } = containerElement;
        put(scrollLeft);
      }
    },
    containerElement as HTMLElement
  );

  // Resizing window resets cache
  useWindowEventListener(
    'resize',
    debounce(() => {
      if (key) {
        remove();
      }
    }, 100)
  );

  return initialCachedValue;
}
