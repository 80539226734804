import { fetchRaw } from './api';
import { getCookieSite, getKatsomoServiceApiUrl } from './conf';
import get from 'lodash/get';
import { useAuth } from './auth';
import { useState } from 'react';
import { User } from '../models/user';
import { Order } from '../models/order';
import { isSmallOperatorVoucherCode } from './products/vouchers';
import { getUserProperty } from './properties';
import { OperatorProperties } from '../models/operator';
import isNil from 'lodash/isNil';

interface UseOperatorLogin {
  login: (operator: string, token: string) => Promise<any>;
  error: string;
}

export function useOperatorLogin(): UseOperatorLogin {
  const [error, setError] = useState<string>(null);
  const { handleAuthentication } = useAuth();

  const login = async (operator: string, token: string) => {
    try {
      const authorizationToken = await handleOperatorLogin(operator, token);

      handleAuthentication(authorizationToken);
    } catch (e) {
      console.error('Operator login failed', e);
      setError(e.message);
    }
  };

  return { login, error };
}

async function handleOperatorLogin(operator: string, token: string): Promise<string> {
  const msg = {
    operator,
    token,
    tokenType: 'jwt',
    site: 'cmore',
  };

  const resp = await fetchRaw(`${getKatsomoServiceApiUrl()}/operator/login`, {
    method: 'POST',
    body: JSON.stringify(msg),
    params: {
      cookieSite: getCookieSite(),
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  if (resp.status < 200 || resp.status >= 300) {
    const json = await resp.json();
    throw new Error(get(json, 'response.code') || get(json, 'error') || 'UNKNOWN');
  }

  const authorizationToken = resp.headers.get('authorization');

  if (!authorizationToken) {
    throw new Error('AUTHORIZATION_HEADER_MISSING');
  }

  return authorizationToken;
}

/**
 * Returns a boolean if user has invalid smallOperator property value, or null if property value is up-to-date.
 *
 * @param user
 * @param activeOrders
 */
export function checkSmallOperatorProperty(user: User, activeOrders: Order[]): boolean | null {
  const hasSmallOperatorOrder = activeOrders?.some((order) => isSmallOperatorVoucherCode(order.voucherCode));
  const propertyValue = getUserProperty<boolean>(user, OperatorProperties.SmallOperator);
  const hasSmallOperatorProperty = !isNil(propertyValue);

  if (hasSmallOperatorOrder && propertyValue !== true) {
    // User has order but no property
    console.log('[pope] smallOperator should be true, now:', propertyValue);
    return true;
  } else if (!hasSmallOperatorOrder && hasSmallOperatorProperty && propertyValue !== false) {
    // User has property but no order (anymore)
    console.log('[pope] smallOperator should be false, now:', propertyValue);
    return false;
  } else if (hasSmallOperatorOrder) {
    console.log('[pope] smallOperator property value:', propertyValue);
  }

  // No need to change property value
  return null;
}

export function isSmallOperatorUser(user: User): boolean {
  return getUserProperty<boolean>(user, OperatorProperties.SmallOperator);
}
