import React, { FunctionComponent, PropsWithChildren } from 'react';
import Link from 'next/link';
import isString from 'lodash/isString';

import { usePathRouter } from '../../services/router/router';
import { Path } from '../../models/paths';
import { ParsedUrlQuery } from 'querystring';
import { UrlObject } from 'url';
import { convertToParsedUrlQuery } from '../../services/utils';
import { useAuth } from '../../services/auth';

function parseQueryParams(url: string): ParsedUrlQuery {
  const [, queryString] = url.split('?');
  if (!queryString) {
    return null;
  }
  return convertToParsedUrlQuery(new URLSearchParams(queryString));
}

export interface PathLinkProps {
  path: Path | string;
  query?: ParsedUrlQuery;
  hash?: string;
  replace?: boolean;

  // next/link extra props
  scroll?: boolean;
  shallow?: boolean;
  passHref?: boolean;
  prefetch?: boolean;
}

export const PathLink: FunctionComponent<PropsWithChildren<PathLinkProps>> = ({
  path,
  query,
  hash,
  children,
  ...props
}: PropsWithChildren<PathLinkProps>) => {
  const { configForPath, getLink, getPagePath } = usePathRouter();
  const pathConfig: Path = isString(path) ? configForPath(path) : path;
  const queryParams = isString(path) ? parseQueryParams(path) : null;
  const { isAuthCompleted, isAuthenticated } = useAuth();

  function parseUrl(pathname: string): string | UrlObject {
    if (!query && !queryParams) {
      return pathname;
    }

    return {
      pathname: /^([^?]+)(?:\?|$)/.exec(pathname)[1],
      query: query || queryParams,
    };
  }

  function getAsParam(as: string | UrlObject) {
    if (isString(as)) {
      const urlHash = hash ? `#${hash}` : '';
      return `${as}${urlHash}`;
    } else {
      return {
        ...as,
        hash,
      };
    }
  }

  const toLink = (href: string | UrlObject, as?: string | UrlObject) => {
    if (!href) {
      return null;
    }

    const asParam = getAsParam(as);

    return (
      <Link href={href} as={asParam} {...props}>
        {children}
      </Link>
    );
  };

  if (pathConfig) {
    const { href, as } = getLink(pathConfig, isAuthCompleted && isAuthenticated);
    return toLink(parseUrl(href), parseUrl(as));
  }

  if (isString(path)) {
    const asPath = getPagePath(path, isAuthCompleted && isAuthenticated);
    return toLink(parseUrl(asPath), parseUrl(path));
  } else {
    return toLink(parseUrl((path as unknown) as string));
  }
};
