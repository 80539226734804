import { Product } from './product';

export interface Voucher {
  code: string;
  discount: number;
  discountFraction: number;
  discountType: VoucherDiscountType;
  expiry: number;
  product: Product;
  productPaymentId: number;
  startDate: number;
  voucherType: VoucherType;
  usages: number;
  pool: string;
}

export enum VoucherDiscountType {
  Free = 'FREE',
  Fraction = 'FRACTION',
  Amount = 'AMOUNT',
}

export enum VoucherType {
  General = 'GENERAL',
  Single = 'SINGLE',
}

export enum ErrorCodes {
  VoucherNotFound = 'VOUCHER_NOT_FOUND',
  InvalidVoucher = 'INVALID_VOUCHER',
  VoucherExpired = 'EXPIRED',
  VoucherNotYetValid = 'NOT_STARTED',
  VoucherUsed = 'VOUCHER_USED',
  MaxRequestsReached = 'MAX_REQUESTS_REACHED_FOR_USER',
  ProductDisabled = 'PRODUCT_DISABLED',
  ProductPaymentNotFound = 'PRODUCT_PAYMENT_NOT_FOUND',
  DiscountNotSupported = 'DISCOUNT_NOT_SUPPORTED',
  UnknownError = 'UNKNOWN',
}
