import { Paths, PathType } from '../models/paths';
import { Tree } from '../models/tree';
import omit from 'lodash/omit';
import { fetchJSON, withCache } from './api';
import { getMotherbirdApiUrl } from './conf';

export const TREE_CACHE_KEY = 'tree';
export const PATHS_CACHE_KEY = 'paths';

export async function fetchPaths(cache = true): Promise<Paths> {
  try {
    const task = () =>
      fetchJSON<Paths>(`${getMotherbirdApiUrl()}/v3/static/svod/web/paths`, { throwErrors: false }).then(cleanPaths);

    return await (cache ? withCache(task, PATHS_CACHE_KEY) : task());
  } catch (e) {
    console.error(e);

    return null;
  }
}

export async function fetchTree(cache = true): Promise<Tree> {
  const task = () =>
    fetchJSON<Tree>(`${getMotherbirdApiUrl()}/v3/static/svod/web/tree`, { throwErrors: false }).then(cleanTree);

  return await (cache ? withCache(task, TREE_CACHE_KEY) : task());
}

export function cleanPaths(paths: Paths): Paths {
  try {
    return paths
      .filter((path) => path.type !== PathType.Channels)
      .map((path) => omit(path, ['params', 'localDataUrl']));
  } catch (e) {
    return paths;
  }
}

export function cleanTree(tree: Tree): Tree {
  return tree.map((path) => {
    if (path.subs) {
      return {
        ...path,
        subs: path.subs.map((sub) => omit(sub, ['image'])),
      };
    }

    return path;
  });
}
