import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Asset } from '../models/asset';
import { hasNativePlayer, isAndroid, isIos, isSafari } from './browser';
import { loadAssetInChromecast, enqueueAssetInChromecast } from './chromecast';
import { LinkData, playerLink } from './links';
import { ViewingHistoryItem } from '../models/viewingHistoryItem';
import { isCategoryProgressLoaded, selectAssetProgress } from '../state/viewing-history/viewing-history.selectors';
import { selectChromecastSessionAvailability } from '../state/player/player.selectors';
import { useRouter } from 'next/router';
import { loginModalOpen } from '../state/user/user.actions';
import { selectGeoBlockStatus } from '../state/user/user.selectors';
import { useAuth } from './auth';
import { canUserAccess, canUserPlay } from './assets';
import { useOrders } from './orders';
import { Category } from 'models/category';
import { useNativeLinkModal } from './player-native';

interface UseAssetPlayResponse {
  play: (returnPath?: string, replace?: boolean) => boolean; // Returns true if opened in Chromecast
  loginAndPlay: (returnPath?: string) => void;
}

const startFakePlayer = () => {
  // This is a dirty little hack to make Safari allow autoplay with sound on.
  const fakePlayer = document.getElementsByClassName('dummy-video')[0] as HTMLVideoElement;
  if (fakePlayer) {
    fakePlayer.play();
  }
};

const createPlayerLink = (asset: Asset, returnPath?: string): LinkData => {
  const linkData = playerLink(asset?.id);

  if (linkData && returnPath) {
    linkData.as = `${linkData.as}?r=${encodeURIComponent(returnPath)}`;
  }

  return linkData;
};

export function useAssetPlay(asset: Asset): UseAssetPlayResponse {
  const router = useRouter();
  const dispatch = useDispatch();
  const { openNativeLinkModal } = useNativeLinkModal();

  const [chromecastOpts, setChromecastOpts] = useState({});
  const chromecastSession = useSelector(selectChromecastSessionAvailability);

  const assetProgress: ViewingHistoryItem = useSelector((state) =>
    asset ? selectAssetProgress(state, { assetId: asset.id }) : null
  );
  const catProgressLoaded: boolean = useSelector((state) =>
    asset
      ? isCategoryProgressLoaded(state, {
          categoryId: asset.categoryId,
        })
      : false
  );

  useEffect(() => {
    if (assetProgress && assetProgress.progress && assetProgress.progress.position && !assetProgress.notStarted) {
      setChromecastOpts({ position: assetProgress.progress.position });
    }
  }, [assetProgress, catProgressLoaded]);

  const play = useCallback(
    (returnPath?: string, replace?: boolean) => {
      if (isAndroid() || isIos()) {
        openNativeLinkModal(asset);
        return false;
      }

      if (chromecastSession) {
        if (asset.live) {
          enqueueAssetInChromecast(asset);
        } else {
          loadAssetInChromecast(asset, chromecastOpts);
        }
        return true;
      } else {
        if (isSafari()) {
          startFakePlayer();
        }

        const { href, as } = createPlayerLink(asset, returnPath);
        router[replace ? 'replace' : 'push'](href, as);
      }

      return false;
    },
    [asset, chromecastSession, chromecastOpts]
  );

  const loginAndPlay = useCallback(
    (returnPath?: string) => {
      dispatch(
        loginModalOpen({
          redirectOnLogin: hasNativePlayer() ? null : createPlayerLink(asset, returnPath),
        })
      );
    },
    [asset]
  );

  return { play, loginAndPlay };
}

/**
 * Checks if user has rights to play an asset. Returns false if geoblocked.
 *
 * @param asset
 */
export function useAssetRights(asset: Asset): { isAccessible: boolean } {
  const { user } = useAuth();
  const { activeOrders } = useOrders();

  const [isAccessible, setAccessible] = useState<boolean>(true);
  const isGeoblocked = useSelector(selectGeoBlockStatus);

  useEffect(() => {
    setAccessible(canUserPlay(asset, user, isGeoblocked, activeOrders));
  }, [asset, user, isGeoblocked, activeOrders]);

  return { isAccessible };
}

/**
 * Checks if user has rights to play assets in a category. Returns false if geoblocked.
 *
 * @param category
 */
export function useCategoryRights(category: Category): { isAccessible: boolean } {
  const { user } = useAuth();
  const { activeOrders } = useOrders();

  const [isAccessible, setAccessible] = useState<boolean>(true);
  const isGeoblocked = useSelector(selectGeoBlockStatus);

  useEffect(() => {
    setAccessible(!isGeoblocked && canUserAccess(category, user, activeOrders));
  }, [category, user, isGeoblocked, activeOrders]);

  return { isAccessible };
}
