import { ContentWithImages } from './image';
import { ContentTag } from './content_tag';
import { AccessProductGroup } from './product';
import { GenrePath } from './genre';
import { Label } from './label';
import { ContentWithRelatedVideos } from './related-videos';

export enum AssetType {
  Clip = 'clip',
  Episode = 'episode',
  Movie = 'movie',
  Sport = 'sport',
  Channel = 'channel',
  Trailer = 'trailer',
}

export type Asset = ContentWithRelatedVideos &
  ContentWithImages & {
    id: number;
    categoryId: number;
    mainCategoryId?: number;
    mainCategoryTitle?: string;
    imdbId?: string;
    imdbRating?: number;

    // Titles and descriptions
    title: string;
    subtitle?: string;
    description?: string;
    parentEventTitle: string;
    seoMetaPageTitle?: string;
    seoMetaDescription?: string;

    type: AssetType;
    productionCountries: string;
    productionYear: number;
    season: number;
    skippableRegions?: SkippableRegion[];
    isChildren: boolean;
    actors: string;
    director: string;
    productGroups: AccessProductGroup[];
    genrePaths?: GenrePath[];
    labels?: Label[];
    parentalRating: string;
    parentalReasons: string[];
    episode: number;
    live: boolean;
    hdId?: number;
    uhdId?: number;
    likes?: number;
    contentPackage?: string;

    // Languages
    audioLanguage: string;
    audioLanguages: string[];
    subtitleLanguages: string[];

    // Times and durations
    duration: number;
    humanDuration: string;
    liveBroadcastTime: string;
    epgLiveBroadcastTime: string;
    epgLiveBroadcastEndTime?: string;
    expireDate: string;

    // URLs
    epgUrl?: string;
    playbackUrl: string;

    // Content tags
    contentTags?: ContentTag[];

    // Player related
    videoTagId: string;
  };

export interface SkippableRegion {
  from: number;
  to: number;
  seekPosition: number;
  title: string;
}
