import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import reject from 'lodash/reject';
import union from 'lodash/union';

import { Asset } from '../models/asset';
import { Category, CategorySubType, CategoryType } from '../models/category';
import { getMotherbirdApiUrl } from './conf';
import { buildURL, fetchJSON } from './api';
import { isLive, isUpcoming } from './assets';
import { assertNumericId } from './utils';
import { isFuture } from 'date-fns';

export async function fetchCategoryById(categoryId: string | number): Promise<Category> {
  assertNumericId(categoryId);

  const url = new URL(`${getMotherbirdApiUrl()}/v3/svod/web/category/${categoryId}`);
  return fetchJSON<Category>(url.toString(), { throwErrors: true });
}

export interface FetchCategoryAssetsOpts {
  sort?: 'newestFirst';
  size?: number;
  includeUpcoming?: boolean;
  hvodOnly?: boolean;
  vipOnly?: boolean;
}

export async function fetchCategoryAssets(
  categoryId: string | number,
  { sort, size, includeUpcoming, hvodOnly, vipOnly }: FetchCategoryAssetsOpts = {}
): Promise<Asset[]> {
  try {
    const params: Record<string, any> = { size: 250 };

    if (sort) {
      params.sort = sort;
    }

    if (size) {
      params.size = size;
    }

    if (!isNil(includeUpcoming)) {
      params.includeUpcoming = includeUpcoming;
    }

    if (hvodOnly) {
      params.hvodOnly = 'true';
    }

    if (vipOnly) {
      params.vipOnly = 'true';
    }

    const url = buildURL(`${getMotherbirdApiUrl()}/v3/svod/web/category/${categoryId}/assets`, params);

    const resp = await fetchJSON<{ result: Asset[] }>(url);

    return resp.result;
  } catch (e) {
    console.error('Error fetching category assets: ', e);
  }
}

export const defaultSeason = (category: Category): Category => {
  if (!category.groups) {
    return category;
  }

  const groupsWithPriority: Category[] = filter(category.groups, (group) => {
    return !!group.priority;
  });

  if (groupsWithPriority.length === 0) {
    return head(category.groups);
  } else {
    const groups: Category[] = orderBy(groupsWithPriority, 'priority', 'desc');
    return head(groups);
  }
};

export const resolveDefaultSeason = (
  seriesCategory: Category,
  asset: Asset | undefined | null,
  forcedAssetProvided: boolean = false,
  forcedSeasonId: number = null
): Category => {
  let season: Category;

  const { groups } = seriesCategory;
  const hasSeasons: boolean = groups && groups.length > 0;

  if (!hasSeasons) {
    return seriesCategory;
  }

  const hasStartedAssets: boolean = asset && !!asset.categoryId;

  // Continuous (forced or no forced asset): always show latest season
  if (
    !forcedSeasonId && // Except when navigating the seasons without javascript and forced season is selected
    isCategoryContinuous(seriesCategory) &&
    seriesCategory.type !== CategoryType.Sport
  ) {
    season = defaultSeason(seriesCategory);
  }

  if (!season) {
    // Normal series (forced asset): season of forced asset
    const nonContinuousOrSportForcedAsset =
      forcedAssetProvided && (!isCategoryContinuous(seriesCategory) || seriesCategory.type === CategoryType.Sport);

    // Normal series (no forced asset): season of last viewed asset
    const nonContinuousViewingHistoryAsset =
      !forcedAssetProvided && !isCategoryContinuous(seriesCategory) && hasStartedAssets;

    if (nonContinuousOrSportForcedAsset || nonContinuousViewingHistoryAsset) {
      season = find(groups, { id: Number(asset?.categoryId) });
    }
  }

  // Forced season provided
  if (!season && forcedSeasonId) {
    season = find(groups, { id: forcedSeasonId });
  }

  // Not forced & no started assets
  if (!season && !forcedAssetProvided && !hasStartedAssets) {
    season = defaultSeason(seriesCategory);
  }

  if (!season) {
    season = seriesCategory;
  }

  return season;
};

export const isCategoryContinuous = (category: Category, fallbackCategory?: Category): boolean => {
  if (!category || !category.subType) {
    if (fallbackCategory) {
      return isCategoryContinuous(fallbackCategory);
    }

    return false;
  }

  return get(category, 'subType') === CategorySubType.Continuous;
};

export const sortSeasons = (categories) => {
  const seasonsWithNumbers = filter(categories, (category) => Boolean(category?.season));
  const seasonsWithoutNumbers = reject(categories, (category) => {
    return category.season && category.season !== null;
  });

  if (seasonsWithNumbers.length === categories.length) {
    // All seasons have season number information, order using it
    return orderBy(categories, 'season', 'asc');
  } else {
    // Order first seasons with season number and add other seasons
    return union(orderBy(seasonsWithNumbers, 'season', 'asc'), seasonsWithoutNumbers);
  }
};

export interface FetchSeasonAssetsOpts {
  sort?: 'newestFirst' | null;
  hvodOnly?: boolean;
  vipOnly?: boolean;
  cacheEnabled?: boolean;
}

export const ASSETS_CACHE = new Map<string, Asset[]>();

export const fetchSeasonAssets = async (
  seasonId: number | string,
  { sort, hvodOnly, vipOnly, cacheEnabled = true }: FetchSeasonAssetsOpts = {}
): Promise<Asset[]> => {
  const key = `${seasonId},${sort},${hvodOnly},${vipOnly}`;

  if (cacheEnabled && ASSETS_CACHE.has(key)) {
    console.log('[assets] cache hit', key);
    return ASSETS_CACHE.get(key);
  }

  if (cacheEnabled) console.log('[assets] cache miss', key);

  try {
    const assets = await fetchCategoryAssets(seasonId, { includeUpcoming: true, sort, hvodOnly, vipOnly });

    const result = sort === 'newestFirst' ? assets.slice().reverse() : assets;

    if (cacheEnabled) {
      ASSETS_CACHE.set(key, result);
    }

    return result;
  } catch (e) {
    console.error('Error fetching season assets: ', e);
  }
};

export const getSelectedSportAsset = (seasonAssets: Asset[], linkedAsset?: Asset): Asset => {
  /*
  - if live, first live item
  - if no live, first upcoming item
  - else last item
  */

  if (!seasonAssets || !seasonAssets.length) {
    return;
  }

  let relevantAsset;

  // Linked asset
  if (linkedAsset) {
    relevantAsset = find(seasonAssets, { id: linkedAsset.id });
  }

  // First live item
  if (!relevantAsset) {
    relevantAsset = find(seasonAssets, (asset: Asset) => isLive(asset));
  }

  // No live items, first upcoming item
  if (!relevantAsset) {
    relevantAsset = find(seasonAssets, (asset: Asset) => isUpcoming(asset));
  }

  // No upcoming items
  if (!relevantAsset) {
    relevantAsset = last(seasonAssets);
  }

  return relevantAsset;
};

export function hasOnlyUpcomingEpisodes(category: Category): boolean {
  let onlyUpcomingEpisodes = false;

  if (category && category.nextUpcomingAssetLiveBroadcastTime) {
    const nextEpisodeUp = new Date(category.nextUpcomingAssetLiveBroadcastTime);
    onlyUpcomingEpisodes = isFuture(nextEpisodeUp);
  }

  return onlyUpcomingEpisodes;
}

/**
 * Show item title with content package name (Hayu, BritBox).
 */
export function formatTitleWithPackage(item: Asset | Category): string {
  if (!item) {
    return null;
  }

  if (item.contentPackage) {
    return `${item.title} (${item.contentPackage})`;
  }

  return item.title;
}
