import { useDispatch } from 'react-redux';
import { MetatagValues } from '../models/metatag';
import { Category, CategoryType } from '../models/category';
import { Asset } from '../models/asset';
import { getImage } from './images';
import { ImageOrientation, ImageSize } from '../models/image';
import { getOrigin } from './api';
import { categoryLink, episodeLink, LinkData, movieLink } from './links';
import has from 'lodash/has';
import { metatagReset, metatagUpdate, titleReset, titleUpdate } from '../state/app/app.actions';
import { useCallback, useMemo } from 'react';

export const defaultMetatags: MetatagValues = {
  description: {
    name:
      'Kaikki C Moren uutuuselokuvat ja sarjat netissä. Katso mielin määrin, missä ja milloin haluat. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  'og:site_name': { property: 'C More' },
  'og:title': { property: 'C More | Koukussa draamaan' },
  'og:type': { property: 'website' },
  'og:image': { property: 'https://www.katsomo.fi/multimedia/cmore/images/cmore-logo-big.png' },
  'og:image:width': { property: `480` },
  'og:image:height': { property: `480` },
};

export const pageSeoValues: Record<string, { title: string; description: string }> = {
  '/elokuvat': {
    title: 'Elokuvat | C More',
    description:
      'Joka viikko uusia ensi-iltoja niin Hollywoodista kuin lähempääkin. Tutustu koko C Moren laajaan leffavalikoimaan ja katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/kaikki': {
    title: 'Elokuvat | C More',
    description:
      'Joka viikko uusia ensi-iltoja niin Hollywoodista kuin lähempääkin. Tutustu koko C Moren laajaan leffavalikoimaan ja katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/toiminta': {
    title: 'Toiminta | Elokuvat | C More',
    description:
      'Näe runsaasti räjähtäviä toimintasuosikkeja ja tutustu koko laajaan leffavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/komedia': {
    title: 'Komedia | Elokuvat | C More',
    description:
      'Näe runsaasti hulvattomia komediasuosikkeja ja tutustu koko laajaan leffavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/kotimaiset': {
    title: 'Kotimaiset | Elokuvat | C More',
    description:
      'Näe suomalaiset elokuvasuosikit ja uudemmat ensi-illat ja tutustu koko laajaan leffavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/dokumentit': {
    title: 'Dokumentit | Elokuvat | C More',
    description:
      'Näe paljon mielenkiintoisia dokumenttielokuvia ja tutustu koko laajaan leffavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/draama': {
    title: 'Draama | Elokuvat | C More',
    description:
      'Näe sykähdyttävimmät draamat uutuuksista klassikoihin ja tutustu koko laajaan leffavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/kauhu': {
    title: 'Kauhu | Elokuvat | C More',
    description:
      'Näe kutkuttavia kauhuelokuvia uutuuksista klassikoihin ja tutustu koko laajaan leffavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/trilleri': {
    title: 'Trilleri | Elokuvat | C More',
    description:
      'Näe runsaasti koukuttavia trillereitä ja tutustu koko laajaan leffavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/scifi': {
    title: 'Scifi | Elokuvat | C More',
    description:
      'Näe runsaasti näyttäviä scifi-suosikkeja ja tutustu koko laajaan leffavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/pohjoismaiset': {
    title: 'Pohjoismaiset | Elokuvat | C More',
    description:
      'Näe pohjoismaiset elokuvasuosikit sekä uudemmat ensi-illat Ruotsista ja muualta C Moressa. Tutustu koko laajaan leffavalikoimaan. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/romanttinen': {
    title: 'Romanttinen | Elokuvat | C More',
    description:
      'Näe tunteita herättävät romanttiset suosikkielokuvat ja tutustu koko laajaan leffavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/elokuvat/lasten': {
    title: 'Lasten | Elokuvat | C More',
    description:
      'Näe paljon lastenelokuvia ja koko perheen suosikkeja ja tutustu koko laajaan leffavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/sarjat': {
    title: 'Sarjat | C More',
    description:
      'Löydä uusi sarjasuosikkisi C Moresta. Yllin kyllin kotimaista ja pohjoismaista draamaa sekä kansainvälisiä laatusarjoja. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/sarjat/kaikki': {
    title: 'Sarjat | C More',
    description:
      'Löydä uusi sarjasuosikkisi C Moresta. Yllin kyllin kotimaista ja pohjoismaista draamaa sekä kansainvälisiä laatusarjoja. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/sarjat/draama': {
    title: 'Draama | Sarjat | C More',
    description:
      'Löydä uusi koukuttava draamasarjasuosikkisi ja tutustu koko laajaan sarjavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/sarjat/jannitys': {
    title: 'Jännitys | Sarjat | C More',
    description:
      'Löydä uusi kutkuttava jännityssarjasuosikkisi ja tutustu koko laajaan sarjavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/sarjat/komedia': {
    title: 'Komedia | Sarjat | C More',
    description:
      'Löydä uusi hulvaton komediasarjasuosikkisi ja tutustu koko laajaan sarjavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/sarjat/kotimaiset': {
    title: 'Kotimaiset | Sarjat | C More',
    description:
      'Laajin kattaus kotimaisia draamasarjoja C Moressa. Löydä uusi kotimainen suosikkisi ja tutustu koko laajaan sarjavalikoimaan. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/sarjat/nordic-noir': {
    title: 'Nordic noir | Sarjat | C More',
    description:
      'Laaja kattaus koukuttavia pohjoismaisia rikossarjoja C Moressa. Löydä uusi nordic noir -suosikkisi ja tutustu koko laajaan sarjavalikoimaan. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/sarjat/toiminta': {
    title: 'Toiminta | Sarjat | C More',
    description:
      'Löydä uusi räjähtävä toimintasarjasuosikkisi ja tutustu koko laajaan sarjavalikoimaan C Moressa. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/sarjat/alkuperaissarja': {
    title: 'Alkuperäissarjat | Sarjat | C More',
    description:
      'Laaja kattaus kotimaisia ja pohjoismaisia C More -alkuperäissarjoja rikosjännäreistä komediaan. Löydä uusi sarjasuosikkisi. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/sarjat/hayu': {
    title: 'Hayu | Sarjat | C More',
    description:
      'Laaja kattaus tämän hetken kuuminta realitya C Moressa. Löydä uusi tosi-tv-suosikkisi ja seuraa lempisarjaasi jenkkitahdissa. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/tv-ohjelmat': {
    title: 'TV-ohjelmat | C More',
    description:
      'Seuraa MTV3:n, Subin ja AVAn suosikkiohjelmiasi C Moressa. Katso ilman mainoksia ja näe jaksoja jo ennakkoon. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/tv-ohjelmat/kaikki': {
    title: 'TV-ohjelmat | C More',
    description:
      'Seuraa MTV3:n, Subin ja AVAn suosikkiohjelmiasi C Moressa. Katso ilman mainoksia ja näe jaksoja jo ennakkoon. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/tv-ohjelmat/draama': {
    title: 'Draama | Sarjat | C More',
    description:
      'Seuraa MTV3:n, Subin ja AVAn suosikkiohjelmiasi C Moressa. Katso ilman mainoksia ja näe jaksoja jo ennakkoon. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/tv-ohjelmat/reality-ja-lifestyle': {
    title: 'Reality ja lifestyle | Sarjat | C More',
    description:
      'Seuraa MTV3:n, Subin ja AVAn suosikkiohjelmiasi C Moressa. Katso ilman mainoksia ja näe jaksoja jo ennakkoon. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/tv-ohjelmat/viihde-ja-komedia': {
    title: 'Viihde ja komedia | Sarjat | C More',
    description:
      'Seuraa MTV3:n, Subin ja AVAn suosikkiohjelmiasi C Moressa. Katso ilman mainoksia ja näe jaksoja jo ennakkoon. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/tv-ohjelmat/kotimaiset': {
    title: 'Kotimaiset | Sarjat | C More',
    description:
      'Seuraa MTV3:n, Subin ja AVAn suosikkiohjelmiasi C Moressa. Katso ilman mainoksia ja näe jaksoja jo ennakkoon. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/tv-ohjelmat/ulkomaiset': {
    title: 'Ulkomaiset | Sarjat | C More',
    description:
      'Seuraa MTV3:n, Subin ja AVAn suosikkiohjelmiasi C Moressa. Katso ilman mainoksia ja näe jaksoja jo ennakkoon. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/juniori': {
    title: 'Lastenohjelmat | C More',
    description:
      'Yllin kyllin katseltavaa perheen pienimmille Muumilaakson tarinoista koko perheen animaatioelokuviin. Katso missä ja milloin vain. Aloita ilmainen kokeilu – ei sitoutumista.',
  },
  '/sport': {
    title: 'Sport | C More',
    description:
      'Koe jännittävimmät urheiluhetket C Moressa! Jääkiekon MM-kilpailut, Liiga, Mestarien liiga, La Liga, Serie A, ja MM-ralli, sekä paljon muuta urheilua. Tutustu ja tilaa alk. 24,95 €/kk.',
  },
  '/kanavat': {
    title: 'Kanavat | C More',
    description:
      'Katso MTV3:n, Subin ja AVAn TV-kanavia tai C Moren maksukanavia suorana C Moressa. Katso haluamallasi laitteella, missä vain – tutustu ja tilaa.',
  },
};

interface UseSEOUpdate {
  update: (title: string, description?: string) => void;
  reset: () => void;
}

export function useSEOUpdate(): UseSEOUpdate {
  const dispatch = useDispatch();

  const update = useCallback((title: string, description?: string) => {
    dispatch(titleUpdate(title));

    dispatch(
      metatagUpdate({
        ...(title ? { 'og:title': { property: title } } : {}),
        ...(description
          ? {
              description: { name: description },
              'og:description': { property: description },
            }
          : {}),
      })
    );
  }, []);

  const reset = useCallback(() => {
    dispatch(titleReset());
    dispatch(metatagReset());
  }, []);

  return useMemo(() => ({ update, reset }), [update, reset]);
}

export function getCategoryTitle(category: Category, forcedAsset?: Asset): string {
  if (forcedAsset) {
    return [
      category.title,
      forcedAsset.subtitle,
      forcedAsset.season && `${forcedAsset.season}. kausi`,
      category.type === CategoryType.Sport ? 'Urheilu' : 'Sarjat',
      'C More',
    ]
      .filter(Boolean)
      .join(' | ');
  } else if (category.seoMetaPageTitle) {
    return category.seoMetaPageTitle;
  }

  return [category.title, category.type === CategoryType.Sport ? 'Urheilu' : 'Sarjat', 'C More']
    .filter(Boolean)
    .join(' | ');
}

export function getSeasonTitle(category: Category, season: Category, forcedAsset?: Asset): string {
  if (!category || !season) {
    return null;
  }

  const seasonTitle = category.id !== season.id ? season.title : null;

  if (forcedAsset) {
    return [
      category.title,
      forcedAsset.subtitle,
      seasonTitle,
      category.type === CategoryType.Sport ? 'Urheilu' : 'Sarjat',
      'C More',
    ]
      .filter(Boolean)
      .join(' | ');
  } else if (category.seoMetaPageTitle) {
    return category.seoMetaPageTitle;
  }

  return [category.title, seasonTitle, category.type === CategoryType.Sport ? 'Urheilu' : 'Sarjat', 'C More']
    .filter(Boolean)
    .join(' | ');
}

export function getMetatagsForCategory(category: Category, forcedAsset?: Asset): MetatagValues {
  const isSport = category.type === CategoryType.Sport;

  const seriesDescription =
    category.seoMetaDescription ||
    `Katso ${category.title} helposti netissä vaikka heti. Ensimmäiset 7 päivää ovat ilmaisia - ei sitoutumista.`;
  const sportDescription = category.seoMetaDescription || category.description || category.title || '';

  const metaDescription = isSport ? sportDescription : seriesDescription;
  const tags: MetatagValues = {
    description: { name: metaDescription },
  };

  const title = getCategoryTitle(category, forcedAsset);

  if (forcedAsset) {
    const assetImage = getImage(forcedAsset, ImageOrientation.Landscape, ImageSize.Large);

    return {
      ...tags,
      'og:title': { property: title },
      'og:type': { property: 'video.episode' },
      'og:description': { property: forcedAsset.seoMetaDescription || forcedAsset.description },
      'og:url': { property: fullUrl(episodeLink(forcedAsset, category)) },
      ...(assetImage
        ? {
            'og:image': { property: assetImage.url },
            'og:image:width': { property: `${assetImage.width}` },
            'og:image:height': { property: `${assetImage.height}` },
          }
        : {}),
      'video:duration': { property: `${forcedAsset.duration}` },
    };
  }

  const categoryImage = getImage(category, ImageOrientation.Landscape, ImageSize.Large);

  return {
    ...tags,
    'og:description': { property: category.description },
    'og:title': { property: title },
    'og:type': { property: 'video.tv_show' },
    'og:url': { property: fullUrl(categoryLink(category)) },
    ...(categoryImage
      ? {
          'og:image': { property: categoryImage.url },
          'og:image:width': { property: `${categoryImage.width}` },
          'og:image:height': { property: `${categoryImage.height}` },
        }
      : {}),
  };
}

export function getMetatagsForAsset(asset: Asset): MetatagValues {
  const assetImage = getImage(asset, ImageOrientation.Landscape, ImageSize.Large);

  const title = asset.seoMetaPageTitle || `${asset.title} | Elokuvat | C More`;

  return {
    description: { name: asset.seoMetaDescription || asset.description },
    'og:title': { property: title },
    'og:type': { property: 'video.movie' },
    'og:description': { property: asset.seoMetaDescription || asset.description },
    'og:url': { property: fullUrl(movieLink(asset)) },
    ...(assetImage
      ? {
          'og:image': { property: assetImage.url },
          'og:image:width': { property: `${assetImage.width}` },
          'og:image:height': { property: `${assetImage.height}` },
        }
      : {}),
    'video:duration': { property: `${asset.duration}` },
  };
}

export function fullUrl(url: LinkData | string): string {
  const urlString: string = has(url, 'as') ? (url as LinkData).as : (url as string);

  if (urlString.startsWith('http:') || urlString.startsWith('https:')) {
    return urlString;
  }

  return `${getOrigin()}${urlString.startsWith('/') ? urlString : `/${urlString}`}`;
}
