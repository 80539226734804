import React, { FunctionComponent } from 'react';
import { GrayButton, ModalBody, ModalButtons, ModalTitle, useOwletModal } from 'owlet-ui';
import { NativePlayerLinks } from './OpenNativePlayer';
import { Asset } from '../../models/asset';

interface OpenNativePlayerModalProps {
  asset: Asset;
}

export const OpenNativePlayerModal: FunctionComponent<OpenNativePlayerModalProps> = ({ asset }) => {
  const { closeModal } = useOwletModal();

  return (
    <>
      <ModalTitle>Jatka sovelluksessa?</ModalTitle>
      <ModalBody>
        <NativePlayerLinks asset={asset} />
      </ModalBody>
      <ModalButtons>
        <GrayButton onClick={() => closeModal()}>Sulje</GrayButton>
      </ModalButtons>
    </>
  );
};
