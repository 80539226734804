import { fetchJSON } from './api';
import { getKatsomoServiceApiUrl } from './conf';
import { User } from '../models/user';
import get from 'lodash/get';
import find from 'lodash/find';
import { fetchUser } from './user';
import { AsyncState, useRetry } from 'owlet-ui/common';
import { useAuth } from './auth';
import { DependencyList, useCallback } from 'react';
import { replaceAllUserProperties } from '../state/user/user.actions';
import { useDispatch } from 'react-redux';

export function getUserProperty<T = any>(user: User, propertyName: string): T {
  if (!user || !user.properties || !user.properties.property) {
    return null;
  }

  return get(find(user.properties.property, { name: propertyName }), 'value', null);
}

export async function storeUserProperty(propertyName: string, value: any, authToken: string): Promise<any> {
  return fetchJSON(`${getKatsomoServiceApiUrl()}/user/properties`, {
    method: 'POST',
    body: JSON.stringify({
      properties: [
        {
          name: propertyName,
          value,
        },
      ],
    }),
    headers: {
      Authorization: authToken,
    },
  });
}

/**
 * Hook that polls the latest user and checks if it contains the given property. If the property is found and has
 * a value, it replaces all user properties in the local state with the server version.
 *
 * Optionally takes a predicate function to control when polling is started.
 *
 * @example
 * const [buttonClicked, setButtonClicked] = useState<boolean>(false);
 *
 * // Start polling when button has been clicked
 * const { value } = usePropertyValuePolling<string>('myProperty', () => buttonClicked)
 *
 * const save = useCallback(() => {
 *   setButtonClicked(true);
 *   // Save something into `myProperty`
 * }, [])
 *
 * useEffect(() => {
 *   if (value) {
 *     // Do something when property value has been saved (and retrieved from server)
 *   }
 * }, [value])
 *
 * return <button onClick={save}>Save property value</button>
 *
 * @param propertyName
 * @param predicate
 * @param deps
 * @param retryCount
 * @param delay
 */
export function usePropertyValuePolling<T = any>(
  propertyName: string,
  predicate: () => boolean = () => true,
  deps: DependencyList = [],
  retryCount = 10,
  delay = 800
): AsyncState<T> {
  const { authToken } = useAuth();
  const dispatch = useDispatch();

  const fn = useCallback(async () => {
    if (predicate()) {
      const latestUser = await fetchUser(authToken);

      const value = latestUser?.properties?.property?.find((prop) => prop?.name === propertyName)?.value;

      if (!value) {
        throw new Error(`Property ${propertyName} not found from properties`);
      }

      // Refresh user properties in state
      dispatch(replaceAllUserProperties(latestUser.properties.property));

      return value;
    }
  }, [propertyName, predicate, authToken]);

  return useRetry(fn, [propertyName, authToken, ...deps], retryCount, delay);
}
