import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import rgba from 'color-alpha';
import { BLACK, DARKESTPURPLE, FONT_REGULAR, IconCmore, ModalClose, OwletModalTheme, WHITE } from 'owlet-ui';
import { FunctionComponent, PropsWithChildren } from 'react';
import { breakpoints, greaterThan, lessThan } from 'owlet-ui/breakpoints';

const Overlay = styled.div`
  background: ${rgba(BLACK, 0.6)};
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 103;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: var(--navi-height) 0 3rem;

  ${greaterThan('small')`
    align-items: center;
  `}
`;

const ModalInnerWrap = styled.div`
  color: ${WHITE};
  font: ${FONT_REGULAR};
  background: ${DARKESTPURPLE};
  margin: 0 4vw;
  position: relative;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  outline-style: none; // Removes blue outline in Safari browsers

  ${greaterThan('small')`
    margin: auto 40px;
  `}

  ${greaterThan('medium')`
    width: calc(${breakpoints.medium}px - 40px);
  `}

  ${greaterThan('large')`
    width: calc(${breakpoints.large}px - 40px);
  `}
`;

const ModalContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 4vw 6vw;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(var(--vh) * 100 - var(--navi-height) - 3rem);

  ${lessThan('large')`
    padding: 8vw;
  `}
  ${lessThan('small')`
    padding: 3rem 1rem;
  `}

  ${greaterThan('xlarge')`
    padding: 80px;
  `}

  &:focus {
    outline: 0;
  }
`;

const LogoWrap = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => props.theme.horizontalMargin};
  z-index: 103;
  height: var(--navi-height);
  display: flex;
  align-items: center;
`;

const StyledIconCmore = styled(IconCmore)`
  display: block;
  fill: ${WHITE};
  width: 120px;
  filter: drop-shadow(0 0 8px #fff);

  ${greaterThan('large')`
    width: 140px;
  `}
`;

const ModalOverlay: FunctionComponent<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <Overlay {...props} data-test="ModalOverlay">
      {children}
      <LogoWrap>
        <StyledIconCmore />
      </LogoWrap>
    </Overlay>
  );
};

const ModalWrap: FunctionComponent<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <ModalInnerWrap {...props}>
      <ModalContent>{children}</ModalContent>
    </ModalInnerWrap>
  );
};

export const SvodModalTheme: OwletModalTheme = {
  overlayComponent: ModalOverlay,
  modalComponent: ModalWrap,
  closeButtonComponent: ModalClose,
};
