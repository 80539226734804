import { EventHandler, useEffect, useRef, useState } from 'react';
import { breakpoints, OwletBreakpoints } from '../breakpoints';
import { useSavedHandler } from './event-listener';
import { isBrowser } from '../../../src/services/utils';

interface UseMediaQueryResponse {
  mediaQuery: MediaQueryList | undefined;
}

function addMediaQueryListener(mediaQuery: MediaQueryList, handler: EventHandler<any>) {
  if (mediaQuery.addEventListener) {
    mediaQuery.addEventListener('change', handler);
  } else if (mediaQuery.addListener) {
    mediaQuery.addListener(handler);
  }
}

function removeMediaQueryListener(mediaQuery: MediaQueryList, handler: EventHandler<any>) {
  if (mediaQuery.removeEventListener) {
    mediaQuery.removeEventListener('change', handler);
  } else if (mediaQuery.removeListener) {
    mediaQuery.removeListener(handler);
  }
}

/**
 * Get MediaQueryList for a media query and optionally assign an event handler.
 *
 * @param query
 * @param handler
 */
export function useMediaQuery(query: string, handler?: EventHandler<any>): UseMediaQueryResponse {
  const { current: savedHandler } = useSavedHandler(handler);
  const { current: mediaQuery } = useRef<MediaQueryList>(isBrowser() ? window.matchMedia(query) : null);

  useEffect(() => {
    if (mediaQuery && savedHandler) {
      const eventListener = (event: Event) => savedHandler(event);

      addMediaQueryListener(mediaQuery, eventListener);

      return () => {
        removeMediaQueryListener(mediaQuery, eventListener);
      };
    } else {
      return () => {};
    }
  }, [mediaQuery, savedHandler]);

  return {
    mediaQuery,
  };
}

type UseBreakpointFunction = {
  (
    type: 'lessThan' | 'greaterThan',
    breakpoint: keyof OwletBreakpoints,
    handler?: EventHandler<any>
  ): UseMediaQueryResponse;
  (
    type: 'between',
    breakpoint: [keyof OwletBreakpoints, keyof OwletBreakpoints],
    handler?: EventHandler<any>
  ): UseMediaQueryResponse;
};

/**
 * Get MediaQueryList for our breakpoints and optionally assign an event handler.
 *
 * @param type
 * @param breakpoint
 * @param handler
 */
export const useBreakpoint: UseBreakpointFunction = (type, breakpoint, handler): UseMediaQueryResponse => {
  let query = null;

  if (type === 'between') {
    const [from, to] = breakpoint as [keyof OwletBreakpoints, keyof OwletBreakpoints];

    query = `(min-width: ${breakpoints[from] + 1}px) and (max-width: ${breakpoints[to]}px)`;
  } else {
    const singleBreakpoint = breakpoint as keyof OwletBreakpoints;

    if (type === 'lessThan') {
      query = `(max-width: ${breakpoints[singleBreakpoint]}px)`;
    } else if (type === 'greaterThan') {
      query = `(min-width: ${breakpoints[singleBreakpoint] + 1}px)`;
    }
  }

  return useMediaQuery(query, handler);
};

/**
 * Returns true if user prefers reduced motion in system settings, otherwise false.
 */
export function useReducedMotion(): boolean {
  const { mediaQuery } = useMediaQuery('(prefers-reduced-motion: reduce)', (evt: MediaQueryListEvent) => {
    setPrefersReducedMotion(evt.matches);
  });

  const [prefersReducedMotion, setPrefersReducedMotion] = useState<boolean>(mediaQuery?.matches || false);

  return prefersReducedMotion;
}
