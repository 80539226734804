import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import parseISO from 'date-fns/parseISO';

import { CalculatedProgress, RawViewingHistoryItem, ViewingHistoryItem } from '../models/viewingHistoryItem';
import { getVimondApiUrl } from './conf';
import { fetchRaw } from './api';

const VIEWING_HISTORY_TIMEOUT = 10000;

/**
 * Loads viewing history progresses for assets in given category.
 *
 * @param categoryId
 * @param authToken
 * @param timeout
 */
const loadCategoryProgresses = async (
  categoryId: string,
  authToken: string,
  timeout: number = VIEWING_HISTORY_TIMEOUT
) => {
  try {
    const response = await fetchRaw(`${getVimondApiUrl()}/viewinghistory/all/${categoryId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      timeout: timeout,
    });

    if (response.ok) {
      return await response.json();
    } else {
      console.error(`User viewing history load failed (code ${response.status}): `, response);
      return null;
    }
  } catch (e) {
    console.error('Error on loading user view history data.', e);
  }
};

/**
 * Loads viewing history progresses for an asset.
 *
 * @param assetId
 * @param authToken
 * @param timeout
 */
export const loadAssetProgress = async (
  assetId: string,
  authToken: string,
  timeout: number = VIEWING_HISTORY_TIMEOUT
) => {
  try {
    const response = await fetchRaw(`${getVimondApiUrl()}/viewinghistory/${assetId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
      timeout: timeout,
    });

    if (response.ok) {
      return await response.json();
    } else if (response.status === 404) {
      return {};
    } else {
      console.error(`User viewing history load failed (code ${response.status}): `, response);
      return null;
    }
  } catch (e) {
    console.error('Error on loading user view history data.', e);
  }
};

/**
 * Loads latest asset watched from each of the given categories.
 *
 * @param categoryIds
 * @param authToken
 * @param timeout
 */
async function loadLatestAssetProgresses(
  categoryIds: number[],
  authToken: string,
  timeout: number = VIEWING_HISTORY_TIMEOUT
): Promise<RawViewingHistoryItem[] | false> {
  try {
    const categoryFetches = map(categoryIds, async (categoryId) => {
      const f = await fetchRaw(`${getVimondApiUrl()}/viewinghistory/all/${categoryId}?video-limit=1`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `${authToken}`,
          'Content-Type': 'application/json',
        },
        timeout: timeout,
      });

      return f.json();
    });

    return flatMap(await Promise.all(categoryFetches));
  } catch (err) {
    console.error('Error on loading most recently watched asset in categories.');
    return false;
  }
}

/**
 * Returns the most recently watched asset from given categories.
 *
 * @param history
 * @param categoryIds
 */
const getLatestAsset = (history: ViewingHistoryItem[], categoryIds: number[]): ViewingHistoryItem => {
  const filteredHistory = filter(history, (historyItem) => {
    const categoryId = Number(historyItem.categoryId);
    return categoryIds.indexOf(categoryId) !== -1;
  });

  const sortedHistoryItems = sortBy(filteredHistory, (item) => {
    return getAssetLastPlayedTime(item);
  });

  return last(sortedHistoryItems);
};

/**
 * Returns viewing history item time that should be used in comparisons. For completed items,
 * use 'completed' property, for in-progress items, use 'progress.updateTime'.
 *
 * @param viewingHistoryItem
 */
const getAssetLastPlayedTime = (viewingHistoryItem: ViewingHistoryItem): number => {
  if (viewingHistoryItem.progress && viewingHistoryItem.progress.updateTime) {
    return parseISO(viewingHistoryItem.progress.updateTime).getTime();
  } else if (viewingHistoryItem.completed) {
    return parseISO(viewingHistoryItem.completed).getTime();
  }
};

const historyToProgress = (history): CalculatedProgress => {
  // if history has both progress and completed, then the progress will be later than the completed
  if (history.progress) {
    return {
      percentage: Math.min(100, (history.progress.position / history.totalTime) * 100),
      position: history.progress.position / 1000,
      updateTime: history.progress.updateTime,
    };
  } else if (history.completed) {
    return {
      percentage: 100,
    };
  }
  return undefined;
};

/**
 * Turns raw UVH item coming from the Vimond API into more useful object that get stored in to state.
 *
 * @param historyItem
 */
export const transformProgress = (historyItem: RawViewingHistoryItem): ViewingHistoryItem => {
  return {
    assetId: historyItem.assetId,
    categoryId: historyItem.category.id,
    progress: historyToProgress(historyItem),
    completed: historyItem.completed ? historyItem.completed.updateTime : false,
    totalTime: historyItem.totalTime,
  };
};

export { loadCategoryProgresses, loadLatestAssetProgresses, getAssetLastPlayedTime, getLatestAsset };
