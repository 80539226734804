import { css } from 'styled-components';

export function clampLines(lines: number) {
  return css`
    @supports (-webkit-line-clamp: 1) {
      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
    }
    overflow: hidden;
  `;
}

export function hideScrollbar(direction: 'horizontal' | 'vertical' | 'both') {
  return css`
    ${direction !== 'vertical' &&
    css`
      overflow-x: -moz-scrollbars-none;
    `};
    ${direction !== 'horizontal' &&
    css`
      overflow-y: -moz-scrollbars-none;
    `};
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  `;
}

export function ratio(width: number, height: number): string {
  return `${(100 / width) * height}%`;
}
