import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { NaviIconCmore } from './styles';
import { ProfileType } from './naviUtils';

interface NaviLogoProps {
  isAuthenticated: boolean;
  profileType: ProfileType;
}

const IconLink = styled.a`
  padding: 0.5rem;
  margin-left: -0.5rem;
`;

export const NaviLogo: FunctionComponent<NaviLogoProps> = React.memo(
  ({ isAuthenticated, profileType }: NaviLogoProps) => {
    const content = (
      <IconLink data-test="Navi__Logo" aria-label="Etusivulle">
        <NaviIconCmore />
      </IconLink>
    );

    return (
      <>
        {isAuthenticated && (
          <>
            {profileType !== ProfileType.Junior && (
              <Link href="/etusivu" as="/etusivu" passHref>
                {content}
              </Link>
            )}
            {profileType === ProfileType.Junior && (
              <Link href="/juniori" as="/juniori" passHref>
                {content}
              </Link>
            )}
          </>
        )}
        {!isAuthenticated && (
          <Link href="/" as="/" passHref>
            {content}
          </Link>
        )}
      </>
    );
  }
);

NaviLogo.displayName = 'NaviLogo';
