import React from 'react';
import { SVGProps } from '../utils';

const SvgLive = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510.236 510.236" {...props}>
    <path d="M405.104 427.576H105.129c-29.787 0-54.02-24.236-54.02-54.027V145.594c0-29.791 24.232-54.029 54.02-54.029h299.975c29.779 0 54.006 24.238 54.006 54.029v227.955c-.001 29.791-24.227 54.027-54.006 54.027zM105.129 117.965c-15.229 0-27.619 12.395-27.619 27.629v227.955c0 15.234 12.391 27.627 27.619 27.627h299.975c15.223 0 27.605-12.393 27.605-27.627V145.594c0-15.234-12.383-27.629-27.605-27.629H105.129z" />
    <path d="M159.389 316.346h-36.266c-6.975 0-13.051-6.076-13.051-13.051v-83.703c0-8.773 6.525-13.049 13.051-13.049s13.051 4.275 13.051 13.049v70.877h23.215c8.775 0 13.051 6.525 13.051 13.051-.001 6.3-4.276 12.826-13.051 12.826zm44.261 1.125c-6.525 0-13.051-4.049-13.051-12.375v-85.953c0-8.326 6.525-12.6 13.051-12.6s13.051 4.273 13.051 12.6v85.953c0 8.326-6.525 12.375-13.051 12.375zm112.688-94.278l-28.35 86.627c-1.801 5.625-7.875 7.65-12.15 7.65h-1.125c-4.275 0-10.125-2.025-11.926-7.65l-28.568-86.627c-.449-1.576-.674-3.15-.674-4.5 0-7.426 6.299-12.15 13.275-12.15 4.725 0 9.449 2.699 11.471 8.775l17.096 51.75 16.877-51.75c2.023-6.076 6.525-8.775 11.699-8.775 6.525 0 13.051 4.725 13.051 12.15 0 1.35-.225 2.924-.676 4.5zm72.236 93.153H344.97c-6.977 0-13.051-6.076-13.051-13.051v-83.701c0-6.977 6.074-13.051 13.051-13.051h43.604c8.775 0 13.051 6.525 13.051 12.824 0 6.525-4.275 13.051-13.051 13.051h-30.553v15.82h22.062c8.775 0 13.051 6.525 13.051 13.051 0 6.301-4.275 12.826-13.051 12.826h-22.062v16.354h30.553c8.775 0 13.051 6.525 13.051 13.051 0 6.3-4.275 12.826-13.051 12.826z" />
  </svg>
);

export default SvgLive;
