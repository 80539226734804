import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AppState } from './app.reducers';

export const selectAppState = () => (state: any) => state.app;

export const selectPaths = createSelector(selectAppState(), (appState: AppState) => appState.paths);

export const selectTree = createSelector(selectAppState(), (appState: AppState) => appState.tree);

export const selectLastRoute = createSelector(selectAppState(), (appState: AppState) => appState.lastRoute);

export const selectTitle = createSelector(selectAppState(), (appState: AppState) => appState.title);

export const selectMetatags = createSelector(selectAppState(), (appState: AppState) => appState.metatags);

export const selectCanonicalUrl = createSelector(selectAppState(), (appState: AppState) => appState.canonicalUrl);

export const selectChatStatus = createSelector(selectAppState(), (appState: AppState) => appState.chatStatus);

export const selectIsMobileLayout = createSelector(selectAppState(), (appState: AppState) => appState.isMobileLayout);

export const selectIsActive = createSelector(selectAppState(), (appState: AppState) => appState.isActive);
export const useIsActive = () => useSelector(selectIsActive);

export const selectInvisibilityStart = createSelector(
  selectAppState(),
  (appState: AppState) => appState.invisibilityStart
);
export const useInvisibilityStart = () => useSelector(selectInvisibilityStart, isEqual);
