import React, { FunctionComponent, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { BLACK, IconLock, WHITE90 } from 'owlet-ui';
import { Asset } from '../../../models/asset';
import { Category } from '../../../models/category';
import { canUserAccess } from '../../../services/assets';
import { useAuth } from '../../../services/auth';
import { useOrders } from '../../../services/orders';
import { TextLabel } from '../../labels/StripeLabel';
import { CuratedContentContext } from '../../../services/curated';

interface ProductLockLabelProps {
  item: Asset | Category;
  className?: string;
}

const StyledTextLabel = styled(TextLabel)`
  background: ${WHITE90};
  color: ${BLACK};
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  padding: 0.225rem 0.5rem;
`;

const Lock = styled(IconLock)`
  display: block;
  width: 0.9rem;
  fill: ${BLACK};
`;

export const ProductLockLabel: FunctionComponent<ProductLockLabelProps> = ({ item, className, children }) => {
  const { user } = useAuth();
  const { activeOrders } = useOrders();
  const { interactive } = useContext(CuratedContentContext);

  const canAccess = useMemo<boolean>(() => {
    if (!item || !user || !activeOrders || !interactive) {
      return true;
    }

    return canUserAccess(item, user, activeOrders);
  }, [item, user, activeOrders, interactive]);

  if (canAccess || !interactive) {
    return <>{children}</>;
  }

  return (
    <StyledTextLabel className={className}>
      <Lock />
    </StyledTextLabel>
  );
};
