import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { IconClose, WHITE, Title2, Button, regular, FONT_REGULAR, SIZE_BASE } from 'owlet-ui';
import { greaterThan, breakpoints } from 'owlet-ui/breakpoints';
import { useWindowWidth } from '../../services/utils';
import { storeClosedNotification, useOverlayNotification } from '../../services/notifications';
import { TargetLink } from '../link/TargetLink';
import { appendClosedNotification } from '../../state/notifications/notifications.actions';
import { useAuth } from '../../services/auth';

const Dim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.33);
  z-index: 1000;
`;

const Container = styled.div`
  position: relative;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  z-index: 1001;
  margin: 40px 0 0;
  padding: 0 4vw;

  ${greaterThan('large')`
    margin-top: 80px;
  `}
`;

const Box = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100vw - 40px);
  margin: 0 auto;
  background: #3f005e;
  background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 8px;
  color: ${WHITE};
  padding: 60px 40px 66px;
  box-shadow: 0 0 32px 0px rgba(0, 0, 0, 0.5);

  ${greaterThan('large')`
    padding: 24px 40px 66px;
  `}
`;

const Close = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  svg {
    color: white;
    width: 32px;
    height: 32px;
  }
`;

const Image = styled.div`
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  min-height: 140px;
  max-height: 300px;
  flex-grow: 1;

  ${greaterThan('large')`
    height: 365px;
    max-height: auto;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  div:nth-child(2) {
    margin-top: 18px;
  }

  ${greaterThan('large')`
    min-width: 420px;
    flex-direction: row;

    div:nth-child(2) {
      margin-left: 66px;
    }
  `}

  ${Title2},
  p {
    margin-top: 18px;
  }
`;

const ContentWrap = styled.div`
  ${greaterThan('large')`
    min-width: 50%;
  `}
`;

const SubTitle = styled.h3`
  display: inline-block;
  padding: 6px 15px;
  margin: 0;
  background-color: #570580;
  border-radius: 8px;
  font-size: 18px;
  color: white;

  ${regular()}
`;

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  white-space: nowrap;
  gap: 18px;
`;

const CloseButton = styled.button`
  appearance: none;
  background: rgba(0, 0, 0, 0.5);
  color: ${WHITE};
  border: 0;
  font: ${FONT_REGULAR};
  font-size: ${SIZE_BASE};
  padding: 1rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;
  user-select: none;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;

export default function OverlayNotifications() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const notification = useOverlayNotification();
  const windowWidth = useWindowWidth();

  // Prevent body from scrolling when notification is open
  useEffect(() => {
    document.body.style.overflow = notification ? 'hidden' : '';
  }, [notification]);

  // Reset body overflow when component unmounts
  useEffect(
    () => () => {
      document.body.style.overflow = '';
    },
    []
  );

  if (notification === null) {
    return null;
  }

  const image = notification.images?.[0];

  const handleClose = () => {
    dispatch(appendClosedNotification(notification));
    storeClosedNotification(notification, user?.id?.toString());
  };

  return (
    <Dim>
      <Container data-test={`overlay-notification-${notification.id}`}>
        <Box>
          <Close onClick={handleClose} data-test="overlay-notification-close">
            <IconClose fill={WHITE} />
          </Close>

          <Title2>{notification.shortSubtitle}</Title2>
          <Content data-test="overlay-notification-content">
            {image && (
              <Image
                style={{
                  backgroundImage: `url(${image.url})`,
                  aspectRatio: windowWidth < breakpoints.large ? `${image.width}/${image.height}` : undefined,
                }}
              />
            )}
            <ContentWrap>
              {notification.subtitle && <SubTitle>{notification.subtitle}</SubTitle>}
              <Title2>{notification.title}</Title2>
              <p>{notification.plainText}</p>
              <Actions>
                {notification.target && (
                  <TargetLink item={notification} anchorComponent={Button} onClick={handleClose}>
                    {notification.target.title}
                  </TargetLink>
                )}
                <CloseButton onClick={handleClose}>Sulje</CloseButton>
              </Actions>
            </ContentWrap>
          </Content>
        </Box>
      </Container>
    </Dim>
  );
}
