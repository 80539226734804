import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IconLikeActive, medium, SIZE_BASE, SIZE_SMALL, WHITE } from 'owlet-ui';
import { Asset, AssetType } from '../../../models/asset';
import { Category, CategoryType } from '../../../models/category';
import { PathLink } from '../../link/PathLink';
import { ParentalRating } from '../../parental/ParentalRating';
import { ImdbRating } from '../../imdb/ImdbRating';
import { getParentalReasons } from '../../../services/assets';

interface DetailsMetaProps {
  item: Category | Asset;
}

const Wrap = styled.div`
  margin: 1rem 0 0;
  ${medium()};
  font-size: ${SIZE_BASE};

  > * {
    margin-right: 0.55rem;
  }
`;

const LikeCounter = styled.span``;

const MetaItem = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  & + & {
    margin-top: 1rem;
  }
  span + span,
  span + div {
    padding-left: 1rem;
  }
`;

const LikesIcon = styled(IconLikeActive)`
  width: 1.15rem;
  margin-right: 0.25rem;
  fill: ${WHITE};
  display: inline-block;
  vertical-align: top;
`;

const StyledParentalRating = styled(ParentalRating)`
  display: inline-flex;
  vertical-align: middle;
  padding-right: 1rem;
  svg {
    width: 1.55rem;
  }
`;

const Year = styled.span`
  display: inline-block;
  padding-top: 1px;
`;

const Genre = styled.span`
  padding-right: 0.75rem;
`;

const BottomRow = styled.div`
  font-size: ${SIZE_SMALL};
  max-width: 100%;
  white-space: normal;
  margin-top: 1rem;
  a {
    color: ${WHITE};
  }
`;

const shouldSeparateParentalReasons = (item: Category | Asset) => {
  const parentalReasons = getParentalReasons(item);
  const { parentalRating, imdbRating, likes } = item;
  const iconList = [
    ...parentalReasons,
    ...(parentalRating || '').split('-'), // Parental rating is 1 or 2 icons
    ...[imdbRating, imdbRating], // Likes and IMDb-ratings are basically two items (icon and rating), therefore counted twice here
    ...[likes, likes],
  ].filter(Boolean);

  const limit = item.type === AssetType.Movie ? 6 : 7;

  return iconList.length >= limit;
};

export const DetailsMeta: FunctionComponent<DetailsMetaProps> = React.memo(({ item }: DetailsMetaProps) => {
  if (!item) {
    return null;
  }

  const year = (item as Category).productionYears || item.productionYear || null;
  const isSport = item.type === CategoryType.Sport;
  const showYear = !isSport && year;

  const genres =
    item.genrePaths?.length > 0
      ? item.genrePaths
          .filter(({ path }) => Boolean(path))
          .slice(0, 3)
          .map(({ name, path }, i, arr) => (
            <React.Fragment key={i}>
              <PathLink path={path} passHref>
                <a>{name}</a>
              </PathLink>
              {i < arr.length - 1 && ' - '}
            </React.Fragment>
          ))
      : null;

  const showGenres = Boolean(genres && genres.length);
  const multiLineRatings = shouldSeparateParentalReasons(item);

  return (
    <Wrap>
      <MetaItem>
        <ImdbRating className="float-rating" item={item} />
        {item.likes > 0 && (
          <LikeCounter>
            <LikesIcon />
            {item.likes}%
          </LikeCounter>
        )}
        {!multiLineRatings && <StyledParentalRating item={item} />}
      </MetaItem>
      {multiLineRatings && (
        <MetaItem>
          <StyledParentalRating item={item} />
        </MetaItem>
      )}
      {(showYear || showGenres) && (
        <BottomRow>
          {showGenres && <Genre>{genres}</Genre>}
          {showYear && <Year data-test="DetailsMeta__Year">{year}</Year>}
        </BottomRow>
      )}
    </Wrap>
  );
});

DetailsMeta.displayName = 'DetailsMeta';
