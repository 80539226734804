// Only take into account orders ended after 1.2.2017 as we don't care about legacy Katsomo orders
export const LEGACY_ORDER_END_DATE = new Date('2017-02-01T00:00:00Z');

// Switch to Telia terms from MTV terms happened on July 1st 2021 Finnish time
export const TELIA_TERMS_START_DATE = new Date('2021-06-30T21:00:00.000Z');
export const TELIA_TERMS_END_DATE = new Date('2023-01-31T22:00:00.000Z');

export interface Order {
  id: number;
  productId: number;
  productGroupId: number;
  autorenewErrors: number;
  autorenewStatus: AutorenewStatus;
  status: OrderStatus;
  startDate: string;
  endDate: string;
  accessEndDate: string;
  earliestEndDate?: string;
  productPaymentId: number;
  productName: string;
  price: number;
  period: number;
  userId: number;
  orderRef?: string;
  paymentInfo?: string;
  userPaymentMethod?: UserPaymentMethod;
  upgradeOrderId: number;
  voucherCode?: string;
  paymentInfoExpiryDate?: string;
}

export enum OrderStatus {
  Active = 'ACTIVE',
  Stopped = 'STOPPED',
}

export enum AutorenewStatus {
  Active = 'ACTIVE',
  Stopped = 'STOPPED',
  Converted = 'CONVERTED',
  NotEligible = 'NOT_ELIGIBLE',
}

export interface OrderTransaction {
  orderId: number;
  price: number;
  registered: number;
  transactionReference: string;
  status: OrderTransactionStatus;
  userPaymentMethodId: string;
  startDate: number;
  endDate: number;
  type: OrderTransactionType;
}

export enum OrderTransactionStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

export enum OrderTransactionType {
  Purchase = 'PURCHASE',
  RefundPurchase = 'REFUND_PURCHASE',
  AutoRenew = 'AUTORENEW',
}

export interface OrderConversion {
  downgrade: number[];
  upgrade: number[];
  coexist?: number[];
}

export interface UserPaymentMethod {
  id: string;
  paymentProviderId: number;
  paymentInfo: string;
  expireDate: number;
  registered: number;
  userPaymentMethodType: 'CREDIT_CARD' | string;
  allowOneClickBuy: boolean;
}
