export enum ContentTag {
  /**
   * Content is tagged as tv program
   */
  TvProgram = 'tv-program',
  /**
   * Content is tagged as news
   */
  News = 'news',
  /**
   * The asset doesn't have next episode, no need to check for it in the client
   */
  NoNextEpisode = 'no-next-episode',
  /**
   * The asset is additional camera (used only for F1)
   */
  AdditionalCamera = 'additional-camera',
  /**
   * Content is tagged as F1
   */
  Formula1 = 'f1',
  /**
   * Determines the explicit 'live' status for the asset,
   * this is set by content editors and should override the actual live-attribute.
   */
  Live = 'live',
  /**
   * Asset has other related assets, e.g. additional cameras or other content that can be
   * fetched from /related endpoint
   */
  HasRelated = 'has-related',
  /**
   * Asset id is found from list of permanent live badge category
   */
  ConstantLive = 'constant-live',
  /**
   * Asset contains ads and should show a badge
   */
  AdsBadge = 'ads-badge',
}
