import Cookies from 'js-cookie';

export const CONST = {
  auth: 'cmore-auth',
};

export const set = (key: string, value: string, keySuffix: string = '') => {
  const fullKey = getCookieKey(key, keySuffix);
  return Cookies.set(fullKey, value);
};

export const get = (key: string, keySuffix: string = '') => {
  const fullKey = getCookieKey(key, keySuffix);
  return Cookies.get(fullKey);
};

export const remove = (key: string, keySuffix: string = '') => {
  const fullKey = getCookieKey(key, keySuffix);
  // Note: if we do not use default attributes (path, domain), those need to be specified here also.
  return Cookies.remove(fullKey);
};

const getCookieKey = (key: string, suffix: string) => {
  return `${key}${suffix ? `_${suffix}` : ''}`;
};
