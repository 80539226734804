import React, {
  FunctionComponent,
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isString from 'lodash/isString';
import { createPortal } from './portal';
import { OwletModalTheme, ModalOpts } from './models';
import { ModalContext, ModalContextProps } from './ModalContext';
import { useWindowEventListener } from '../common/event-listener';
import { useIsomorphicLayoutEffect } from '../common/utils';

/**
 * Provider component for modals. Takes outer modal components (overlay and wrapper) and
 * close button component as props. The components can be styled to fit the application theme.
 */
export const ModalProvider: FunctionComponent<PropsWithChildren<OwletModalTheme>> = ({
  children,
  ...themeProps
}: PropsWithChildren<OwletModalTheme>) => {
  const [modals, setModals] = useState<ModalOpts[]>([]);

  const pushModal = useCallback((modal: ModalOpts) => {
    if (!modal) {
      return;
    }

    if (!modal.name) {
      throw new Error('Modal name is required');
    }

    setModals((currentModals) => {
      if (modal.force || modal.replace) {
        // If replacing, run previous modals onClose handler before closing
        if (
          modal.replace &&
          currentModals.length > 0 &&
          currentModals[0].onClose &&
          currentModals[0].name !== modal.name
        ) {
          currentModals[0].onClose();
        }

        return [
          modal,
          ...(modal.replace ? currentModals.slice(1) : currentModals).filter((m) => m.name === modal.name),
        ];
      } else {
        // If modal already exists, replace with new version
        if (currentModals.some((m) => m.name === modal.name)) {
          return currentModals.map((m) => (m.name === modal.name ? modal : m));
        }

        return [...currentModals, modal];
      }
    });
  }, []);

  const popModal = useCallback((modal?: ModalOpts | string) => {
    setModals((currentModals) => {
      // If specific modal is given as a parameter, only close that modal,
      // otherwise close the open modal whatever its name is
      if (modal) {
        if (isString(modal)) {
          return currentModals.filter((m) => m.name !== modal);
        }

        return currentModals.filter((m) => m.name !== modal.name);
      }

      return currentModals.slice(1);
    });
  }, []);

  const clearModals = useCallback(() => {
    setModals([]);
  }, []);

  const contextValue = useMemo<ModalContextProps>(() => ({ modals, pushModal, popModal, clearModals }), [
    modals,
    pushModal,
    popModal,
    clearModals,
  ]);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      <ModalContainer {...themeProps} />
    </ModalContext.Provider>
  );
};

const ModalContainer: FunctionComponent<OwletModalTheme> = ({
  overlayComponent: OverlayComponent,
  modalComponent: ModalComponent,
  closeButtonComponent: CloseButtonComponent,
}: OwletModalTheme) => {
  const { modals, popModal } = useContext(ModalContext);
  const [currentModal, setCurrentModal] = useState<ModalOpts | null>(null);

  const handleOverlayClick = useCallback(() => {
    if (currentModal) {
      if (currentModal.closeOnOutsideClick !== false) {
        if (currentModal?.onManualClose) {
          currentModal.onManualClose();
        }

        popModal(currentModal);
      }
    }
  }, [currentModal]);

  const handleCloseClick = useCallback(() => {
    if (currentModal) {
      if (currentModal.onManualClose) {
        currentModal.onManualClose();
      }

      popModal(currentModal);
    }
  }, [currentModal]);

  const preventPropagation = useCallback((evt: MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    evt.nativeEvent.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
  }, []);

  useEffect(() => {
    if (modals.length > 0) {
      setCurrentModal((prevModal: ModalOpts) => {
        if (prevModal !== modals[0]) {
          if (prevModal?.onClose) {
            prevModal.onClose();
          }

          if (modals[0].onOpen) {
            modals[0].onOpen();
          }
        }

        return modals[0];
      });
    } else {
      setCurrentModal((prevModal: ModalOpts) => {
        if (prevModal?.onClose) {
          prevModal.onClose();
        }

        return null;
      });
    }
  }, [modals]);

  useIsomorphicLayoutEffect(() => {
    if (currentModal) {
      document.body.style.overflow = 'hidden';
      document.body.classList.add('with-modal');
    } else {
      document.body.style.overflow = 'visible';
      document.body.classList.remove('with-modal');
    }
  }, [currentModal]);

  useWindowEventListener('keyup', (evt: KeyboardEvent) => {
    if (currentModal && currentModal.closeOnEsc !== false && evt.key === 'Escape') {
      if (currentModal?.onManualClose) {
        currentModal.onManualClose();
      }

      popModal(currentModal);
    }
  });

  if (currentModal) {
    const { modal, closeButtonVisible, component: CustomComponent } = currentModal;

    const content = (
      <>
        {closeButtonVisible !== false && <CloseButtonComponent onClick={handleCloseClick} />}
        {modal}
      </>
    );

    const componentProps: HTMLAttributes<HTMLDivElement> = {
      onClick: preventPropagation,
      'aria-modal': 'true',
      tabIndex: -1,
      role: 'dialog',
    };

    return createPortal(
      <>
        <OverlayComponent onClick={handleOverlayClick}>
          {CustomComponent ? (
            <CustomComponent {...componentProps}>{content}</CustomComponent>
          ) : (
            <ModalComponent {...componentProps}>{content}</ModalComponent>
          )}
        </OverlayComponent>
      </>,
      document.body
    );
  }

  return null;
};
