export function truncateWords(value: string, amountOfWords: number = 40): string {
  let result = value;
  if (value) {
    const words = value.split(/\s+/);
    if (words.length > Math.abs(amountOfWords)) {
      if (amountOfWords < 0) {
        amountOfWords *= -1;
        result = '...' + words.slice(words.length - amountOfWords, words.length).join(' ');
      } else {
        result = words.slice(0, amountOfWords).join(' ') + '...';
      }
    }
  }
  return result;
}

export function cleanUrl(url: string): string {
  if (!url) {
    return url;
  }

  return url.replace(/[?&#].*/g, '');
}

export function sanitizeUserInput(value: any): string {
  if (!value) {
    return value;
  }
  // Allows numbers, letters, spaces and following chars: - + @ . , _ '
  // Ranges À-Ö, Ø-ö and ø-ÿ contain character codes: 192-214, 216-246 and 248-255
  return `${value}`.replace(/[^\w\sÀ-ÖØ-öø-ÿ\-@.,_']/gi, '').trim();
}
