import React, { MouseEvent, PropsWithChildren, forwardRef } from 'react';
import styled from 'styled-components';
import { FONT_REGULAR, SIZE_BASE, SIZE_LARGE, SIZE_SMALLER } from '../typography';
import { WHITE, WHITE05, WHITE15, WHITE25, WHITE40 } from '../colors';
import classNames from 'classnames';

export enum ButtonSize {
  DEFAULT = 'default',
  BIG = 'big',
  SMALL = 'small',
}

export interface ButtonProps {
  size?: ButtonSize;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  className?: string;
  ref?: React.RefObject<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
}

const StyledButton = styled.button`
  appearance: none;
  background-color: #e0001c;
  color: ${WHITE};
  font: ${FONT_REGULAR};
  font-size: ${SIZE_BASE};
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  position: relative;
  outline: 0;
  user-select: none;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};

  &:not([disabled]) {
    &:active,
    &:hover {
      background-color: #d4262f;
    }

    &:active {
      background-color: #961313;
    }
  }

  &[disabled] {
    color: ${WHITE40};
    background: ${WHITE15};
    border-color: ${WHITE15};
  }

  &.big {
    font-size: ${SIZE_LARGE};
  }

  &.small {
    font-size: ${SIZE_SMALLER};
  }

  &:focus {
    box-shadow: 0 0 5px ${WHITE};
  }
`;

// eslint-disable-next-line react/display-name
export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    {
      disabled,
      onClick,
      children,
      size = ButtonSize.DEFAULT,
      className,
      type = 'button',
      ...props
    }: PropsWithChildren<ButtonProps>,
    ref
  ) => {
    return (
      <StyledButton
        className={classNames(className, size)}
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
        ref={ref}
        type={type}
        {...props}
      >
        {children}
      </StyledButton>
    );
  }
);

export const GrayButton = styled(Button)`
  background: ${WHITE25};
  border-color: ${WHITE25};

  &:not([disabled]) {
    &:active,
    &:hover {
      background: ${WHITE40};
    }
  }

  &[disabled] {
    background: ${WHITE05};
    border-color: ${WHITE05};
  }
`;
