import React, {
  PropsWithChildren,
  ReactElement,
  UIEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { ThemeContext } from 'styled-components';
import debounce from 'lodash/debounce';
import isFinite from 'lodash/isFinite';
import isNil from 'lodash/isNil';
import { useWindowEventListener } from '../common/event-listener';
import {
  getDefaultNaviCtrlElement,
  NaviContainer,
  NaviCtrlNext,
  NaviCtrlPrev,
  SlotContainer,
  StripeContainer,
} from './styles';
import { NaviDirection } from './models';
import { getMarginWidth, usePassWheelEvent } from '../common/dom';
import { PaginationContext, PaginationContextProps } from '../paginator/PaginationContext';
import { isMobileBrowser } from '../common/browser';
import { useScrollCache } from './scroll-cache';
import throttle from 'lodash/throttle';

export type StripeItem = any;

export interface StripeProps {
  items?: StripeItem[];
  itemMapper?: StripeItemMapper;
  slotBreakpoints?: { [key: string]: number };
  navi?: {
    prev: ReactElement;
    next: ReactElement;
  };
  interactive?: boolean;
  mobile?: boolean;
  focusItem?: number | StripeItem;
  goToItem?: number | StripeItem;
  scrollCacheKey?: string;
  onContainerScroll?: (event: UIEvent<HTMLUListElement>) => void;
  onUserScroll?: (item: StripeItem) => void;
}

export interface StripeItemMapper {
  (item: any, i: number, interactive: boolean, options?: any): ReactElement | undefined;
}

const DEFAULT_ITEM_MAPPER: StripeItemMapper = (item: any, i: number, interactive: boolean = true) => {
  return (
    <SlotItem className={interactive ? undefined : 'do-not-touch'} key={i}>
      {item.title}
    </SlotItem>
  );
};

export const DEFAULT_BREAKPOINTS = {
  default: 3,
  xsmall: 4,
  medium: 6,
  xlarge: 8,
  xxxlarge: 10,
};

export const getPaginationStatus = (
  scrollWidth: number,
  marginWidth: number,
  slotWidth: number,
  offsetWidth: number,
  scrollLeft: number
) => {
  // movies occupy one slot and series/sport occupy two slots
  const totalSlots = Math.round((scrollWidth - 2 * marginWidth) / slotWidth);
  const slotsPerPage = Math.round((offsetWidth - 2 * marginWidth) / slotWidth);

  const pageCount = Math.ceil(totalSlots / slotsPerPage);

  let currentPage;
  if (scrollLeft === 0 || pageCount === 1) {
    // scrolled to start
    currentPage = 0;
  } else if (scrollLeft + offsetWidth >= scrollWidth) {
    // scroll to end
    currentPage = pageCount - 1;
  } else {
    // count which slot is shown on the left edge and on which page that is
    const slotIdx = Math.abs(Math.round((scrollLeft - marginWidth) / slotWidth));
    currentPage = Math.ceil(slotIdx / slotsPerPage);
  }

  return {
    currentPage,
    pageCount,
  };
};

export const Stripe = React.memo<PropsWithChildren<StripeProps>>(
  ({
    items = [],
    itemMapper = DEFAULT_ITEM_MAPPER,
    slotBreakpoints = DEFAULT_BREAKPOINTS,
    navi = {
      prev: getDefaultNaviCtrlElement(NaviDirection.PREV),
      next: getDefaultNaviCtrlElement(NaviDirection.NEXT),
    },
    interactive = true,
    mobile,
    scrollCacheKey,
    onContainerScroll,
    onUserScroll,
    focusItem,
    goToItem,
    children,
  }: PropsWithChildren<StripeProps>) => {
    const slotContainerEl = useRef<HTMLUListElement>(null);
    const themeContext = useContext(ThemeContext);

    const [hasPrev, setHasPrev] = useState(false);
    const [hasNext, setHasNext] = useState(false);

    const [pageCount, setPageCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const disableUserScrollListener = useRef<boolean>(false);

    // Navi is visible by default or if user is not on mobile
    const [isNaviVisible, setNaviVisible] = useState<boolean>(isNil(mobile) ? true : !mobile);

    // Remember scroll position
    const cachedPosition = useScrollCache(scrollCacheKey, slotContainerEl.current);

    useEffect(() => {
      // Update navi visibility if no value is set
      if (isNil(isNaviVisible)) {
        setNaviVisible(!isMobileBrowser());
      }
    }, [isNaviVisible]);

    const filteredItems = items.filter(Boolean);

    const updateNavi = () => {
      if (slotContainerEl.current) {
        const { scrollLeft, scrollWidth, offsetWidth } = slotContainerEl.current;
        setHasPrev(scrollLeft > 0);
        // Rounding up helps prevent showing next navigation for 1px wide "extra pages"
        setHasNext(Math.ceil(scrollLeft + offsetWidth) < scrollWidth);
      }
    };

    const updatePagination = () => {
      // Pagination context is only used by child components, so we can skip updates if there are no children
      if (slotContainerEl.current && children) {
        const { scrollLeft, scrollWidth, offsetWidth } = slotContainerEl.current;
        const slotWidth = getSlotWidth();
        const marginWidth = getMarginWidth(themeContext);

        const { currentPage: _currentPage, pageCount: _pageCount } = getPaginationStatus(
          scrollWidth,
          marginWidth,
          slotWidth,
          offsetWidth,
          scrollLeft
        );

        setPageCount(_pageCount);
        setCurrentPage(_currentPage);
      }
    };

    const handleOnScroll = (event: UIEvent<HTMLUListElement>) => {
      updateNavi();
      updatePagination();
      onContainerScroll?.(event);
      if (!disableUserScrollListener.current) {
        handleUserScroll();
      }
    };

    const handleUserScroll = throttle(() => {
      onUserScroll?.(getCurrentItem());
    }, 500);

    useEffect(() => {
      updateNavi();
      updatePagination();
    }, [slotContainerEl, items]);

    const scrollToItem = (item: number | StripeItem) => {
      if (slotContainerEl.current && item) {
        let idx: number;

        if (isFinite(item)) {
          idx = item;
        } else {
          idx = items.indexOf(item);
        }

        if (idx === -1) {
          return;
        }

        // Ignore programmatical scrolling to items
        disableUserScrollListener.current = true;
        if (idx === 0) {
          slotContainerEl.current.scrollLeft = 0;
        } else {
          // Fixes issue where scrolling is done before stripe is properly rendered
          setTimeout(() => {
            // According to Sentry slotContainerEl.current might be null despite the earlier check
            if (slotContainerEl.current) {
              const slotWidth = getSlotWidth();
              slotContainerEl.current!.scrollLeft = idx * slotWidth;
            }
          });
        }
        // Needs timeout or clears too fast
        setTimeout(() => {
          disableUserScrollListener.current = false;
        }, 50); // Longer timeout should prevent choosing wrong date when initially loading the sports stripe
      }
    };

    useEffect(() => {
      scrollToItem(goToItem);
    }, [goToItem, items]);

    useEffect(() => {
      if (slotContainerEl.current) {
        if (cachedPosition && !goToItem) {
          slotContainerEl.current.scrollLeft = cachedPosition;
        } else if (focusItem) {
          scrollToItem(focusItem);
        }
      }
    }, [cachedPosition, goToItem, focusItem]);

    useWindowEventListener(
      'resize',
      debounce(() => {
        updateNavi();
        updatePagination();
      }, 100)
    );

    const getSlotWidth = (): number => {
      if (filteredItems.length > 0 && slotContainerEl.current) {
        const { firstElementChild } = slotContainerEl.current!;

        if (firstElementChild) {
          const { width } = (firstElementChild as HTMLElement).getBoundingClientRect();

          // If the measured item was wide (sport/series) but there are any narrow items (movies), return narrow slot
          return Boolean(items[0].categoryId || items[0].category) &&
            filteredItems.some((item) => Boolean(item.assetId || item.asset))
            ? width / 2
            : width;
        }
      }

      return 0;
    };

    const getCurrentItem = () => {
      if (!slotContainerEl.current) {
        return;
      }
      const { scrollLeft, scrollWidth, clientWidth } = slotContainerEl.current;
      const marginWidth = getMarginWidth(themeContext);
      const slotWidth = getSlotWidth();

      // If we're scrolled to the end, return the last item
      if (scrollWidth - Math.ceil(scrollLeft) <= clientWidth) {
        return items[items.length - 1];
      }

      const itemCount = Math.round((scrollLeft - marginWidth) / slotWidth);
      return items[getItemIndex(itemCount)];
    };

    const getItemIndex = (itemCount: number) => {
      return itemCount >= 0 ? (itemCount < items.length ? itemCount : items.length - 1) : 0;
    };

    const scroll = (direction: NaviDirection) => {
      if (!slotContainerEl.current) {
        return;
      }

      const { scrollLeft, offsetWidth } = slotContainerEl.current;

      const marginWidth = getMarginWidth(themeContext);

      let scrollX = scrollLeft + offsetWidth * direction - marginWidth * direction * 2;

      const slotWidth = getSlotWidth();
      const itemIndex = Math.round((scrollX - marginWidth) / slotWidth);
      if ((scrollX - marginWidth) % slotWidth !== 0) {
        scrollX = slotWidth * itemIndex;
      }

      slotContainerEl.current.scrollLeft = scrollX;
      onUserScroll?.(items[itemIndex]);
    };

    const stopMouseOver = useCallback((evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => evt.stopPropagation(), []);

    const scrollPrev = useCallback(() => scroll(NaviDirection.PREV), [items]);
    const scrollNext = useCallback(() => scroll(NaviDirection.NEXT), [items]);

    const paginationContextValue = useMemo<PaginationContextProps>(() => ({ pageCount, currentPage }), [
      pageCount,
      currentPage,
    ]);

    const handleWheelEvent = usePassWheelEvent(slotContainerEl);

    return (
      <>
        <StripeContainer>
          <SlotContainer slotBreakpoints={slotBreakpoints} onScroll={handleOnScroll} ref={slotContainerEl}>
            {filteredItems.map((item: any, i: number) => itemMapper(item, i, interactive))}
          </SlotContainer>
          {navi && isNaviVisible && (
            <NaviContainer>
              <NaviCtrlPrev
                onClick={scrollPrev}
                onMouseOver={stopMouseOver}
                onWheel={handleWheelEvent}
                className={hasPrev ? 'has-items' : 'no-items'}
              >
                {navi.prev}
              </NaviCtrlPrev>
              <NaviCtrlNext
                onClick={scrollNext}
                onMouseOver={stopMouseOver}
                onWheel={handleWheelEvent}
                className={hasNext ? 'has-items' : 'no-items'}
              >
                {navi.next}
              </NaviCtrlNext>
            </NaviContainer>
          )}
        </StripeContainer>
        <PaginationContext.Provider value={paginationContextValue}>{children}</PaginationContext.Provider>
      </>
    );
  }
);

Stripe.displayName = 'Stripe';
Stripe.whyDidYouRender = true;

export const SlotItem = styled.li`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const WideSlotItem = styled(SlotItem)`
  grid-column: span 2 / auto;
`;
