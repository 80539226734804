import React, { useEffect } from 'react';
import Head from 'next/head';
import Page from '../layouts/Main';
import styled from 'styled-components';
import { NotFound } from '../components/errors/NotFound';
import { GenericError } from '../components/errors/GenericError';
import { captureException } from '../services/sentry';
import { useTracking } from '../services/tracking/use-tracking';
import { greaterThan } from 'owlet-ui/breakpoints';
import { useAuth } from '../services/auth';
import { PageContext } from '../models/context';

interface ErrorProps {
  errorCode: number;
}

const Container = styled.div`
  margin: ${(props) => props.theme.naviHeightSmall} ${(props) => props.theme.horizontalMargin} 0;

  ${greaterThan('large')`
    margin-top: ${(props) => props.theme.naviHeight};
  `}
`;

export const ErrorPage = ({ errorCode }: ErrorProps) => {
  const { sendPageView } = useTracking();
  const { isAuthCompleted } = useAuth();

  const notFound = errorCode === 404;
  const genericError = errorCode !== 200 && !notFound;

  const title = errorCode ? `C More - ${errorCode}` : 'C More';

  useEffect(() => {
    // Send additional page view event to track error pages separately
    if (isAuthCompleted) {
      sendPageView(`/error${errorCode}`);
    }
  }, [isAuthCompleted]);

  return (
    <Page>
      <Head>
        <title>{title}</title>
      </Head>
      <Container>
        {notFound && <NotFound />}
        {genericError && <GenericError />}
      </Container>
    </Page>
  );
};

ErrorPage.getInitialProps = async ({ err, res, query }: PageContext) => {
  // Override status code if provided in the query
  if (query.statusCode && res) {
    res.statusCode = Number(query.statusCode);
  }

  if (err) {
    captureException(err);
  }

  return {
    errorCode: res && res.statusCode,
  };
};

export default ErrorPage;
