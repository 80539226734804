import { User } from '../models/user';
import { fetchRaw } from './api';
import { getKatsomoServiceApiUrl } from './conf';
import { getUserProperty } from './properties';
import { CmProduct, ProductType } from '../models/product';
import { Order } from '../models/order';
import { findProductForOrder } from './products/products';

export function isTeliaDotProduct(product: CmProduct): boolean {
  return product?.productType === ProductType.TeliaDot;
}

export async function cleanTeliaIdProperty(
  user: User,
  activeOrders: Order[],
  validProducts: CmProduct[],
  authToken: string
): Promise<boolean> {
  try {
    if (user && activeOrders && validProducts && authToken) {
      const hasTeliaDotOrder = activeOrders.some((order) =>
        isTeliaDotProduct(findProductForOrder(order, validProducts))
      );

      // Check if none of the orders is for Telia DOT product
      if (!hasTeliaDotOrder) {
        return deleteTeliaIdProperty(user, authToken);
      }
    }
  } catch (e) {
    // ignore error
  }

  return false;
}

async function deleteTeliaIdProperty(user: User, authToken: string): Promise<boolean> {
  const teliaId = getUserProperty(user, 'teliaId');

  // User has a teliaId property, call katsomo-service to remove it
  if (teliaId) {
    try {
      const response = await fetchRaw(`${getKatsomoServiceApiUrl()}/telia/user/${user.id}/removeProperty/teliaId`, {
        method: 'DELETE',
        headers: {
          Authorization: authToken,
        },
      });

      return response.ok;
    } catch (e) {
      console.error(e);
    }
  }

  return false;
}
