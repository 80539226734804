import { Tracker, TrackingEventHandlerType } from '../../models/tracking';
import { getConfig } from '../conf';
import noop from 'lodash/noop';

// Please note that use-tracking.ts contains a global setting that disables google analytics if user
// has not given consent for performance cookies.

export const googleAnalyticsTracker: Tracker = {
  name: 'ga',
  initialize: async () => {
    const { enabled } = getConfig().tracking.google;

    if (!enabled) {
      return false;
    }

    return await new Promise<boolean>((resolve) => {
      let retryCount = 200;

      const retry = () => {
        retryCount -= 1;
        setTimeout(() => {
          isReady();
        }, 100);
      };

      const isReady = () => {
        // Retry count exceeded, fail init
        if (retryCount === 0) {
          console.log('[tracking] ga init failed: retry count exceeded');
          resolve(false);
          return;
        }

        const ga = window['ga'] || {};

        if (ga.loaded) {
          resolve(true);
          return;
        }

        retry();
      };

      isReady();
    });
  },
  [TrackingEventHandlerType.PageView]: (event) => {
    const ga = window['ga'] || noop;

    ga('set', 'userId', event.data.userId);
    ga('set', 'page', event.label);
    ga('send', 'pageview');
  },
  [TrackingEventHandlerType.CustomEvent]: noop,
};
