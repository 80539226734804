import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useOperatorLogin } from '../../services/operator';
import { useFeedback } from '../../services/feedback';
import {
  OPERATOR_LOGIN_USER_FETCH_DELAY,
  removeTokenFromLocalStorage,
  storeTokenToLocalStorage,
  useAuth,
} from '../../services/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthStarted } from '../../state/user/user.selectors';
import { authFail } from '../../state/user/user.actions';
import { CircularProgress, useOwletModal, useWindowEventListener } from 'owlet-ui';
import { AuthRenewal } from './AuthRenewal';

/**
 * App-level component that handles regular authentication (using server-side cookie) and
 * operator authentication (using `partner` and `jwt` query parameters).
 */
export const AuthenticationHandler: FunctionComponent = () => {
  const { route, replace } = useRouter();
  const { error: showErrorMessage } = useFeedback();
  const { authToken, handleAuthentication, isAuthCompleted } = useAuth();
  const { login, error: operatorLoginError } = useOperatorLogin();
  const isAuthStarted = useSelector(selectIsAuthStarted);
  const dispatch = useDispatch();
  const { openModal, closeModal } = useOwletModal();
  const timer = useRef<any>();

  const handleOperatorLogin = () => {
    const url = new URL(location.href);
    const operator = url.searchParams.get('partner');
    const token = url.searchParams.get('jwt');

    if (operator && token) {
      console.log('auth: operator login...', { operator, token });

      // JWT login
      login(operator, token);

      // Clean URL
      url.searchParams.delete('partner');
      url.searchParams.delete('jwt');

      openModal({
        name: 'OperatorLoginLoadingModal',
        modal: <CircularProgress />,
        closeButtonVisible: false,
        closeOnOutsideClick: false,
        closeOnEsc: false,
        replace: true,
      });

      timer.current = setTimeout(() => {
        closeModal('OperatorLoginLoadingModal');
      }, OPERATOR_LOGIN_USER_FETCH_DELAY);

      replace(route, `${url.pathname}${url.search}`, { shallow: true });
    } else if (!isAuthStarted && !isAuthCompleted) {
      // Regular authentication
      handleAuthentication();
    }
  };

  useEffect(() => {
    handleOperatorLogin();
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    if (operatorLoginError) {
      showErrorMessage({
        message: 'Kirjautuminen epäonnistui. Yritä myöhemmin uudelleen.',
        timeout: 10000,
      });
      dispatch(authFail());
    }
  }, [operatorLoginError]);

  useWindowEventListener('beforeunload', () => {
    if (authToken) {
      storeTokenToLocalStorage(authToken);
    } else {
      removeTokenFromLocalStorage();
    }
  });

  return <AuthRenewal />;
};
