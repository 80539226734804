import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { Asset } from '../../models/asset';
import { EpgItem } from '../../models/channel';
import isString from 'lodash/isString';
import { Category } from '../../models/category';
import dynamic from 'next/dynamic';
import { getParentalReasons } from '../../services/assets';

const IconAgeRate7 = dynamic(() =>
  import(/* webpackChunkName: "ParentalRatingIcons" */ 'owlet-ui/icon/icons/AgeRate7')
);
const IconAgeRate12 = dynamic(() =>
  import(/* webpackChunkName: "ParentalRatingIcons" */ 'owlet-ui/icon/icons/AgeRate12')
);
const IconAgeRate16 = dynamic(() =>
  import(/* webpackChunkName: "ParentalRatingIcons" */ 'owlet-ui/icon/icons/AgeRate16')
);
const IconAgeRate18 = dynamic(() =>
  import(/* webpackChunkName: "ParentalRatingIcons" */ 'owlet-ui/icon/icons/AgeRate18')
);
const IconAgeRateAhdistus = dynamic(() =>
  import(/* webpackChunkName: "ParentalRatingIcons" */ 'owlet-ui/icon/icons/AgeRateAhdistus')
);
const IconAgeRatePaihteet = dynamic(() =>
  import(/* webpackChunkName: "ParentalRatingIcons" */ 'owlet-ui/icon/icons/AgeRatePaihteet')
);
const IconAgeRateS = dynamic(() =>
  import(/* webpackChunkName: "ParentalRatingIcons" */ 'owlet-ui/icon/icons/AgeRateS')
);
const IconAgeRateSeksi = dynamic(() =>
  import(/* webpackChunkName: "ParentalRatingIcons" */ 'owlet-ui/icon/icons/AgeRateSeksi')
);
const IconAgeRateT = dynamic(() =>
  import(/* webpackChunkName: "ParentalRatingIcons" */ 'owlet-ui/icon/icons/AgeRateT')
);
const IconAgeRateVakivalta = dynamic(() =>
  import(/* webpackChunkName: "ParentalRatingIcons" */ 'owlet-ui/icon/icons/AgeRateVakivalta')
);

interface ParentalRatingProps {
  item: Asset | Category | EpgItem;
  className?: string;
}

const Wrap = styled.div`
  display: flex;
  flex-flow: nowrap;
  align-items: center;

  > * + * {
    margin-left: 0.25rem;
  }

  svg {
    width: 1.5rem;
  }
`;

export const ParentalRating: FunctionComponent<ParentalRatingProps> = ({ item, className }: ParentalRatingProps) => {
  const parentalReasons = getParentalReasons(item);

  if (!item.parentalRating && !parentalReasons?.length) {
    return null;
  }

  const getIcon = (key: string): ReactElement => {
    switch (key.toLowerCase()) {
      case '7':
        return <IconAgeRate7 key={key} />;
      case '12':
        return <IconAgeRate12 key={key} />;
      case '16':
        return <IconAgeRate16 key={key} />;
      case '18':
        return <IconAgeRate18 key={key} />;
      case 'ahdistus':
        return <IconAgeRateAhdistus key={key} />;
      case 'päihteet':
        return <IconAgeRatePaihteet key={key} />;
      case 's':
        return <IconAgeRateS key={key} />;
      case 'seksi':
        return <IconAgeRateSeksi key={key} />;
      case 't':
        return <IconAgeRateT key={key} />;
      case 'väkivalta':
        return <IconAgeRateVakivalta key={key} />;
      default:
        return null;
    }
  };

  const parseParentalRating = (parentalRating: string) => {
    if (!isString(parentalRating)) {
      return null;
    }

    return parentalRating
      .split(' - ')
      .map(getIcon)
      .filter((value) => value !== null)
      .reduce((result, icon, idx, arr) => {
        result.push(icon);

        if (idx < arr.length - 1) {
          result.push(<span key={idx}>-</span>);
        }

        return result;
      }, []);
  };

  const parentalRating = parseParentalRating(item.parentalRating);

  // @ts-ignore
  return (
    <Wrap className={className}>
      {parentalRating}
      {parentalReasons?.length > 0 && parentalReasons.map((r) => getIcon(r))}
    </Wrap>
  );
};
