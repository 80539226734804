import { useState } from 'react';
import { useDocumentEventListener, useWindowEventListener } from './event-listener';
import useSSR from 'use-ssr';

export interface ActivityStatus {
  online: boolean;
  pageVisible: boolean;
}

/**
 * Get user's online status and page visibility status. This hook can be used to reduce component activity when
 * page is hidden or network is not available.
 */
export function useActivity(): ActivityStatus {
  const { isBrowser } = useSSR();

  const [online, setOnline] = useState<boolean>(true);
  const [pageVisible, setPageVisible] = useState<boolean>(true);

  useWindowEventListener('online', () => setOnline(true));
  useWindowEventListener('offline', () => setOnline(false));

  const { eventName, propertyName } = (() => {
    if (isBrowser) {
      if (typeof document.hidden !== 'undefined') {
        return {
          eventName: 'visibilitychange',
          propertyName: 'hidden',
        };
      } else if (typeof document['msHidden'] !== 'undefined') {
        return {
          eventName: 'msvisibilitychange',
          propertyName: 'msHidden',
        };
      } else if (typeof document['webkitHidden'] !== 'undefined') {
        return {
          eventName: 'webkitvisibilitychange',
          propertyName: 'webkitHidden',
        };
      }
    }

    return {
      eventName: '',
      propertyName: '',
    };
  })();

  useDocumentEventListener(eventName, () => setPageVisible(!document[propertyName]));

  return {
    online,
    pageVisible,
  };
}
