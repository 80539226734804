import React, { FunctionComponent } from 'react';
import { BaseContentVideoProps, ContentVideo } from './ContentVideo';
import { useTrackVisibility } from 'react-intersection-observer-hook';

type VisibilityTrackedContentVideoProps = BaseContentVideoProps & {
  lazyload?: boolean;
};

export const VisibilityTrackedContentVideo: FunctionComponent<VisibilityTrackedContentVideoProps> = ({
  lazyload = false,
  ...props
}: VisibilityTrackedContentVideoProps) => {
  const [ref, { isVisible, wasEverVisible }] = useTrackVisibility({ threshold: 0.15 });

  // If lazyload=true, only render video when element has been visible once
  const visible = !lazyload || wasEverVisible;

  return (
    <div className="content-video" ref={ref}>
      {visible && <ContentVideo {...props} forcePause={!isVisible} />}
    </div>
  );
};
