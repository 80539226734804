import { getKatsomoServiceApiUrl, getMotherbirdApiUrl } from './conf';
import { FavoriteItem, FavoriteResponse } from '../models/favorite';
import { buildURL, fetchJSON, fetchRaw } from './api';
import useSWR, { mutate } from 'swr';
import { useAuth } from './auth';
import { VimondResults } from '../models/results';
import { Subprofile } from '../models/subprofile';
import { User } from '../models/user';
import { getCachebusterUrl } from './url';
import { Asset } from '../models/asset';
import { Category } from '../models/category';
import { isAsset } from './assets';

export interface UseFavoritesResponse {
  items: FavoriteItem[];
  loading: boolean;
  update: () => void;
}

interface UseFavoriteStatusResponse {
  isFavorited: boolean;
  loading: boolean;
  update: (data?: boolean) => void;
}

/**
 * Fetch a single page of favorites. Requires user and subprofile to create cachebuster param.
 *
 * @param size
 * @param page
 * @param user
 * @param subprofile
 * @param authToken
 * @param filter
 */
export async function fetchFavorites(
  size: number,
  page: number = 0,
  user: User,
  subprofile: Subprofile,
  authToken: string,
  filter?: string
): Promise<FavoriteItem[]> {
  const params: Record<string, any> = {
    size,
    start: page,
  };

  if (filter) {
    params.filter = filter;
  }

  const url = getCachebusterUrl(buildURL(`${getMotherbirdApiUrl()}/v3/svod/web/favorites`, params), user, subprofile);

  const resp = await fetchJSON<VimondResults<FavoriteItem>>(url, {
    headers: {
      Authorization: authToken,
    },
    throwErrors: true,
  });

  return resp.result || [];
}

/**
 * SWR-based hook for loading data for favorites stripe.
 *
 * @param size
 * @param childrenOnly
 */
export function useFavorites(size: number = 20, childrenOnly?: boolean): UseFavoritesResponse {
  const { user, authToken, activeSubprofile, isSubProfileLoaded, profileIdentifier } = useAuth();

  const allowFetch =
    profileIdentifier && // identifier formed
    isSubProfileLoaded && // subprofile loaded or loading failed
    (!childrenOnly || activeSubprofile?.kidsProfile); // either non-kids content or user has kids profile

  const filter = childrenOnly ? 'lasten' : null;

  const key = allowFetch ? `/favorites/${profileIdentifier}${filter ? `/${filter}` : ''}` : null;

  const { data, isValidating } = useSWR(key, () => fetchFavorites(size, 0, user, activeSubprofile, authToken, filter), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    items: data || [],
    loading: isValidating,
    update: () => {
      if (profileIdentifier) {
        mutate(`/favorites/${profileIdentifier}`);
      }
    },
  };
}

interface FavoritesUpdaterParams {
  id: number;
  type: FavoriteTypeParam;
  status: boolean;
}

/**
 * SWR-based hook for remote updating of data in favorites stripe.
 */
export function useFavoritesUpdater(): (item?: FavoritesUpdaterParams) => void {
  const { profileIdentifier, activeSubprofile } = useAuth();

  return (item?) => {
    if (profileIdentifier) {
      const key = `/favorites/${profileIdentifier}`;

      mutate(key);

      if (activeSubprofile?.kidsProfile) {
        mutate(`${key}/lasten`);
      }

      // Also update specific item if given
      if (item && profileIdentifier) {
        mutate(`${key}/${item.type}/${item.id}`, item.status, false);
      }
    }
  };
}

export function useFavoriteStatus(item: Asset | Category): UseFavoriteStatusResponse {
  const { authToken, isSubProfileLoaded, profileIdentifier } = useAuth();

  // identifier formed, and subprofile loaded or loading failed
  const allowFetch = item && authToken && profileIdentifier && isSubProfileLoaded;

  const type = isAsset(item) ? FavoriteTypeParam.AssetId : FavoriteTypeParam.CategoryId;
  const key = allowFetch ? `/favorites/${profileIdentifier}/${type}/${item.id}` : null;

  const { data, isValidating } = useSWR<boolean>(key, () => fetchFavoriteStatus(item.id, type, authToken), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    isFavorited: data,
    loading: isValidating,
    update: (data?: boolean) => {
      if (profileIdentifier) {
        mutate(`/favorites/${profileIdentifier}`);
        mutate(`/favorites/${profileIdentifier}/lasten`);

        if (data !== undefined) {
          mutate(`/favorites/${profileIdentifier}/${type}/${item.id}`, data);
        }

        // TODO mutate grid data
      }
    },
  };
}

export enum FavoriteTypeParam {
  AssetId = 'assetId',
  CategoryId = 'programCategoryId',
}

export async function fetchFavoriteStatus(
  id: number,
  typeParam: FavoriteTypeParam,
  authToken: string
): Promise<boolean> {
  if (!id || !authToken) {
    return false;
  }

  const resp = await fetchRaw(`${getKatsomoServiceApiUrl()}/user/favorites/hasFavorited`, {
    params: {
      site: 'cmore',
      [typeParam]: id,
    },
    headers: {
      Accept: 'application/json;v=2',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
  });

  if (!resp.ok) {
    throw new Error(`Fetching favorite status for ${id} failed`);
  }

  const json: FavoriteResponse = await resp.json();

  return json.hasFavorited;
}

export async function addFavorite(id: number, paramName: FavoriteTypeParam, authToken: string): Promise<boolean> {
  if (!id || !authToken) {
    return false;
  }

  try {
    const resp = await fetchRaw(`${getKatsomoServiceApiUrl()}/user/favorites`, {
      method: 'POST',
      body: JSON.stringify({
        site: 'cmore',
        [paramName]: id,
      }),
      headers: {
        Accept: 'application/json;v=2',
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
    });

    return resp.ok;
  } catch (e) {
    // skip error handling
  }

  return false;
}

export async function removeFavorite(id: number, paramName: FavoriteTypeParam, authToken: string): Promise<boolean> {
  if (!id || !authToken) {
    return false;
  }

  try {
    const url = buildURL(`${getKatsomoServiceApiUrl()}/user/favorites`, {
      site: 'cmore',
      [paramName]: id,
    });

    const resp = await fetchRaw(url, {
      method: 'DELETE',
      headers: {
        Authorization: authToken,
      },
    });

    return resp.ok;
  } catch (e) {
    // skip error handling
  }

  return false;
}
