import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Category, CategoryType } from '../../../models/category';
import { IconWrap, PlayButton, PlayLabel, StyledIconPlay } from './styles';
import { useAuth } from '../../../services/auth';
import { useRouter } from 'next/router';
import { categoryLink } from '../../../services/links';
import { useCategoryRights } from '../../../services/player';
import { NoAccess } from './NoAccess';
import { DateBadge } from '../../datetime-badge/DateBadge';
import { useDetailsFloat } from './hooks';
import { buildEDDLPlayEvent, useEDDL } from '../../../services/tracking/eddl-analytics';

interface CategoryPlayProps {
  category: Category;
  isUpcoming: boolean;
}

const StyledDateBadge = styled(DateBadge)`
  margin-bottom: 1rem;
`;

export const CategoryPlay: FunctionComponent<CategoryPlayProps> = ({ category, isUpcoming }: CategoryPlayProps) => {
  const { isAuthCompleted, isAuthenticated } = useAuth();
  const { push, asPath } = useRouter();
  const { isAccessible } = useCategoryRights(category);

  const { state } = useDetailsFloat();
  const sendEDDLPlayEvent = useEDDL(() => buildEDDLPlayEvent(state.contentContext, category), [category, state]);

  // Sport categories don't show play button because they generally have only live assets and old recordings,
  // and it's of no use to automatically play either of those
  if (category.type === CategoryType.Sport) {
    return null;
  }

  if (isUpcoming) {
    const nextEpisodeUp = new Date(category.nextUpcomingAssetLiveBroadcastTime);
    return <StyledDateBadge date={nextEpisodeUp} showTime={true} />;
  }

  // User has no rights to see this content
  if (isAuthenticated && !isAccessible) {
    const linkData = categoryLink(category);
    return <NoAccess linkData={linkData} />;
  }

  if (!isAuthCompleted) {
    return null;
  }

  const handleClick = () => {
    const linkData = categoryLink(category);

    push(linkData.href, `${linkData.as}?autoplay=${encodeURIComponent(asPath)}`);

    // Analytics
    sendEDDLPlayEvent();
  };

  return (
    <PlayButton onClick={handleClick} aria-label={`Toista ${category.title}`}>
      <IconWrap>
        <StyledIconPlay />
      </IconWrap>
      <PlayLabel>Katso {category.title}</PlayLabel>
    </PlayButton>
  );
};
