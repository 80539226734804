import { Category, CategorySubType, CategoryType } from '../../models/category';
import { Asset } from '../../models/asset';

export const ActionTypes = {
  CATEGORY_REQUEST: 'series/category_request',
  CATEGORY_SUCCESS: 'series/category_success',
  SELECTED_ASSET_SUCCESS: 'series/selected_asset_success',
  SELECTED_SEASON_REQUEST: 'series/selected_season_request',
  SELECTED_SEASON_SUCCESS: 'series/selected_season_success',
  CHANGE_SEASON_SUCCESS: 'series/change_season_success',
  SEASON_ASSETS_SUCCESS: 'series/season_assets_success',
  RESET: 'series_reset',
};

export const resetSeriesData = () => {
  return {
    type: ActionTypes.RESET,
  };
};

export const categoryRequest = (
  category: Category,
  forcedAsset: Asset,
  forcedSeasonId: number | false = null,
  cacheEnabled = true
) => {
  return {
    type: ActionTypes.CATEGORY_REQUEST,
    payload: {
      category,
      forcedAsset,
      forcedSeasonId,
      cacheEnabled,
    },
  };
};

export const categorySuccess = (category: Category) => {
  return {
    type: ActionTypes.CATEGORY_SUCCESS,
    payload: {
      category,
    },
  };
};

export const selectedAssetSuccess = (asset: Asset) => {
  return {
    type: ActionTypes.SELECTED_ASSET_SUCCESS,
    payload: {
      asset,
    },
  };
};

export const selectedSeasonRequest = (
  seasonId: string | number,
  seasonType: CategorySubType,
  categoryType: CategoryType,
  cacheEnabled: boolean = true
) => {
  return {
    type: ActionTypes.SELECTED_SEASON_REQUEST,
    payload: {
      seasonId,
      seasonType,
      categoryType,
      cacheEnabled,
    },
  };
};

export const selectedSeasonSuccess = (seasonId: string | number) => {
  return {
    type: ActionTypes.SELECTED_SEASON_SUCCESS,
    payload: {
      seasonId,
    },
  };
};

export const changeSeasonSuccess = (season: Category) => {
  return {
    type: ActionTypes.CHANGE_SEASON_SUCCESS,
    payload: {
      season,
    },
  };
};

export const seasonAssetsSuccess = (assets: Asset[], seasonId: string) => {
  return {
    type: ActionTypes.SEASON_ASSETS_SUCCESS,
    payload: {
      assets,
      seasonId,
    },
  };
};
