import {
  Tracker,
  TrackingEvent,
  TrackingEventData,
  TrackingEventHandlerType,
  TrackingEvents,
} from '../../models/tracking';
import compact from 'lodash/compact';
import take from 'lodash/take';
import get from 'lodash/get';
import { getConfig } from '../conf';
import { Order } from '../../models/order';
import { CmProduct, ProductPayment } from '../../models/product';
import { cleanUrl } from '../format';

interface AdobePageViewOptions {
  label: string;
  pageType?: string;
  events?: string;
  userId?: number | string;
  profileId?: string;
  orders?: Array<{ productId: number; productGroupId: number }>;
  productsDetails?: string; // Used when purchasing
  singleProductId?: string; // eVar56
  paymentType?: number | string;
  promocode?: string;
}

function updateDataLayer(opts: AdobePageViewOptions): void {
  const digitalData = window['digitalData'] || {};

  // Replace '/' with ':' in name
  // if name is passed in as URL and thus, is not compliant with adobe naming rules
  const name = 'cmore:' + opts.label.replace(/\//g, ':');
  const path = compact(name.split(':'));
  const isLoggedIn = Boolean(opts.userId);

  // Page section
  digitalData.page = {
    name: path.join(':'),
    brand: 'cmore',
    website: 'cmore.fi',
    hier1: take(path, 1).join(':'),
    hier2: take(path, 2).join(':'),
    hier3: take(path, 3).join(':'),
    hier4: take(path, 4).join(':'),
    hier5: take(path, 5).join(':'),
    login: isLoggedIn ? 'kirjautunut sisaan' : 'ei kirjautunut sisaan',
    error: '',
  };

  digitalData.pageType = opts.pageType || '';

  // User section
  digitalData.user = isLoggedIn ? { id: opts.userId } : {};
  digitalData.eVar79 = isLoggedIn && opts.profileId ? opts.profileId : null;

  // Events section
  digitalData.events = opts.events || '';

  // E-commerce section
  digitalData.ecommerce = {
    products: opts.productsDetails || '',
    paymentType: opts.paymentType || '',
    promocode: opts.promocode || '',
  };

  const orders: TrackingEventData['orders'] = get(opts, 'orders', []);
  const activeOrderProductIds = orders.map(({ productId }) => productId).join(',');

  digitalData.product = {
    active: activeOrderProductIds,
  };

  if (opts.singleProductId) {
    digitalData.product.cmoretuote = opts.singleProductId;
  } else {
    delete digitalData.product.cmoretuote;
  }

  // pt-object. Adobe analytics script reads directly from the window.pt -object.
  window['pt'] = {
    userid: opts.userId || '',
    cmore_tuote: activeOrderProductIds || '',
    cmore_ilmainen: '',
  };
}

function sendPageView(opts: AdobePageViewOptions): void {
  updateDataLayer(opts);

  const satellite = window['_satellite'];
  const fail = () => {
    console.log('[tracking] sending adobe page view failed', { satellite, digitalData: window['digitalData'] });
  };

  // Send page view event
  if (satellite && satellite.track) {
    try {
      satellite.track('pageview');
      console.log('[tracking] adobe page view', window['digitalData']);
    } catch (e) {
      fail();
    }
  } else {
    fail();
  }
}

const sendAdobeEvent = (description: string, data: Record<string, any>) => {
  const digitalData = window['digitalData'];

  const fail = () => {
    console.log('[tracking] sending adobe event failed', { description, data, digitalData });
  };

  if (digitalData && digitalData.helper && digitalData.helper.event) {
    try {
      digitalData.helper.event('o', description, data);
      console.log('[tracking] adobe event', { description, data });
    } catch (e) {
      fail();
    }
  } else {
    fail();
  }
};

const sendSimpleAdobeEvent = (description: string) => {
  const satellite = window['_satellite'];

  const fail = () => {
    console.log('[tracking] sending simple adobe event failed', { description, satellite });
  };

  if (satellite && satellite.track) {
    try {
      satellite.track(description);
      console.log('[tracking] adobe simple event', description);
    } catch (e) {
      fail();
    }
  } else {
    fail();
  }
};

export const adobeTracker: Tracker = {
  name: 'adobe',
  initialize: async () => {
    const { enabled, scriptUrl } = getConfig().tracking.adobe;

    if (!enabled || !scriptUrl) {
      return false;
    }

    // Initialize window.digitalData here, Adobe's script will fill it
    window['digitalData'] = window['digitalData'] || {};
    window['digitalData'].isReady = false;

    try {
      // Wait for Adobe to get its act together and mark as ready
      return await new Promise<boolean>((resolve) => {
        let retryCount = 200;

        const isReady = () => {
          if (!window['_satellite']?.track) {
            // Not ready yet

            // Retry count exceeded, fail init
            if (retryCount === 0) {
              console.log('[tracking] adobe init failed: retry count exceeded');
              resolve(false);
              return;
            }

            // Try again
            retryCount -= 1;
            setTimeout(() => {
              isReady();
            }, 100);
          } else {
            window['digitalData'].isReady = true;
            resolve(true);
          }
        };

        isReady();
      });
    } catch (e) {
      return false;
    }
  },
  [TrackingEventHandlerType.PageView]: (event: TrackingEvent) => {
    const url = new URL(event.url);
    const params = new URLSearchParams(url.search);

    // Don't send page view events when showing purchase-related modals
    if (params.has('maksu') || params.has('peruttu')) {
      return;
    }

    sendPageView({
      label: cleanUrl(event.label),
      userId: event.data.userId,
      profileId: event.data.profileId,
      orders: event.data.orders,
    });
  },
  [TrackingEventHandlerType.CustomEvent]: (event: TrackingEvent) => {
    switch (event.label) {
      case TrackingEvents.SignupDone:
        (() => {
          updateDataLayer({
            label: 'rekisteroityminen:hyvaksytty',
            events: 'event12',
            userId: event.data.userId,
            orders: event.data.orders,
          });
          sendAdobeEvent('Registration success', {
            events: 'event12',
          });
        })();
        break;
      case TrackingEvents.SignupError:
        (() => {
          updateDataLayer({
            label: 'rekisteroityminen:hylatty',
            events: 'event13',
            userId: event.data.userId,
            orders: event.data.orders,
          });
          sendAdobeEvent('When registration for a service fails', {
            events: 'event13',
          });
        })();
        break;
      case TrackingEvents.PurchaseProductView:
      case TrackingEvents.PurchaseConfirmation:
        (() => {
          const { product } = event.data as TrackingEventData & {
            product: CmProduct;
          };

          const events = event.label === TrackingEvents.PurchaseProductView ? 'prodView' : 'scOpen,scAdd';

          updateDataLayer({
            label: 'maksaminen',
            events,
            productsDetails: `${product.product.productGroupId};${product.product.id}`,
            userId: event.data.userId,
            orders: event.data.orders,
          });
          sendAdobeEvent('maksaminen', {
            events,
            products: `${product.product.productGroupId};${product.product.id}`,
          });
        })();
        break;
      case TrackingEvents.PurchaseCheckout:
        (() => {
          // TODO event9 = upgrade?
          // TODO event10 = downgrade?
          const { product, productPayment } = event.data as TrackingEventData & {
            product: CmProduct;
            productPayment: ProductPayment;
          };

          updateDataLayer({
            label: 'maksaminen:siirrymaksamaan',
            events: 'scCheckout',
            productsDetails: `${product.product.productGroupId};${product.product.id}`,
            paymentType: productPayment['@id'],
            userId: event.data.userId,
            orders: event.data.orders,
          });
          sendAdobeEvent('maksaminen:siirrymaksamaan', {
            events: 'scCheckout',
            products: `${product.product.productGroupId};${product.product.id}`,
          });
        })();
        break;
      case TrackingEvents.PurchaseDone:
        (() => {
          const { product, productId, productGroupId, paymentId } = event.data as TrackingEventData & {
            product: CmProduct;
            productGroupId: string;
            productId: string;
            paymentId: string;
          };

          sendPageView({
            label: 'maksaminen:hyvaksytty',
            events: 'purchase',
            productsDetails: `${productGroupId};${productId};1;${product.product.price}`,
            paymentType: paymentId,
            userId: event.data.userId,
            orders: event.data.orders,
          });

          sendSimpleAdobeEvent('gtag-purchase');
        })();
        break;
      case TrackingEvents.CancelDone:
        (() => {
          const { order } = event.data as { order: Order };

          sendPageView({
            label: 'cancelorder',
            events: 'event26',
            singleProductId: `${order.productId}`,
            userId: event.data.userId,
            orders: event.data.orders,
          });
        })();
        break;
      case TrackingEvents.CancelCurated:
        (() => {
          // Product to be cancelled
          const { cancelProduct } = event.data as { cancelProduct: CmProduct };

          sendPageView({
            label: `profiili:peruutusputki:peruuta:${cancelProduct.product.id}`,
            singleProductId: `${cancelProduct.product.id}`,
            userId: event.data.userId,
            orders: event.data.orders,
          });
        })();
        break;
      case TrackingEvents.PurchaseSwitchCurated:
        (() => {
          const { cancelProduct, product } = event.data as { cancelProduct: CmProduct; product: CmProduct };

          sendPageView({
            label: `profiili:peruutusputki:vaihda:${cancelProduct.product.id}:${product.product.id}`,
            userId: event.data.userId,
            orders: event.data.orders,
          });
        })();
        break;
      case TrackingEvents.SurveyDismiss:
        (() => {
          const { surveyId } = event.data;
          sendAdobeEvent('cx-score:suljettu', {
            events: 'event57',
            prop29: surveyId,
          });
        })();
        break;
      case TrackingEvents.SurveyAnswer:
        (() => {
          const { surveyId, rating } = event.data;
          sendAdobeEvent('cx-score:vastattu', {
            events: 'event56',
            prop29: surveyId,
            prop30: rating,
          });
        })();
        break;
    }
  },
};
