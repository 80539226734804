import React from 'react';
import { OpenNativePlayerModal } from '../components/player/OpenNativePlayerModal';
import { Asset } from '../models/asset';
import { useOwletModal } from 'owlet-ui';

export function useNativeLinkModal() {
  const { openModal } = useOwletModal();

  const openNativeLinkModal = (asset: Asset) => {
    openModal({
      name: 'OpenNativePlayerModal',
      modal: <OpenNativePlayerModal asset={asset} />,
    });
  };

  return { openNativeLinkModal };
}
