import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IconClose } from '../icon/icons';
import { WHITE } from '../colors';
import { greaterThan } from '../breakpoints';
import { regular } from '../typography';

export interface ModalCloseProps {
  onClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const InvisibleButton = styled.button`
  ${regular()};
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  appearance: none !important;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 5;
  overflow: hidden;
  background: none;
  display: flex;
  align-items: center;
`;

const ButtonLabel = styled.span`
  color: ${WHITE};
  text-transform: uppercase;
  font-size: 1rem;
  padding-right: 0.75rem;
`;

const CloseIcon = styled(IconClose)`
  width: 24px;
  display: block;
  fill: ${WHITE};

  ${greaterThan('small')`
    width: 32px;
  `}
`;

export const ModalClose: FunctionComponent<ModalCloseProps> = ({ onClick }: ModalCloseProps) => {
  return (
    <InvisibleButton onClick={onClick} aria-labelledby="close-modal">
      <ButtonLabel id="close-modal">Sulje</ButtonLabel>
      <CloseIcon />
    </InvisibleButton>
  );
};
