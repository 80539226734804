import React, { FunctionComponent } from 'react';
import { NaviMenuLink } from './NaviMenuLink';
import { IconSearch } from 'owlet-ui';
import { MenuIcon } from './styles';

const icon = (
  <MenuIcon data-test="Navi__Menu__Search">
    <IconSearch />
  </MenuIcon>
);

export const NaviMenuSearch: FunctionComponent = () => {
  return <NaviMenuLink path="/hae" title="Hae" image={icon} />;
};
