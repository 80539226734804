import React from 'react';
import { SVGProps } from '../utils';

const SvgFavoriteOn = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path
      d="M452.31 149c-13.93-39.71-43-64.33-83.71-71.25A114.22 114.22 0 00349 76.1c-32.27 0-60.66 14.25-82 41.18l-11.11 14-12-15.19C224.33 91 193.42 76 161 76a106.35 106.35 0 00-28.18 3.88c-37 10.58-65.48 39.39-76.17 77.22-10.9 38.56-1.36 79.1 25.56 108.34 36.57 39.71 74 79.84 110.12 118.71l35.1 37.72c8.8 9.43 18.34 14.14 28.39 14.14s19.8-4.82 28.71-14.35L326 377.33c34.37-36.88 68-72.92 101.94-109.8 31.18-34.16 39.67-75.13 24.37-118.53z"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={26.5}
    />
  </svg>
);

export default SvgFavoriteOn;
