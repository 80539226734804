import { useDispatch } from 'react-redux';
import { FeedbackLevel, FeedbackMessage } from '../models/feedback';
import { addFeedbackMessage, clearFeedbackMessages, removeFeedbackMessage } from '../state/feedback/feedback.actions';
import uniqueId from 'lodash/uniqueId';

export interface UseFeedback {
  add: (message: FeedbackMessage) => void;
  info: (message: FeedbackMessage) => void;
  error: (message: FeedbackMessage) => void;
  success: (message: FeedbackMessage) => void;
  fatal: (message: FeedbackMessage) => void;
  remove: (message: FeedbackMessage) => void;
  clear: () => void;
}

export const generateFeedbackId = () => uniqueId(`${Date.now()}_`);

export function useFeedback(): UseFeedback {
  const dispatch = useDispatch();

  function add(message: FeedbackMessage) {
    dispatch(
      addFeedbackMessage({
        id: generateFeedbackId(),
        level: FeedbackLevel.Info, // default to info level
        ...message,
      })
    );
  }

  function info(message: FeedbackMessage) {
    add({
      ...message,
      level: FeedbackLevel.Info,
    });
  }

  function error(message: FeedbackMessage) {
    add({
      ...message,
      level: FeedbackLevel.Error,
    });
  }

  function success(message: FeedbackMessage) {
    add({
      ...message,
      level: FeedbackLevel.Success,
    });
  }

  function fatal(message: FeedbackMessage) {
    add({
      ...message,
      level: FeedbackLevel.Fatal,
    });
  }

  function remove(message: FeedbackMessage) {
    console.log('[feedback] Close manually', message);
    dispatch(removeFeedbackMessage(message));
  }

  function clear() {
    dispatch(clearFeedbackMessages());
  }

  return {
    add,
    info,
    error,
    success,
    fatal,
    remove,
    clear,
  };
}
