import React from 'react';
import { SVGProps } from '../utils';

const SvgClose = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path d="M274 255l151-151a13 13 0 10-19-19L255 236 104 85a13 13 0 10-19 19l151 151L85 406a13 13 0 1019 19l151-151 151 151a13 13 0 1019-19z" />
  </svg>
);

export default SvgClose;
