import { useSelector } from 'react-redux';
import * as browser from './browser';
import { Notification, ClosedNotification, LoginStatus, PersonalNotification } from '../models/notification';
import { getMotherbirdApiUrl } from './conf';
import head from 'lodash/head';
import has from 'lodash/has';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import { Order } from '../models/order';
import { User } from '../models/user';
import { Subprofile } from '../models/subprofile';
import { setItem, Keys, getItem, getKeys, removeItem } from './local-storage';
import {
  selectMenuNotificationCount,
  hasLoadedNotifications,
  selectNotificationCenterOpened,
  selectOverlayNotifications,
  selectClosedNotifications,
} from '../state/notifications/notifications.selectors';
import { useAuth } from './auth';
import { SubscriptionStatus } from '../models/paths';
import { fetchRaw } from './api';
import { selectSubprofilesErrorStatus } from '../state/user/user.selectors';
import { Category } from '../models/category';
import { useRouter } from 'next/router';
import { useOrders } from './orders';

interface UseNotificationsResponse {
  menuUnseenNotificationsCount: number;
}

export const useOverlayNotification = (): Notification | null => {
  const { asPath } = useRouter();
  const { user } = useAuth();
  const { activeOrders } = useOrders();
  const closedNotifications = useSelector(selectClosedNotifications);
  const overlayNotifications = useSelector(selectOverlayNotifications)
    .filter(
      (notification) =>
        visibleForUser(notification, user) &&
        visibleForBrowser(notification) &&
        visibleForUserOrders(notification, activeOrders) &&
        visibleForSubscriptionStatus(notification, activeOrders) &&
        notSeen(notification, closedNotifications)
    )
    .filter((notification) => {
      const isHomepage = ['/', '/etusivu'].includes(asPath);
      return notification.conditions?.paths?.includes(asPath) ?? isHomepage;
    });

  return overlayNotifications.length > 0 ? overlayNotifications[0] : null;
};

export const useNotifications = (): UseNotificationsResponse => {
  const { activeSubprofile, isAuthCompleted } = useAuth();
  const notificationCount = useSelector(selectMenuNotificationCount);
  const loaded = useSelector(hasLoadedNotifications);
  const notificationCenterSeen = useSelector(selectNotificationCenterOpened);
  const subprofilesError = useSelector(selectSubprofilesErrorStatus);
  const authReady = activeSubprofile && isAuthCompleted;
  const notificationCenterReady = loaded && (authReady || subprofilesError) && notificationCenterSeen;
  return {
    menuUnseenNotificationsCount: notificationCenterReady ? notificationCount : 0,
  };
};

export async function fetchNotifications(): Promise<Notification[]> {
  const resp = await fetchRaw(`${getMotherbirdApiUrl()}/v3/static/svod/web/notifications/web`);
  return resp.json();
}

export async function fetchPersonalNotifications(authToken: string): Promise<Notification[]> {
  const resp = await fetchRaw(`${getMotherbirdApiUrl()}/v3/svod/web/personal-notifications`, {
    headers: {
      Authorization: authToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return resp.json();
}

export function filterBannerNotifications(notifications: Notification[], user: User, orders: Order[]): Notification[] {
  return notifications
    .filter(visibleForBrowser)
    .filter((notification: Notification) => !isOverlayNotification(notification))
    .filter((notification: Notification) => visibleForUser(notification, user))
    .filter((notification: Notification) => visibleForUserOrders(notification, orders))
    .filter((notification: Notification) => visibleForSubscriptionStatus(notification, orders))
    .filter((notification: Notification) => {
      return hasPathTargetedConditions(notification) || isEmergencyAndUserNotLogged(notification, user);
    });
}

export function filterCenterNotifications(notifications: Notification[], user: User, orders: Order[]): Notification[] {
  return notifications
    .filter(visibleForBrowser)
    .filter((notification: Notification) => visibleForUser(notification, user))
    .filter((notification: Notification) => visibleForUserOrders(notification, orders))
    .filter((notification: Notification) => visibleForSubscriptionStatus(notification, orders))
    .filter(
      (notification: Notification) => notification.type === 'overlay' || !hasPathTargetedConditions(notification)
    );
}

export const orderNotificationCenterNotifications = (notifications: Notification[]) => {
  // Order by type (emergencies first) and by publish date (newest first)
  return orderBy(notifications, ['type', (notification: Notification) => notification.published], ['asc', 'desc']);
};

export function visibleForBrowser(notification: Notification): boolean {
  if (!hasConditions(notification)) {
    return true;
  }
  const conditions = notification.conditions;
  const browserDetectors = {
    chrome: browser.isChrome,
    edge: browser.isEdge,
    firefox: browser.isFirefox,
    safari: browser.isSafari,
    safari8: browser.isSafari8,
    safari9: browser.isSafari9,
    safari10: browser.isSafari10,
    safari11: browser.isSafari11,
    safari12: browser.isSafari12,
    internetexplorer: browser.isIE,
  };
  const browsers = conditions.browsers;
  if (!browsers || browsers.length < 1) {
    return true;
  }

  const matchesUsedBrowser = !!browsers.find((browser) => {
    const detector = browserDetectors[browser];
    return detector && detector();
  });

  // Optionally match notification to specific major version of a browser
  // Version check only makes sense when notification is targeted at one browser (browsers.length === 1)
  if (browsers.length === 1 && (conditions.maxAppVersion || conditions.minAppVersion)) {
    const maxAppVersion = conditions.maxAppVersion || Number.MAX_VALUE;
    const minAppVersion = conditions.minAppVersion || 0;
    const majorVersion = head(browser.getBrowserVersion());
    return matchesUsedBrowser && majorVersion && majorVersion >= minAppVersion && majorVersion <= maxAppVersion;
  }

  return matchesUsedBrowser;
}

export function visibleForUser(notification: Notification, user: User): boolean {
  if (!hasConditions(notification)) {
    return true;
  }
  const status = notification.conditions.loginStatus;
  if (!status) {
    return true;
  }

  if (!user && (status === LoginStatus.LoggedOut || notification.conditions.paymentFlow)) {
    return true;
  } else if (user && status === LoginStatus.LoggedIn) {
    return true;
  } else {
    return false;
  }
}

export function isOverlayNotification(notification: Notification) {
  return notification.type === 'overlay';
}

export function isEmergencyAndUserNotLogged(notification: Notification, user: User): boolean {
  if (notification.type !== 'emergency') {
    return false;
  }

  // Emergency notifications should always be shown for non-logged in users
  return !user;
}

export function visibleForUserOrders(notification: Notification, orders: Order[]): boolean {
  if (!hasConditions(notification)) {
    return true;
  }

  const { productIds: products, productGroups } = notification.conditions;

  const productIds = (products || []).map(Number);
  const productGroupIds = (productGroups || []).map(Number);

  if (productIds.length === 0 && productGroupIds.length === 0) {
    return true;
  }
  if (!orders) {
    return false;
  }

  return !!orders.find((order: Order) => {
    return includes(productIds, order.productId) || includes(productGroupIds, order.productGroupId);
  });
}

export function visibleForPaths(notification: Notification, currentPath: string): boolean {
  if (!hasConditions(notification)) {
    return true;
  }

  const paths = notification.conditions.paths;

  if (notification.conditions.paymentFlow) {
    return visibleInPaymentFlow();
  }

  if (!paths || paths.length === 0) {
    return true;
  }

  return paths.some((path) => {
    // remove trailing slash
    path = path.replace(/\/$/, '');

    if (currentPath.match(new RegExp(`^${path}[/]?`))) {
      return true;
    } else if (path.match(/^\/category\/\d+/)) {
      return matchAssetOrCategory('[\\w-]+', path);
    } else if (path.match(/^\/asset\/\d+/)) {
      return matchAssetOrCategory('elokuva', path);
    } else if (path === '/etusivu' /*Path.Etusivu*/) {
      return currentPath === '/';
    }
  });

  function visibleInPaymentFlow(): boolean {
    return /^\/rekisterointi|profiili\/vaihda|\/tilaa(?:$|\/)/.test(currentPath);
  }

  function matchAssetOrCategory(pathPrefix: string, path: string): boolean {
    return new RegExp(`^/${pathPrefix}/${path.match(/\d+/)[0]}`).test(currentPath);
  }
}

export function visibleForModals(notification: Notification, currentModalPath: string): boolean {
  if (!hasConditions(notification)) {
    return true;
  }

  const paths = notification.conditions.paths;
  if (!paths || paths.length === 0) {
    return true;
  }

  return paths.some((path) => {
    // Compare paths without trailing slash
    return path.replace(/\/$/, '') === currentModalPath;
  });
}

export function visibleForSubscriptionStatus(notification: Notification, orders: Order[]) {
  if (!hasConditions(notification)) {
    return true;
  }

  const status = notification.conditions.subscriptionStatus;
  const hasActiveOrders = (orders || []).some(
    (order: Order) => order && order.status && order.status.toString() === SubscriptionStatus.Active
  );

  if (!status) {
    return true;
  } else if (status === SubscriptionStatus.Inactive && !hasActiveOrders) {
    return true;
  } else if (status === SubscriptionStatus.Active && hasActiveOrders) {
    return true;
  } else {
    return false;
  }
}

export function visibleForCategory(notification: Notification, category: Category): boolean {
  if (!category || !notification.conditions) {
    return false;
  }

  const { categories, paths } = notification.conditions;
  const categoryIds = [category.id, ...(category.groups ? category.groups.map(({ id }) => id) : [])];

  return categoryIds.some((id) => categories?.includes(`${id}`) || paths?.includes(`/category/${id}`));
}

export function notSeen(notification: Notification, closedNotifications: ClosedNotification[]): boolean {
  // notification has updated timestamp -> show it to user
  // example value: published: "20191221T1330+0200"
  const closedItem = closedNotifications.find((closedItem) => closedItem.id === notification.id);
  if (closedItem) {
    return isAfter(parseISO(notification.published), parseISO(closedItem.published));
  }
  return true;
}

export function hasConditions(notification: Notification): boolean {
  return notification.conditions && Object.keys(notification.conditions).length > 0;
}

export function hasPathTargetedConditions(notification: Notification): boolean {
  return (
    has(notification, 'conditions.paths') ||
    has(notification, 'conditions.assets') ||
    has(notification, 'conditions.categories') ||
    has(notification, 'conditions.paymentFlow')
  );
}

export function storeNotificationCenterSeen(seenAt: Date, profile: Subprofile | User): void {
  const unixTime = getUnixTime(seenAt);
  setItem(Keys.NOTIFICATION_CENTER_OPENED, `${unixTime}`, { suffix: `_${profile.id}` });
}

export function restoreNotificationCenterSeen(profile: Subprofile | User): Date {
  const notificationCenterOpened = getItem(Keys.NOTIFICATION_CENTER_OPENED, { suffix: `_${profile.id}` });
  if (notificationCenterOpened) {
    return fromUnixTime(parseInt(notificationCenterOpened, 10));
  }
  // No previously recorded date, use January 1, 1970
  return new Date(0);
}

export function storeClosedNotification(notification: Notification, suffix?: string) {
  setItem(Keys.NOTIFICATION, `${notification.published}`, { suffix: `-${[notification.id, suffix].join('|')}` });
}

export function removeClosedNotificationFromStore(notification: Notification) {
  removeItem(Keys.NOTIFICATION, { suffix: `-${notification.id}` });
}

export function restoreClosedNotifications(suffix: string): ClosedNotification[] {
  return getKeys(Keys.NOTIFICATION)
    .filter((key) => !key.includes('|') || key.endsWith(`|${suffix}`))
    .map((key) => {
      let id = key.replace(`${Keys.NOTIFICATION}-`, '');

      if (id.includes('|') && suffix) {
        id = id.split('|')[0];
      }

      return {
        id,
        published: getItem(Keys.NOTIFICATION, { suffix: `-${suffix ? [id, suffix].join('|') : id}` }),
      };
    });
}

export function isPersonalNotification(
  notification: Notification | PersonalNotification
): notification is PersonalNotification {
  return typeof notification['type'] === 'undefined';
}

export function sortNotifications(a: Notification | PersonalNotification, b: Notification | PersonalNotification) {
  const aDate = 'published' in a ? parseISO(a.published) : new Date(a.createdAt);
  const bDate = 'published' in b ? parseISO(b.published) : new Date(b.createdAt);
  return bDate.getTime() - aDate.getTime();
}
