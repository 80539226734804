import { customEvent, pageView } from './tracking-depot';
import { useAuth } from '../auth';
import { useOrders } from '../orders';
import { UserContext } from '../../models/tracking';

export interface UseTracking {
  sendEvent: (label: string, data?: Record<string, any>) => void;
  sendPageView: (path: string) => void;
}

/**
 * Hook for tracking click events and custom events.
 *
 * Returns an object with `sendEvent` and `sendPageView` methods.
 * Note: page view events are sent automatically by TrackingInit component.
 *
 * @example
 * const { sendEvent } = useTracking();
 *
 * const onBuySuccess = ({ product }: CmProduct) => {
 *   const { id, productGroupId } = product;
 *   sendEvent('purchase', { product: { id, productGroupId } });
 * }
 */
export function useTracking(): UseTracking {
  const { user, activeSubprofile } = useAuth();
  const { activeOrders } = useOrders();

  const createUserContext = (): UserContext => ({
    userId: user ? user.id : null,
    profileId: activeSubprofile ? activeSubprofile.id : null,
    orders: activeOrders ? activeOrders.map(({ productGroupId, productId }) => ({ productId, productGroupId })) : [],
  });

  return {
    sendEvent: (label: string, data: Record<string, any> = {}) => {
      setTimeout(() => {
        customEvent(label, {
          ...data,
          ...createUserContext(),
        });
      }, 0);
    },

    sendPageView: (path: string) => {
      setTimeout(() => {
        pageView(path, createUserContext());
      }, 0);
    },
  };
}
