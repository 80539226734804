import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Asset } from '../../models/asset';
import { getImageUrl } from '../../services/images';
import { ImageOrientation, ImageSize } from '../../models/image';
import { StripeImage } from '../stripe/StripeImage';
import { BottomRightLabels, TopLeftLabels, TopRightLabels } from '../stripe/stripes';
import { isSpeakFinnish, isUpcoming } from '../../services/assets';
import { AssetDateBadge } from '../datetime-badge/AssetDateBadge';
import { ratio, WHITE15 } from 'owlet-ui';
import { movieLink } from '../../services/links';
import Link from 'next/link';
import classNames from 'classnames';
import { selectIsMobileLayout } from '../../state/app/app.selectors';
import { useContentLabels } from '../../services/labels';
import { SpeakFinLabel } from '../labels/SpeakFinLabel';
import { CuratedAsset } from '../../models/curated';
import { useDetailsFloat } from '../stripe/float/hooks';
import { GridItemOptions } from '../../services/swr-grid';
import { RatingLabels } from '../labels/RatingLabels';
import { buildEDDLMoreinfoEvent, useEDDL } from '../../services/tracking/eddl-analytics';
import { ContentItemContext, ContentSectionContext } from '../../services/content-context';
import { ProductLockLabel } from '../stripe/product-lock/ProductLockLabel';

type GridMovieProps = {
  asset: Asset;
  options?: GridItemOptions;
};

const AssetGridItemContainer = styled.a`
  display: block;
  transform-origin: 50% 50%;
  padding-bottom: ${ratio(4, 2.75 * 2)};
  transition: transform 350ms ease;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    opacity: 0;
    transition: opacity 250ms ease;
    pointer-events: none;
  }
`;

const AssetGridItem = styled.li`
  display: block;
  overflow: hidden;
  position: relative;
  background: ${WHITE15};
  border-radius: 0.25rem;
  margin: 0 ${(props) => props.theme.stripeHalfMargin} 3rem;

  ${BottomRightLabels} {
    &::before {
      content: '';
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
      position: absolute;
      top: -100%;
      right: 0;
      left: -999px;
      bottom: 0;
      z-index: -1;
      pointer-events: none;
    }
  }
`;

const Upcoming = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  transition: opacity 250ms ease;
`;

export const GridMovie: FunctionComponent<GridMovieProps> = ({ asset, options = {} }: GridMovieProps) => {
  const isMobile = useSelector(selectIsMobileLayout);
  const { topLeftLabels, topRightLabels } = useContentLabels(asset, 'normal');
  const { sort } = options;

  const id = `grid-movie-${asset.id}`;
  const { state, updateState } = useDetailsFloat();

  const curatedItem = useMemo<CuratedAsset>(
    () => ({
      asset,
      assetId: asset.id,
    }),
    [asset]
  );
  const imageUrl = getImageUrl(asset, ImageOrientation.Portrait, ImageSize.Medium);
  const linkData = movieLink(asset);

  const { index } = useContext(ContentItemContext);
  const contentSectionContext = useContext(ContentSectionContext);
  const handleMouseOver = useCallback(() => {
    if (state?.item !== curatedItem) {
      updateState({
        item: curatedItem,
        parentId: id,
        contentContext: {
          ...contentSectionContext,
          index,
        },
        wideParent: false,
      });
    }
  }, [id, state, index, updateState, curatedItem, contentSectionContext]);

  const handleEDDL = useEDDL(() => buildEDDLMoreinfoEvent({ ...contentSectionContext, index }, asset), [
    asset,
    index,
    contentSectionContext,
  ]);

  const className = classNames({ mobile: isMobile });

  return (
    <AssetGridItem className={className} onMouseOver={handleMouseOver} id={id}>
      <Link href={linkData.href} as={linkData.as} passHref>
        <AssetGridItemContainer onClick={handleEDDL}>
          <StripeImage url={imageUrl} orientation={ImageOrientation.Portrait} alt={asset.title} />
          {topLeftLabels?.length > 0 && <TopLeftLabels>{topLeftLabels}</TopLeftLabels>}
          <TopRightLabels>
            <ProductLockLabel item={asset}>
              {isSpeakFinnish(asset) && <SpeakFinLabel />}
              {topRightLabels}
            </ProductLockLabel>
          </TopRightLabels>
          {isUpcoming(asset) && (
            <Upcoming>
              <AssetDateBadge asset={asset} />
            </Upcoming>
          )}
          <RatingLabels item={asset} ratingType={sort} />
        </AssetGridItemContainer>
      </Link>
    </AssetGridItem>
  );
};
