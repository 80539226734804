import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import { WHITE15 } from 'owlet-ui';
import { useDispatch } from 'react-redux';
import { logoutRequest } from '../../state/user/user.actions';
import { useAuth } from '../../services/auth';

interface NaviLogoutProps {
  className?: string;
}

const LogoutLink = styled.a`
  border-top: 1px solid ${WHITE15};
`;

export const NaviLogout: FunctionComponent<NaviLogoutProps> = ({ className }) => {
  const dispatch = useDispatch();
  const { isAuthLoading, isAuthenticated, isLoggingOut } = useAuth();
  const logout = useCallback(() => {
    dispatch(logoutRequest());
  }, []);

  if (isAuthLoading || !isAuthenticated || isLoggingOut) {
    return null;
  }

  return (
    <li className={className}>
      <LogoutLink onClick={logout} data-test="NaviLogout__link">
        Kirjaudu ulos
      </LogoutLink>
    </li>
  );
};
