import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IconImdb, WHITE } from 'owlet-ui';
import { Asset } from '../../models/asset';
import { Category } from '../../models/category';
import { ExternalLink } from '../link/ExternalLink';
import { formatNumber } from '../../services/format/number';

interface ImdbRatingProps {
  item: Asset | Category;
  className?: string;
  onlyRating?: boolean;
}

const Wrap = styled.span`
  display: inline-flex;
  align-items: center;

  a {
    display: inline-flex;
    align-items: center;
    color: ${WHITE};
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    svg {
      width: 2.7rem;
      margin-right: 0.6rem;
    }
  }
`;

export const ImdbRating: FunctionComponent<ImdbRatingProps> = ({ item, className, onlyRating }: ImdbRatingProps) => {
  const { imdbId, imdbRating: rating } = item;

  if (!imdbId || !rating) {
    return null;
  }

  const ratingElement = (
    <>
      <IconImdb />
      <span data-test="ImdbRating__Rating">{formatNumber(rating, 1)}</span>
    </>
  );

  return (
    <Wrap className={className}>
      {onlyRating && ratingElement}
      {!onlyRating && <ExternalLink href={`https://www.imdb.com/title/${imdbId}/`}>{ratingElement}</ExternalLink>}
    </Wrap>
  );
};
