import React, { FunctionComponent } from 'react';
import { LinkData } from '../../../services/links';
import Link from 'next/link';
import { IconWrap, PlayInactive, PlayLabel, StyledIconLock } from './styles';

interface NoAccessProps {
  linkData: LinkData;
}

export const NoAccess: FunctionComponent<NoAccessProps> = ({ linkData }: NoAccessProps) => {
  if (!linkData) {
    return null;
  }

  return (
    <Link href={linkData.href} as={linkData.as} passHref>
      <PlayInactive>
        <IconWrap>
          <StyledIconLock />
        </IconWrap>
        <PlayLabel>Ei katseluoikeutta</PlayLabel>
      </PlayInactive>
    </Link>
  );
};
