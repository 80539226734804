import { delay, fork, put, select, takeEvery } from 'redux-saga/effects';
import { ActionTypes, removeFeedbackMessage } from './feedback.actions';
import { FeedbackMessage } from '../../models/feedback';
import { selectFeedbackMessagesWithNavigationClose } from './feedback.selectors';

export function* setFeedbackTimeout(action): any {
  const message: FeedbackMessage = action.payload;

  if (message.timeout) {
    yield delay(message.timeout);
    console.log('[feedback] Close on timeout', message);
    yield put(removeFeedbackMessage(message));
  } else {
    yield null;
  }
}

export function* clearOnNavigationChange(): any {
  const messages = yield select(selectFeedbackMessagesWithNavigationClose);

  while (messages.length) {
    const message = yield messages.shift();
    console.log('[feedback] Close on navigation', message);
    yield put(removeFeedbackMessage(message));
  }
}

export function* watchAddFeedbackMessage() {
  yield takeEvery(ActionTypes.ADD_FEEDBACK_MESSAGE, setFeedbackTimeout);
}

export function* watchNavigationChange() {
  yield takeEvery(ActionTypes.CLEAR_FEEDBACK_MESSAGES_ON_NAVIGATION, clearOnNavigationChange);
}

const feedbackSagas = [fork(watchAddFeedbackMessage), fork(watchNavigationChange)];

export default feedbackSagas;
