import { put, call, fork, takeLatest } from 'redux-saga/effects';
import {
  notificationsSuccess,
  notificationsFail,
  ActionTypes,
  notificationsLoaded,
  personalNotificationsSuccess,
  personalNotificationsFail,
} from './notifications.actions';
import { fetchNotifications, fetchPersonalNotifications } from '../../services/notifications';
import { Notification, PersonalNotification } from '../../models/notification';
import { AnyAction } from 'redux';

export function* fetchAllNotifications(): any {
  try {
    const notifications: Notification[] = yield call(fetchNotifications);
    yield put(notificationsSuccess(notifications));
    yield put(notificationsLoaded());
  } catch (e) {
    console.error('Failed to load notifications', e);
    yield put(notificationsFail());
  }
}

export function* fetchAllPersonalNotifications(action: AnyAction): any {
  try {
    const authToken = action.payload;

    if (authToken) {
      const personalNotifications: PersonalNotification[] = yield call(fetchPersonalNotifications, authToken);
      yield put(personalNotificationsSuccess(personalNotifications));
    }
  } catch (e) {
    console.error('Failed to load personal notifications', e);
    yield put(personalNotificationsFail());
  }
}

export function* watchNotificationsRequest() {
  yield takeLatest(ActionTypes.NOTIFICATIONS_REQUEST, fetchAllNotifications);
  yield takeLatest(ActionTypes.PERSONAL_NOTIFICATIONS_REQUEST, fetchAllPersonalNotifications);
}

const notificationsSagas = [fork(watchNotificationsRequest)];

export default notificationsSagas;
