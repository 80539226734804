import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import rgba from 'color-alpha';
import classNames from 'classnames';
import { TELIAPURPLE } from '../colors';

export interface CircularProgressProps {
  color?: string;
  size?: ProgressSize;
  className?: any;
  button?: boolean;
  delay?: number;
}

export enum ProgressSize {
  DEFAULT = 'default',
  SMALL = 'small',
  TINY = 'tiny',
  MINI = 'mini',
}

const CIRCULAR_PROGRESS_WIDTH = {
  [ProgressSize.DEFAULT]: '6rem',
  [ProgressSize.SMALL]: '3.5rem',
  [ProgressSize.TINY]: '1.75rem',
  [ProgressSize.MINI]: '1.15rem',
};

const CIRCULAR_PROGRESS_BORDER = {
  [ProgressSize.DEFAULT]: '0.75rem',
  [ProgressSize.SMALL]: '0.55rem',
  [ProgressSize.TINY]: '0.35rem',
  [ProgressSize.MINI]: '0.25rem',
};

const Wrap = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

const AnimatedWrap = styled.div<CircularProgressProps>`
  animation: spin 1.1s infinite linear;
  border-radius: 50%;
  width: ${(props) => CIRCULAR_PROGRESS_WIDTH[props.size as ProgressSize]};
  height: ${(props) => CIRCULAR_PROGRESS_WIDTH[props.size as ProgressSize]};
  border-top: ${(props) => CIRCULAR_PROGRESS_BORDER[props.size as ProgressSize]} solid
    ${(props) => rgba(props.color as string, 0.2)};
  border-right: ${(props) => CIRCULAR_PROGRESS_BORDER[props.size as ProgressSize]} solid
    ${(props) => rgba(props.color as string, 0.2)};
  border-bottom: ${(props) => CIRCULAR_PROGRESS_BORDER[props.size as ProgressSize]} solid
    ${(props) => rgba(props.color as string, 0.2)};
  border-left: ${(props) => CIRCULAR_PROGRESS_BORDER[props.size as ProgressSize]} solid ${(props) => props.color};

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const CircularProgress: FunctionComponent<CircularProgressProps> = ({
  color = TELIAPURPLE,
  size = ProgressSize.DEFAULT,
  button = false,
  className,
  delay = 0,
}: CircularProgressProps) => {
  const [visible, setVisible] = useState<boolean>(delay === 0);
  const timerRef = useRef<any>(null);

  useEffect(() => {
    if (delay > 0) {
      timerRef.current = setTimeout(() => {
        setVisible(true);
      }, delay);
    }

    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  if (!visible) {
    return null;
  }

  const wrapClassName = classNames(className, {
    button,
  });

  return (
    <Wrap className={wrapClassName} data-test="CircularProgress">
      <AnimatedWrap color={color} size={size} />
    </Wrap>
  );
};
