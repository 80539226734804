import useSSR from 'use-ssr';
import { useCallback } from 'react';

export function useConsentModal() {
  const { isBrowser } = useSSR();

  const openSettings = useCallback(() => {
    if (isBrowser) {
      (window as any)?.OneTrust?.ToggleInfoDisplay();
    }
  }, [isBrowser]);

  return {
    openSettings,
  };
}
