import React from 'react';
import { SVGProps } from '../utils';

const SvgAspa = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510.2 510.2" {...props}>
    <path d="M243.2 354.5c-7.2 0-13-5.8-13-13v-.6c0-16.8 0-42.1 22.4-67.9 14.1-16.2 30.6-28.8 46.6-41 30.8-23.4 57.3-43.6 57.3-83.4 0-24-9.5-44.4-27.6-59.1C311.3 75 286.3 67 258.8 67 156.2 67 151 146.8 151 162.8c0 7.2-5.8 13-13 13s-13-5.8-13-13c0-28.9 9.9-57.5 27.1-78.4C168.5 64.6 200.2 41 258.8 41c33.5 0 64.3 10 86.6 28.3 24 19.5 37.1 47.7 37.1 79.3 0 52.6-34.3 78.8-67.6 104.1-14.9 11.4-30.4 23.1-42.7 37.4-16.1 18.5-16.1 35-16.1 50.9v.6c.1 7.1-5.7 12.9-12.9 12.9zm-1.7 112.4c-14.1 0-25.5-11.5-25.5-25.5 0-14.1 11.5-25.5 25.5-25.5 14.1 0 25.5 11.4 25.5 25.5 0 14-11.4 25.5-25.5 25.5z" />
  </svg>
);

export default SvgAspa;
