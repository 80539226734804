import produce from 'immer';

import { ActionTypes } from './player.actions';
import { Asset } from '../../models/asset';
import { AudioTrack } from '../../models/audiotrack';
import { Subtitle } from '../../models/subtitle';

export interface PlayerState {
  assetPlaying: Asset;
  queuedMedia: Asset; // Queued live asset waiting for selection
  queuedMediaID: number | null; // Queued asset id needed to start from beginning
  nextAsset: Asset;
  playerLoaded: boolean;
  chromecast: {
    assetPlaying: Asset;
    appName: string;
    friendlyName: string;
    available: boolean;
    sessionAvailable: boolean;
    session: any; // Chromecast session object
    receiverAvailable: boolean;
    isLivePlaying: boolean;
    currentMedia: any; // Chromecast media object
    subtitleFormat: string;
    subtitles: Subtitle[];
    audioTracks: AudioTrack[];
    currentPosition: number;
    seeking: boolean;
    currentLivePosition: number;
    currentPositionAndDuration: any;
    mediaFinished: boolean;
    activeAudioTrack: AudioTrack;
    activeSubtitle: Subtitle;
    liveMedia: {
      range: {
        start: number;
        end: number;
        duration: number;
      };
      currentTime: number;
      progress: number;
      isAtLivePosition: number;
    };
    hqFilterEnabled: boolean;
    pauseTime: number;
    adBreakOnGoing: boolean;
    adBreakClipData: any;
    isUHDCapable: boolean;
  };
  livecenter: {
    cameraSelectorOpen: boolean;
    cameraSelectorPlayer: number;
    chromecastCameraSelectorOpen: boolean;
    activeInstance: {
      playerInstance: any;
      videoModel: any;
    };
    playerAssets: {
      1: number;
      2: number;
      3: number;
      4: number;
    };
  };
}

export const initialPlayerState: PlayerState = {
  assetPlaying: null,
  queuedMedia: null,
  queuedMediaID: null,
  nextAsset: null,
  playerLoaded: false,
  chromecast: {
    assetPlaying: null,
    subtitleFormat: null,
    subtitles: [],
    audioTracks: [],
    currentPosition: null,
    seeking: false,
    currentLivePosition: null,
    currentPositionAndDuration: null,
    activeAudioTrack: null,
    activeSubtitle: null,
    appName: '',
    friendlyName: '',
    available: false,
    sessionAvailable: false,
    session: {},
    receiverAvailable: false,
    isLivePlaying: false,
    currentMedia: null,
    liveMedia: null,
    mediaFinished: false,
    hqFilterEnabled: false,
    pauseTime: 0,
    adBreakOnGoing: false,
    adBreakClipData: null,
    isUHDCapable: false,
  },
  livecenter: {
    cameraSelectorOpen: false,
    cameraSelectorPlayer: null,
    chromecastCameraSelectorOpen: false,
    playerAssets: {
      1: null,
      2: null,
      3: null,
      4: null,
    },
    activeInstance: {
      playerInstance: null,
      videoModel: null,
    },
  },
};

const playerReducer = (state = initialPlayerState, action: any) => {
  switch (action.type) {
    case ActionTypes.PLAY_ASSET:
      return produce(state, (draft) => {
        const { asset, playInChromecast } = action.payload;

        draft.assetPlaying = asset;
        draft.chromecast.assetPlaying = playInChromecast ? asset : null;
        draft.chromecast.pauseTime = 0;
      });
    case ActionTypes.ENQUEUE_CHROMECAST_ASSET:
      return produce(state, (draft) => {
        const { asset } = action.payload;

        draft.queuedMedia = asset;
      });
    case ActionTypes.CLEAR_CHROMECAST_QUEUED_ASSET:
      return produce(state, (draft) => {
        draft.queuedMedia = null;
      });
    case ActionTypes.QUEUE_MEDIA_FROM_START:
      return produce(state, (draft) => {
        draft.queuedMediaID = action.payload;
      });
    case ActionTypes.CLEAR_ASSET:
      return produce(state, (draft) => {
        draft.assetPlaying = null;
        draft.chromecast.assetPlaying = null;
      });
    case ActionTypes.SET_NEXT_ASSET:
      return produce(state, (draft) => {
        draft.nextAsset = action.payload.nextAsset;
      });
    case ActionTypes.SET_SUBTITLE_FORMAT:
      return produce(state, (draft) => {
        draft.chromecast.subtitleFormat = action.payload.format;
      });
    case ActionTypes.SET_CHROMECAST_AVAILABILITY:
      return produce(state, (draft) => {
        draft.chromecast.available = action.payload.status;
      });
    case ActionTypes.SET_CHROMECAST_RECEIVER_AVAILABILITY:
      return produce(state, (draft) => {
        draft.chromecast.receiverAvailable = action.payload.status;
      });
    case ActionTypes.SET_CHROMECAST_MEDIA_FINISHED:
      return produce(state, (draft) => {
        draft.chromecast.mediaFinished = action.payload.isFinished;
      });
    case ActionTypes.SET_CHROMECAST_MEDIA_SEEKING:
      return produce(state, (draft) => {
        draft.chromecast.seeking = action.payload.seeking;
      });
    case ActionTypes.SET_CHROMECAST_SESSION:
      return produce(state, (draft) => {
        draft.chromecast.session = action.payload.session;
      });
    case ActionTypes.SET_CHROMECAST_SESSION_STATUS:
      return produce(state, (draft) => {
        const { status } = action.payload;

        if (status === 'available') {
          draft.chromecast.sessionAvailable = true;
        } else {
          draft.chromecast.sessionAvailable = false;
        }
      });
    case ActionTypes.SET_CHROMECAST_SESSION_NAME:
      return produce(state, (draft) => {
        draft.chromecast.appName = action.payload.appName;
        draft.chromecast.friendlyName = action.payload.friendlyName;
      });
    case ActionTypes.SET_CHROMECAST_CURRENT_MEDIA:
      return produce(state, (draft) => {
        draft.chromecast.currentMedia = action.payload.currentMedia;
      });
    case ActionTypes.SET_CHROMECAST_CURRENT_POSITION:
      return produce(state, (draft) => {
        draft.chromecast.currentPosition = action.payload.position;
      });
    case ActionTypes.SET_CHROMECAST_CURRENT_LIVE_POSITION:
      return produce(state, (draft) => {
        draft.chromecast.currentLivePosition = action.payload.position;
      });
    case ActionTypes.SET_CHROMECAST_CURRENT_POSITION_AND_DURATION:
      return produce(state, (draft) => {
        draft.chromecast.currentPositionAndDuration = action.payload.positionAndDuration;
      });
    case ActionTypes.SET_CHROMECAST_SUBTITLES:
      return produce(state, (draft) => {
        draft.chromecast.subtitles = action.payload.subtitles;
      });
    case ActionTypes.SET_CHROMECAST_ACTIVE_AUDIOTRACK:
      return produce(state, (draft) => {
        draft.chromecast.activeAudioTrack = action.payload.audioTrack;
      });
    case ActionTypes.SET_CHROMECAST_AUDIO_TRACKS:
      return produce(state, (draft) => {
        draft.chromecast.audioTracks = action.payload.audioTracks;
      });
    case ActionTypes.SET_CHROMECAST_HQ_FILTER:
      return produce(state, (draft) => {
        draft.chromecast.hqFilterEnabled = action.payload.status;
      });
    case ActionTypes.SET_CHROMECAST_UHD_CAPABILITY:
      return produce(state, (draft) => {
        draft.chromecast.isUHDCapable = action.payload.status;
      });
    case ActionTypes.SET_CHROMECAST_ADBREAK_ONGOING:
      return produce(state, (draft) => {
        draft.chromecast.adBreakOnGoing = action.payload.status;
      });
    case ActionTypes.SET_CHROMECAST_ADBREAK_CLIP_DATA:
      return produce(state, (draft) => {
        draft.chromecast.adBreakClipData = action.payload.adBreakClipData;
      });
    case ActionTypes.SET_CHROMECAST_ACTIVE_SUBTITLE:
      return produce(state, (draft) => {
        draft.chromecast.activeSubtitle = action.payload.subtitle;
      });
    case ActionTypes.SET_CHROMECAST_LIVE_MEDIA:
      return produce(state, (draft) => {
        draft.chromecast.liveMedia = action.payload.liveMedia;
        draft.chromecast.isLivePlaying = !!action.payload.liveMedia;
      });
    case ActionTypes.TOGGLE_LIVECENTER_CAMERA_SELECTOR:
      return produce(state, (draft) => {
        if (action.payload.forChromecast) {
          draft.livecenter.chromecastCameraSelectorOpen = !draft.livecenter.chromecastCameraSelectorOpen;
        } else {
          const newStatus = !draft.livecenter.cameraSelectorOpen;

          if (!newStatus) {
            draft.livecenter.cameraSelectorPlayer = null;
          } else {
            draft.livecenter.cameraSelectorPlayer = action.payload.playerNumber;
          }

          draft.livecenter.cameraSelectorOpen = !draft.livecenter.cameraSelectorOpen;
        }
      });
    case ActionTypes.SET_LIVECENTER_CAMERA_SELECTOR_STATUS:
      return produce(state, (draft) => {
        if (action.payload.forChromecast) {
          draft.livecenter.chromecastCameraSelectorOpen = action.payload.status;
        } else {
          if (!action.payload.status) {
            draft.livecenter.cameraSelectorPlayer = null;
          } else {
            draft.livecenter.cameraSelectorPlayer = action.payload.playerNumber;
          }

          draft.livecenter.cameraSelectorOpen = action.payload.status;
        }
      });
    case ActionTypes.SET_LIVECENTER_ACTIVE_INSTANCE:
      return produce(state, (draft) => {
        draft.livecenter.activeInstance.playerInstance = action.payload.instance;
        draft.livecenter.activeInstance.videoModel = action.payload.videoModel;
      });
    case ActionTypes.SET_PLAYER_LOADED_STATUS:
      return produce(state, (draft) => {
        draft.playerLoaded = action.payload.status;
      });
    case ActionTypes.SET_LIVECENTER_PLAYER_ASSET:
      return produce(state, (draft) => {
        draft.livecenter.playerAssets[action.payload.player] = action.payload.assetId;
      });
    case ActionTypes.SET_CHROMECAST_STREAM_TYPE:
      return produce(state, (draft) => {
        draft.chromecast.isLivePlaying = Boolean(draft.chromecast.liveMedia && action.payload.type === 'LIVE');
      });
    case ActionTypes.INCREASE_CHROMECAST_PAUSE_TIME:
      return produce(state, (draft) => {
        draft.chromecast.pauseTime = draft.chromecast.pauseTime + 1;
      });
    case ActionTypes.RESET_CHROMECAST_PAUSE_TIME:
      return produce(state, (draft) => {
        draft.chromecast.pauseTime = 0;
      });
    default:
      return state;
  }
};

export default playerReducer;
