import AbortController from 'abort-controller';
import assign from 'lodash/assign';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import startsWith from 'lodash/startsWith';
import { getConfig } from './conf';
import { cache } from './cache';
import { devTime, devTimeEnd } from './utils';

export interface FetchOpts {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: BodyInit | null;
  params?: URLSearchParams | Record<string, any>;
  headers?: HeadersInit;
  withCredentials?: boolean;
  cors?: boolean | null;
  timeout?: number;
  signal?: AbortSignal;
}

export type FetchJSONOpts = FetchOpts & {
  throwErrors?: boolean;
};

export type HttpError = Error & {
  statusCode: number;
};

const defaultOpts: FetchOpts = {
  method: 'GET',
  withCredentials: false,
  cors: null,
};

export async function fetchRaw(url: string, opts: FetchOpts = defaultOpts): Promise<Response> {
  const urlWithParams = buildURL(url, opts ? opts.params : undefined);

  const reqOpts: RequestInit = {
    method: opts.method,
    signal: opts.signal,
  };

  reqOpts.headers = opts.headers || {};

  reqOpts.headers['Origin'] = getOrigin();

  if (opts.withCredentials === true) {
    reqOpts.credentials = 'include';
  } else if (opts.withCredentials === false) {
    reqOpts.credentials = 'omit';
  }

  if (opts.cors === true) {
    reqOpts.mode = 'cors';
  } else if (opts.cors === false) {
    reqOpts.mode = 'no-cors';
  }

  if (opts.body) {
    reqOpts.body = opts.body;
  }

  // Add custom User-Agent to server-side requests
  if (typeof window === 'undefined') {
    reqOpts.headers['User-Agent'] = `owlet-svod/${process.env.npm_package_version || '?'}`;
  }

  if (opts.timeout) {
    let timeout: any;
    let controller: AbortController;

    // Note: This does not actually abort fetch-requests in Safari
    try {
      controller = new AbortController();
      reqOpts.signal = controller.signal;
    } catch (e) {
      // Ignore error if AbortController is unavailable
    }

    return Promise.race([
      fetch(urlWithParams, reqOpts),
      new Promise<never>((resolve, reject) => {
        timeout = setTimeout(() => {
          reject(new Error(`Request timed out (${opts.timeout}ms): ${opts.method || 'GET'} ${url}`));
          controller?.abort();
        }, opts.timeout);
      }),
    ]).finally(() => {
      clearTimeout(timeout);
    });
  }

  return fetch(urlWithParams, reqOpts);
}

/**
 * Fetch JSON-formatted data from the given URL. You can give additional query params and headers in the `opts`
 * parameter (even when the URL already contains query params).
 *
 * @param url
 * @param opts
 */
export async function fetchJSON<T>(url: string, opts: FetchJSONOpts = defaultOpts): Promise<T> {
  const headers = assign({}, opts.headers);
  const headerNames = keys(headers).map((name) => name.toLowerCase());

  if (!includes(headerNames, 'accept')) {
    headers['Accept'] = 'application/json;v=2';
  }
  if (!includes(headerNames, 'content-type')) {
    headers['Content-Type'] = 'application/json';
  }

  const resp = await fetchRaw(url, assign({}, opts, { headers }));

  if (opts.throwErrors !== false) {
    if (!resp.ok) {
      const message = `Error in fetching ${resp.url}, server returned ${resp.status}`;
      console.error(message, resp);
      const error = new Error(message) as HttpError;
      error.statusCode = resp.status;
      throw error;
    }
  }

  const body = await resp.text();

  // Handle empty response that would cause a JSON parse error
  if (!body) {
    return null;
  }

  try {
    return JSON.parse(body);
  } catch (err) {
    // Parsing body JSON failed (non-JSON response)
    return null;
  }
}

// If data contains this property, don't cache it
export const SKIP_CACHE = '__skip_cache';

export async function withCache<T = any>(
  fetcher: () => Promise<T>,
  cacheKey: string,
  cacheSeconds = 60 * 15
): Promise<T> {
  devTime(cacheKey);

  const getData = async () => {
    const cachedData = cache.get<T>(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    try {
      const data = await fetcher();

      if (!isNil(data) && !(SKIP_CACHE in data)) {
        cache.set(cacheKey, data, cacheSeconds);
      }

      return data;
    } catch (e) {
      const oldCachedData = cache.get<T>(cacheKey, true);
      if (oldCachedData) {
        console.error(`Fetch error, using old cached version (cacheKey: ${cacheKey})`, e);
        return oldCachedData;
      }

      throw e;
    }
  };

  const result = await getData();

  devTimeEnd(cacheKey);

  return result;
}

interface UrlBuilderOptions {
  dedupeParams?: boolean;
}

export function buildURL(
  baseURL: string,
  params?: URLSearchParams | Record<string, any>,
  opts?: UrlBuilderOptions
): string {
  if (!baseURL) {
    return null;
  }

  const { dedupeParams }: UrlBuilderOptions = assign({ dedupeParams: false }, opts);

  let url: URL;
  const origin = getOrigin();
  let pathOnly = false;

  if (!startsWith(baseURL, 'http')) {
    pathOnly = true;
    url = new URL(`${origin}${baseURL}`);
  } else {
    url = new URL(baseURL);
  }

  const searchParams = new URLSearchParams(url.search);

  const setValue = (key, value) => {
    if (dedupeParams) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  if (params) {
    if (params instanceof URLSearchParams) {
      params.forEach((val, key) => {
        setValue(key, val);
      });
    } else {
      keys(params).forEach((key) => {
        const val = params[key];
        setValue(key, isNil(val) ? '' : val);
      });
    }
  }

  url.search = searchParams.toString();

  const urlString = url.toString();

  if (pathOnly && startsWith(urlString, origin)) {
    return urlString.substring(origin.length);
  }

  return urlString;
}

export function getOrigin(): string {
  if (typeof window === 'undefined') {
    return getConfig().siteUrl;
  }

  const { location } = window;
  return location.origin || `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}`;
}

/**
 * Wrapper for setting a timeout in milliseconds for an async task. Uses Promise.race to either reject with an error
 * after the given timeout or resolve/reject with the task.
 *
 * @param task
 * @param timeoutInMillis
 * @param timeoutError
 */
export async function withTimeout<T = any>(
  task: Promise<T>,
  timeoutInMillis: number,
  timeoutError = new Error('Task timed out')
): Promise<T> {
  return Promise.race([
    new Promise<never>((resolve, reject) => {
      setTimeout(() => reject(timeoutError), timeoutInMillis);
    }),
    task,
  ]);
}
