import { createSelector, Selector } from 'reselect';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { ViewingHistoryState } from './viewing-history.reducers';
import { ViewingHistoryItem } from '../../models/viewingHistoryItem';

const history = (state) => state.viewingHistory.history;
const categoriesLoaded = (state) => state.viewingHistory.categoriesLoaded;
const categoryId = (state, props) => (props.categoryId ? props.categoryId : null);
const assetId = (state, props) => (props.assetId ? props.assetId : null);

export const selectViewingHistoryState = () => (state: any) => state.viewingHistory;

export const selectViewingHistory: Selector<ViewingHistoryState, ViewingHistoryItem[]> = createSelector(
  selectViewingHistoryState(),
  (state) => state.history
);
export const selectViewingHistoryError = createSelector(selectViewingHistoryState(), (state) => state.error);

export const isCategoryProgressLoaded = createSelector(
  [categoriesLoaded, categoryId],
  (categoriesLoaded, categoryId) => {
    return categoryId ? categoriesLoaded.indexOf(categoryId) !== -1 : false;
  }
);

export const selectAssetProgress = createSelector([history, assetId], (history, assetId) => {
  return assetId ? find(history, (item) => Number(item.assetId) === Number(assetId)) : false;
});

export const selectCategoryProgress = createSelector([history, categoryId], (history, categoryId) => {
  return assetId ? filter(history, (item) => Number(item.categoryId) === Number(categoryId)) : false;
});
