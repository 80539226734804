import { useContext } from 'react';
import isString from 'lodash/isString';
import { ModalOpts } from './models';
import { ModalContext } from './ModalContext';

export interface UseOwletModalResponse {
  openModal: (modal: ModalOpts) => void;
  closeModal: (modal?: ModalOpts | string) => void;
  isModalOpen: boolean;
}

/**
 * Custom hook for displaying modals.
 *
 * Modals are stackable, so if there are multiple pieces of code that show modals, the next modal is shown when the
 * previous one is closed. The calling component must take care of the correct order of the modals.
 *
 * When opening a modal from another modal, set `replace: true` to close the previous modal and replace it with the
 * new one.
 *
 * @example
 * // Display modal from useEffect() with onClose
 * const { openModal } = useOwletModal();
 * const { query, replace, pathname, asPath } = useRouter();
 *
 * useEffect(() => {
 *   if (query.maksu === 'maksettu') {
 *     openModal({
 *       name: 'PaymentFeedbackModal',
 *       modal: <PaymentFeedbackModal />,
 *       onClose: () => {
 *         replace(pathname, cleanUrl(asPath), { shallow: true });
 *       },
 *     });
 *   }
 * }, [query.maksu])
 *
 * @example
 * // Force display a modal
 * const isError = useSelector(selectIsOrdersError);
 *
 * useEffect(() => {
 *   if (isError) {
 *     openModal({
 *       name: 'OrdersLoadingError',
 *       modal: <div>Loading orders failed</div>,
 *       closeButtonVisible: false,
 *       closeOnEsc: false,
 *       closeOnOutsideClick: false,
 *       force: true // Replace possibly shown another modal
 *     })
 *   }
 * }, [isError])
 */
export function useOwletModal(): UseOwletModalResponse {
  const { modals, pushModal, popModal } = useContext(ModalContext);

  return {
    openModal: pushModal,
    closeModal: (modal?: ModalOpts | string) => {
      if (isString(modal)) {
        popModal(modal);
      } else if (modal?.modal) {
        // Prevent sending MouseEvents etc. as parameter
        popModal(modal);
      } else {
        popModal();
      }
    },
    isModalOpen: modals.length > 0,
  };
}
