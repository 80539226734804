import { CustomerSupportArticle, CustomerSupportCategory } from '../../models/customerSupport';
import produce from 'immer';
import { ActionTypes } from './customer-support.actions';

export interface CustomerSupportState {
  articles: CustomerSupportArticle[];
  categories: CustomerSupportCategory[];
  articlesStarted: boolean;
  articlesCompleted: boolean;
  categoriesStarted: boolean;
  categoriesCompleted: boolean;
}

export const initialCustomerSupportState: CustomerSupportState = {
  articles: null,
  categories: null,
  articlesStarted: false,
  articlesCompleted: false,
  categoriesStarted: false,
  categoriesCompleted: false,
};

const customerSupportReducer = (state = initialCustomerSupportState, action: any) => {
  switch (action.type) {
    case ActionTypes.ARTICLES_REQUEST:
      return produce(state, (draft) => {
        draft.articlesStarted = true;
      });
    case ActionTypes.ARTICLES_SUCCESS:
      return produce(state, (draft) => {
        draft.articles = action.payload;
        draft.articlesCompleted = true;
      });
    case ActionTypes.ARTICLES_FAIL:
      console.log('customer support: articles fail');
      return produce(state, (draft) => {
        draft.articles = [];
        draft.articlesCompleted = true;
      });
    case ActionTypes.CATEGORIES_REQUEST:
      return produce(state, (draft) => {
        draft.categoriesStarted = true;
      });
    case ActionTypes.CATEGORIES_SUCCESS:
      return produce(state, (draft) => {
        draft.categories = action.payload;
        draft.categoriesCompleted = true;
      });
    case ActionTypes.CATEGORIES_FAIL:
      console.log('customer support: categories fail');
      return produce(state, (draft) => {
        draft.categories = [];
        draft.categoriesCompleted = true;
      });
    default:
      return state;
  }
};

export default customerSupportReducer;
