/* istanbul ignore file */

import { combineReducers } from 'redux';

import AppReducers, { AppState, initialAppState } from './app/app.reducers';
import FeedbackReducers, { FeedbackState, initialFeedbackState } from './feedback/feedback.reducers';
import ProductsReducers, { initialProductsState, ProductsState } from './products/products.reducers';
import UserReducers, { initialUserState, UserState } from './user/user.reducers';
import ViewingHistoryReducers, {
  initialViewingHistoryState,
  ViewingHistoryState,
} from './viewing-history/viewing-history.reducers';
import SeriesReducers, { initialSeriesState, SeriesState } from './series/series.reducers';
import PlayerReducers, { initialPlayerState, PlayerState } from './player/player.reducers';
import NotificationReducers, {
  initialNotificationsState,
  NotificationsState,
} from './notifications/notifications.reducers';
import CustomerSupportReducers, {
  CustomerSupportState,
  initialCustomerSupportState,
} from './customer-support/customer-support.reducers';
import PurchaseReducers, { initialPurchaseState, PurchaseState } from './purchase/purchase.reducers';
import UIReducers, { initialUIState, UIState } from './ui/ui.reducers';

export interface RootState {
  app: AppState;
  ui: UIState;
  feedback: FeedbackState;
  products: ProductsState;
  user: UserState;
  viewingHistory: ViewingHistoryState;
  series: SeriesState;
  player: PlayerState;
  notifications: NotificationsState;
  customerSupport: CustomerSupportState;
  purchase: PurchaseState;
}

export const combinedInitialState: RootState = {
  app: initialAppState,
  ui: initialUIState,
  feedback: initialFeedbackState,
  products: initialProductsState,
  user: initialUserState,
  viewingHistory: initialViewingHistoryState,
  series: initialSeriesState,
  player: initialPlayerState,
  notifications: initialNotificationsState,
  customerSupport: initialCustomerSupportState,
  purchase: initialPurchaseState,
};

export default combineReducers({
  app: AppReducers,
  ui: UIReducers,
  feedback: FeedbackReducers,
  products: ProductsReducers,
  user: UserReducers,
  viewingHistory: ViewingHistoryReducers,
  series: SeriesReducers,
  player: PlayerReducers,
  notifications: NotificationReducers,
  customerSupport: CustomerSupportReducers,
  purchase: PurchaseReducers,
});
