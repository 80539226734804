import { createSelector, Selector } from 'reselect';
import { PlayerState } from './player.reducers';
import { Asset } from '../../models/asset';

export const selectPlayerState = () => (state: any) => state.player;

export const selectAssetPlaying: Selector<PlayerState, Asset> = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.assetPlaying
);

export const selectChromecastAssetPlaying: Selector<PlayerState, Asset> = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.assetPlaying
);

export const selectQueuedAsset: Selector<PlayerState, Asset> = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.queuedMedia
);

export const selectQueueMediaID: Selector<PlayerState, number | null> = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.queuedMediaID
);

export const selectNextAsset = createSelector(selectPlayerState(), (playerState: PlayerState) => playerState.nextAsset);

export const selectSubtitleFormat = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.subtitleFormat
);

export const selectChromecastSubtitles = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.subtitles
);

export const selectActiveAudioTrack = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.activeAudioTrack
);

export const selectChromecastAudioTracks = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.audioTracks
);

export const selectChromecastReceiverAvailability = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.receiverAvailable
);

export const selectChromecastSessionAvailability = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.sessionAvailable
);

export const selectChromecastSession = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.session
);

export const selectChromecastMediaFinished = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.mediaFinished
);

export const selectChromecastCurrentPosition = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.currentPosition
);

export const selectChromecastMediaSeeking = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.seeking
);

export const selectChromecastCurrentLivePosition = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.currentLivePosition
);

export const selectChromecastCurrentPositionAndDuration = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.currentPositionAndDuration
);

export const selectChromecastCurrentMedia = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.currentMedia
);

export const selectReceiverName = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.session.receiver.friendlyName
);

export const selectActiveSubtitle = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.activeSubtitle
);

export const selectChromecastLiveMedia = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.liveMedia
);

export const selectIsLivePlaying = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.isLivePlaying
);

export const selectHQFilter = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.hqFilterEnabled
);

export const hasNextAsset = createSelector(selectPlayerState(), (playerState: PlayerState) => !!playerState.nextAsset);

export const selectPauseTime = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.pauseTime
);

export const selectLivecenterCamSelectorStatus = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.livecenter.cameraSelectorOpen
);

export const selectLivecenterCamSelectorChromecastStatus = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.livecenter.chromecastCameraSelectorOpen
);

export const selectLivecenterActivePlayerInstance = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.livecenter.activeInstance
);

export const selectLivecenterCameraSelectedPlayed = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.livecenter.cameraSelectorPlayer
);

export const selectLivecenterPlayerAssets = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.livecenter.playerAssets
);

export const selectPlayerLoadedStatus = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.playerLoaded
);

export const selectChromecastUHDCapability = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.isUHDCapable
);

export const selectChromecastAdBreakStatus = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.adBreakOnGoing
);

export const selectChromecastAdBreakClipData = createSelector(
  selectPlayerState(),
  (playerState: PlayerState) => playerState.chromecast.adBreakClipData
);
