import { getConfig } from './conf';
import { fetchRaw } from './api';
import get from 'lodash/get';

/**
 * Check user's geoblocking status by requesting an asset (C More tv channel by default).
 *
 * Returns true if user is blocked, false if user is not blocked.
 */
export async function checkGeoBlockStatus(authToken: string): Promise<boolean> {
  // If user is not authenticated, just return false (not blocked)
  if (!authToken) {
    return false;
  }

  const config = getConfig();
  const url = `${config.api.vimondUrl}/api/web/asset/${config.geoblock.checkAssetId}/play`;

  let resp: Response;

  try {
    resp = await fetchRaw(url, {
      headers: {
        Accept: 'application/json;=2',
        Authorization: authToken,
      },
      withCredentials: true,
    });

    if (resp.status === 401 || resp.status === 403) {
      const body = await resp.json();
      const code = get(body, 'error.code');

      if (code === 'ASSET_PLAYBACK_INVALID_GEO_LOCATION') {
        return true;
      }
    }

    return false;
  } catch (e) {
    console.error('Failed to determine geoblock status', e);
    return false;
  }
}
