import React, { FunctionComponent, useRef } from 'react';
import styled, { ThemeProps } from 'styled-components';
import { SortOption } from '../../models/deferred';
import { WHITE15, IconDownArrow, OwletTheme, WHITE, useDocumentEventListener } from 'owlet-ui';
import find from 'lodash/find';
import { usePathRouter } from '../../services/router/router';
import Link from 'next/link';

interface SortOptionsMenuProps {
  sortOptions: SortOption[];
  sortValue?: string;
}

const Wrap = styled.div`
  position: relative;
  margin: 1rem 0;
  user-select: none;
`;

const Trigger = styled.label`
  cursor: pointer;
  color: ${WHITE};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  svg {
    fill: ${WHITE};
    width: 28px;
    margin-left: 0.35rem;
  }
`;

const TriggerCheckbox = styled.input`
  position: absolute;
  left: -9999px;
  opacity: 0;
`;

const Menu = styled.ul`
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 0;
  background: ${WHITE15};
  box-shadow: ${(props: ThemeProps<OwletTheme>) => props.theme.dropShadow};

  ${TriggerCheckbox}:checked ~ & {
    display: block;
  }
`;

const MenuItemLink = styled.a`
  display: block;
  padding: 0.5rem 1rem;
  color: ${WHITE};
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
    color: ${WHITE};
  }
`;

export const SortOptionsMenu: FunctionComponent<SortOptionsMenuProps> = ({
  sortOptions,
  sortValue,
}: SortOptionsMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { currentPathConfig, query, pathname } = usePathRouter();

  let foundSort: SortOption;
  let selectedSort: SortOption;

  if (sortOptions?.length > 1) {
    foundSort = find(sortOptions, { value: sortValue });
    selectedSort = foundSort || sortOptions?.[0];
  }

  const closeMenu = () => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = false;
    }
  };

  // Click outside to close
  useDocumentEventListener('click', (event: MouseEvent) => {
    if (checkboxRef.current && menuRef.current) {
      const isOpen = checkboxRef.current.checked;

      if (isOpen && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    }
  });

  if (!selectedSort || !currentPathConfig) {
    return null;
  }

  return (
    <Wrap ref={menuRef}>
      <TriggerCheckbox type="checkbox" id="sort-checkbox" ref={checkboxRef} tabIndex={-1} />
      <Trigger htmlFor="sort-checkbox">
        {selectedSort.name}
        <IconDownArrow />
      </Trigger>
      <Menu data-test="SortOptions__Menu">
        {sortOptions.map((opt) => {
          const href = {
            pathname,
            query: { ...query, sort: opt.value },
          };

          const as = {
            pathname: currentPathConfig.visibleUrl,
            query: { sort: opt.value },
          };

          return (
            <li key={opt.value}>
              <Link href={href} as={as} passHref>
                <MenuItemLink onClick={closeMenu}>{opt.name}</MenuItemLink>
              </Link>
            </li>
          );
        })}
      </Menu>
    </Wrap>
  );
};
