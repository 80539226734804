import { Target } from './curated';

export interface ProductGroupIds {
  Cmore: number;
  SportPlus: number;
  TotalPlus: number;
  Liigapassi: number;
  Sport: number;
  CmoreDigita: number;
  SportDigita: number;
  TotalDigita: number;
  Hockey: number;
  Hvod: number;
}

export interface CmProduct {
  title: string;
  footnote?: string;
  description?: string[][];
  list: Array<[string, ...string[]]>;
  target: Target;
  product?: Product;
  productType?: ProductType;
  whitelistedForCodes?: boolean;
}

export enum ProductType {
  TeliaVas = 'VAS',
  TeliaDot = 'DOT',
  VIP = 'VIP',
}

export interface Product {
  '@id'?: string;
  id: number; // created in saga
  productGroupId: number;
  description: string;
  enabled: boolean;
  price: number;
  paymentPlan: PaymentPlan;
  productPayments: ProductPayment[];
  productStatus: ProductStatus;
  minimumPeriods: number;
}

export enum ProductStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Hidden = 'HIDDEN',
}

export enum PaymentType {
  Subscription = 'SUBSCRIPTION',
  SinglePeriod = 'SINGLE_PERIOD',
}

export interface PaymentPlan {
  paymentType: PaymentType;
  period: number;
}

export enum PaymentProvider {
  Voucher = 4,
  CreditCard = 5,
  Bank = 25,
  CreditCardProd = 45, // TEST -> PROD ONLY
}

export interface ProductPayment {
  '@id': string;
  enabled: boolean;
  paymentProviderId: PaymentProvider;
  initPrice: number;
  initPeriod: number;
  productPaymentStatus: ProductPaymentStatus;
}

export enum ProductPaymentStatus {
  Enabled = 'ENABLED',
  Hidden = 'HIDDEN',
  Disabled = 'DISABLED',
}

export enum AccessType {
  Paid = 'PAID',
  FreeLogin = 'FREE_LOGIN',
  FreeNoLogin = 'FREE_NOLOGIN',
}

/**
 * Product group reference in assets and categories
 */
export interface AccessProductGroup {
  id: number;
  accessType: AccessType;
}

export interface ProductFeature {
  title: string;
  description: string[];
  enabled: boolean;
}
