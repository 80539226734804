import { CmProduct } from '../../models/product';

export const ActionTypes = {
  PRODUCTS_SUCCESS: 'products/products_success',
  PRODUCTS_FAIL: 'products/products_fail',
};

export const productsSuccess = (products: CmProduct[]) => ({
  type: ActionTypes.PRODUCTS_SUCCESS,
  payload: products,
});

export const productsFail = () => ({
  type: ActionTypes.PRODUCTS_FAIL,
});
