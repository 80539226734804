import React, { FunctionComponent } from 'react';
import { Icon404, RED, Title1 } from 'owlet-ui';
import { ErrorPageWrap, ErrorText } from './styles';
import Link from 'next/link';
import styled from 'styled-components';

const StyledIcon404 = styled(Icon404)`
  width: 30rem;
  max-width: 100%;
  fill: ${RED};
`;

export const NotFound: FunctionComponent = () => {
  return (
    <ErrorPageWrap>
      <Title1>Hukassa?</Title1>
      <StyledIcon404 />
      <ErrorText>
        <p>Hups, sivua tai videota ei löydy.</p>
        <p>
          Jos tarvitset apua,{' '}
          <Link href="/asiakaspalvelu">
            <a>asiakaspalvelumme</a>
          </Link>{' '}
          auttaa sinua.
        </p>
        <p>
          Jos etsit jotakin tiettyä ohjelmaa, kokeile{' '}
          <Link href="/hae">
            <a>hakua</a>
          </Link>
          .
        </p>
        <p>
          <Link href="/">
            <a>Etusivulta</a>
          </Link>{' '}
          löydät uusimmat ja kiinnostavimmat videot.
        </p>
      </ErrorText>
    </ErrorPageWrap>
  );
};
