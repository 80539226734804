import styled, { createGlobalStyle, css } from 'styled-components';
import { greaterThan, lessThan } from './breakpoints';

export const GlobalFontStyles = createGlobalStyle`

@font-face {
  font-family: FyranSans;
  src:
    url('/fonts/fyran-sans-regular.woff2') format('woff2'),
    url('/fonts/fyran-sans-regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}
@font-face {
  font-family: FyranSansBold;
  src:
    url('/fonts/fyran-sans-medium.woff2') format('woff2'),
    url('/fonts/fyran-sans-medium.woff') format('woff');
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}

html {
  font-size: 15px;
  -webkit-font-smoothing: antialiased;

  ${greaterThan('small')`
    font-size: 18px;
  `}
}
`;

export const WEIGHT_REGULAR = 400;
export const WEIGHT_MEDIUM = 700;
export const WEIGHT_BOLD = 900;

export const FAMILY_REGULAR = "'FyranSans', Arial, Helvetica, sans-serif";
export const FAMILY_MEDIUM = "'FyranSansBold', Arial, Helvetica, sans-serif";
export const FAMILY_BOLD = "'FyranSansBold', Arial, Helvetica, sans-serif";
export const FAMILY_EMOJI =
  "'apple color emoji', 'segoe ui emoji', 'notocoloremoji', 'segoe ui symbol', 'android emoji', 'emojisymbols', 'emojione mozilla'";

export const FONT_REGULAR = `normal ${WEIGHT_REGULAR} 1rem ${FAMILY_REGULAR}`;
export const FONT_MEDIUM = `normal ${WEIGHT_MEDIUM} 1rem ${FAMILY_MEDIUM}`;
export const FONT_BOLD = `normal ${WEIGHT_BOLD} 1rem ${FAMILY_BOLD}`;

export function regular() {
  return css`
    font-family: ${FAMILY_REGULAR};
    font-weight: ${WEIGHT_REGULAR};
  `;
}

export function medium() {
  return css`
    font-family: ${FAMILY_MEDIUM};
    font-weight: ${WEIGHT_MEDIUM};
  `;
}

export function bold() {
  return css`
    font-family: ${FAMILY_BOLD};
    font-weight: ${WEIGHT_BOLD};
  `;
}

export const SIZE_BASE = '1rem';
export const SIZE_LARGE = '1.125rem';
export const SIZE_LARGER = '1.2rem';
export const SIZE_SMALL = '0.93rem';
export const SIZE_SMALLER = '0.83rem';
export const SIZE_TINY = '0.75rem';

export function title1() {
  return css`
    margin: 0;
    font-weight: ${WEIGHT_MEDIUM};
    font-family: ${FAMILY_MEDIUM};
    font-size: 2.9rem;
    line-height: 1.2;

    ${lessThan('small')`
      font-size: 1.8rem;
    `}

    ${lessThan('xsmall')`
      font-size: 1.5rem;
    `}
  `;
}

export function title2() {
  return css`
    margin: 0;
    font-weight: ${WEIGHT_MEDIUM};
    font-family: ${FAMILY_MEDIUM};
    font-size: 1.8rem;
    line-height: 1.2;
  `;
}

export function title3() {
  return css`
    margin: 0;
    font-weight: ${WEIGHT_MEDIUM};
    font-family: ${FAMILY_MEDIUM};
    font-size: 1.25rem;
    line-height: 1.2;
  `;
}

export function title4() {
  return css`
    margin: 0;
    font-weight: ${WEIGHT_MEDIUM};
    font-family: ${FAMILY_MEDIUM};
    font-size: ${SIZE_LARGE};
  `;
}

export function title5() {
  return css`
    margin: 0;
    font-weight: ${WEIGHT_MEDIUM};
    font-family: ${FAMILY_MEDIUM};
    font-size: 1rem;
  `;
}

export const Title1 = styled.h1`
  ${title1()};
`;

export const Title2 = styled.h2`
  ${title2()};
`;

export const Title3 = styled.h3`
  ${title3()};
`;

export const Title4 = styled.h4`
  ${title4()};
`;

export const Title5 = styled.h5`
  ${title5()};
`;
