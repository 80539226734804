import React from 'react';
import { SVGProps } from '../utils';

const SvgWarning = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510.2 510.2" {...props}>
    <path d="M467 471H41.9c-6.7 0-12.6-3.3-15.9-8.8-3.3-5.5-3.4-12.3-.2-18.2L238.5 53.1c3.3-6 9.4-9.7 15.9-9.7 6.6 0 12.7 3.7 15.9 9.7L483.1 444c3.2 5.8 3.1 12.6-.2 18.2-3.3 5.5-9.2 8.8-15.9 8.8zM66.7 438h375.5L254.5 92.9 66.7 438z" />
    <path d="M253.5 348.5c-9.1 0-16.5-7.4-16.5-16.5V199c0-9.1 7.4-16.5 16.5-16.5S270 189.9 270 199v133c0 9.1-7.4 16.5-16.5 16.5z" />
    <circle cx={253.5} cy={390} r={16.5} />
  </svg>
);

export default SvgWarning;
