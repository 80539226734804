import React, { FunctionComponent, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

type ContentImageProps = {
  lazyload?: boolean;
  placeholderSrc?: () => string;
} & JSX.IntrinsicElements['img'];

/**
 * Drop-in replacement for <img> element. If loading image fails or src is missing, calls placeholderSrc() method to
 * get an alternative image url. If lazyload=true, uses lazySizes for lazyloading the image.
 */
export const ContentImage: FunctionComponent<ContentImageProps> = ({
  src,
  placeholderSrc,
  lazyload = false,
  className,
  alt,
  onError: originalOnError,
  ...props
}: ContentImageProps) => {
  const [error, setError] = useState<boolean>(!src);
  const previousSrc = useRef<string>(src);

  // If src was empty but gets a value, remove error state
  useEffect(() => {
    if (src !== previousSrc.current && Boolean(src)) {
      setError(false);
      previousSrc.current = src;
    }
  }, [src]);

  const onError = useCallback(
    (evt: SyntheticEvent<HTMLImageElement, Event>) => {
      setError(true);

      if (originalOnError) {
        originalOnError(evt);
      }
    },
    [originalOnError]
  );

  if (error) {
    if (!placeholderSrc) {
      return null;
    }

    // Placeholder image should not be lazyloaded
    const cls = classNames(className, { lazyload: false });

    return <img {...props} src={placeholderSrc()} alt={alt || ''} className={cls} />;
  }

  // Only use lazySizes if loading="lazy" is not set
  const lazySizesEnabled = Boolean(lazyload && props.loading !== 'lazy');

  const cls = classNames(className, { lazyload: lazySizesEnabled });

  if (lazySizesEnabled) {
    return <img {...props} data-src={src} onError={onError} alt={alt || ''} className={cls} />;
  }

  return <img {...props} src={src} onError={onError} alt={alt || ''} className={cls} />;
};
