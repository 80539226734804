import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

interface PersonalInformationProps {
  children: ReactNode;
}

const PersonInfoWrap = styled.span``;

/**
 * Use this component to surround any personal information like email, name, initials, user-id so they can be recognized
 * and masked in analytics systems.
 *
 * @param children
 */

export const PersonalInformation: FunctionComponent<PropsWithChildren<PersonalInformationProps>> = ({
  children,
}: PersonalInformationProps) => {
  return <PersonInfoWrap className="pii-info">{children}</PersonInfoWrap>;
};
